import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';

import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import CompressionForm from './CompressionForm';
import { compressionComplianceSchema, mapForm2Api } from './utils';

const EditCompression = ({ compressionId, handleCancel, handleRefresh }) => {
  const { clearFeedback, setSuccessFeedback, setErrorFeedback } = useFeedback();
  const { patientId } = useParams();
  const { getUser } = useUserDetails();
  const {
    data: compressionData,
    doRequest: getCompression,
    error,
  } = useRequest(api.getCompression);

  const handleSubmit = (values) => {
    clearFeedback();
    api
      .updateCompression({ id: compressionId, data: mapForm2Api(values) })
      .then(() => {
        getUser(patientId);
        setSuccessFeedback('Compression compliance log updated successfully');
        handleCancel();
        handleRefresh();
      })
      .catch(() => {
        setErrorFeedback('Error saving compression compliance log');
      });
  };

  useEffect(() => {
    getCompression(compressionId);
  }, [compressionId, getCompression]);

  useEffect(() => {
    if (!error) return;
    handleCancel();
    setErrorFeedback('Error loading compression compliance log', [error]);
  }, [handleCancel, setErrorFeedback, error]);

  if (!compressionData) return <Loader />;

  return (
    <FormWrapper
      initialValues={compressionData}
      validationSchema={compressionComplianceSchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => <CompressionForm formik={formik} titlePrefix="Edit" />}
    </FormWrapper>
  );
};

EditCompression.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  compressionId: PropTypes.string.isRequired,
};

export default EditCompression;
