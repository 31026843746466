import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { ErrorText, StyledTextField } from 'utils/styles';

const DateTimeSelector = ({
  name,
  label,
  value,
  onChange,
  disabled,
  error,
  isRequired,
}) => {
  const handleChange = (newValue) => {
    onChange({
      target: {
        value: newValue.toISOString(),
        name,
      },
    });
  };

  const selectedValue = React.useMemo(() => {
    if (!value) return null;
    return typeof value === 'string' ? new Date(value) : value;
  }, [value]);

  return (
    <Box width="100%">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDateTimePicker
          id={name}
          name={name}
          label={label}
          value={selectedValue}
          onChange={handleChange}
          disabled={disabled}
          slots={{ textField: StyledTextField }}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              required: isRequired,
              error: error,
              helperText: error,
            },
          }}
        />
      </LocalizationProvider>
      {error && <ErrorText>{error}</ErrorText>}
    </Box>
  );
};

DateTimeSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
};

export default DateTimeSelector;
