import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { ErrorText } from 'utils/styles';

import {
  Description,
  Scale,
  ScaleLabel,
  ValueContainer,
} from './ScaleSelect.styles';

const ScaleSelect = ({ name, value, onChange, error, disabled }) => {
  const handleChange = (e) => {
    onChange({
      target: {
        value: e.target.value,
        name,
      },
    });
  };

  const renderScale = () => {
    let scale = [];
    for (let i = 100; i >= 0; i--) {
      scale.push(
        <div className={`scale-row ${i % 5 === 0 ? 'numbered' : ''}`} key={i}>
          <div className="tick">
            <div
              className={`line 
                ${i % 10 === 0 ? 'lg' : ''} 
                ${i % 10 === 5 ? 'md' : ''}
              `}
            ></div>
          </div>
          <div className="text">{i % 5 === 0 ? i : ''}</div>
        </div>,
      );
    }
    return scale;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
      <Box>
        <Description>
          <li>We would like to know how good or bad your health is TODAY;</li>
          <li>This scale is numbered from 0 to 100;</li>
          <li>
            100 means the best health you can imagine. 0 means the worst health
            you can imagine;
          </li>
          <li>Mark an X on the scale to indicate how your health is TODAY;</li>
          <li>
            Now, please write the number you marked on the scale in the box
            below.
          </li>
        </Description>
        {error && <ErrorText>{error}</ErrorText>}
        <ValueContainer>{value}</ValueContainer>
      </Box>
      <Box sx={{ ml: { xs: 0, md: 10 } }}>
        <ScaleLabel>The best health you can imagine</ScaleLabel>
        <Scale>
          {renderScale()}
          <input
            type="range"
            name={name}
            value={value}
            onChange={handleChange}
            className="slider"
            min="0"
            max="100"
            disabled={disabled}
          />
        </Scale>
        <ScaleLabel>The worst health you can imagine</ScaleLabel>
      </Box>
    </Box>
  );
};

ScaleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ScaleSelect;
