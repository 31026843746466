import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { getFieldProps } from 'utils/forms';

import LogForm from 'components/log-form';
import SingleChoice from 'components/forms/single-choice';

import { formFields } from './utils';

const PainMobilityForm = ({ formik, titlePrefix }) => (
  <LogForm title={`${titlePrefix} Pain, Mobility and Leg Swelling Log`}>
    <Grid item container spacing={2}>
      {formFields.map((field) => (
        <Grid item xs={12} key={field.name}>
          <SingleChoice
            {...field}
            {...getFieldProps({ name: field.name, formik })}
          />
        </Grid>
      ))}
    </Grid>
  </LogForm>
);

PainMobilityForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
  }),
  titlePrefix: PropTypes.string,
};

export default PainMobilityForm;
