import React from 'react';
import PropTypes from 'prop-types';
import { updatePassword } from 'aws-amplify/auth';

import FormWrapper from 'components/forms/form-wrapper';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';

import SettingsPasswordForm from '../form/SettingsPasswordForm';
import { passwordValidation } from '../form/validations';

const SettingsPassword = ({ editing, setEditing, showButton }) => {
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const onPasswordSave = async ({ currentPassword, newPassword }) => {
    clearFeedback();
    try {
      await updatePassword({ oldPassword: currentPassword, newPassword });
      setSuccessFeedback('Password updated with success');
      setEditing(false);
    } catch (err) {
      setErrorFeedback(
        err.name === 'NotAuthorizedException'
          ? 'Incorrect Current Password'
          : getErrorMessage(err),
      );
    }
  };

  return (
    <FormWrapper
      initialValues={{
        currentPassword: '',
        newPassword: '',
      }}
      validationSchema={passwordValidation}
      disabled={!editing}
      confirmStr="Save Password"
      onSubmit={onPasswordSave}
      onCancel={() => setEditing(false)}
      onEdit={showButton ? () => setEditing(true) : null}
      controlsOnTop
    >
      {({ formik }) => (
        <SettingsPasswordForm formik={formik} disabled={!editing} />
      )}
    </FormWrapper>
  );
};

SettingsPassword.propTypes = {
  setEditing: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  showButton: PropTypes.bool.isRequired,
};

export default SettingsPassword;
