export const GET_CHAT_ROOMS_REQUEST = 'GET_CHAT_ROOMS_REQUEST';
export const GET_CHAT_ROOMS_SUCCESS = 'GET_CHAT_ROOMS_SUCCESS';
export const GET_CHAT_ROOMS_FAILURE = 'GET_CHAT_ROOMS_FAILURE';

export const SELECTED_CHAT_ROOM = 'SELECTED_CHAT_ROOM';

export const GET_CHAT_MESSAGES_REQUEST = 'GET_CHAT_MESSAGES_REQUEST';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAILURE = 'GET_CHAT_MESSAGES_FAILURE';

export const SEND_CHAT_MESSAGE_REQUEST = 'SEND_CHAT_MESSAGE_REQUEST';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS';
export const SEND_CHAT_MESSAGE_FAILURE = 'SEND_CHAT_MESSAGE_FAILURE';

export const CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED';

export const CHAT_MESSAGES_READ = 'CHAT_MESSAGES_READ';

export const CHAT_ROOM_WINDOW_OPEN = 'CHAT_ROOM_WINDOW_OPEN';
export const CHAT_ROOM_WINDOW_CLOSE = 'CHAT_ROOM_WINDOW_CLOSE';

export const SET_COLLAPSE_WINDOW = 'SET_COLLAPSE_WINDOW';
