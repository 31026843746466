import React from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import { INPUT_TYPES, OTHER_VALUE, yesNoOptions } from 'utils/constants';
import {
  deviceFields,
  deviceDetailsSection,
  deviceUDISection,
  deviceDetailsContent,
  deviceUDIContent,
  encounterFormsEnum,
  procedureLimbOptions,
  sideSubtitle,
  sidesValues,
} from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';
import Select from 'components/forms/select';
import {
  EndOfProcedureAssessments,
  FieldArrayColumnsSection,
  MultipleColumnsSection,
  ProcedureGeneralSection,
  ProcedureSidesSection,
} from 'components/encounters/procedures-sections';

export const fields = {
  LIMBS: 'limbs',
  DILATATION_PERFORMED: 'performed',
  NUMBER_OF_BALLONS: 'totalNumberOfBalloonsUsed',
  INDICATION_FOR_STENTING: 'indicationForStenting',
  INDICATION_FOR_STENTING_OTHER: 'indicationForStentingOther',
  TOTAL_STENTS: 'totalNumberOfStentsPlaced',
  IVC_FILTER_PLACED: 'placedInConjunctionWithTheProcedureIVCFilter',
  BRAND_OR_PRODUCT_NAME: 'brandOrProductNameIVCFilter',
  IVC_FILTER_TYPE: 'typeOfIVCFilter',
  IVC_FILTER_TYPE_OTHER: 'typeOtherIVCFilter',
  RATIONALE_FOR_IVC_FILTER_PLACEMENT: 'rationaleForIVCFilterPlacement',
  FILTER_REMOVED: 'wasIVCFilterRemovedDuringProcedure',
  WILL_FILTER_BE_REMOVED: 'willIVCFilterBeRemovedDuringLaterProcedure',
  VENOUS_STENTS_PLACED: 'venousStentsPlaced',
  STENT_MODALITY: 'stentModalities',
  STENT_MODALITY_OTHER: 'stentModalitiesOther',
  STENTED_ANATOMY: 'stentAnatomies',
  STENTED_ANATOMY_OTHER: 'stentAnatomyOther',
  STENT_PROCEDURE_NOTES: 'procedureNotes',
};

const DilatationEnum = {
  PreDilatation: 'Pre-Dilatation',
  PostDilatation: 'Post-Dilatation',
};

export const venousStentInitialValues = Object.values(sidesValues).reduce(
  (acc, cur) => ({
    [cur]: { [fields.VENOUS_STENTS_PLACED]: [] },
    ...acc,
  }),
  {},
);

const indicationForStentingOptions = [
  {
    value: 'NonThromboticIliacVeinLesion',
    label: 'Non-Thrombotic Iliac Vein Lesion',
  },
  { value: 'PostThromboticObstruction', label: 'Post-Thrombotic Obstruction' },
  { value: 'OtherCompressiveLesion', label: 'Other Compressive Lesion' },
  { value: 'AcuteDVT', label: 'Acute DVT' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const stentedAnatomiesOptions = [
  { value: 'InferiorVenaCava', label: 'Inferior Vena Cava' },
  { value: 'CommonIliac', label: 'Common Iliac' },
  { value: 'ExternalIliac', label: 'External Iliac' },
  { value: 'CommonFemoral', label: 'Common Femoral' },
  { value: 'Femoral', label: 'Femoral' },
  { value: 'DeepFemoralProfunda', label: 'Deep Femoral (Profunda)' },
  { value: 'Popliteal', label: 'Popliteal' },
  { value: 'AcrossTheInguinalLigament', label: 'Across the Inguinal Ligament' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const stentModalityOptions = [
  {
    value: 'BareMetalStentVenousStent',
    label: 'Bare Metal Stent (Venous Stent)',
  },
  { value: 'BareMetalStentWallstent', label: 'Bare Metal Stent (Wallstent)' },
  {
    value: 'BareMetalStentNitinolArterialStent',
    label: 'Bare Metal Stent (Nitinol Arterial Stent)',
  },
  {
    value: 'DrugElutingStentArterial',
    label: 'Drug Eluting Stent (Arterial)',
  },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const ivcFilterTypeOptions = [
  { value: 'Permanent', label: 'Permanent' },
  { value: 'Retrievable', label: 'Retrievable' },
  { value: 'TemporaryAngelCatheter', label: 'Temporary (Angel Catheter)' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const totalNumberOfStentsPlacedOptions = Array.from(
  { length: 5 },
  (_, i) => i + 1,
).map((key) => ({
  label: key,
  value: key,
}));

const VenousStent = ({ disabled }) => {
  const formik = useFormikContext();

  const sides = React.useMemo(
    () => formik?.values[fields.LIMBS],
    [formik?.values],
  );

  const dilatationSection = React.useCallback(
    (phase) => [
      sideSubtitle,
      {
        label: `${DilatationEnum[phase]} performed?`,
        input: (side) => (
          <Chips
            options={yesNoOptions}
            isRequired
            {...getFieldProps({
              formik,
              name: `${side}.${fields.DILATATION_PERFORMED + phase}`,
              disabled,
              changeOverride: (value) => {
                if (!value) {
                  formik?.setValues(
                    update(formik?.values, {
                      [side]: {
                        [fields.DILATATION_PERFORMED + phase]: { $set: value },
                        [fields.NUMBER_OF_BALLONS + phase]: { $set: null },
                        [deviceFields.COMPANY_NAME + phase]: { $set: null },
                        [deviceFields.BRAND_OR_PRODUCT_NAME + phase]: {
                          $set: null,
                        },
                        [deviceFields.PRODUCT_DIAMETER + phase]: { $set: null },
                        [deviceFields.PRODUCT_LENGTH + phase]: { $set: null },
                        [deviceFields.PRESSURE_INFLATED_TO + phase]: {
                          $set: null,
                        },
                        [deviceFields.DEVICE_IDENTIFIER + phase]: {
                          $set: null,
                        },
                        [deviceFields.PRODUCTION_DATE + phase]: { $set: null },
                        [deviceFields.EXPIRATION_DATE + phase]: { $set: null },
                        [deviceFields.LOT_NUMBER + phase]: { $set: null },
                        [deviceFields.SERIAL_NUMBER + phase]: { $set: null },
                        [fields.STENT_PROCEDURE_NOTES + phase]: { $set: null },
                      },
                    }),
                  );
                }
              },
            })}
          />
        ),
      },
      {
        label: `Total Number of Balloons Used (${DilatationEnum[phase]})?`,
        input: (side) => {
          if (!formik.values[side][fields.DILATATION_PERFORMED + phase])
            return '-';
          return (
            <Input
              type={INPUT_TYPES.NUMERIC}
              {...getFieldProps({
                formik,
                name: `${side}.${fields.NUMBER_OF_BALLONS + phase}`,
                disabled,
              })}
            />
          );
        },
      },
    ],
    [disabled, formik],
  );

  const ivcFilterPlacementSection = React.useMemo(
    () => [
      sideSubtitle,
      {
        label: 'Was an IVC Filter placed in conjunction with the procedure?',
        input: (side) => (
          <Chips
            options={yesNoOptions}
            isRequired
            {...getFieldProps({
              formik,
              name: `${side}.${fields.IVC_FILTER_PLACED}`,
              disabled,
            })}
          />
        ),
      },
      {
        label: 'Type of IVC Filter',
        input: (side) => (
          <Chips
            options={ivcFilterTypeOptions}
            isRequired
            otherField={{
              name: `${side}.${fields.IVC_FILTER_TYPE_OTHER}`,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: `${side}.${fields.IVC_FILTER_TYPE}`,
              disabled,
              changeOverride: (value) => {
                if (value === OTHER_VALUE) {
                  formik?.setValues(
                    update(formik?.values, {
                      [side]: {
                        [fields.IVC_FILTER_TYPE]: { $set: value },
                        [fields.IVC_FILTER_TYPE_OTHER]: { $set: '' },
                      },
                    }),
                  );
                }
              },
            })}
          />
        ),
      },
      {
        label: 'Brand or Product Name',
        input: (side) => (
          <Input
            type={INPUT_TYPES.COMMENT}
            isRequired
            {...getFieldProps({
              formik,
              name: `${side}.${fields.BRAND_OR_PRODUCT_NAME}`,
              disabled,
            })}
          />
        ),
      },
    ],
    [disabled, formik],
  );

  const ivcFilterDetailsSection = React.useMemo(
    () => [
      {
        label: 'Rationale for IVC Filter Placement?',
        input: (side) => (
          <Input
            type={INPUT_TYPES.COMMENT}
            {...getFieldProps({
              formik,
              name: `${side}.${fields.RATIONALE_FOR_IVC_FILTER_PLACEMENT}`,
              disabled,
            })}
          />
        ),
      },
      {
        label: 'Was the IVC Filter removed during the procedure?',
        input: (side) => (
          <Chips
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: `${side}.${fields.FILTER_REMOVED}`,
              disabled,
            })}
          />
        ),
      },
      {
        label: 'Will the IVC Filter be removed during a later procedure?',
        input: (side) => (
          <Chips
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: `${side}.${fields.WILL_FILTER_BE_REMOVED}`,
              disabled,
            })}
          />
        ),
      },
    ],
    [disabled, formik],
  );

  const venousStentingFields = React.useMemo(
    () => ({
      title: 'Venous Stenting',
      items: [
        sideSubtitle,
        {
          label: 'Indication for Stenting',
          input: (side) => (
            <Chips
              options={indicationForStentingOptions}
              isRequired
              isMultiple
              otherField={{
                name: `${side}.${fields.INDICATION_FOR_STENTING_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${side}.${fields.INDICATION_FOR_STENTING}`,
                disabled,
                changeOverride: (value) => {
                  if (!value.includes(OTHER_VALUE)) {
                    formik?.setValues(
                      update(formik?.values, {
                        [side]: {
                          [fields.INDICATION_FOR_STENTING]: { $set: value },
                          [fields.INDICATION_FOR_STENTING_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          ),
        },
        {
          label: 'Total Number of Stents Placed',
          input: (side, push, pop) => (
            <Select
              options={totalNumberOfStentsPlacedOptions}
              isRequired
              {...getFieldProps({
                formik,
                name: `${side}.${fields.TOTAL_STENTS}`,
                disabled,
                changeOverride: (value) => {
                  const prevValue =
                    formik.values[side][fields.TOTAL_STENTS] || 0;
                  if (prevValue < value) {
                    for (let i = prevValue; i < value; i++) {
                      push({
                        [fields.STENT_MODALITY]: [],
                        [fields.STENT_MODALITY_OTHER]: null,
                        [fields.STENTED_ANATOMY]: [],
                        [fields.STENTED_ANATOMY_OTHER]: null,
                        [deviceFields.COMPANY_NAME]: null,
                        [deviceFields.BRAND_OR_PRODUCT_NAME]: null,
                        [deviceFields.PRODUCT_DIAMETER]: null,
                        [deviceFields.PRODUCT_LENGTH]: null,
                        [deviceFields.PRESSURE_INFLATED_TO]: null,
                        [deviceFields.DEVICE_IDENTIFIER]: null,
                        [deviceFields.PRODUCTION_DATE]: null,
                        [deviceFields.EXPIRATION_DATE]: null,
                        [deviceFields.LOT_NUMBER]: null,
                        [deviceFields.SERIAL_NUMBER]: null,
                        [fields.STENT_PROCEDURE_NOTES]: null,
                      });
                    }
                  } else {
                    for (let i = value; i < prevValue; i++) pop();
                  }
                },
              })}
            />
          ),
        },
      ],
    }),
    [disabled, formik],
  );

  const deviceDetailsExtraSection = deviceDetailsContent.map(
    ({ name, label, type, isRequired = true, adornment }) =>
      (side, i) => (
        <Input
          label={label}
          type={type || INPUT_TYPES.SHORT_TEXT}
          isRequired={isRequired}
          endAdornment={
            adornment && (
              <InputAdornment position="end">{adornment}</InputAdornment>
            )
          }
          {...getFieldProps({
            formik,
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${name}`,
            disabled,
          })}
        />
      ),
  );

  const deviceUDIExtraSection = deviceUDIContent.map(
    ({ name, label, mask }) =>
      (side, i) => (
        <InputMask
          mask={mask}
          maskChar=" "
          {...getFieldProps({
            formik,
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${name}`,
            disabled,
          })}
        >
          {() => (
            <Input
              name={`${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${name}`}
              label={label}
              disabled={disabled}
            />
          )}
        </InputMask>
      ),
  );

  const extraFields = React.useMemo(
    () => [
      (_, i) => `Stent #${i + 1}`,
      (side, i) => (
        <Chips
          label="Stented Anatomy"
          options={stentedAnatomiesOptions}
          isRequired
          isMultiple
          otherField={{
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${fields.STENTED_ANATOMY_OTHER}`,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${fields.STENTED_ANATOMY}`,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [side]: {
                      [fields.VENOUS_STENTS_PLACED]: {
                        [i]: {
                          [fields.STENTED_ANATOMY]: { $set: value },
                          [fields.STENTED_ANATOMY_OTHER]: { $set: '' },
                        },
                      },
                    },
                  }),
                );
              }
            },
          })}
        />
      ),
      (side, i) => (
        <Chips
          label="Stent Modality"
          options={stentModalityOptions}
          isRequired
          isMultiple
          otherField={{
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${fields.STENT_MODALITY_OTHER}`,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${fields.STENT_MODALITY}`,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [side]: {
                      [fields.VENOUS_STENTS_PLACED]: {
                        [i]: {
                          [fields.STENT_MODALITY]: { $set: value },
                          [fields.STENT_MODALITY_OTHER]: { $set: '' },
                        },
                      },
                    },
                  }),
                );
              }
            },
          })}
        />
      ),
      ...deviceDetailsExtraSection,
      ...deviceUDIExtraSection,
      (side, i) => (
        <Input
          label="Additional Notes"
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.VENOUS_STENTS_PLACED}.${i}.${fields.STENT_PROCEDURE_NOTES}`,
            disabled,
          })}
        />
      ),
    ],
    [deviceDetailsExtraSection, deviceUDIExtraSection, formik, disabled],
  );

  const dilatationHidden = React.useCallback(
    (side, suffix) =>
      !formik.values[side][fields.DILATATION_PERFORMED + suffix],
    [formik.values],
  );

  const dilatationSections = [
    {
      title: 'Venoplasty Procedure',
      subtitle: 'Pre-Dilatation',
      items: dilatationSection('PreDilatation'),
    },
    {
      subtitle: 'Device Details',
      items: deviceDetailsSection(
        formik,
        disabled,
        'PreDilatation',
        dilatationHidden,
      ),
    },
    {
      subtitle: 'Device UDI (Bar Code)',
      items: deviceUDISection(
        formik,
        disabled,
        'PreDilatation',
        dilatationHidden,
      ),
    },
    {
      subtitle: 'Post-Dilatation',
      items: dilatationSection('PostDilatation'),
    },
    {
      subtitle: 'Device Details',
      items: deviceDetailsSection(
        formik,
        disabled,
        'PostDilatation',
        dilatationHidden,
      ),
    },
    {
      subtitle: 'Device UDI (Bar Code)',
      items: deviceUDISection(
        formik,
        disabled,
        'PostDilatation',
        dilatationHidden,
      ),
    },
  ];

  const ivcFilterSections = [
    {
      title: 'IVC Filter',
      subtitle: 'IVC Filter Placement',
      items: ivcFilterPlacementSection,
    },
    {
      subtitle: 'Device UDI (Bar Code)',
      items: deviceUDISection(formik, disabled, 'IVCFilter'),
    },
    {
      subtitle: 'IVC Filter Details',
      items: ivcFilterDetailsSection,
    },
  ];

  return (
    <>
      <ProcedureGeneralSection disabled={disabled} />
      <FormSectionTitle>Venous Stent Limb</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={8}>
          <Chips
            label="Stent Procedure Limb"
            options={procedureLimbOptions}
            isRequired
            isMultiple
            {...getFieldProps({
              formik,
              name: fields.LIMBS,
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <ProcedureSidesSection sides={sides} disabled={disabled} />
      <MultipleColumnsSection
        sides={sides}
        sidesSections={dilatationSections}
      />
      <FieldArrayColumnsSection
        sides={sides}
        generalFields={venousStentingFields}
        extraFields={extraFields}
      />
      <MultipleColumnsSection sides={sides} sidesSections={ivcFilterSections} />
      <EndOfProcedureAssessments
        encounter={encounterFormsEnum.VENOUS_STENT}
        disabled={disabled}
      />
    </>
  );
};

export default VenousStent;

VenousStent.propTypes = { disabled: PropTypes.bool };
