import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import api from 'core/api';
import useUserDetails from 'hooks/useUserDetails';
import useRequest from 'hooks/useRequest';

import AssessmentForm from './assessment-form/AssessmentForm';
import { AssessmentsElements } from './utils';
import {
  AssessmentButtonGroup,
  Wrapper,
  FormContainer,
  AssessmentButton,
} from './ECRF.styles';

const assessmentLabels = {
  PatientScreening: 'patientScreening',
  PatientMedicalStatus: 'patientMedicalStatus',
  WoundTreatment: 'woundTreatment',
  WoundAssessment: 'woundAssessment',
};

const ECRF = () => {
  const [form, setForm] = useState('patientScreening');
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);
  const { data: assessmentData, doRequest } = useRequest(api.getAssessmentECRF);

  useEffect(() => {
    doRequest(patientId, { assessmentType: form });
  }, [doRequest, form, patientId]);

  return (
    <Wrapper>
      <AssessmentButtonGroup>
        {user?.patient?.ecrfAssessmentTypes?.map((assessment) => {
          return (
            <AssessmentButton
              key={assessmentLabels[assessment]}
              selected={assessmentLabels[assessment] === form}
              onClick={() => setForm(assessmentLabels[assessment])}
              fullWidth
            >
              {AssessmentsElements[assessmentLabels[assessment]]?.name}
            </AssessmentButton>
          );
        })}
      </AssessmentButtonGroup>
      <FormContainer>
        {assessmentData == null ||
        Object.keys(assessmentData ?? {})[0] !== form ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <AssessmentForm assessment={form} values={assessmentData} />
        )}
      </FormContainer>
    </Wrapper>
  );
};

export default ECRF;
