import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import {
  formatISOString,
  US_DATE_FORMAT,
  DEFAULT_FIELD_FORMAT,
} from 'utils/date';

import Loader from 'components/loader';
import Table from 'components/table';
import Title from 'components/titles/Title';

const tableHeader = [
  { title: 'Completed' },
  { title: 'Completed Date' },
  { title: 'Start Date' },
  { title: 'End Date' },
];

const TaskTable = ({ active, title }) => {
  const { doRequest, data: taskData } = useRequest(api.getTaskRuleTasks);
  const { ruleId } = useParams();
  const [page, setPage] = useState(1);

  useEffect(() => {
    doRequest(ruleId, {
      Active: active,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
    });
  }, [doRequest, ruleId, active, page]);

  const rows = useMemo(() => {
    if (!taskData?.data?.length) return [{ data: ['-', '-', '-', '-'] }];
    return taskData.data.map((row) => ({
      data: [
        row.completedAt ? 'Yes' : 'No',
        row.completedAt
          ? formatISOString(row.completedAt, DEFAULT_FIELD_FORMAT)
          : '-',
        formatISOString(row.startDate, US_DATE_FORMAT),
        formatISOString(row.endDate, US_DATE_FORMAT),
      ],
      id: row.id,
    }));
  }, [taskData]);

  return (
    <Box mb={active ? 3 : 0}>
      <Title type="h2" isBold hasGutter>
        {title}
      </Title>
      {taskData ? (
        <Table
          header={tableHeader}
          rows={rows}
          minimumRows={1}
          page={page}
          totalPages={taskData?.pageCount}
          handlePageChange={(_, p) => setPage(p)}
        />
      ) : (
        <Loader />
      )}
    </Box>
  );
};

TaskTable.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const TaskRuleHistory = () => (
  <>
    <TaskTable active={true} title="Scheduled Tasks" />
    <TaskTable active={false} title="Historical Tasks" />
  </>
);

export default TaskRuleHistory;
