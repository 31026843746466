import { useState } from 'react';

export const modalTypeEnum = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

const useWidgetModal = () => {
  const [modal, setModal] = useState(null);

  const handleClose = () => setModal(null);

  const handleEdit = (item) => setModal({ type: modalTypeEnum.EDIT, item });

  const handleDelete = (item) => setModal({ type: modalTypeEnum.DELETE, item });

  const handleAdd = () => setModal({ type: modalTypeEnum.ADD });

  return {
    modal,
    handleAdd,
    handleClose,
    handleEdit,
    handleDelete,
  };
};

export default useWidgetModal;
