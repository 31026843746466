import * as actionTypes from './actionTypes';

export const setTimeTracking =
  ({ dateFrom, dateTo }) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.SET_TIME_TRACKING,
      payload: { dateFrom, dateTo },
    });
  };
