import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { useTheme } from 'context/theme';
import { sidesValues } from 'utils/encounters';
import { FormSectionTitle } from 'utils/styles';

import { fields } from 'pages/practitioner/patients/details/dashboard/sections/care-track/encounters/details/data/venousStent';

export const FieldArrayColumnsSection = ({
  sides,
  generalFields,
  extraFields,
}) => {
  const { palette } = useTheme();

  const { title, items } = generalFields;
  return (
    !!sides?.length && (
      <>
        {title && <FormSectionTitle>{title}</FormSectionTitle>}
        <Grid container spacing={2} sx={{ py: 2 }}>
          {sides.map((side, index) => (
            <Grid
              key={side}
              container
              item
              alignItems="flex-start"
              md={12 / ((sides.length + 1) * (index === 0 ? 0.5 : 1))}
            >
              <FieldArray
                name={`${sidesValues[side]}.${fields.VENOUS_STENTS_PLACED}`}
                render={({ form, push, pop }) => (
                  <Grid container item spacing={2}>
                    {items?.map(({ label, input }, idx) => (
                      <React.Fragment key={label + idx}>
                        {!!idx && (
                          <Grid item xs={12}>
                            <Divider sx={{ borderColor: palette.grey[300] }} />
                          </Grid>
                        )}
                        <Grid container item spacing={2} alignItems="center">
                          {index === 0 && (
                            <Grid
                              item
                              md={6}
                              xs={12}
                              sx={{ textAlign: 'right' }}
                            >
                              <span>{label}</span>
                            </Grid>
                          )}
                          <Grid item md={index === 0 ? 6 : 12} xs={12}>
                            {input(sidesValues[side], push, pop)}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                    {form.values[sidesValues[side]][
                      fields.VENOUS_STENTS_PLACED
                    ]?.map((_, i) =>
                      extraFields?.map((field, ii) => (
                        <React.Fragment key={ii}>
                          <Grid item xs={12}>
                            <Divider sx={{ borderColor: palette.grey[300] }} />
                          </Grid>
                          <Grid container item spacing={2} alignItems="center">
                            {index === 0 && <Grid item md={6} xs={12}></Grid>}
                            <Grid item md={index === 0 ? 6 : 12} xs={12}>
                              {field(sidesValues[side], i)}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )),
                    )}
                  </Grid>
                )}
              />
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
};

FieldArrayColumnsSection.propTypes = {
  sides: PropTypes.arrayOf(PropTypes.string),
  generalFields: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.array,
  }),
  extraFields: PropTypes.arrayOf(PropTypes.func),
};
