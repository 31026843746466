import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';

import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import Template from 'pages/practitioner/patients/details/dashboard/Template';

import QuestionnaireForm from '../questionnaire-form';
import { getQuestionnaireFormSchema, questionnairesForms } from '../utils';

const QuestionnaireResponse = () => {
  const navigate = useNavigate();
  const { questionnaireType, responseId } = useParams();
  const {
    data: formData,
    doRequest: getQuestionnaireResponse,
    isLoading,
  } = useRequest(api.getQuestionnaireResponse);

  const form = questionnairesForms[questionnaireType];

  useEffect(() => {
    getQuestionnaireResponse(responseId);
  }, [getQuestionnaireResponse, responseId]);

  if (isLoading || !formData) return <Loader />;

  return (
    <Template title={formData?.name}>
      <FormWrapper
        initialValues={Object.values(formData?.values)[0]}
        validationSchema={getQuestionnaireFormSchema(form)}
        onCancel={() => navigate(-1)}
        readOnly
      >
        {({ formik }) => (
          <QuestionnaireForm
            formik={formik}
            form={form}
            type={questionnaireType}
            disabled
          />
        )}
      </FormWrapper>
    </Template>
  );
};

export default QuestionnaireResponse;
