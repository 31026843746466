import styled from '@emotion/styled';

import Tooltip from '@mui/material/Tooltip';

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '.MuiTooltip-arrow': {
    color: theme.palette.primary.main,
  },

  '.MuiTooltip-tooltip	': {
    padding: '0.5rem',
    fontSize: '1rem',
    backgroundColor: theme.palette.primary.main,
  },
}));
