import React from 'react';
import { useLocation } from 'react-router-dom';

import { ECRF_PATHS, PRACTITIONER_PATHS, ROOT_PATHS } from 'navigation/paths';

export const useECRF = () => {
  const { pathname } = useLocation();

  const isECRF = React.useMemo(
    () => pathname?.split('/')?.slice(0, 2)?.join('/') === ROOT_PATHS.eCRF,
    [pathname],
  );
  const rootPath = React.useMemo(
    () => (isECRF ? ECRF_PATHS.patients : PRACTITIONER_PATHS.patients),
    [isECRF],
  );

  return {
    isECRF,
    rootPath,
  };
};

export default useECRF;
