import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { useTheme } from 'context/theme';
import { weight } from 'theme/fonts';

import {
  StyledTableCell,
  StyledTableSortLabel,
  StyledTableRow,
  StyledPagination,
  StyledPaginationItem,
  Section,
  LoadingDiv,
  TableFeedback,
} from './Table.styles';

const Table = ({
  header,
  rows = [],
  minimumRows = 5,
  page = 1,
  totalPages = 1,
  handlePageChange,
  isLoading,
  error,
  handleRowClick,
  columnStyles = [],
  orderBy,
  orderDirection,
}) => {
  const { palette } = useTheme();

  const numberOfColumns = React.useMemo(() => header.length, [header.length]);
  const numberOfRows = React.useMemo(() => rows.length, [rows.length]);

  const isClickable = typeof handleRowClick === 'function';

  const onRowClick = (id) => {
    if (isClickable) {
      handleRowClick(id);
    }
  };

  return (
    <Section>
      {isLoading && (
        <LoadingDiv>
          <CircularProgress />
        </LoadingDiv>
      )}
      {error && (
        <TableFeedback variant="filled" severity="error">
          {error}
        </TableFeedback>
      )}
      <TableContainer
        component={Paper}
        sx={{ boxShadow: `0 0.1rem 0.25rem ${palette.grey[300]}` }}
      >
        <MUITable sx={{ width: '100%' }}>
          <TableHead>
            <StyledTableRow>
              {header.map(({ title, handleClick, id, style }, index) => (
                <StyledTableCell
                  key={index}
                  style={style || columnStyles[index]}
                >
                  {typeof handleClick === 'function' ? (
                    <StyledTableSortLabel
                      active={orderBy === id}
                      direction={
                        orderBy === id ? orderDirection.toLowerCase() : 'asc'
                      }
                      onClick={() => handleClick(id)}
                      sx={{
                        fontWeight: orderBy === id ? weight.bold : 'initial',
                      }}
                    >
                      {title}
                    </StyledTableSortLabel>
                  ) : (
                    title
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ data, id }, rowIndex) => (
              <StyledTableRow
                key={rowIndex}
                onClick={() => onRowClick(id)}
                style={{
                  cursor: isClickable ? 'pointer' : 'default',
                }}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {data.map((column, columnIndex) => (
                  <StyledTableCell key={columnIndex}>{column}</StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
            {numberOfRows < minimumRows &&
              Array.from(Array(minimumRows - rows.length)).map((_, rowIdx) => (
                <StyledTableRow key={rowIdx}>
                  {Array.from(Array(numberOfColumns)).map((_, colIdx) => (
                    <StyledTableCell key={colIdx} />
                  ))}
                </StyledTableRow>
              ))}
          </TableBody>
        </MUITable>
      </TableContainer>
      {totalPages > 1 && (
        <StyledPagination
          page={page}
          count={totalPages}
          color="primary"
          onChange={handlePageChange}
          renderItem={(item) => <StyledPaginationItem {...item} />}
        />
      )}
    </Section>
  );
};

Table.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      handleClick: PropTypes.func,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      ),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  minimumRows: PropTypes.number,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  handlePageChange: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleRowClick: PropTypes.func,
  columnStyles: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
};

export default Table;
