import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';

import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import Template from 'pages/practitioner/patients/details/dashboard/Template';

import QuestionnaireForm from '../questionnaire-form';
import {
  getFormQuestions,
  getQuestionnaireFormSchema,
  questionnairesForms,
} from '../utils';

const NewQuestionnaireResponse = () => {
  const navigate = useNavigate();
  const { patientId, questionnaireType } = useParams();
  const { setSuccessFeedback, setErrorFeedback } = useFeedback();
  const { data, doRequest, isLoading } = useRequest(api.getQuestionnaireForm);
  const { doRequest: addQuestionnaireResponse } = useRequest(
    api.addQuestionnaireResponse,
  );

  const form = questionnairesForms[questionnaireType];

  const initialValues = form
    .flat()
    .filter(({ text }) => !text)
    .reduce((prev, cur) => {
      return cur.fields ? [...prev, ...cur.fields] : [...prev, cur];
    }, [])
    .reduce((obj, { name }) => ({ ...obj, [name]: '' }), {});

  const handleCancel = () => navigate(-1);

  const handleSubmit = async (values) => {
    const payload = { values: { [questionnaireType]: {} } };

    getFormQuestions(form).map((question) => {
      payload.values[questionnaireType][question.name] = parseInt(
        values[question.name],
      );
    });

    try {
      await addQuestionnaireResponse(data.id, payload);
      setSuccessFeedback('Added with success');
      handleCancel();
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  useEffect(() => {
    doRequest({ type: questionnaireType, patientId, notifyPatient: false });
  }, [doRequest, questionnaireType, patientId]);

  if (isLoading) return <Loader />;

  return (
    <Template title={data?.name}>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={getQuestionnaireFormSchema(form)}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      >
        {({ formik }) => <QuestionnaireForm formik={formik} form={form} />}
      </FormWrapper>
    </Template>
  );
};

export default NewQuestionnaireResponse;
