import { SET_FEEDBACK, CLEAR_FEEDBACK } from './actionTypes';

export const doSetFeedback = (feedback) => (dispatch) => {
  dispatch({
    type: SET_FEEDBACK,
    payload: feedback,
  });
};

export const doClearFeedback = () => (dispatch) => {
  dispatch({ type: CLEAR_FEEDBACK });
};
