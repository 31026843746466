import React from 'react';
import { View } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import {
  StyledAuthenticator,
  Title,
  SubTitle,
} from 'pages/account/AppAuth.styles';

import RoleSwitch from './RoleSwitch';

const components = {
  Header() {
    return (
      <View textAlign="center">
        <Title>Disease State Management Portal</Title>
        <SubTitle>Brought to you by Impact Health</SubTitle>
      </View>
    );
  },
  ResetPassword: {
    Header() {
      return null;
    },
  },
};

const formFields = {
  resetPassword: {
    username: {
      placeholder: 'Email or Phone Number',
    },
  },
};

const AppAuth = () => {
  return (
    <StyledAuthenticator
      hideSignUp
      components={components}
      formFields={formFields}
    >
      <RoleSwitch />
    </StyledAuthenticator>
  );
};

export default AppAuth;
