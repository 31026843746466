import React from 'react';
import InputMask from 'react-input-mask';

import InputAdornment from '@mui/material/InputAdornment';

import { getFieldProps } from 'utils/forms';

import Input from 'components/forms/input';

import { INPUT_TYPES, OTHER_VALUE } from './constants';

const encounterTypeEnum = {
  ACUTE_STENT_THROMBOSIS: 'AcuteStentThrombosis',
  CHRONIC_VENOUS_OBSTRUCTION: 'ChronicVenousObstruction',
  DECLINED_INTERVENTION: 'DeclinedIntervention',
  DIAGNOSTICS_AND_RESULTS: 'DiagnosticResults',
  HEALED: 'Healed',
  INTRAVASCULAR_ULTRASOUND: 'IntravascularUltrasound',
  POST_OP_SYMPTOMS: 'PostOpSymptoms',
  STENT_RESTENOSIS: 'StentRestenosis',
  SUPERFICIAL_VEIN_INSUFFICIENCY: 'SuperficialVeinInsufficiency',
  SVI_PROCEDURE: 'SVIProcedure',
  ULTRASOUND_AND_REFLUX_STUDY: 'UltrasoundAndRefluxStudy',
  VENOGRAPHY_CT_MR: 'VenographyCtMr',
  VENOPLASTY: 'Venoplasty',
  VENOUS_STENT: 'VenousStent',
  VENOUS_STENT_OBSTRUCTION: 'VenousStentObstruction',
  VLU_FOLLOW_UP: 'VLUFollowUp',
  WOUND_ASSESSMENT: 'WoundAssessment',
  WOUND_INTAKE: 'WoundIntake',
};

export const encounterTypeLabels = {
  [encounterTypeEnum.ACUTE_STENT_THROMBOSIS]: 'Acute Stent Thrombosis',
  [encounterTypeEnum.CHRONIC_VENOUS_OBSTRUCTION]: 'Chronic Venous Obstruction',
  [encounterTypeEnum.DECLINED_INTERVENTION]: 'Declined Intervention',
  [encounterTypeEnum.DIAGNOSTICS_AND_RESULTS]: 'Diagnostic Results',
  [encounterTypeEnum.HEALED]: 'Healed',
  [encounterTypeEnum.INTRAVASCULAR_ULTRASOUND]: 'Intravascular Ultrasound',
  [encounterTypeEnum.POST_OP_SYMPTOMS]: 'Post-Op Symptoms',
  [encounterTypeEnum.STENT_RESTENOSIS]: 'Stent Restenosis',
  [encounterTypeEnum.SUPERFICIAL_VEIN_INSUFFICIENCY]:
    'Superficial Vein Insufficiency',
  [encounterTypeEnum.SVI_PROCEDURE]: 'SVI Procedure',
  [encounterTypeEnum.ULTRASOUND_AND_REFLUX_STUDY]: 'Ultrasound & Reflux Study',
  [encounterTypeEnum.VENOGRAPHY_CT_MR]: 'Venography (CT, MR)',
  [encounterTypeEnum.VENOPLASTY]: 'Venoplasty',
  [encounterTypeEnum.VENOUS_STENT]: 'Venous Stent',
  [encounterTypeEnum.VENOUS_STENT_OBSTRUCTION]: 'Venous Stent Obstruction',
  [encounterTypeEnum.VLU_FOLLOW_UP]: 'VLU Follow-Up',
  [encounterTypeEnum.WOUND_ASSESSMENT]: 'Wound Assessment',
  [encounterTypeEnum.WOUND_INTAKE]: 'Wound Intake',
};

export const encounterFormsEnum = {
  ADDITIONAL_INFORMATION: 'AdditionalInformation',
  ANKLE_BRACHIAL_PRESSURE_INDEX: 'AnkleBrachialPressureIndex',
  COMPRESSION_FORM: 'CompressionForm',
  CVO_INTERVENTION_PLANNING: 'CVOInterventionPlanning',
  DIAGNOSTIC_RESULTS: 'DiagnosticResults',
  IVUS: 'IVUS',
  MEDICATION_FORM: 'MedicationForm',
  PATIENT_MEDICAL_HISTORY: 'PatientMedicalHistory',
  PLETHYSMOGRAPHY: 'Plethysmography',
  REFLUX_STUDY: 'RefluxStudy',
  SUSPECTED_VLU: 'SuspectedVLU',
  SVI_INTERVENTION_PLANNING: 'SVIInterventionPlanning',
  SVI_PROCEDURE: 'SVIProcedure',
  ULTRASOUND: 'Ultrasound',
  VENOGRAPHY_CT_MR: 'VenographyCtMr',
  VENOPLASTY: 'Venoplasty',
  VENOUS_STENT: 'VenousStent',
  VENOUS_STENT_REINTERVENTION: 'VenousStentReintervention',
  VLU_FOLLOW_UP_VISIT: 'VLUFollowUpVisit',
  VLU_INTAKE: 'VLUIntake',
  WOUND_ASSESSMENT: 'WoundAssessment',
  X_RAY: 'XRay',
};

export const encounterFormsPathEnum = Object.entries(encounterFormsEnum).reduce(
  (acc, cur) => ({ ...acc, [cur[1]]: cur[0].toLowerCase().replace(/_/g, '-') }),
  {},
);

export const encounterFormsTitleEnum = {
  [encounterFormsEnum.ADDITIONAL_INFORMATION]: 'Additional Information',
  [encounterFormsEnum.ANKLE_BRACHIAL_PRESSURE_INDEX]:
    'Ankle-Brachial Pressure Index',
  [encounterFormsEnum.COMPRESSION_FORM]: 'Compression Form',
  [encounterFormsEnum.CVO_INTERVENTION_PLANNING]: 'CVO Intervention Planning',
  [encounterFormsEnum.DIAGNOSTIC_RESULTS]: 'Diagnostic Results',
  [encounterFormsEnum.IVUS]: 'IVUS',
  [encounterFormsEnum.MEDICATION_FORM]: 'Medication Form',
  [encounterFormsEnum.PATIENT_MEDICAL_HISTORY]: 'Patient Medical History',
  [encounterFormsEnum.PLETHYSMOGRAPHY]: 'Plethysmography',
  [encounterFormsEnum.REFLUX_STUDY]: 'Reflux Study',
  [encounterFormsEnum.SUSPECTED_VLU]: 'Suspected VLU',
  [encounterFormsEnum.SVI_INTERVENTION_PLANNING]: 'SVI Intervention Planning',
  [encounterFormsEnum.SVI_PROCEDURE]: 'SVI Procedure',
  [encounterFormsEnum.ULTRASOUND]: 'Ultrasound',
  [encounterFormsEnum.VENOGRAPHY_CT_MR]: 'Venography (CT, MR)',
  [encounterFormsEnum.VENOPLASTY]: 'Venoplasty',
  [encounterFormsEnum.VENOUS_STENT]: 'Venous Stent',
  [encounterFormsEnum.VENOUS_STENT_REINTERVENTION]:
    'Venous Stent Reintervention',
  [encounterFormsEnum.VLU_FOLLOW_UP_VISIT]: 'VLU Follow-Up Visit',
  [encounterFormsEnum.VLU_INTAKE]: 'VLU Intake',
  [encounterFormsEnum.WOUND_ASSESSMENT]: 'Wound Assessment',
  [encounterFormsEnum.X_RAY]: 'X-Ray',
};

export const encounterStatusEnum = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
};

export const limbOptions = [
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
  { value: 'Both', label: 'Both' },
];

export const sideOptions = [
  { value: 'No', label: 'No' },
  { value: 'Left', label: 'Yes, Left' },
  { value: 'Right', label: 'Yes, Right' },
  { value: 'Both', label: 'Yes, Both' },
];

export const sidesValues = {
  Left: 'left',
  Right: 'right',
  IVC: 'ivc',
};

export const sidesLabels = {
  left: 'Left',
  right: 'Right',
  ivc: 'IVC',
};

export const procedureLimbOptions = Object.values(sidesLabels).map((side) => ({
  value: side,
  label: side,
}));

export const anatomyOptions = [
  { value: 'CommonIliac', label: 'Common Iliac' },
  { value: 'ExternalIliac', label: 'External Iliac' },
  { value: 'CommonFemoral', label: 'Common Femoral' },
  { value: 'Femoral', label: 'Femoral' },
  { value: 'DeepFemoralProfunda', label: 'Deep Femoral (Profunda)' },
  { value: 'Popliteal', label: 'Popliteal' },
  { value: 'AcrossTheInguinalLigament', label: 'Across the Inguinal Ligament' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

export const deviceFields = {
  COMPANY_NAME: 'deviceCompanyName',
  BRAND_OR_PRODUCT_NAME: 'deviceBrandOrProductName',
  PRODUCT_DIAMETER: 'deviceProductDiameterMm',
  PRODUCT_LENGTH: 'deviceProductLengthMm',
  PRESSURE_INFLATED_TO: 'devicePressureInflatedToAtm',
  DEVICE_IDENTIFIER: 'deviceIdentifier',
  PRODUCTION_DATE: 'deviceProductionDate',
  EXPIRATION_DATE: 'deviceExpirationDate',
  LOT_NUMBER: 'deviceLotNumber',
  SERIAL_NUMBER: 'deviceSerialNumber',
  ADDITIONAL_NOTES: 'additionalNotes',
};

export const deviceDetailsContent = [
  {
    name: deviceFields.COMPANY_NAME,
    label: 'Company Name',
  },
  {
    name: deviceFields.BRAND_OR_PRODUCT_NAME,
    label: 'Brand or Product Name',
  },
  {
    name: deviceFields.PRODUCT_DIAMETER,
    label: 'Product Diameter',
    type: INPUT_TYPES.NUMERIC,
    adornment: 'mm',
  },
  {
    name: deviceFields.PRODUCT_LENGTH,
    label: 'Product Length',
    type: INPUT_TYPES.NUMERIC,
    adornment: 'mm',
  },
  {
    name: deviceFields.PRESSURE_INFLATED_TO,
    label: 'Pressure Inflated to',
    isRequired: false,
    type: INPUT_TYPES.NUMERIC,
    adornment: 'atm',
  },
];

export const deviceUDIContent = [
  {
    name: deviceFields.DEVICE_IDENTIFIER,
    label: 'Device Identifier (01)',
    mask: '(01)99999999999999',
  },
  {
    name: deviceFields.PRODUCTION_DATE,
    label: 'Production Date (11)',
    mask: '(11)999999',
  },
  {
    name: deviceFields.EXPIRATION_DATE,
    label: 'Expiration Date (17)',
    mask: '(17)999999',
  },
  {
    name: deviceFields.LOT_NUMBER,
    label: 'Lot Number (10)',
    mask: '(10)************',
  },
  {
    name: deviceFields.SERIAL_NUMBER,
    label: 'Serial Number (21)',
    mask: '(21)************',
  },
];

export const deviceDetailsSection = (formik, disabled, suffix = '', hidden) =>
  deviceDetailsContent.map(
    ({ name, label, type, isRequired = true, adornment }) => {
      return {
        label,
        input: (side) => {
          if (hidden && hidden(side, suffix)) return '-';
          return (
            <Input
              type={type || INPUT_TYPES.SHORT_TEXT}
              isRequired={isRequired}
              endAdornment={
                adornment && (
                  <InputAdornment position="end">{adornment}</InputAdornment>
                )
              }
              {...getFieldProps({
                formik,
                name: `${side}.${name}${suffix}`,
                disabled,
              })}
            />
          );
        },
      };
    },
  );

export const deviceUDISection = (formik, disabled, suffix = '', hidden) =>
  deviceUDIContent.map(({ name, label, mask }) => {
    return {
      label,
      input: (side) => {
        if (hidden && hidden(side, suffix)) return '-';
        return (
          <InputMask
            mask={mask}
            maskChar=" "
            {...getFieldProps({
              formik,
              name: `${side}.${name}${suffix}`,
              disabled,
            })}
          >
            {() => (
              <Input name={`${side}.${name}${suffix}`} disabled={disabled} />
            )}
          </InputMask>
        );
      },
    };
  });

export const sideSubtitle = {
  label: '',
  input: (side) => (
    <span style={{ fontWeight: 'bold' }}>{sidesLabels[side]}</span>
  ),
};
