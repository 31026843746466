import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';

import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import MedicationComplianceForm from './MedicationComplianceForm';

import { medicationComplianceSchema, mapForm2Api } from './utils';

const EditMedicationCompliance = ({ itemId, handleCancel, handleRefresh }) => {
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { getUser } = useUserDetails();
  const { patientId } = useParams();
  const { data: medicationData, doRequest: getMedication } = useRequest(
    api.getMedication,
  );

  useEffect(() => {
    getMedication(itemId);
  }, [itemId, getMedication]);

  const handleSubmit = (formValues) => {
    clearFeedback();
    api
      .updateMedication({ id: itemId, data: mapForm2Api(formValues) })
      .then(() => {
        getUser(patientId);
        setSuccessFeedback('Medication compliance log updated successfully');
        handleCancel();
        handleRefresh();
      })
      .catch((err) => {
        setErrorFeedback('Error saving medication compliance log', [
          getErrorMessage(err),
        ]);
      });
  };

  if (!medicationData) return <Loader />;

  return (
    <FormWrapper
      initialValues={medicationData}
      validationSchema={medicationComplianceSchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => (
        <MedicationComplianceForm formik={formik} titlePrefix="Edit" />
      )}
    </FormWrapper>
  );
};

EditMedicationCompliance.propTypes = {
  itemId: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};

export default EditMedicationCompliance;
