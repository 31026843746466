import React from 'react';
import PropTypes from 'prop-types';

import { getFieldProps } from 'utils/forms';

import LogForm from 'components/log-form';
import ComplianceQuestion from 'components/compliance-questions/ComplianceQuestion';

import { formFields } from './utils';

const CompressionForm = ({ formik, titlePrefix }) => (
  <LogForm title={`${titlePrefix} Compression Compliance Log`}>
    {formFields(formik).map(({ isVisible, changeOverride, ...element }) => (
      <ComplianceQuestion
        element={element}
        isVisible={isVisible}
        key={element.name}
        {...getFieldProps({
          formik,
          name: element.name,
          changeOverride: changeOverride || null,
        })}
      />
    ))}
  </LogForm>
);

CompressionForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  titlePrefix: PropTypes.string,
};

export default CompressionForm;
