export const siteTableAttributes = (handleSort) => [
  {
    id: 'Name',
    title: 'Site Name',
    handleClick: handleSort,
  },
  {
    id: 'Active',
    title: 'Active',
    handleClick: handleSort,
  },
  {
    id: 'DiseaseCount',
    title: 'Disease States',
    handleClick: handleSort,
  },
];
