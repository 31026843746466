import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const StyledImage = styled('img')(() => ({
  maxWidth: '15rem',
  maxHeight: '15rem',
  marginTop: '0.5rem',
  borderRadius: '0.25rem',
  objectFit: 'contain',

  [breakpointsTheme.breakpoints.down('sm')]: {
    maxHeight: 'none',
    maxWidth: 'none',
    width: '100%',
  },
}));
