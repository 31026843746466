import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useChat from 'hooks/useChat';

import ErrorMessage from 'components/error-message';
import Loader from 'components/loader';

import RoomTab from './RoomTab';
import { LoadingContainer, TabsContainer } from './RoomTabs.styles';

const RoomTabs = ({ searchFilter, ownerId }) => {
  const {
    chatRooms,
    chatRoomsLoading,
    chatRoomsErrorMessage,
    onRoomSelect,
    selectedRoomId,
  } = useChat();

  const filteredRooms = useMemo(() => {
    return searchFilter
      ? chatRooms.filter(({ members }) => {
          return members.reduce((res, member) => {
            if (
              member.userId !== ownerId &&
              member.name.toLowerCase().includes(searchFilter)
            ) {
              return true;
            }
            return res;
          }, false);
        })
      : chatRooms;
  }, [chatRooms, searchFilter, ownerId]);

  return (
    <TabsContainer>
      {chatRoomsLoading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
      {chatRoomsErrorMessage ? (
        <ErrorMessage message={chatRoomsErrorMessage} />
      ) : (
        filteredRooms?.map((room) => (
          <RoomTab
            key={room.id}
            room={room}
            ownerId={ownerId}
            onClick={() => onRoomSelect(room.id)}
            isSelected={room.id === selectedRoomId}
          />
        ))
      )}
    </TabsContainer>
  );
};

RoomTabs.propTypes = {
  ownerId: PropTypes.string.isRequired,
  searchFilter: PropTypes.string,
};

export default RoomTabs;
