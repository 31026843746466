import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import StyledIconButton from 'components/buttons/StyledIconButton';
import HeaderTitle from 'components/titles/HeaderTitle';

const WidgetHeader = ({
  onAddClick,
  onLogsClick,
  title,
  isLogsList = false,
}) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    <HeaderTitle title={title} backUrl={isLogsList ? '..' : null} />
    <Box display="flex" justifyContent="flex-end">
      <StyledIconButton name="add" color="primary" onClick={onAddClick} />
      {onLogsClick && (
        <StyledIconButton name="menu" color="grey" onClick={onLogsClick} />
      )}
    </Box>
  </Box>
);

WidgetHeader.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  onLogsClick: PropTypes.func,
  title: PropTypes.node,
  isLogsList: PropTypes.bool,
};

export default WidgetHeader;
