import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import useECRF from 'hooks/useECRF';
import useUser from 'hooks/useUser';
import { genderOptions } from 'utils/constants';
import { timezoneOptions } from 'utils/date';
import { DiseaseLabels, pathwayStepsOptions } from 'utils/enums';
import { getFieldProps, normalizeDiseases } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';
import { getActivatedInfo } from 'utils/users';

import CountrySelector from 'components/forms/country-selector';
import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';
import InputPhoneNumber from 'components/forms/input-phone-number';
import Select from 'components/forms/select';
import SelectPractitioner from 'components/forms/select-practitioner';
import Toogle from 'components/toggle';

const PatientForm = ({ formik, readOnly = false, forcedPractitioner }) => {
  const { userDetails } = useUser();
  const { isECRF } = useECRF();
  const { active, deactivatedAt, activatedAt } = formik.values;

  const diseaseOptions = useMemo(
    () => normalizeDiseases(userDetails?.practitioner?.diseases),
    [userDetails.practitioner],
  );

  const activatedInfo = useMemo(
    () => getActivatedInfo({ active, activatedAt, deactivatedAt }),
    [active, activatedAt, deactivatedAt],
  );

  const hospitalSystemId = useMemo(() => {
    return isECRF
      ? userDetails?.hospitalECRFUser?.hospitalSystemId
      : userDetails?.practitioner?.hospitalSystemId;
  }, [isECRF, userDetails]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSectionTitle>Information</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Toogle
            label="Active"
            info={activatedInfo}
            {...getFieldProps({ formik, name: 'active', disabled: readOnly })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="First Name"
            isRequired
            {...getFieldProps({
              formik,
              name: 'firstName',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Last Name"
            isRequired
            {...getFieldProps({
              formik,
              name: 'lastName',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Gender"
            isRequired
            options={genderOptions}
            displayEmptyOption
            {...getFieldProps({ formik, name: 'gender', disabled: readOnly })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Date of Birth"
            isRequired
            {...getFieldProps({
              formik,
              name: 'birthDate',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Timezone"
            options={timezoneOptions}
            {...getFieldProps({
              formik,
              name: 'ianaTimezone',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Address"
            {...getFieldProps({ formik, name: 'street', disabled: readOnly })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="City"
            {...getFieldProps({ formik, name: 'city', disabled: readOnly })}
          />
        </Grid>
        <CountrySelector formik={formik} disabled={readOnly} />
        <Grid item xs={12} md={4}>
          <Input
            label="Email"
            isRequired
            {...getFieldProps({ formik, name: 'email', disabled: readOnly })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputPhoneNumber
            label="Phone Number"
            isRequired
            {...getFieldProps({
              formik,
              name: 'phoneNumber',
              disabled: readOnly,
            })}
          />
        </Grid>
      </Grid>
      <FormSectionTitle>Practitioner Assignment</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={6}>
          <SelectPractitioner
            formik={formik}
            name="practitioners"
            label="Practitioner(s)"
            forcedPractitioner={forcedPractitioner}
            disabled={readOnly}
            placeholder="Select the practitioner(s)"
            hospitalSystemId={hospitalSystemId}
          />
        </Grid>
      </Grid>
      {!isECRF && (
        <>
          <FormSectionTitle>Disease State Assignment</FormSectionTitle>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} md={6}>
              <Select
                label="Disease State(s)"
                options={diseaseOptions}
                placeholder={'Select the disease state(s)'}
                multiple
                {...getFieldProps({
                  formik,
                  name: 'diseases',
                  disabled: readOnly,
                })}
              />
            </Grid>
            <Grid item container spacing={2}>
              {formik.values?.diseases?.map((disease) => (
                <Grid item xs={12} md={4} key={disease}>
                  <Select
                    label={`${DiseaseLabels[disease]} - Pathway Step`}
                    options={pathwayStepsOptions}
                    {...getFieldProps({
                      formik,
                      name: `diseasePathwaySteps.${disease}`,
                      disabled: readOnly,
                    })}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};

PatientForm.propTypes = {
  children: PropTypes.node,
  forcedPractitioner: PropTypes.string,
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    values: PropTypes.shape({
      active: PropTypes.bool,
      activatedAt: PropTypes.string,
      deactivatedAt: PropTypes.string,
      country: PropTypes.string,
      diseases: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  readOnly: PropTypes.bool,
  activatedOnText: PropTypes.string,
};
export default PatientForm;
