import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { actions as userActions, selectors } from 'store/user';

export const useUser = () => {
  const dispatch = useDispatch();

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          logout: userActions.doLogout,
          getCurrentUserDetails: userActions.doGetCurrentUserDetails,
          updateCurrentUserDetails: userActions.doUpdateCurrentUserDetails,
        },
        dispatch,
      ),
    [dispatch],
  );

  const isLoading = useSelector(selectors.isLoading);
  const error = useSelector(selectors.getError);
  const userDetails = useSelector(selectors.getUserDetails) ?? {};
  const userGroups = userDetails?.roles ?? [];

  const errorMessage = useMemo(() => {
    switch (error?.message) {
      case 'User is disabled.':
        return 'Your account is inactive. If you think that this is an error, please contact an admin directly.';
      default:
        return error?.message;
    }
  }, [error]);

  return {
    ...actions,
    isLoading,
    error,
    errorMessage,
    userDetails,
    userGroups,
  };
};

export default useUser;
