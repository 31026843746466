import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/loader';

import useCachedImage, {
  ImageSizeEnum,
  ImageFitEnum,
} from 'hooks/useCachedImage';

const imageSize = {
  small: ImageSizeEnum.SIZE_128,
  normal: ImageSizeEnum.SIZE_256,
  big: ImageSizeEnum.SIZE_512,
};

const VluImageDisplay = ({ url, maskedUrl, markerUrl, size }) => {
  const { cachedUrl } = useCachedImage({
    url,
    size: imageSize[size],
    fit: ImageFitEnum.CONTAIN,
  });

  const maskedCachedUrl = useCachedImage({
    url: maskedUrl,
    size: imageSize[size],
    fit: ImageFitEnum.CONTAIN,
  });

  const markerCachedUrl = useCachedImage({
    url: markerUrl,
    size: imageSize[size],
    fit: ImageFitEnum.CONTAIN,
  });

  if (!url)
    return (
      <span style={{ fontSize: size === 'small' ? '0.75rem' : '1rem' }}>
        No wound image.
      </span>
    );

  if (cachedUrl) {
    return (
      <div style={{ position: 'relative' }}>
        <img
          src={cachedUrl}
          alt={url}
          style={{ left: 0, objectFit: 'contain' }}
        />
        {maskedCachedUrl.cachedUrl !== '' && (
          <img
            src={maskedCachedUrl.cachedUrl}
            alt={maskedUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              objectFit: 'contain',
              opacity: '0.7',
            }}
          />
        )}
        {markerCachedUrl.cachedUrl !== '' && (
          <img
            src={markerCachedUrl.cachedUrl}
            alt={markerUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              objectFit: 'contain',
              opacity: '0.5',
              filter:
                'grayscale(100%) brightness(70%) sepia(100%) hue-rotate(60deg) saturate(250%)',
            }}
          />
        )}
      </div>
    );
  } else {
    return <Loader />;
  }
};

VluImageDisplay.propTypes = {
  url: PropTypes.string,
  maskedUrl: PropTypes.string,
  markerUrl: PropTypes.string,
  size: PropTypes.string,
};

export default VluImageDisplay;
