import { USER_GROUPS } from 'utils/constants';

export const LOGIN_PATH = '/login';
export const COMMON_PATH = '/dashboard';

export const ROOT_PATHS = {
  systemAdministrator: '/system-administrator',
  practitioner: '/practitioner',
  patient: '/patient',
  woundImageTracer: '/wound-image-tracer',
  woundDetector: '/wound-detector',
  questionnaires: '/questionnaires',
  eCRF: '/ecrf',
};

// User roles for X-IH-ROLE header
export const headerRoleFromRootPath = {
  [ROOT_PATHS.systemAdministrator]: USER_GROUPS.systemAdministrator,
  [ROOT_PATHS.practitioner]: USER_GROUPS.practitioner,
  [ROOT_PATHS.patient]: USER_GROUPS.patient,
  [ROOT_PATHS.woundImageTracer]: USER_GROUPS.woundImageTracer,
  [ROOT_PATHS.woundDetector]: USER_GROUPS.woundDetector,
  [ROOT_PATHS.eCRF]: USER_GROUPS.eCRF,
};

export const SYS_ADMIN_PATHS = {
  patients: `${ROOT_PATHS.systemAdministrator}/patients`,
  users: `${ROOT_PATHS.systemAdministrator}/users`,
  hospitalSystems: `${ROOT_PATHS.systemAdministrator}/organizations`,
  hospitalSystemSites: (systemId) =>
    `${ROOT_PATHS.systemAdministrator}/organizations/${systemId}/sites`,
  system: `${ROOT_PATHS.systemAdministrator}/system`,
  settings: `${ROOT_PATHS.systemAdministrator}/user-settings`,
};

export const PRACTITIONER_PATHS = {
  patients: `${ROOT_PATHS.practitioner}/patients`,
  messages: `${ROOT_PATHS.practitioner}/messages`,
  settings: `${ROOT_PATHS.practitioner}/settings`,
  dashboards: `${ROOT_PATHS.practitioner}/dashboards`,
};

export const PATIENT_DASHBOARD_PATHS = {
  HOME: 'home',
  CARE_TRACK: 'care-track',
  ENCOUNTERS: 'encounters',
  PATIENT_DATA: 'patient-data',
  MANAGE: 'manage',
  TIME_TRACK: 'time-track',
  QUESTIONNAIRES: 'questionnaires',
};

export const CARE_TRACK_PATHS = {
  DASHBOARD: '/',
  COMPRESSION_COMPLIANCE: 'patient-data/compression-compliance',
  PAIN_MOBILITY: 'patient-data/pain-mobility',
  WOUND_SIZE: 'patient-data/wound-size',
  MEDICATION_COMPLIANCE: 'patient-data/medication-compliance',
  ENCOUNTERS_ADD: 'encounters/add',
  ENCOUNTERS_DETAILS: 'encounters/:encounterId',
  QUESTIONNAIRE: 'questionnaires/:questionnaireType',
  NEW_QUESTIONNAIRE_RESPONSE: 'questionnaires/:questionnaireType/add',
  QUESTIONNAIRE_RESPONSE: 'questionnaires/:questionnaireType/:responseId',
};

export const TASKS_PATHS = {
  NEW_RULE: 'rules/new',
  EDIT_RULE: 'rules/:ruleId/*',
};

export const ECRF_PATHS = {
  patients: `${ROOT_PATHS.eCRF}/patients`,
  settings: `${ROOT_PATHS.eCRF}/settings`,
};

export const ECRF_DASHBOARD_PATHS = {
  ECRF: 'home',
  MANAGE: 'manage',
};
