import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';

import { ErrorText } from 'utils/styles';

import { StyledFormControlLabel, StyledRow } from './TableChoice.styles';

const TableRow = ({
  question,
  name,
  value,
  onChange,
  error,
  disabled,
  options,
  isFirstRow,
}) => {
  const handleChange = (newValue) => () => {
    onChange({
      target: {
        value: newValue,
        name,
      },
    });
  };

  return (
    <StyledRow>
      <div>
        {question}
        {error && <ErrorText>{error}</ErrorText>}
      </div>
      {options.map((option) => {
        if (!isFirstRow && option.label === 'I do not work') {
          return <div key={option.value} />;
        } else {
          return (
            <div key={option.value}>
              <StyledFormControlLabel
                label={option.value}
                control={
                  <Radio
                    name={name}
                    disabled={disabled}
                    checked={value == option.value}
                    onChange={handleChange(option.value)}
                  />
                }
              />
            </div>
          );
        }
      })}
    </StyledRow>
  );
};

TableRow.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  isFirstRow: PropTypes.bool,
};

export default TableRow;
