import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES } from 'utils/constants';
import { limbOptions } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import FileUpload from 'components/forms/file-upload';
import Input from 'components/forms/input';

const fields = {
  ABI_DATE: 'ankleBrachialPressureIndexDate',
  LIMB_SIDE: 'limbSide',
  ABI_LEFT: 'ankleBrachialPressureIndexLeft',
  ABI_RIGHT: 'ankleBrachialPressureIndexRight',
  ASSET_ID: 'assetId',
  ADDITIONAL_NOTES: 'additionalNotes',
};

const AnkleBrachialPressureIndex = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>ABI Results</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of ABI"
          isRequired
          {...getFieldProps({
            formik,
            name: fields.ABI_DATE,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="Ulcerated Limb(s)"
          options={limbOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.LIMB_SIDE,
            disabled,
            changeOverride: (value) => {
              formik?.setValues(
                update(formik?.values, {
                  [fields.LIMB_SIDE]: {
                    $set: value,
                  },
                  [fields.ABI_LEFT]: {
                    $set: null,
                  },
                  [fields.ABI_RIGHT]: {
                    $set: null,
                  },
                }),
              );
            },
          })}
        />
      </Grid>
      {['Left', 'Both'].includes(formik.values[fields.LIMB_SIDE]) && (
        <Grid item xs={12} md={4}>
          <Input
            label="ABI (Left Limb)"
            type={INPUT_TYPES.NUMERIC}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.ABI_LEFT,
              disabled,
            })}
          />
        </Grid>
      )}
      {['Right', 'Both'].includes(formik.values[fields.LIMB_SIDE]) && (
        <Grid item xs={12} md={4}>
          <Input
            label="ABI (Right Limb)"
            type={INPUT_TYPES.NUMERIC}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.ABI_RIGHT,
              disabled,
            })}
          />
        </Grid>
      )}
    </Grid>
    <FormSectionTitle>Additional Information</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FileUpload
          label="File Upload"
          {...getFieldProps({
            formik,
            name: fields.ASSET_ID,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Additional Notes"
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: fields.ADDITIONAL_NOTES,
            disabled,
          })}
        />
      </Grid>
    </Grid>
  </>
);

export default AnkleBrachialPressureIndex;

AnkleBrachialPressureIndex.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
