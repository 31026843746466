import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { diseaseOptions } from 'utils/enums';
import { getFieldProps } from 'utils/forms';

import CountrySelector from 'components/forms/country-selector';
import Input from 'components/forms/input';
import Select from 'components/forms/select';

const HospitalSystemForm = ({ formik, readOnly = false }) => (
  <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Input
          label="Hospital System Name"
          isRequired
          {...getFieldProps({
            formik,
            name: 'hospitalSystemName',
            disabled: readOnly,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Headquarters Address"
          {...getFieldProps({
            formik,
            name: 'headquartersAddress',
            disabled: readOnly,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="City"
          {...getFieldProps({ formik, name: 'city', disabled: readOnly })}
        />
      </Grid>
      <CountrySelector formik={formik} disabled={readOnly} />
      <Grid item xs={12} md={8}>
        <Select
          label="Managed Disease State(s)"
          options={diseaseOptions}
          placeholder="Select the managed disease state(s)"
          dataTest="managed-disease-state-select"
          multiple
          noTags
          {...getFieldProps({
            formik,
            name: 'managedDiseaseStates',
            disabled: readOnly,
          })}
        />
      </Grid>
    </Grid>
  </form>
);

HospitalSystemForm.propTypes = {
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    initialValues: PropTypes.shape({
      managedDiseaseStates: PropTypes.arrayOf(PropTypes.string),
    }),
    values: PropTypes.shape({
      country: PropTypes.string,
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

export default HospitalSystemForm;
