import { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAuthSession } from 'aws-amplify/auth';
import { HubConnectionBuilder } from '@microsoft/signalr';

import { getErrorMessage } from 'utils/error';
import useUser from 'hooks/useUser';
import { actions as chatActions } from 'store/chat';

let hubConnection = null;

export const useChat = () => {
  const dispatch = useDispatch();
  const [pushedMessage, setPushedMessage] = useState(null);
  const { userDetails } = useUser();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          fetchRooms: chatActions.doGetChatRooms,
          fetchMessages: chatActions.doGetChatRoomsMessages,
          onRoomSelect: chatActions.doSelectChatRoom,
          onMesssageSend: chatActions.doSendChatRoomMessage,
          onMesssageReceived: chatActions.doReceiveChatRoomMessage,
          onMessagesRead: chatActions.doUpdateChatRoomMessagesRead,
          openChatWindow: chatActions.doOpenChatRoomWindow,
          closeChatWindow: chatActions.doCloseChatRoomWindow,
          onCollapseWindow: chatActions.setCollapseWindow,
        },
        dispatch,
      ),
    [dispatch],
  );

  const urlPractitioner = async () => {
    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken.toString();
    return `${process.env.REACT_APP_CHAT_SOCKET_URL}?access_token=${accessToken.jwtToken}`;
  };

  const startSocket = async () => {
    const url = await urlPractitioner();

    hubConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    hubConnection.start();

    hubConnection.on('ChatMessageReceived', (data) => {
      const jsonData = JSON.parse(data);
      actions.onMesssageReceived(jsonData.data);
    });

    hubConnection.on('ChatMemberUpdated', () => {});
  };

  const closeSocket = () => {
    if (hubConnection?.connectionId)
      hubConnection.stop().then(() => {
        hubConnection = null;
      });
  };

  const {
    data: chatRooms,
    loading: chatRoomsLoading,
    error: chatRoomsError,
  } = useSelector((state) => state.chat?.rooms);

  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
    sending: isSending,
    sendingError,
  } = useSelector((state) => state.chat?.messages);

  const selectedRoomId = useSelector((state) => state.chat.selectedRoomId);
  const openRoomWindowId = useSelector((state) => state.chat.windowRoomId);

  const selectedRoom = useMemo(
    () => chatRooms?.find(({ id }) => id === selectedRoomId),
    [chatRooms, selectedRoomId],
  );

  const chatRoomsErrorMessage = useMemo(
    () => chatRoomsError && getErrorMessage(chatRoomsError),
    [chatRoomsError],
  );

  const totalUnreadCount = useMemo(
    () => chatRooms.reduce((res, { unreadCount }) => res + unreadCount, 0),
    [chatRooms],
  );

  const videoCallMessage = useMemo(
    () =>
      `${userDetails.firstName} ${userDetails.lastName} is inviting you to a tele-health video call. Click the following link to join. https://doxy.me/${userDetails?.practitioner?.doxyTelehealthLink}`,
    [userDetails],
  );

  return {
    ...actions,
    startSocket,
    closeSocket,
    selectedRoomId,
    selectedRoom,
    openRoomWindowId,
    chatRooms,
    chatRoomsLoading,
    chatRoomsErrorMessage,
    messagesData,
    messagesLoading,
    messagesError,
    isSending,
    sendingError,
    totalUnreadCount,
    pushedMessage,
    setPushedMessage,
    videoCallMessage,
  };
};

export default useChat;
