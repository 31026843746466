import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import { getErrorMessage, getErrorDetails } from 'utils/error';

import Loader from 'components/loader';
import FormWrapper from 'components/forms/form-wrapper';

import TaskRuleForm from '../forms/TaskRuleForm';
import { mapApi2Form, mapForm2Api, validationSchema } from '../forms/utils';

const TaskRuleInfo = () => {
  const [editing, setEditing] = useState(false);
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { ruleId } = useParams();
  const { data, doRequest: getTaskRule } = useRequest(api.getTaskRule);

  useEffect(() => {
    getTaskRule(ruleId);
  }, [getTaskRule, ruleId]);

  const handleSubmit = async (values) => {
    clearFeedback();
    try {
      await api.updateTaskRule(ruleId, mapForm2Api(values));
      setSuccessFeedback('Updated with success');
      setEditing(false);
    } catch (error) {
      setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
    }
  };

  return (
    <>
      <Box sx={{ position: 'absolute', top: '1.5rem', right: '1.5rem' }}>
        {!editing && (
          <Button variant="contained" onClick={() => setEditing(true)}>
            Edit
          </Button>
        )}
      </Box>
      {data ? (
        <FormWrapper
          initialValues={mapApi2Form(data)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          onCancel={() => setEditing(false)}
          disabled={!editing}
        >
          {({ formik }) => (
            <TaskRuleForm formik={formik} disabled={!editing} detailsEdit />
          )}
        </FormWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TaskRuleInfo;
