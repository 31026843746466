import React from 'react';
import PropTypes from 'prop-types';

import HeaderTitle from 'components/titles';

import Sidebar from './Sidebar';
import { Header, TemplateContainer, WrapperContainer } from './Template.styles';

const Template = ({ headerRight, children, ...rest }) => (
  <TemplateContainer>
    <Sidebar />
    <WrapperContainer>
      <Header>
        <HeaderTitle {...rest} />
        {headerRight}
      </Header>
      {children}
    </WrapperContainer>
  </TemplateContainer>
);

Template.propTypes = {
  children: PropTypes.node,
  headerRight: PropTypes.node,
};

export default Template;
