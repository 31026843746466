import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import InputComponent from './InputComponent';

const QuestionnaireForm = ({ formik, form, disabled = false }) => (
  <form onSubmit={formik.handleSubmit}>
    {form?.map((group, inx) => (
      <Box key={inx}>
        {inx > 0 && <Divider light sx={{ my: 3 }} />}
        <Grid container spacing={2}>
          {group?.map((el, index) => (
            <Grid item xs={12} key={index}>
              <InputComponent
                element={el}
                formik={formik}
                disabled={disabled}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    ))}
  </form>
);

QuestionnaireForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      date: PropTypes.string,
    }),
    errors: PropTypes.shape({}),
    submitCount: PropTypes.number,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  form: PropTypes.array,
  disabled: PropTypes.bool,
};

export default QuestionnaireForm;
