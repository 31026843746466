import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { formatISOString } from 'utils/date';

import { Date, Name, Message, WrapperContainer } from './RoomTabs.styles';

const RoomTab = ({
  room: { lastMessage, unreadCount, members },
  ownerId,
  onClick,
  isSelected,
}) => {
  const conversationUsers = useMemo(
    () => members.filter((member) => member.userId !== ownerId),
    [members, ownerId],
  );

  const lastMsgPreview = useMemo(() => {
    if (!lastMessage?.id) return 'No Messages';
    return lastMessage?.content?.body;
  }, [lastMessage]);

  return (
    <WrapperContainer
      isUnread={unreadCount}
      isSelected={isSelected}
      onClick={onClick}
    >
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Name isUnread={unreadCount}>
          {conversationUsers.map(({ name }) => name).join(', ')}
        </Name>
        <Date>
          {lastMessage?.createdAt
            ? formatISOString(lastMessage.createdAt)
            : '--'}
        </Date>
      </Box>
      <Message isUnread={unreadCount}>{lastMsgPreview}</Message>
    </WrapperContainer>
  );
};

RoomTab.propTypes = {
  room: PropTypes.shape({
    lastMessage: PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      content: PropTypes.shape({
        body: PropTypes.string,
      }),
    }),
    unreadCount: PropTypes.number,
    members: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  ownerId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default RoomTab;
