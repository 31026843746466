import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';

import { getFieldProps } from 'utils/forms';
import { ErrorText, Label } from 'utils/styles';

import Input from 'components/forms/input';

import { StyledButton } from './Chips.styles';

const Chips = ({
  name,
  value = [],
  onChange,
  disabled,
  isRequired,
  label,
  error,
  options,
  otherField,
  isMultiple = false,
}) => {
  const formik = useFormikContext();

  const chipValue = isMultiple ? value : [value];

  const handleChange = (newValue) => {
    const getValue = () => {
      if (isMultiple) {
        return value.includes(newValue)
          ? value.filter((item) => item !== newValue)
          : [...value, newValue];
      } else {
        return value === newValue ? null : newValue;
      }
    };

    onChange({
      target: {
        value: getValue(),
        name,
      },
    });
  };

  return (
    <>
      {label && (
        <Label
          htmlFor={name}
          error={!!error}
          required={isRequired}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
      <Grid container spacing={1}>
        {options?.map((option, idx) => (
          <Grid item key={`${option?.value}–${idx}`}>
            <StyledButton
              variant="contained"
              disabled={disabled}
              onClick={() => handleChange(option?.value)}
              color={chipValue?.includes(option?.value) ? 'primary' : 'inherit'}
              active={chipValue?.includes(option?.value).toString()}
            >
              {option?.label}
            </StyledButton>
          </Grid>
        ))}
        {chipValue?.includes(otherField?.value) && (
          <Grid item>
            <Input
              name={otherField?.name}
              disabled={disabled}
              placeholder="Please specify"
              {...getFieldProps({
                formik,
                name: otherField?.name,
                disabled,
              })}
            />
          </Grid>
        )}
      </Grid>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

Chips.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  isMultiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      label: PropTypes.string,
    }),
  ).isRequired,
  otherField: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
  }),
};

export default Chips;
