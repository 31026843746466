import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import { formatISOString } from 'utils/date';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

import Table from 'components/table';

const patientTableAttributes = [
  { title: 'Date' },
  { title: 'Source' },
  { title: 'Engagement Type' },
  { title: 'Details' },
  { title: 'User' },
];

const PatientHistory = () => {
  const { page, handlePageChange } = useTableState();
  const { patientId } = useParams();
  const { data, doRequest, error, isLoading } = useRequest(
    api.getPatientHistory,
  );

  const listFilters = useMemo(
    () => ({ patientId, page: page - 1, pageSize: DEFAULT_PAGE_SIZE }),
    [patientId, page],
  );

  useEffect(() => {
    doRequest(listFilters);
  }, [doRequest, listFilters]);

  const rows = useMemo(
    () =>
      (data?.data || []).map((item) => ({
        patientId,
        data: [
          formatISOString(item.timestamp),
          item.role,
          item.historyEntryType,
          item.details,
          item.userName,
        ],
      })),
    [patientId, data],
  );

  return (
    <Table
      header={patientTableAttributes}
      page={page}
      rows={rows}
      error={error}
      isLoading={isLoading}
      totalPages={data?.pageCount}
      handlePageChange={handlePageChange}
    />
  );
};

export default PatientHistory;
