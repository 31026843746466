import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { FieldArray } from 'formik';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { INPUT_TYPES, OTHER_VALUE, yesNoOptions } from 'utils/constants';
import { sideOptions } from 'utils/encounters';
import {
  LegEnum,
  LimbLocationEnum,
  LimbLocationLabel,
  LimbSideEnum,
  mapEnumToOptions,
} from 'utils/enums';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle, FormSubSectionTitle } from 'utils/styles';
import { getWoundName } from 'utils/woundImages';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';
import Select from 'components/forms/select';
import ToolTip from 'components/tooltip';

const legOptions = mapEnumToOptions({ data: LegEnum });

const limbLocationOptions = mapEnumToOptions({
  data: LimbLocationEnum,
  labels: LimbLocationLabel,
});

const limbSideOptions = mapEnumToOptions({ data: LimbSideEnum });

const woundInfectionStatusOptions = [
  { value: 'NoInfection', label: 'No infection' },
  { value: 'Infected', label: 'Infected' },
];

const appearanceOfUlcerBedOptions = [
  { value: 'SingleUlcerBed', label: 'No Single defined ulcer bed' },
  {
    value: 'DiffuseUlcerBed',
    label: 'Diffuse ulcer bed with epithelialization',
  },
];

const conditionOfWoundEdgeOptions = [
  { value: 'Attached', label: 'Attached' },
  { value: 'NotAttached', label: 'Not Attached' },
  { value: 'RolledUnder', label: 'Rolled Under' },
  { value: 'Irregular', label: 'Irregular' },
  { value: 'Callous', label: 'Callous' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const exposedStructuresTypeOptions = [
  { value: 'Bone', label: 'Bone' },
  { value: 'Tendon', label: 'Tendon' },
  { value: 'Muscle', label: 'Muscle' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const biologicTherapiesOptions = [
  { value: 'AutologousDressing', label: 'Autologous Dressing' },
  {
    value: 'CellularAndOrdTissueBasedProducts',
    label: 'Cellular and/or Tissue Based Products',
  },
  { value: 'GrowthFactors', label: 'Growth Factors' },
];

const biologicAgentsOptions = [
  {
    value: 'ElectricalStimulationTherapy',
    label: 'Electrical Stimulation Therapy',
  },
  { value: 'ElectromagneticTherapy', label: 'Electromagnetic Therapy' },
  { value: 'HyperbaricOxygenTherapy', label: 'Hyperbaric Oxygen Therapy' },
  {
    value: 'TranscutaneousOxygenTherapy',
    label: 'Transcutaneous Oxygen Therapy',
  },
  { value: 'ShockwaveTherapy', label: 'Shockwave Therapy' },
  { value: 'UltrasoundTherapy', label: 'Ultrasound Therapy' },
];

const debridementsOptions = [
  { value: 'Mechanical', label: 'Mechanical' },
  { value: 'Collagenase', label: 'Collagenase' },
  { value: 'Maggot', label: 'Maggot' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const dressingsOptions = [
  { value: 'Alginates', label: 'Alginates' },
  {
    value: 'AntimicrobialAntibacterialDressings',
    label: 'Antimicrobial/Antibacterial Dressings',
  },
  { value: 'Collagens', label: 'Collagens' },
  { value: 'Composites', label: 'Composites' },
  { value: 'ContactLayers', label: 'Contact Layers' },
  { value: 'FoamDressings', label: 'Foam Dressings' },
  { value: 'GauzesAndNonWovens', label: 'Gauzes & Non-Wovens' },
  { value: 'GellingFiberDressings', label: 'Gelling Fiber Dressings' },
  { value: 'Hydrocolloids', label: 'Hydrocolloids' },
  { value: 'HydrogelsAmorphouse', label: 'Hydrogels: Amorphouse' },
  { value: 'HydrogelsImpregnanted', label: 'Hydrogels: Impregnanted' },
  { value: 'HydrogelsSheets', label: 'Hydrogels: Sheets' },
  { value: 'ImpregnatedDressings', label: 'Impregnated Dressings' },
  { value: 'MedicalGradeHoney', label: 'Medical Grade Honey' },
  { value: 'SiliconeGelSheets', label: 'Silicone Gel Sheets' },
  {
    value: 'SpecialtyAbsorptivesSuperAbsorbants',
    label: 'Specialty Absorptives / Super Absorbants',
  },
  { value: 'TransparentFilms', label: 'Transparent Films' },
  { value: 'WoundFillers', label: 'Wound Fillers' },
];

const painManagementAnalgesicsOptions = [
  { value: 'OralAnalgesic', label: 'Oral Analgesic' },
  { value: 'TopicalAnalgesic', label: 'Topical Analgesic' },
];

const skinCareOptions = [
  { value: 'AdhesiveRemovers', label: 'Adhesive Removers' },
  { value: 'AntimicrobialsAntifungals', label: 'Antimicrobials/Antifungals' },
  { value: 'Cleansers', label: 'Cleansers' },
  {
    value: 'LiquidSkinProtectantsSealants',
    label: 'Liquid Skin Protectants/Sealants',
  },
  { value: 'MoistureBarriers', label: 'Moisture Barriers' },
  { value: 'TherapeuticMoisturizers', label: 'Therapeutic Moisturizers' },
];

const oralMedicationsOptions = [
  { value: 'OralAntibiotic', label: 'Topical Antibiotic' },
  { value: 'OralAnalgesic', label: 'Oral Analgesic' },
];

const woundBedCleaningOptions = [
  { value: 'TapWater', label: 'Tap Water' },
  { value: 'SpecialCleansing', label: 'Special Cleansing' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const exudateVolumeOptions = [
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
];

const exudateTypeOptions = [
  { value: 'Haemoserous', label: 'Haemoserous' },
  { value: 'Serous', label: 'Serous' },
  { value: 'Pus', label: 'Pus' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const exudateColorOptions = [
  { value: 'Red', label: 'Red' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Green', label: 'Green' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const capillaryRefillTimeOptions = [
  { value: 'LessThan3Seconds', label: '<3 seconds' },
  { value: 'GreaterThan3Seconds', label: '>3 seconds' },
];

const pittingEdemaSeverityOptions = [
  {
    value: 'RapidlyDisappears',
    label: '1+ : ≤ 2mm pitting that disappears rapidly',
  },
  {
    value: 'DisappearsIn10To15Seconds',
    label: '2+ : 2-4 mm pitting that disappears in 10-15 seconds',
  },
  {
    value: 'DisappearsAfter1Minute',
    label:
      '3+ : 4-6 mm pitting that may last more than 1 minute; dependent extremity looks fuller',
  },
  {
    value: 'DisappearsAfter2Minute',
    label:
      '4+ : 6-8 mm pitting that may last more than 2 minute; dependent extremity is grossly distorted',
  },
];

const OTHER_OPTION_EXERCISE = 'Exercise';
const OTHER_OPTION_MENTAL = 'MentalTreatments';
const OTHER_OPTION_NUTRITIONAL = 'NutritionalManagement';
const OTHER_OPTION_SOCIAL = 'Social';

const otherOptions = [
  { value: OTHER_OPTION_EXERCISE, label: 'Exercise' },
  { value: OTHER_OPTION_MENTAL, label: 'Mental Treatments' },
  { value: OTHER_OPTION_NUTRITIONAL, label: 'Nutritional Management' },
  { value: OTHER_OPTION_SOCIAL, label: 'Social' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const fields = {
  WOUND_ASSESSMENT_DATE: 'woundAssessmentDate',
  ID: 'id',
  WOUND: 'woundId',
  LEG: 'leg',
  LIMB_LOCATION: 'limbLocation',
  LIMB_LOCATION_OTHER: 'limbLocationOther',
  LIMB_SIDE: 'limbSide',
  ALL_ULCERS_HEALED: 'allUlcersHealed',
  ULCER_RECCURED: 'recurredUlcer',
  DATE_OF_RECURRENCE: 'recurredUlcerDate',
  NUMBER_OF_ULCERS: 'ulcersCount',
  LONGEST_LENGTH: 'length',
  GREATEST_WIDTH: 'width',
  APPROXIMATED_SURFACE_AREA: 'totalArea',
  WOUND_DEPTH: 'woundDepth',
  WOUND_INFECTION_STATUS: 'woundInfectionStatus',
  APPEARANCE_OF_ULCER_BED: 'ulcerBeds',
  CONDITION_OF_WOUND_EDGE: 'woundEdgeConditions',
  CONDITION_OF_WOUND_EDGE_OTHER: 'woundEdgeConditionsOther',
  WOUND_TREATMENT: 'woundTreatment',
  ADJUNCTIVE_WOUND_TREATMENTS: 'adjunctiveWoundTreatments',
  BIOLOGIC_THERAPIES: 'biologicTherapies',
  BIOLOGIC_AGENTS: 'biophysicalAgents',
  DEBRIDEMENTS: 'debridements',
  DEBRIDEMENTS_OTHER: 'debridementOther',
  DRESSINGS: 'dressings',
  PAIN_MANAGEMENT_ANALGESICS: 'painManagementAnalgesics',
  SKIN_CARE: 'skinCare',
  ORAL_MEDICATIONS: 'oralMedications',
  WOUND_BED_CLEANING: 'woundBedCleaning',
  WOUND_BED_CLEANING_OTHER: 'woundBedCleaningOther',
  OTHER_TREATMENTS: 'otherTreatments',
  OTHER_TREATMENT_EXERCISE: 'otherTreatmentExercise',
  OTHER_TREATMENT_MENTAL: 'otherTreatmentMental',
  OTHER_TREATMENT_NUTRITIONAL: 'otherTreatmentNutritionalManagement',
  OTHER_TREATMENT_SOCIAL: 'otherTreatmentSocial',
  OTHER_TREATMENT: 'otherTreatment',
  EXPOSED_STRUCTURES: 'exposedStructures',
  EXPOSED_STRUCTURES_TYPE: 'exposedStructuresTypes',
  EXPOSED_STRUCTURES_TYPE_OTHER: 'exposedStructuresTypesOther',
  EXUDATE_VOLUME: 'exudateVolume',
  EXUDATE_TYPE: 'exudateTypes',
  EXUDATE_TYPE_OTHER: 'exudateTypesOther',
  EXUDATE_COLOR: 'exudateColors',
  EXUDATE_COLOR_OTHER: 'exudateColorsOther',
  EXUDATE_ODOROUS: 'exudateOdorous',
  WOUND_SWAB_DATE: 'woundSwabDate',
  CAPILLARY_REFILL: 'capillaryRefill',
  CAPILLARY_REFILL_TIME: 'capillaryRefillTime',
  CALF_LEFT: 'calfCircumferenceLargestLeftPoint',
  CALF_RIGHT: 'calfCircumferenceLargestRightPoint',
  PITTING_EDEMA_LEFT: 'pittingEdemaLeft',
  PITTING_EDEMA_RIGHT: 'pittingEdemaRight',
  PITTING_EDEMA_SEVERITY_LEFT: 'pittingEdemaSeverityLeft',
  PITTING_EDEMA_SEVERITY_RIGHT: 'pittingEdemaSeverityRight',
};

const NumberOfUlcersLabel = () => (
  <ToolTip
    content={
      <span>
        Specify the number of separate ulcers in the selected location. For
        example, enter &apos;2&apos; if there are two unique ulcers in the
        chosen location (e.g., Medial Ankle/Gaiter)
      </span>
    }
  >
    <span>Number of Ulcer(s) in the specified location</span>
  </ToolTip>
);

const measurementsFields = [
  {
    label: <NumberOfUlcersLabel />,
    name: fields.NUMBER_OF_ULCERS,
    isRequired: true,
    unit: '',
  },
  {
    label: 'Longest Length',
    name: fields.LONGEST_LENGTH,
    isRequired: false,
    unit: 'cm',
  },
  {
    label: 'Greatest Width (Perpendicular to Length)',
    name: fields.GREATEST_WIDTH,
    isRequired: false,
    unit: 'cm',
  },
  {
    label: 'Approximate Surface Area',
    name: fields.APPROXIMATED_SURFACE_AREA,
    isRequired: false,
    unit: 'cm sq',
  },
  {
    label: 'Wound Depth',
    name: fields.WOUND_DEPTH,
    isRequired: true,
    unit: 'cm',
  },
];

const adjunctiveWoundTreatmentsFields = [
  {
    label: 'Biologic therapies',
    name: fields.BIOLOGIC_THERAPIES,
    options: biologicTherapiesOptions,
  },
  {
    label: 'Biologic agents',
    name: fields.BIOLOGIC_AGENTS,
    options: biologicAgentsOptions,
    md: 8,
  },
  {
    label: 'Dressings',
    name: fields.DRESSINGS,
    options: dressingsOptions,
    md: 12,
  },
  {
    label: 'Skin care',
    name: fields.SKIN_CARE,
    options: skinCareOptions,
    md: 8,
  },
  {
    label: 'Pain management analgesics',
    name: fields.PAIN_MANAGEMENT_ANALGESICS,
    options: painManagementAnalgesicsOptions,
  },
  {
    label: 'Oral medications',
    name: fields.ORAL_MEDICATIONS,
    options: oralMedicationsOptions,
  },
];

const adjunctiveWoundTreatmentsOtherFields = [
  {
    label: 'Debridements',
    name: fields.DEBRIDEMENTS,
    options: debridementsOptions,
  },
  {
    label: 'Wound Bed Cleaning',
    name: fields.WOUND_BED_CLEANING,
    options: woundBedCleaningOptions,
  },
];

const otherFields = [
  {
    label: 'Exercise',
    name: fields.OTHER_TREATMENT_EXERCISE,
    option: OTHER_OPTION_EXERCISE,
  },
  {
    label: 'Mental',
    name: fields.OTHER_TREATMENT_MENTAL,
    option: OTHER_OPTION_MENTAL,
  },
  {
    label: 'Nutritional management',
    name: fields.OTHER_TREATMENT_NUTRITIONAL,
    option: OTHER_OPTION_NUTRITIONAL,
  },
  {
    label: 'Social',
    name: fields.OTHER_TREATMENT_SOCIAL,
    option: OTHER_OPTION_SOCIAL,
  },
  { label: OTHER_VALUE, name: fields.OTHER_TREATMENT, option: OTHER_VALUE },
];

const woundBeConditionsFields = [
  { label: 'Healthy Granulation (Red)', name: 'healthyGranulation' },
  { label: 'Slough (Yellow/Brown)', name: 'slough' },
  { label: 'Necrotic (Black)', name: 'necrotic' },
  {
    label: 'Infected / Critically Colonised (Green)',
    name: 'infectedOrCriticallyColonised',
  },
  { label: 'Cellulitic', name: 'cellulitic' },
  { label: 'Epithelialization', name: 'epithelialization' },
];

const extremityDetailsFields = [
  { label: 'Varicose Veins', name: 'varicoseVeins' },
  { label: 'Black/Brown (Hemosiderin) Staining', name: 'blackBrownStaining' },
  { label: 'Ankle flare (visible capillary distension)', name: 'ankleFlare' },
  { label: 'Eczema (varicose)', name: 'eczema' },
  { label: 'Hyperkeratosis', name: 'hyperkeratosis' },
  { label: 'Atrophie blanche (painful white area)', name: 'atrophieBlanche' },
  { label: 'Induration (Hardness)', name: 'induration' },
  { label: 'Erythema', name: 'erythema' },
  { label: 'Dependent Rubor', name: 'dependentRubor' },
  { label: 'Hyperpigmentation', name: 'hyperpigmentation' },
  { label: 'Lipodermatosclerosis', name: 'lipodermatosclerosis' },
];

const newWound = 'newWound';

const woundAssessmentInitialValues = {
  [fields.WOUND]: newWound,
  [fields.ID]: null,
  [fields.LEG]: null,
  [fields.LIMB_LOCATION]: null,
  [fields.LIMB_LOCATION_OTHER]: null,
  [fields.LIMB_SIDE]: null,
  [fields.ALL_ULCERS_HEALED]: null,
  [fields.ULCER_RECCURED]: null,
  [fields.NUMBER_OF_ULCERS]: null,
  [fields.LONGEST_LENGTH]: null,
  [fields.GREATEST_WIDTH]: null,
  [fields.APPROXIMATED_SURFACE_AREA]: null,
  [fields.WOUND_DEPTH]: null,
  [fields.WOUND_INFECTION_STATUS]: null,
  [fields.APPEARANCE_OF_ULCER_BED]: [],
  [fields.CONDITION_OF_WOUND_EDGE]: [],
  [fields.CONDITION_OF_WOUND_EDGE_OTHER]: null,
  [fields.ADJUNCTIVE_WOUND_TREATMENTS]: null,
  [fields.WOUND_TREATMENT]: {},
};

const otherFieldMaps = {
  [OTHER_OPTION_EXERCISE]: fields.OTHER_TREATMENT_EXERCISE,
  [OTHER_OPTION_MENTAL]: fields.OTHER_TREATMENT_MENTAL,
  [OTHER_OPTION_NUTRITIONAL]: fields.OTHER_TREATMENT_NUTRITIONAL,
  [OTHER_OPTION_SOCIAL]: fields.OTHER_TREATMENT_SOCIAL,
  [OTHER_VALUE]: fields.OTHER_TREATMENT,
};

export const parseWoundAssessmentData = (values, isForm2Api) => {
  const { wounds, ...rest } = values;
  const newWounds = wounds?.map(({ woundId, ...wound }) => {
    const id = isForm2Api
      ? woundId === newWound
        ? null
        : woundId
      : woundId || newWound;
    return {
      woundId: id,
      ...wound,
    };
  });
  return { wounds: newWounds, ...rest };
};

export const WoundAssessment = ({ disabled, formik }) => {
  const { patientId } = useParams();

  const { doRequest: getWounds, data, isLoading } = useRequest(api.getWounds);

  React.useEffect(() => {
    getWounds(patientId);
  }, [getWounds, patientId]);

  const woundOptions = React.useMemo(() => {
    const wounds = data?.data?.map((wound) => {
      return {
        value: wound?.id,
        label: getWoundName(wound),
      };
    });
    wounds?.push({ value: newWound, label: 'New Wound' });
    return wounds;
  }, [data]);

  if (isLoading) return null;

  return (
    <FieldArray
      name="wounds"
      render={({ form, push, remove }) => (
        <>
          <FormSectionTitle>Date of Wound Assessment</FormSectionTitle>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date of Wound Assessment Form"
                isRequired
                {...getFieldProps({
                  formik,
                  name: fields.WOUND_ASSESSMENT_DATE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ alignSelf: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => push(woundAssessmentInitialValues)}
                disabled={disabled}
              >
                Add Wound to be Assessed
              </Button>
            </Grid>
          </Grid>
          {form.values?.wounds?.map((_, index) => {
            const isDisabled =
              formik.values.wounds[index][fields.WOUND] !== newWound ||
              disabled;
            return (
              <React.Fragment key={index}>
                <FormSectionTitle>#{index} - VLU Location(s)</FormSectionTitle>
                <Grid container spacing={2} sx={{ py: 2 }}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Select
                        label="Wound to be Assessed"
                        options={woundOptions || []}
                        isRequired
                        {...getFieldProps({
                          formik,
                          name: `wounds.${index}.${fields.WOUND}`,
                          disabled,
                          changeOverride: (value) => {
                            const wound =
                              data?.data?.find(({ id }) => id === value) || {};
                            formik?.setValues(
                              update(formik?.values, {
                                wounds: {
                                  [index]: {
                                    [fields.WOUND]: { $set: value },
                                    [fields.LEG]: { $set: wound?.leg },
                                    [fields.LIMB_LOCATION]: {
                                      $set: wound?.limbLocation,
                                    },
                                    [fields.LIMB_LOCATION_OTHER]: {
                                      $set: wound?.limbLocationOther,
                                    },
                                    [fields.LIMB_SIDE]: {
                                      $set: wound?.limbSide,
                                    },
                                  },
                                },
                              }),
                            );
                          },
                        })}
                      ></Select>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ alignSelf: 'flex-end' }}>
                      <Button
                        variant="contained"
                        onClick={() => remove(index)}
                        disabled={disabled}
                      >
                        Remove wound #{index}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Chips
                        label="Limb"
                        options={legOptions}
                        isRequired
                        {...getFieldProps({
                          formik,
                          name: `wounds.${index}.${fields.LEG}`,
                          disabled: isDisabled,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Chips
                        label="Location on the limb"
                        options={limbLocationOptions}
                        isRequired
                        otherField={{
                          name: `wounds.${index}.${fields.LIMB_LOCATION_OTHER}`,
                          value: OTHER_VALUE,
                        }}
                        {...getFieldProps({
                          formik,
                          name: `wounds.${index}.${fields.LIMB_LOCATION}`,
                          disabled: isDisabled,
                          changeOverride: (value) => {
                            if (value === OTHER_VALUE) {
                              formik?.setValues(
                                update(formik?.values, {
                                  wounds: {
                                    [index]: {
                                      [fields.LIMB_LOCATION]: {
                                        $set: value,
                                      },
                                      [fields.LIMB_LOCATION_OTHER]: {
                                        $set: '',
                                      },
                                    },
                                  },
                                }),
                              );
                            }
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Chips
                        label="Side of the limb"
                        options={limbSideOptions}
                        isRequired
                        {...getFieldProps({
                          formik,
                          name: `wounds.${index}.${fields.LIMB_SIDE}`,
                          disabled: isDisabled,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <FormSectionTitle>
                  #{index} - Ulcer Healing & Recurrence
                </FormSectionTitle>
                <Grid container spacing={2} sx={{ py: 2 }}>
                  <Grid item xs={12} md={4}>
                    <Chips
                      label="Have all ulcers in the specified location completely healed?"
                      options={yesNoOptions}
                      isRequired
                      {...getFieldProps({
                        formik,
                        name: `wounds.${index}.${fields.ALL_ULCERS_HEALED}`,
                        disabled,
                        changeOverride: (value) => {
                          if (!value) {
                            formik?.setValues(
                              update(formik?.values, {
                                wounds: {
                                  [index]: {
                                    [fields.ALL_ULCERS_HEALED]: {
                                      $set: value,
                                    },
                                    [fields.ULCER_RECCURED]: {
                                      $set: null,
                                    },
                                    [fields.DATE_OF_RECURRENCE]: {
                                      $set: null,
                                    },
                                    [fields.NUMBER_OF_ULCERS]: {
                                      $set: null,
                                    },
                                    [fields.LONGEST_LENGTH]: {
                                      $set: null,
                                    },
                                    [fields.GREATEST_WIDTH]: {
                                      $set: null,
                                    },
                                    [fields.APPROXIMATED_SURFACE_AREA]: {
                                      $set: null,
                                    },
                                    [fields.WOUND_DEPTH]: {
                                      $set: null,
                                    },
                                    [fields.WOUND_INFECTION_STATUS]: {
                                      $set: null,
                                    },
                                    [fields.APPEARANCE_OF_ULCER_BED]: {
                                      $set: [],
                                    },
                                    [fields.CONDITION_OF_WOUND_EDGE]: {
                                      $set: [],
                                    },
                                    [fields.CONDITION_OF_WOUND_EDGE_OTHER]: {
                                      $set: null,
                                    },
                                    [fields.ADJUNCTIVE_WOUND_TREATMENTS]: {
                                      $set: null,
                                    },
                                    [fields.WOUND_TREATMENT]: {
                                      $set: {},
                                    },
                                  },
                                },
                              }),
                            );
                          }
                        },
                      })}
                    />
                  </Grid>
                  {formik.values.wounds[index][fields.ALL_ULCERS_HEALED] && (
                    <>
                      <Grid item xs={12} md={4}>
                        <Chips
                          label="Has the ulcer reccured in this location since healing?"
                          options={yesNoOptions}
                          isRequired
                          {...getFieldProps({
                            formik,
                            name: `wounds.${index}.${fields.ULCER_RECCURED}`,
                            disabled,
                            changeOverride: (value) => {
                              if (!value) {
                                formik?.setValues(
                                  update(formik?.values, {
                                    wounds: {
                                      [index]: {
                                        [fields.ULCER_RECCURED]: {
                                          $set: value,
                                        },
                                        [fields.DATE_OF_RECURRENCE]: {
                                          $set: null,
                                        },
                                      },
                                    },
                                  }),
                                );
                              }
                            },
                          })}
                        />
                      </Grid>
                      {formik.values.wounds[index][fields.ULCER_RECCURED] && (
                        <Grid item xs={12} md={4}>
                          <DateSelector
                            label="Date of reccurrence"
                            isRequired
                            {...getFieldProps({
                              formik,
                              name: `wounds.${index}.${fields.DATE_OF_RECURRENCE}`,
                              disabled,
                            })}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                {(![true, null, undefined].includes(
                  formik.values.wounds[index][fields.ALL_ULCERS_HEALED],
                ) ||
                  formik.values.wounds[index][fields.ULCER_RECCURED]) && (
                  <>
                    <FormSectionTitle>
                      #{index} - Ulcer Measurements
                    </FormSectionTitle>
                    <Grid container spacing={2} sx={{ py: 2 }}>
                      {measurementsFields.map(
                        ({ label, name, isRequired, unit }) => (
                          <Grid item xs={12} md={4} key={name}>
                            <Input
                              label={label}
                              type={INPUT_TYPES.NUMERIC}
                              isRequired={isRequired}
                              endAdornment={
                                unit && (
                                  <InputAdornment position="end">
                                    {unit}
                                  </InputAdornment>
                                )
                              }
                              {...getFieldProps({
                                formik,
                                name: `wounds.${index}.${name}`,
                                disabled,
                              })}
                            />
                          </Grid>
                        ),
                      )}
                      <Grid item xs={12} md={4}>
                        <Chips
                          label="Wound Infection Status"
                          options={woundInfectionStatusOptions}
                          isRequired
                          {...getFieldProps({
                            formik,
                            name: `wounds.${index}.${fields.WOUND_INFECTION_STATUS}`,
                            disabled,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Chips
                          label="Appearance of Ulcer Bed"
                          options={appearanceOfUlcerBedOptions}
                          isMultiple
                          {...getFieldProps({
                            formik,
                            name: `wounds.${index}.${fields.APPEARANCE_OF_ULCER_BED}`,
                            disabled,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Chips
                          label="Condition of Wound Edge"
                          options={conditionOfWoundEdgeOptions}
                          isMultiple
                          otherField={{
                            name: `wounds.${index}.${fields.CONDITION_OF_WOUND_EDGE_OTHER}`,
                            value: OTHER_VALUE,
                          }}
                          {...getFieldProps({
                            formik,
                            name: `wounds.${index}.${fields.CONDITION_OF_WOUND_EDGE}`,
                            disabled,
                            changeOverride: (value) => {
                              if (!value.includes(OTHER_VALUE)) {
                                formik?.setValues(
                                  update(formik?.values, {
                                    wounds: {
                                      [index]: {
                                        [fields.CONDITION_OF_WOUND_EDGE]: {
                                          $set: value,
                                        },
                                        [fields.CONDITION_OF_WOUND_EDGE_OTHER]:
                                          {
                                            $set: null,
                                          },
                                      },
                                    },
                                  }),
                                );
                              }
                            },
                          })}
                        />
                      </Grid>
                    </Grid>
                    <FormSectionTitle>
                      #{index} - Adjunctive Wound Treatments
                    </FormSectionTitle>
                    <Grid container spacing={2} sx={{ py: 2 }}>
                      <Grid item xs={12}>
                        <Chips
                          label="Were any adjunctive wound treatments given during the visit?"
                          options={yesNoOptions}
                          isRequired
                          {...getFieldProps({
                            formik,
                            name: `wounds.${index}.${fields.ADJUNCTIVE_WOUND_TREATMENTS}`,
                            disabled,
                            changeOverride: (value) => {
                              if (!value) {
                                formik?.setValues(
                                  update(formik?.values, {
                                    wounds: {
                                      [index]: {
                                        [fields.ADJUNCTIVE_WOUND_TREATMENTS]: {
                                          $set: value,
                                        },
                                        [fields.WOUND_TREATMENT]: {
                                          $set: {},
                                        },
                                      },
                                    },
                                  }),
                                );
                              }
                            },
                          })}
                        />
                      </Grid>
                      {formik.values.wounds[index][
                        fields.ADJUNCTIVE_WOUND_TREATMENTS
                      ] && (
                        <>
                          <>
                            {adjunctiveWoundTreatmentsFields.map(
                              ({ label, name, options, md }) => (
                                <Grid item xs={12} md={md || 4} key={name}>
                                  <Chips
                                    label={label}
                                    options={options}
                                    isMultiple
                                    {...getFieldProps({
                                      formik,
                                      name: `wounds.${index}.${fields.WOUND_TREATMENT}.${name}`,
                                      disabled,
                                    })}
                                  />
                                </Grid>
                              ),
                            )}
                          </>
                          <>
                            {adjunctiveWoundTreatmentsOtherFields.map(
                              ({ label, name, options }) => (
                                <Grid item xs={12} md={4} key={name}>
                                  <Chips
                                    label={label}
                                    options={options}
                                    isMultiple
                                    otherField={{
                                      name: `wounds.${index}.${fields.WOUND_TREATMENT}.${name}Other`,
                                      value: OTHER_VALUE,
                                    }}
                                    {...getFieldProps({
                                      formik,
                                      name: `wounds.${index}.${fields.WOUND_TREATMENT}.${name}`,
                                      disabled,
                                      changeOverride: (value) => {
                                        if (!value.includes(OTHER_VALUE)) {
                                          formik?.setValues(
                                            update(formik?.values, {
                                              wounds: {
                                                [index]: {
                                                  [fields.WOUND_TREATMENT]: {
                                                    [name]: { $set: value },
                                                    [name + 'Other']: {
                                                      $set: '',
                                                    },
                                                  },
                                                },
                                              },
                                            }),
                                          );
                                        }
                                      },
                                    })}
                                  />
                                </Grid>
                              ),
                            )}
                          </>
                          <>
                            <Grid item xs={12} md={4}>
                              <Chips
                                label="Other"
                                options={otherOptions}
                                isMultiple
                                {...getFieldProps({
                                  formik,
                                  name: `wounds.${index}.${fields.WOUND_TREATMENT}.${fields.OTHER_TREATMENTS}`,
                                  disabled,
                                  changeOverride: (value) =>
                                    formik?.setValues(
                                      update(formik?.values, {
                                        wounds: {
                                          [index]: {
                                            [fields.WOUND_TREATMENT]:
                                              otherOptions?.reduce(
                                                (res, opt) =>
                                                  value?.includes(opt?.value)
                                                    ? res
                                                    : {
                                                        ...res,
                                                        [otherFieldMaps[
                                                          opt?.value
                                                        ]]: {
                                                          $set: null,
                                                        },
                                                      },
                                                {
                                                  [fields.OTHER_TREATMENTS]: {
                                                    $set: value,
                                                  },
                                                },
                                              ),
                                          },
                                        },
                                      }),
                                    ),
                                })}
                              />
                            </Grid>
                            {otherFields.map(
                              ({ name, label, option }) =>
                                formik.values.wounds[index][
                                  fields.WOUND_TREATMENT
                                ][fields.OTHER_TREATMENTS]?.includes(
                                  option,
                                ) && (
                                  <Grid item xs={12} md={4} key={name}>
                                    <Input
                                      label={label + ' Treatment'}
                                      style={{ marginBottom: '1rem' }}
                                      {...getFieldProps({
                                        formik,
                                        name: `wounds.${index}.${fields.WOUND_TREATMENT}.${name}`,
                                        disabled,
                                      })}
                                    />
                                  </Grid>
                                ),
                            )}
                          </>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </React.Fragment>
            );
          })}
          <FormSectionTitle>Ulcer & Limb Details</FormSectionTitle>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid item xs={12}>
              <FormSubSectionTitle>Wound Bed Condition</FormSubSectionTitle>
            </Grid>
            {woundBeConditionsFields.map(({ label, name }) => (
              <Grid item xs={12} md={4} key={name}>
                <Input
                  label={label}
                  type={INPUT_TYPES.NUMERIC}
                  min={0}
                  max={100}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  {...getFieldProps({
                    formik,
                    name,
                    disabled,
                  })}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={4}>
              <Chips
                label="Exposed Structures (Bone/Tendon/Muscle)"
                options={yesNoOptions}
                {...getFieldProps({
                  formik,
                  name: fields.EXPOSED_STRUCTURES,
                  disabled,
                  changeOverride: (value) => {
                    if (!value) {
                      formik?.setValues(
                        update(formik?.values, {
                          [fields.EXPOSED_STRUCTURES]: {
                            $set: value,
                          },
                          [fields.EXPOSED_STRUCTURES_TYPE]: {
                            $set: [],
                          },
                        }),
                      );
                    }
                  },
                })}
              />
            </Grid>
            {formik.values[fields.EXPOSED_STRUCTURES] && (
              <Grid item xs={12} md={8}>
                <Chips
                  label="Type"
                  options={exposedStructuresTypeOptions}
                  isMultiple
                  otherField={{
                    name: fields.EXPOSED_STRUCTURES_TYPE_OTHER,
                    value: OTHER_VALUE,
                  }}
                  {...getFieldProps({
                    formik,
                    name: fields.EXPOSED_STRUCTURES_TYPE,
                    disabled,
                    changeOverride: (value) => {
                      if (!value.includes(OTHER_VALUE)) {
                        formik?.setValues(
                          update(formik?.values, {
                            [fields.EXPOSED_STRUCTURES_TYPE]: {
                              $set: value,
                            },
                            [fields.EXPOSED_STRUCTURES_TYPE_OTHER]: {
                              $set: null,
                            },
                          }),
                        );
                      }
                    },
                  })}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormSubSectionTitle>Exudate</FormSubSectionTitle>
            </Grid>
            <Grid item xs={12} md={4}>
              <Chips
                label="Volume"
                options={exudateVolumeOptions}
                {...getFieldProps({
                  formik,
                  name: fields.EXUDATE_VOLUME,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Chips
                label="Exudate Type"
                options={exudateTypeOptions}
                isMultiple
                otherField={{
                  name: fields.EXUDATE_TYPE_OTHER,
                  value: OTHER_VALUE,
                }}
                {...getFieldProps({
                  formik,
                  name: fields.EXUDATE_TYPE,
                  disabled,
                  changeOverride: (value) => {
                    if (!value.includes(OTHER_VALUE)) {
                      formik?.setValues(
                        update(formik?.values, {
                          [fields.EXUDATE_TYPE]: {
                            $set: value,
                          },
                          [fields.EXUDATE_TYPE_OTHER]: {
                            $set: null,
                          },
                        }),
                      );
                    }
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Chips
                label="Exudate Color"
                options={exudateColorOptions}
                isMultiple
                otherField={{
                  name: fields.EXUDATE_COLOR_OTHER,
                  value: OTHER_VALUE,
                }}
                {...getFieldProps({
                  formik,
                  name: fields.EXUDATE_COLOR,
                  disabled,
                  changeOverride: (value) => {
                    if (!value.includes(OTHER_VALUE)) {
                      formik?.setValues(
                        update(formik?.values, {
                          [fields.EXUDATE_COLOR]: {
                            $set: value,
                          },
                          [fields.EXUDATE_COLOR_OTHER]: {
                            $set: null,
                          },
                        }),
                      );
                    }
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Chips
                label="Odorous"
                options={yesNoOptions}
                {...getFieldProps({
                  formik,
                  name: fields.EXUDATE_ODOROUS,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Wound Swab Date Taken"
                {...getFieldProps({
                  formik,
                  name: fields.WOUND_SWAB_DATE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSubSectionTitle>Extremity Details</FormSubSectionTitle>
            </Grid>
            {extremityDetailsFields.map(({ label, name }) => (
              <Grid item xs={12} md={4} key={name}>
                <Chips
                  label={label}
                  options={sideOptions}
                  {...getFieldProps({
                    formik,
                    name,
                    disabled,
                  })}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={4}>
              <Chips
                label="Capillary Refill"
                options={sideOptions}
                {...getFieldProps({
                  formik,
                  name: fields.CAPILLARY_REFILL,
                  disabled,
                  changeOverride: (value) => {
                    if (value === 'No') {
                      formik?.setValues(
                        update(formik?.values, {
                          [fields.CAPILLARY_REFILL]: {
                            $set: value,
                          },
                          [fields.CAPILLARY_REFILL_TIME]: {
                            $set: null,
                          },
                        }),
                      );
                    }
                  },
                })}
              />
            </Grid>
            {!['No', null, undefined].includes(
              formik.values[fields.CAPILLARY_REFILL],
            ) && (
              <Grid item xs={12} md={4}>
                <Chips
                  label="Capillary Refill Time"
                  options={capillaryRefillTimeOptions}
                  {...getFieldProps({
                    formik,
                    name: fields.CAPILLARY_REFILL_TIME,
                    disabled,
                  })}
                />
              </Grid>
            )}
          </Grid>
          <FormSectionTitle>Edema & Calf Assessment</FormSectionTitle>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <Input
                  label="Left Calf Circumference at largest point"
                  type={INPUT_TYPES.NUMERIC}
                  endAdornment={
                    <InputAdornment position="end">cm</InputAdornment>
                  }
                  {...getFieldProps({
                    formik,
                    name: fields.CALF_LEFT,
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Right Calf Circumference at largest point"
                  type={INPUT_TYPES.NUMERIC}
                  endAdornment={
                    <InputAdornment position="end">cm</InputAdornment>
                  }
                  {...getFieldProps({
                    formik,
                    name: fields.CALF_RIGHT,
                    disabled,
                  })}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <Chips
                  label="Pitting Edema Present (Left)"
                  options={yesNoOptions}
                  {...getFieldProps({
                    formik,
                    name: fields.PITTING_EDEMA_LEFT,
                    disabled,
                    changeOverride: (value) => {
                      if (!value) {
                        formik?.setValues(
                          update(formik?.values, {
                            [fields.PITTING_EDEMA_LEFT]: {
                              $set: value,
                            },
                            [fields.PITTING_EDEMA_SEVERITY_LEFT]: {
                              $set: null,
                            },
                          }),
                        );
                      }
                    },
                  })}
                />
              </Grid>
              {formik.values[fields.PITTING_EDEMA_LEFT] && (
                <Grid item xs={12} md={8}>
                  <Chips
                    label="Pitting Edema Severity (Left)"
                    options={pittingEdemaSeverityOptions}
                    {...getFieldProps({
                      formik,
                      name: fields.PITTING_EDEMA_SEVERITY_LEFT,
                      disabled,
                    })}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <Chips
                  label="Pitting Edema Present (Right)"
                  options={yesNoOptions}
                  {...getFieldProps({
                    formik,
                    name: fields.PITTING_EDEMA_RIGHT,
                    disabled,
                    changeOverride: (value) => {
                      if (!value) {
                        formik?.setValues(
                          update(formik?.values, {
                            [fields.PITTING_EDEMA_RIGHT]: {
                              $set: value,
                            },
                            [fields.PITTING_EDEMA_SEVERITY_RIGHT]: {
                              $set: null,
                            },
                          }),
                        );
                      }
                    },
                  })}
                />
              </Grid>
              {formik.values[fields.PITTING_EDEMA_RIGHT] && (
                <Grid item xs={12} md={8}>
                  <Chips
                    label="Pitting Edema Severity (Right)"
                    options={pittingEdemaSeverityOptions}
                    {...getFieldProps({
                      formik,
                      name: fields.PITTING_EDEMA_SEVERITY_RIGHT,
                      disabled,
                    })}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

WoundAssessment.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      wounds: PropTypes.array,
    }),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default WoundAssessment;
