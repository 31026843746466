import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { actions as accountActions, selectors } from 'store/account';

const useUser = () => {
  const dispatch = useDispatch();

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getCurrentAccountDetails: accountActions.doGetCurrentAccountDetails,
          getCurrentAccountLogoUrl: accountActions.doGetCurrentAccountLogoUrl,
        },
        dispatch,
      ),
    [dispatch],
  );

  const accountDetails = useSelector(selectors.getAccount) ?? {};
  const accountDetailsLoading = useSelector(selectors.getAccountLoading);
  const accountLogoUrl = useSelector(selectors.getAccountLogoUrl);

  return {
    ...actions,
    accountDetails,
    accountDetailsLoading,
    accountLogoUrl,
  };
};

export default useUser;
