import api, { getRootPath } from 'core/api';
import { ROOT_PATHS } from 'navigation/paths';

import {
  GET_WOUND_IMAGES_REQUEST,
  GET_WOUND_IMAGES_SUCCESS,
  GET_WOUND_IMAGES_FAILURE,
  GET_WOUND_IMAGE_REQUEST,
  GET_WOUND_IMAGE_SUCCESS,
  GET_WOUND_IMAGE_FAILURE,
  GET_WOUND_IMAGE_NOTES_REQUEST,
  GET_WOUND_IMAGE_NOTES_SUCCESS,
  GET_WOUND_IMAGE_NOTES_FAILURE,
  UPDATE_WOUND_IMAGE_REQUEST,
  UPDATE_WOUND_IMAGE_SUCCESS,
  UPDATE_WOUND_IMAGE_FAILURE,
} from './actionTypes';

const isDetector = () => getRootPath() === ROOT_PATHS.woundDetector;

export const doGetWoundImages = (options) => async (dispatch) => {
  dispatch({
    type: GET_WOUND_IMAGES_REQUEST,
  });
  try {
    const requestFunc = isDetector()
      ? api.getWoundDetectorImages
      : api.getWoundImages;
    const {
      data: { data, pageCount },
    } = await requestFunc(options);

    dispatch({
      payload: {
        data,
        pageCount,
      },
      type: GET_WOUND_IMAGES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_WOUND_IMAGES_FAILURE,
    });
  }
};

export const doGetWoundImage = (imageId) => async (dispatch) => {
  dispatch({
    type: GET_WOUND_IMAGE_REQUEST,
  });

  try {
    const requestFunc = isDetector()
      ? api.getWoundDetectorImage
      : api.getWoundImage;
    const { data: woundImage } = await requestFunc(imageId);

    dispatch({
      payload: {
        image: woundImage,
        imageId,
      },
      type: GET_WOUND_IMAGE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_WOUND_IMAGE_FAILURE,
    });
  }
};

export const doUpdateImageWoundImage =
  ({ imageId, surfaces, status, flag, reasonForNoTracing }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_WOUND_IMAGE_REQUEST,
    });
    const submitFunction = isDetector()
      ? api.updateWoundDetectorImage
      : api.updateWoundImage;

    try {
      await submitFunction(imageId, surfaces, status, flag, reasonForNoTracing);

      dispatch({
        type: UPDATE_WOUND_IMAGE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_WOUND_IMAGE_FAILURE,
        payload: {
          error,
        },
      });
    }
  };

export const doGetWoundImageNotes = (imageId, options) => async (dispatch) => {
  dispatch({
    type: GET_WOUND_IMAGE_NOTES_REQUEST,
  });

  try {
    const { data } = await api.getWoundImageNotes(imageId, options);

    dispatch({
      payload: {
        notes: data.data,
        pageCount: data.pageCount,
        currentPage: options?.page || 1,
        listingId: imageId,
      },
      type: GET_WOUND_IMAGE_NOTES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_WOUND_IMAGE_NOTES_FAILURE,
    });
  }
};
