import api from 'core/api';

import {
  GET_HOSPITAL_SYSTEMS_REQUEST,
  GET_HOSPITAL_SYSTEMS_SUCCESS,
  GET_HOSPITAL_SYSTEMS_FAILURE,
  GET_HOSPITAL_SYSTEM_REQUEST,
  GET_HOSPITAL_SYSTEM_SUCCESS,
  GET_HOSPITAL_SYSTEM_FAILURE,
} from './actionTypes';

export const doGetHospitalSystems = (options) => async (dispatch) => {
  dispatch({
    type: GET_HOSPITAL_SYSTEMS_REQUEST,
  });

  try {
    const {
      data: { pageCount, data: items },
    } = await api.getHospitalSystems(options);

    dispatch({
      payload: {
        items,
        pageCount,
      },
      type: GET_HOSPITAL_SYSTEMS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_HOSPITAL_SYSTEMS_FAILURE,
    });
  }
};

export const doGetHospitalSystem = (id) => async (dispatch) => {
  dispatch({
    type: GET_HOSPITAL_SYSTEM_REQUEST,
  });

  try {
    const { data } = await api.getHospitalSystem(id);

    dispatch({
      payload: {
        id,
        hospitalSystem: data,
      },
      type: GET_HOSPITAL_SYSTEM_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_HOSPITAL_SYSTEM_FAILURE,
    });
  }
};
