import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'store/hospital-system-sites';

const useHospitalSystemSiteList = () => {
  const dispatch = useDispatch();

  const listActions = useMemo(
    () =>
      bindActionCreators(
        {
          getHospitalSystemSites: actions.doGetHospitalSystemSites,
        },
        dispatch,
      ),
    [dispatch],
  );

  const { error, loading, data } = useSelector(
    (state) => state.hospitalSystemSites?.list || {},
  );

  return {
    ...listActions,
    isLoading: loading,
    error,
    items: data?.hospitalSystems || [],
    pageCount: data?.pageCount,
  };
};

export default useHospitalSystemSiteList;
