import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import useChat from 'hooks/useChat';
import useUser from 'hooks/useUser';
import { breakpointsTheme } from 'theme/muiTheme';

import Loader from 'components/loader';

import Template from '../template';

import RoomHeader from './components/room-header';
import RoomTabs from './components/room-tabs';
import RoomMessages from './components/room-messages';

import {
  WrapperContainer,
  RoomWrapperContainer,
  NoRoomSelected,
} from './Chat.styles';

const Chat = () => {
  const {
    userDetails: { id: ownerId },
  } = useUser();
  const [searchFilter, setSearchFilter] = useState(null);
  const { fetchRooms, onRoomSelect, selectedRoomId } = useChat();

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  useEffect(() => {
    return () => {
      onRoomSelect(null);
    };
  }, [onRoomSelect]);

  const onFilter = (_, search) => setSearchFilter(search.toLowerCase());

  const matches = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

  if (!ownerId) return <Loader />;
  return (
    <Template>
      <Box height="100%">
        <RoomHeader ownerId={ownerId} onFilter={onFilter} />
        <WrapperContainer>
          {!matches || (matches && !selectedRoomId) ? (
            <RoomWrapperContainer>
              <RoomTabs searchFilter={searchFilter} ownerId={ownerId} />
            </RoomWrapperContainer>
          ) : null}
          {selectedRoomId ? (
            <RoomMessages ownerId={ownerId} />
          ) : (
            <NoRoomSelected>No Conversation Selected</NoRoomSelected>
          )}
        </WrapperContainer>
      </Box>
    </Template>
  );
};

export default Chat;
