import React from 'react';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import useWoundImageDetails from 'hooks/useWoundImageDetails';
import { woundImagesStatus } from 'utils/constants';

import StyledIconButton from 'components/buttons/StyledIconButton';
import ConfirmDialog from 'components/confirm-dialog';

export default function ActionButtons() {
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { imageId } = useParams();
  const { updateWoundImage, isDeleted, isUpdating } =
    useWoundImageDetails(imageId);

  const handleUpdate = React.useCallback(async () => {
    const newState = isDeleted
      ? woundImagesStatus.open
      : woundImagesStatus.deleted;
    await updateWoundImage({ imageId, surfaces: [], status: newState });
    setDeleteModal(false);
  }, [imageId, isDeleted, updateWoundImage]);

  const renderModal = React.useMemo(
    () => (
      <ConfirmDialog
        open={deleteModal}
        handleCancel={() => setDeleteModal(false)}
        handleConfirm={handleUpdate}
        title="Are you sure you want to delete this image?"
        subtitle="This image will be lost."
      />
    ),
    [deleteModal, handleUpdate],
  );

  return (
    <>
      {renderModal}
      {isDeleted ? (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleUpdate}
          disabled={isUpdating}
        >
          Re-open
        </Button>
      ) : (
        <StyledIconButton
          onClick={() => setDeleteModal(true)}
          data-test="image-delete-button"
          name="delete"
          hasMargin
          disabled={isUpdating}
        />
      )}
    </>
  );
}
