import { INPUT_TYPES } from 'utils/constants';

import {
  yesNoOptions,
  howSevereOptions,
  howOftenTimeOptions,
  howOftenOptions,
  timeOfDayOptions,
  howMuchOptions,
  getHowLimitedOptions,
  getOneYearAgoOptions,
} from './utils';

const legProblemsFields = [
  {
    name: 'problemHeavyLegs',
    label: '1. Heavy legs',
  },
  {
    name: 'problemAchingLegs',
    label: '2. Aching legs',
  },
  {
    name: 'problemSwelling',
    label: '3. Swelling',
  },
  {
    name: 'problemNightCramps',
    label: '4. Night cramps',
  },
  {
    name: 'problemHeatOrBurningSensation',
    label: '5. Heat or burning sensation',
  },
  {
    name: 'problemRestlessLegs',
    label: '6. Restless legs',
  },
  {
    name: 'problemThrobbing',
    label: '7. Throbbing',
  },
  {
    name: 'problemItching',
    label: '8. Itching',
  },
  {
    name: 'problemTingling',
    label: '9. Tingling sensation (e.g. pins and needles)',
  },
];

const activitiesFields = [
  {
    name: 'limitationWorkDailyActivities',
    label: 'a. Daily activities at work',
  },
  {
    name: 'limitationHomeDailyActivities',
    label:
      'b. Daily activities at home (e.g. housework, ironing, doing odd jobs repairs around the house, gardening, etc...) ',
  },
  {
    name: 'limitationSocialStandingActivities',
    label:
      'c. Social or leisure activities in which you are standing for long periods (e.g. parties, weddings, taking public transportation, shopping, etc...)',
  },
  {
    name: 'limitationSocialSittingActivities',
    label:
      'd. Social or leisure activities in which you are sitting for long periods (e.g. going to the cinema or the theater, travelling, etc...) ',
  },
];

const workFields = [
  {
    name: 'pastFourWeeksCutDownAmountOfTime',
    label:
      'a. Cut down the amount of time you spent on work or other activities',
  },
  {
    name: 'pastFourWeeksAccomplishedLess',
    label: 'b. Accomplished less than you would like',
  },
  {
    name: 'pastFourWeeksLimitedWorkActivities',
    label: 'c. Were limited in the kind of work or other activities',
  },
  {
    name: 'pastFourWeeksDifficultyPerforming',
    label:
      'd. Had difficulty performing the work or other activities (for example, it took extra effort)',
  },
];

const moodFields = [
  {
    name: 'feelingConcernedAboutLegAppearance',
    label: 'a. Have you felt concerned about the appearance of your leg(s)?',
  },
  {
    name: 'feelingIrritable',
    label: 'b. Have you felt irritable?',
  },
  {
    name: 'feelingABurden',
    label: 'c. Have you felt a burden to your family or friends?',
  },
  {
    name: 'feelingWorried',
    label: 'd. Have you been worried about bumping into things?',
  },
  {
    name: 'feelingLegAppearanceInfluencedClothingChoices',
    label:
      'e. Has the appearance of your leg(s) influenced your choice of clothing?',
  },
];

export const VeinesQolSym = [
  [
    {
      text: 'Answer every question by marking the answer as indicated. If you are unsure about how to answer a question, please give the best answer you can.\n                        These questions are about your leg problem(s).',
      type: INPUT_TYPES.INFO,
    },
  ],
  [
    {
      label:
        '1. During the past 4 weeks, how often have you had any of the following leg problems?',
      type: INPUT_TYPES.TABLE,
      options: howOftenOptions,
      fields: legProblemsFields,
    },
  ],
  [
    {
      name: 'problemMostIntenseTimeOfDay',
      label: '2. At what time of day is your leg problem most intense ?',
      type: INPUT_TYPES.RADIO,
      options: timeOfDayOptions,
    },
  ],
  [
    {
      name: 'problemOneYearComparison',
      label:
        '3. Compared to one year ago, how would you rate your leg problem in general now?',
      type: INPUT_TYPES.RADIO,
      options: getOneYearAgoOptions('VeinesQolSym'),
    },
  ],
  [
    {
      label:
        '4. The following items are about activities that you might do in a typical day. Does your leg problem now limit you in these activities ? If so, how much ?',
      type: INPUT_TYPES.TABLE,
      options: getHowLimitedOptions(true),
      fields: activitiesFields,
    },
  ],
  [
    {
      label:
        '5. During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your leg problem ?',
      type: INPUT_TYPES.TABLE,
      options: yesNoOptions,
      fields: workFields,
    },
  ],
  [
    {
      name: 'pastFourWeeksSocialActivitiesInterference',
      label:
        '6. During the past 4 weeks, to what extent has your leg problem interfered with your normal social activities with family, friends, neighbors or groups?',
      type: INPUT_TYPES.RADIO,
      options: howMuchOptions,
    },
  ],
  [
    {
      name: 'pastFourWeeksLegPain',
      label: '7. How much leg pain have you had during the past 4 weeks?',
      type: INPUT_TYPES.RADIO,
      options: howSevereOptions,
    },
  ],
  [
    {
      label:
        '8. These questions are about how you feel and how things have been with you during the past 4 weeks as a result of your leg problem. For each question, please give the one answer that comes closest to the way you have been feeling. How much of the time during the past 4 weeks... ',
      type: INPUT_TYPES.TABLE,
      options: howOftenTimeOptions,
      fields: moodFields,
    },
  ],
];
