import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES, OTHER_VALUE } from 'utils/constants';
import {
  deviceDetailsSection,
  deviceUDISection,
  encounterFormsEnum,
  procedureLimbOptions,
  sideSubtitle,
} from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';
import {
  EndOfProcedureAssessments,
  MultipleColumnsSection,
  ProcedureGeneralSection,
  ProcedureSidesSection,
} from 'components/encounters/procedures-sections';

const indicationForVenoplastyOptions = [
  { value: 'Stenosis', label: 'Stenosis' },
  { value: 'ResidualThrombus', label: 'Residual Thrombus' },
  { value: 'ExtrinsicCompression', label: 'Extrinsic Compression' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const anatomicalLocationOfVenoplastyOptions = [
  { value: 'InferiorVenaCava', label: 'Inferior Vena Cava' },
  { value: 'CommonIliac', label: 'Common Iliac' },
  { value: 'InternalIliac', label: 'Internal Iliac' },
  { value: 'ExternalIliac', label: 'External Iliac' },
  { value: 'CommonFemoral', label: 'Common Femoral' },
  { value: 'Femoral', label: 'Femoral' },
  { value: 'DeepFemoralProfunda', label: 'Deep Femoral (Profunda)' },
  { value: 'Popliteal', label: 'Popliteal' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const fields = {
  LIMBS: 'limbs',
  INDICATION_FOR_VENOPLASTY: 'indicationForVenoplasty',
  INDICATION_FOR_VENOPLASTY_OTHER: 'indicationForVenoplastyOther',
  ANATOMICAL_LOCATION_OF_VENOPLASTY: 'anatomicalLocationsOfVenoplasty',
  ANATOMICAL_LOCATION_OF_VENOPLASTY_OTHER:
    'anatomicalLocationOfVenoplastyOther',
  ADDITIONAL_NOTES_DEVICE_UDI: 'deviceAdditionalNotes',
};

export const Venoplasty = ({ formik, disabled }) => {
  const sides = React.useMemo(
    () => formik.values[fields.LIMBS],
    [formik.values],
  );

  const venoplastyProcedureSection = React.useMemo(
    () => [
      sideSubtitle,
      {
        label: 'Indication for Venoplasty (without venous stenting)',
        input: (side) => (
          <Chips
            options={indicationForVenoplastyOptions}
            isRequired
            isMultiple
            otherField={{
              name: `${side}.${fields.INDICATION_FOR_VENOPLASTY_OTHER}`,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: `${side}.${fields.INDICATION_FOR_VENOPLASTY}`,
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      [side]: {
                        [fields.INDICATION_FOR_VENOPLASTY]: { $set: value },
                        [fields.INDICATION_FOR_VENOPLASTY_OTHER]: { $set: '' },
                      },
                    }),
                  );
                }
              },
            })}
          />
        ),
      },
      {
        label: 'Anatomical Location of Venoplasty?',
        input: (side) => (
          <Chips
            options={anatomicalLocationOfVenoplastyOptions}
            isRequired
            isMultiple
            otherField={{
              name: `${side}.${fields.ANATOMICAL_LOCATION_OF_VENOPLASTY_OTHER}`,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: `${side}.${fields.ANATOMICAL_LOCATION_OF_VENOPLASTY}`,
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      [side]: {
                        [fields.ANATOMICAL_LOCATION_OF_VENOPLASTY]: {
                          $set: value,
                        },
                        [fields.ANATOMICAL_LOCATION_OF_VENOPLASTY_OTHER]: {
                          $set: '',
                        },
                      },
                    }),
                  );
                }
              },
            })}
          />
        ),
      },
    ],
    [disabled, formik],
  );

  const sidesSections = React.useMemo(() => {
    const udiSection = deviceUDISection(formik, disabled);
    udiSection.push({
      label: 'Additional Notes',
      input: (side) => (
        <Input
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ADDITIONAL_NOTES_DEVICE_UDI}`,
            disabled,
          })}
        />
      ),
    });
    return [
      {
        title: 'Venoplasty Procedure',
        items: venoplastyProcedureSection,
      },
      {
        subtitle: 'Device Details',
        items: deviceDetailsSection(formik, disabled),
      },
      {
        subtitle: 'Device UDI (Bar Code)',
        items: udiSection,
      },
    ];
  }, [disabled, formik, venoplastyProcedureSection]);

  return (
    <>
      <ProcedureGeneralSection disabled={disabled} />
      <FormSectionTitle>Venoplasty Limb</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={8}>
          <Chips
            label="Venoplasty Limb"
            options={procedureLimbOptions}
            isRequired
            isMultiple
            {...getFieldProps({
              formik,
              name: fields.LIMBS,
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <ProcedureSidesSection sides={sides} disabled={disabled} />
      <MultipleColumnsSection sides={sides} sidesSections={sidesSections} />
      <EndOfProcedureAssessments
        encounter={encounterFormsEnum.VENOPLASTY}
        disabled={disabled}
      />
    </>
  );
};

export default Venoplasty;

Venoplasty.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
