export const getHospitalSystemFormValues = ({
  name,
  street,
  city,
  country,
  state,
  diseases,
} = {}) => ({
  hospitalSystemName: name ?? '',
  headquartersAddress: street ?? '',
  city: city ?? '',
  country: country ?? '',
  state: state ?? '',
  managedDiseaseStates: diseases ?? [],
});
