import * as Yup from 'yup';

import { phoneValidation } from 'utils/validations';

export default Yup.object({
  active: Yup.bool().required('Required'),
  name: Yup.string().required('Required'),
  street: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  contactName: Yup.string().required('Required'),
  contactEmail: Yup.string().email('Invalid email'),
  contactPhone: phoneValidation(),
  contactFax: phoneValidation('fax'),
});
