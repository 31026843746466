import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useChat from 'hooks/useChat';
import { breakpointsTheme } from 'theme/muiTheme';

import SearchInput from 'components/forms/input/SearchInput';
import StyledBackIconButton from 'components/buttons/StyledBackIconButton';

import RoomPatientInfo from './RoomPatientInfo';

const RoomHeader = ({ ownerId, onFilter }) => {
  const { selectedRoom, onRoomSelect } = useChat();
  const matches = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

  const roomUserId = useMemo(
    () =>
      selectedRoom?.members.find(({ userId }) => userId !== ownerId)?.userId,
    [selectedRoom, ownerId],
  );

  return (
    <Box mb={2}>
      {!matches || (matches && !selectedRoom) ? (
        <SearchInput onFilter={onFilter} />
      ) : null}
      {roomUserId && (
        <Box display="flex" alignItems="center">
          {matches ? (
            <StyledBackIconButton
              color="primary"
              onClick={() => onRoomSelect(null)}
            >
              <ArrowBackIosIcon viewBox="0 0 14 24" fontSize="inherit" />
            </StyledBackIconButton>
          ) : null}
          <RoomPatientInfo userId={roomUserId} />
        </Box>
      )}
    </Box>
  );
};

RoomHeader.propTypes = {
  ownerId: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default RoomHeader;
