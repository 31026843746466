import React from 'react';
import PropTypes from 'prop-types';

import { StyledCard } from 'utils/styles';

import Title from 'components/titles/Title';

const Template = ({ children, title }) => (
  <StyledCard>
    {title && (
      <Title type="h1" isBold hasGutter>
        {title}
      </Title>
    )}
    {children}
  </StyledCard>
);

Template.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

export default Template;
