import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useWoundImageDetails from 'hooks/useWoundImageDetails';
import { woundImagesStatus } from 'utils/constants';

import StyledBackIconButton from 'components/buttons/StyledBackIconButton';

import ActionButtons from './ActionButtons';

const Header = ({ handleBackClick, isImageTracer }) => {
  const { imageId } = useParams();
  const {
    updateWoundImage,
    woundImage,
    isApproved,
    isSubmitted,
    isFlagged,
    isDeleted,
  } = useWoundImageDetails(imageId);

  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <StyledBackIconButton onClick={handleBackClick}>
        <ArrowBackIcon fontSize="inherit" />
      </StyledBackIconButton>
      <Box display="flex">
        {!isDeleted && isImageTracer && (
          <Button
            variant="outlined"
            sx={{ marginRight: '0.5rem' }}
            onClick={() =>
              updateWoundImage({
                imageId,
                surfaces: woundImage.surfaces,
                status: woundImage?.status,
                flag: !isFlagged,
                reasonForNoTracing: woundImage.reasonForNoTracing,
              })
            }
          >
            {isFlagged ? 'Remove flag' : 'Flag for review'}
          </Button>
        )}
        {!isDeleted && !isImageTracer && (isSubmitted || isApproved) && (
          <Button
            variant="outlined"
            sx={{ marginRight: '0.5rem' }}
            onClick={() =>
              updateWoundImage({
                imageId,
                surfaces: woundImage.surfaces,
                status: isApproved
                  ? woundImagesStatus.submitted
                  : woundImagesStatus.approved,
                flag: woundImage.flag,
                reasonForNoTracing: woundImage.reasonForNoTracing,
              })
            }
          >
            {isApproved ? 'Unapprove' : 'Approve'}
          </Button>
        )}
        <ActionButtons imageId={imageId} />
      </Box>
    </Box>
  );
};

Header.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  isImageTracer: PropTypes.bool.isRequired,
};

export default Header;
