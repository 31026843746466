import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';

import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import WoundSizeForm from './WoundSizeForm';
import { woundSizeSchema } from './utils';

const EditWoundSize = ({ id, handleCancel, handleRefresh, handleAddWound }) => {
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { data, doRequest, isLoading } = useRequest(api.getWoundBySizeId);
  const { patientId } = useParams();
  const { getUser } = useUserDetails();

  useEffect(() => {
    doRequest(id, { generateImageDownloadUrl: true });
  }, [id, doRequest]);

  const handleSubmit = async (data) => {
    clearFeedback();
    try {
      await api.updateWoundSize(id, {
        ...data,
        timestamp: new Date(data.timestamp).toISOString(),
      });
      getUser(patientId);
      setSuccessFeedback('Updated with success');
      if (handleRefresh) handleRefresh();
      handleCancel();
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  if (isLoading || !data) return <Loader />;

  return (
    <FormWrapper
      initialValues={{ ...data, woundId: data.wound.id }}
      validationSchema={woundSizeSchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => (
        <WoundSizeForm formik={formik} handleAddWound={handleAddWound} />
      )}
    </FormWrapper>
  );
};

EditWoundSize.propTypes = {
  id: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleAddWound: PropTypes.func.isRequired,
};

export default EditWoundSize;
