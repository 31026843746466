import { formatDate } from 'utils/date';

export const getReasonString = (reasons) => {
  if (!Array.isArray(reasons) || reasons.length === 0) return '';
  return reasons.reduce(
    (acc, item) => (acc ? `${acc}, ${item.text}` : item.text),
    '',
  );
};

export const getSimpleGraphLabel = ({ datum }) =>
  formatDate(datum.x) + ' - ' + datum.y.toFixed(2) + 'cm²';

export const getTimestampGraphLabel = ({ datum }) =>
  formatDate(new Date(datum.timestamp));

export const getWoundTotalGraphLabel = ({ datum }) =>
  `${getSimpleGraphLabel({ datum })} ${
    datum.customLabel ? `\n${datum.customLabel}` : ''
  }`;
