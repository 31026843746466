import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import { formatISOString } from 'utils/date';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import useWidgetModal, { modalTypeEnum } from 'hooks/useWidgetModal';

import { RowActions } from 'components/table/RowActions';
import WidgetContainer from 'components/widgets/widget-container';
import DeleteComplianceLog from 'components/compliance-questions/DeleteComplianceLog';
import Table from 'components/table';

import NewCompression from './NewCompression';
import EditCompression from './EditCompression';
import { columnStyles } from './Compression.styles';
import { stockingReasonOptions, formFieldEnum } from './utils';

const header = [
  { title: 'Date' },
  { title: 'Value' },
  { title: 'Reason Given' },
  { title: '' },
];

const CompressionList = () => {
  const { patientId } = useParams();
  const { page, handlePageChange } = useTableState('Timestamp', 'Desc');
  const { modal, handleAdd, handleClose, handleEdit, handleDelete } =
    useWidgetModal();
  const {
    data: listData,
    doRequest: getList,
    isLoading,
  } = useRequest(api.getCompressionList);

  const handleRefresh = useCallback(() => {
    getList({
      PatientId: patientId,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
    });
  }, [page, getList, patientId]);

  useEffect(handleRefresh, [handleRefresh]);

  const renderModalContent = useMemo(() => {
    switch (modal?.type) {
      case modalTypeEnum.ADD:
        return (
          <NewCompression
            handleCancel={handleClose}
            handleRefresh={handleRefresh}
          />
        );
      case modalTypeEnum.EDIT:
        return (
          <EditCompression
            compressionId={modal.item.id}
            handleRefresh={handleRefresh}
            handleCancel={handleClose}
          />
        );
      case modalTypeEnum.DELETE:
        return (
          <DeleteComplianceLog
            handleCancel={handleClose}
            deleteFunc={async () => api.deleteCompression(modal.item.id)}
            handleRefresh={handleRefresh}
          />
        );
      default:
        return null;
    }
  }, [modal, handleRefresh, handleClose]);

  const rows = useMemo(
    () =>
      listData?.data.map((obj) => ({
        data: [
          formatISOString(obj.timestamp),
          obj[formFieldEnum.STOCKINGS] ? 'Yes' : 'No',
          obj[formFieldEnum.STOCKINGS_REASONS]
            ?.map((o) => stockingReasonOptions[o])
            ?.join(', ') || '--',
          <RowActions
            key={obj.id}
            handleEdit={() => handleEdit(obj)}
            handleDelete={() => handleDelete(obj)}
          />,
        ],
      })),
    [listData, handleDelete, handleEdit],
  );

  return (
    <WidgetContainer
      title="Compression Compliance Logs"
      onAddClick={handleAdd}
      modalContent={renderModalContent}
      onModalClose={handleClose}
      isLogsList
    >
      <Table
        header={header}
        page={page}
        rows={rows}
        totalPages={listData?.pageCount || 1}
        handlePageChange={handlePageChange}
        columnStyles={columnStyles}
        isLoading={isLoading}
      />
    </WidgetContainer>
  );
};

export default CompressionList;
