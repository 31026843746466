import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { formatISOString, US_DATE_FORMAT } from 'utils/date';

import Table from 'components/table';

const CareTrackGraph = () => {
  const { patientId } = useParams();
  const { page, sortBy, sortOrder, handlePageChange } = useTableState(
    'createdAt',
    'Asc',
  );

  const {
    data: eventsData,
    doRequest,
    error,
    isLoading,
  } = useRequest(api.getEvents);

  const listFilters = useMemo(
    () => ({
      PatientId: patientId,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
    }),
    [page, patientId],
  );

  const eventsTableAttributes = [
    { title: 'Event', id: 'event' },
    { title: 'Created At', id: 'createdAt' },
  ];

  useEffect(() => {
    doRequest(listFilters);
  }, [doRequest, listFilters]);

  const rows = useMemo(
    () =>
      eventsData?.data?.map((info) => {
        return {
          id: info?.id,
          data: [
            info?.details,
            formatISOString(info?.createdAt, US_DATE_FORMAT),
          ],
        };
      }) ?? [],
    [eventsData],
  );

  return (
    <Table
      header={eventsTableAttributes}
      page={page}
      rows={rows}
      orderBy={sortBy}
      orderDirection={sortOrder}
      error={error}
      isLoading={isLoading}
      totalPages={eventsData?.pageCount}
      handlePageChange={handlePageChange}
    />
  );
};

export default CareTrackGraph;
