import { actionTypes } from 'store/user';

const sessionMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS:
      window.location.replace('/');
      break;
    default:
      break;
  }
  return next(action);
};

export default sessionMiddleware;
