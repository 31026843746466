import styled from '@emotion/styled';

import MUICardContent from '@mui/material/CardContent';
import MUICardHeader from '@mui/material/CardHeader';

import { family, weight } from 'theme/fonts';

export const CardHeader = styled(MUICardHeader)(({ theme, title }) => ({
  padding: '0.5rem',
  color: theme.palette.base.white,
  backgroundColor:
    title === 'Past'
      ? theme.palette.grey[300]
      : title === 'Present'
        ? theme.palette.primary.dark
        : theme.palette.primary.light,

  '.MuiCardHeader-title': {
    font: weight.bold + ' 1rem ' + family.openSans,
  },
}));

export const CardContent = styled(MUICardContent)(() => ({
  padding: '0.5rem',
  fontSize: '0.85rem',

  '&:last-child': {
    paddingBottom: '0.5rem',
  },

  '& ul': {
    padding: '0 0 0 2rem',
    margin: 0,

    '& li': {
      margin: '0.5rem 0',
    },
  },
}));
