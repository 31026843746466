import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import { actions as imageCacheActions } from 'store/cached-images';

export const ImageSizeEnum = {
  SIZE_32: '32',
  SIZE_64: '64',
  SIZE_128: '128',
  SIZE_256: '256',
  SIZE_384: '384',
  SIZE_512: '512',
  SIZE_768: '768',
  SIZE_1024: '1024',
};

export const ImageFitEnum = {
  COVER: 'cover',
  CONTAIN: 'contain',
  FILL: 'fill',
  INSIDE: 'inside',
  OUTSIDE: 'outside',
};

const useCachedImage = ({ url = '', size, fit = ImageFitEnum.INSIDE }) => {
  const dispatch = useDispatch();
  const path = useMemo(
    () =>
      url?.substring(0, url.indexOf('?'))?.concat(`?size=${size}&fit=${fit}`),
    [url, size, fit],
  );
  const stateUrl = useSelector((state) => state.cachedImages[path]);

  const finalUrl = useMemo(
    () => (url && size ? `${url}&d=${size}&fit=${fit}` : url),
    [url, fit, size],
  );

  const cachedUrl = useMemo(() => {
    if (!stateUrl) return '';
    const diff = differenceInMinutes(new Date(), stateUrl.cachedAt);
    return diff < 30 ? stateUrl.url : null;
  }, [stateUrl]);

  useEffect(() => {
    if (cachedUrl) return;
    const img = new Image();
    img.onload = () => {
      dispatch(
        imageCacheActions.doAddImage({
          path,
          url: finalUrl,
        }),
      );
    };
    img.src = finalUrl;
    return () => {
      img.src = '';
    };
  }, [dispatch, path, cachedUrl, finalUrl]);

  return {
    cachedUrl,
  };
};

export default useCachedImage;
