export const GET_HOSPITAL_SYSTEM_SITES_REQUEST =
  'GET_HOSPITAL_SYSTEM_SITES_REQUEST';
export const GET_HOSPITAL_SYSTEM_SITES_SUCCESS =
  'GET_HOSPITAL_SYSTEM_SITES_SUCCESS';
export const GET_HOSPITAL_SYSTEM_SITES_FAILURE =
  'GET_HOSPITAL_SYSTEM_SITES_FAILURE';

export const GET_HOSPITAL_SYSTEM_SITE_REQUEST =
  'GET_HOSPITAL_SYSTEM_SITE_REQUEST';
export const GET_HOSPITAL_SYSTEM_SITE_SUCCESS =
  'GET_HOSPITAL_SYSTEM_SITE_SUCCESS';
export const GET_HOSPITAL_SYSTEM_SITE_FAILURE =
  'GET_HOSPITAL_SYSTEM_SITE_FAILURE';
