import { useMemo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { actions as usersActions } from 'store/users';

const useUserDetails = (id, options = { shouldLoad: false }) => {
  const dispatch = useDispatch();

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getUser: usersActions.doGetUser,
          updateUser: usersActions.doUpdateUser,
        },
        dispatch,
      ),
    [dispatch],
  );

  const {
    details: { loading: isLoading, error },
    byId,
  } = useSelector((state) => state.users);

  const user = useMemo(() => byId[id], [byId, id]);

  useEffect(() => {
    if (options.shouldLoad && id && !isLoading) {
      actions.getUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    ...actions,
    isLoading,
    error,
    user,
  };
};

export default useUserDetails;
