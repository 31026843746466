import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from 'core/api';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import Template from 'pages/system-administrator/template';
import { getErrorMessage } from 'utils/error';
import FormWrapper from 'components/forms/form-wrapper';
import useFeedback from 'hooks/useFeedback';

import HospitalSystemSiteForm from '../form';
import validationSchema from '../form/validationSchema';

const initialValues = {
  active: true,
  name: '',
  street: '',
  city: '',
  country: '',
  state: '',
  diseases: [],
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  contactFax: '',
};

const NewHospitalSystemSite = () => {
  const navigate = useNavigate();
  const { hospitalSystemId } = useParams();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const hospitalSystemName = useSelector(
    (state) => state.hospitalSystems?.byId[hospitalSystemId]?.name,
  );

  const onSubmit = async (data) => {
    clearFeedback();
    api
      .createHospitalSystemSite(hospitalSystemId, data)
      .then(({ data }) => {
        setSuccessFeedback('Hospital System Site created with success');
        navigate(
          `${SYS_ADMIN_PATHS.hospitalSystemSites(hospitalSystemId)}/${data.id}`,
        );
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error));
      });
  };

  const onCancel = () =>
    navigate(`${SYS_ADMIN_PATHS.hospitalSystems}/${hospitalSystemId}`);

  return (
    <Template title={`${hospitalSystemName} - New Site`}>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onCancel={onCancel}
      >
        {({ formik }) => <HospitalSystemSiteForm formik={formik} />}
      </FormWrapper>
    </Template>
  );
};

export default NewHospitalSystemSite;
