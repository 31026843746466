import styled from '@emotion/styled';

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

export const HeaderIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.base.white,

  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

export const TrackTimeIconButton = styled(IconButton)(({ theme }) => ({
  margin: '0 0.5rem',
  color: theme.palette.primary.main,

  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

export const HeaderIconBadge = styled(Badge)(({ theme }) => ({
  '.MuiBadge-badge': {
    background: theme.palette.error.main,
    color: theme.palette.base.white,
  },
}));
