import styled from '@emotion/styled';

import Button from '@mui/material/Button';

import { breakpointsTheme } from 'theme/muiTheme';

import Select from 'components/forms/select';

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.base.white,

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const FiltersContainer = styled('div')(({ smReverse }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1rem',
  marginBottom: '1rem',

  [breakpointsTheme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: smReverse ? 'column-reverse' : 'column',
  },
}));

export const PractitionerFilterButton = styled(Button)(
  ({ theme, selected }) => ({
    textTransform: 'none',
    backgroundColor: selected
      ? theme.palette.base.white
      : theme.palette.grey[300],
    color: selected ? theme.palette.base.black : theme.palette.grey[700],
    whiteSpace: 'noWrap',

    [breakpointsTheme.breakpoints.down('md')]: {
      width: '50%',
    },
  }),
);

export const InviteButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
  overflowX: 'hidden',

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
