import { useState, useCallback } from 'react';

import { getErrorMessage } from 'utils/error';

export const useRequest = (requestFunc) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const doRequest = useCallback(
    async (params, extraParams) => {
      setLoading(true);
      setError(null);
      try {
        const { data } = await requestFunc(params, extraParams);
        setData(data);
      } catch (error) {
        setData(null);
        setError(getErrorMessage(error));
      }
      setLoading(false);
    },
    [requestFunc],
  );

  return {
    doRequest,
    isLoading,
    error,
    data,
  };
};

export default useRequest;
