import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import useAccount from 'hooks/useAccount';
import muiTheme from 'theme/muiTheme';

export const ThemeContext = React.createContext();

export const MuiThemeProvider = ({ children }) => {
  const { accountDetails } = useAccount();

  const primary = React.useMemo(
    () => accountDetails?.primaryColor || muiTheme.palette.primary.main,
    [accountDetails?.primaryColor],
  );

  const primaryLight = React.useMemo(
    () => accountDetails?.primaryLightColor || muiTheme.palette.primary.light,
    [accountDetails?.primaryLightColor],
  );

  const primaryDark = React.useMemo(
    () => accountDetails?.primaryDarkColor || muiTheme.palette.primary.dark,
    [accountDetails?.primaryDarkColor],
  );

  const theme = React.useMemo(
    () =>
      createTheme(muiTheme, {
        palette: {
          primary: {
            main: primary,
            light: primaryLight,
            dark: primaryDark,
          },
          secondary: {
            contrastText: primary,
          },
          gradient: {
            main: `linear-gradient(45deg, ${primary}, ${primaryDark}, ${primaryLight})`,
          },
        },
      }),
    [primary, primaryDark, primaryLight],
  );

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

MuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => React.useContext(ThemeContext);
