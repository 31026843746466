import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import useUser from 'hooks/useUser';
import { COMMON_PATH } from 'navigation/paths';
import { USER_GROUPS } from 'utils/constants';

const ProtectedRoute = ({ component: Component, groupAllowed }) => {
  /*
   * Redirect to dashboard if user is from a not allowed group
   */
  const { userGroups } = useUser();

  return userGroups?.includes(groupAllowed) ? (
    <Component />
  ) : (
    <Navigate to={COMMON_PATH} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  groupAllowed: PropTypes.oneOf(Object.values(USER_GROUPS)),
};

export default ProtectedRoute;
