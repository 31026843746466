import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { submitActions } from './EncounterForm';
import { StyledControls } from './EncounterForm.styles';

const EncounterForm = ({
  handleSave,
  hasPrevious,
  hasNext,
  isDraft,
  formik,
}) => (
  <StyledControls>
    <Button
      variant="contained"
      disabled={!hasPrevious}
      onClick={() =>
        handleSave({ action: submitActions.PREVIOUS })(formik.values)
      }
    >
      Previous
    </Button>
    <Button
      variant="contained"
      disabled={!hasNext}
      onClick={() => handleSave({ action: submitActions.NEXT })(formik.values)}
    >
      Next
    </Button>
    {isDraft && (
      <Button
        variant="contained"
        onClick={() =>
          handleSave({ action: submitActions.SAVE })(formik.values)
        }
      >
        Save
      </Button>
    )}
  </StyledControls>
);

EncounterForm.propTypes = {
  handleSave: PropTypes.func,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  isDraft: PropTypes.bool,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
  }),
};

export default EncounterForm;
