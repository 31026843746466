import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

export const initialState = {
  dateTo: null,
  dateFrom: null,
};

const timeTracking = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_TIME_TRACKING: {
      return update(state, {
        dateTo: { $set: action.payload.dateTo },
        dateFrom: { $set: action.payload.dateFrom },
      });
    }

    default:
      return state;
  }
};

export default timeTracking;
