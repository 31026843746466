import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import StyledIconButton from 'components/buttons/StyledIconButton';
import Loader from 'components/loader';
import ChatInput from 'components/chat/chat-input';
import { findLastReadMessage } from 'components/chat/chat-window/utils';

import useChat from 'hooks/useChat';
import useFeedback from 'hooks/useFeedback';
import useUser from 'hooks/useUser';
import { PATIENT_DASHBOARD_PATHS, PRACTITIONER_PATHS } from 'navigation/paths';
import { formatISOString } from 'utils/date';

import { StyledMessagesContainer, headerStyle } from './RoomMessages.styles';

import RoomMessage from './RoomMessage';

const RoomMessages = ({ ownerId }) => {
  const navigate = useNavigate();
  const { userDetails } = useUser();
  const { setErrorFeedback } = useFeedback();
  const {
    messagesData,
    messagesLoading,
    fetchMessages,
    selectedRoom,
    onMessagesRead,
    pushedMessage,
    setPushedMessage,
    videoCallMessage,
  } = useChat();

  const selectedRoomData = useMemo(
    () => messagesData[selectedRoom?.id] || [],
    [selectedRoom, messagesData],
  );

  useEffect(() => {
    if (selectedRoom?.id) {
      fetchMessages(selectedRoom?.id);
      onMessagesRead(selectedRoom?.id);
    }
  }, [selectedRoom, fetchMessages, onMessagesRead]);

  const membersMap = useMemo(
    () =>
      (selectedRoom?.members || [])?.reduce(
        (res, { name, userId, profileImageUrl, readReceipt }) => ({
          ...res,
          [userId]: { name, profileImageUrl, readReceipt },
        }),
        {},
      ),
    [selectedRoom],
  );

  const roomUserId = useMemo(
    () =>
      selectedRoom?.members.find(({ userId }) => userId !== ownerId)?.userId,
    [selectedRoom, ownerId],
  );

  const messageMap = useMemo(() => {
    return selectedRoomData?.items?.map(
      ({ id, userId, content, attachment, createdAt }) => ({
        user: membersMap[userId],
        body: content?.body,
        attachment,
        date: formatISOString(createdAt),
        received: userId !== ownerId,
        lastMessageRead: findLastReadMessage(
          id,
          membersMap[roomUserId],
          selectedRoomData,
          ownerId,
        ),
      }),
    );
  }, [membersMap, selectedRoomData, ownerId, roomUserId]);

  const onLoadMore = useCallback(
    () =>
      selectedRoomData?.nextCursor &&
      fetchMessages(selectedRoom.id, selectedRoomData.nextCursor),
    [fetchMessages, selectedRoomData, selectedRoom],
  );

  const onVideoCall = useCallback(() => {
    userDetails?.practitioner?.doxyTelehealthLink
      ? setPushedMessage(videoCallMessage)
      : setErrorFeedback(
          'Please add your free Doxy.me telehealth link in your profile settings',
        );
  }, [userDetails, videoCallMessage, setPushedMessage, setErrorFeedback]);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box style={headerStyle}>
        <Box>{messagesLoading && <Loader />}</Box>
        <Box>
          <StyledIconButton
            name="account"
            color="primary"
            onClick={() =>
              navigate(
                `${PRACTITIONER_PATHS.patients}/${roomUserId}/${PATIENT_DASHBOARD_PATHS.HOME}`,
              )
            }
          />
          <StyledIconButton
            name="video"
            color="primary"
            onClick={onVideoCall}
          />
        </Box>
      </Box>
      <StyledMessagesContainer id="messageContainer">
        <InfiniteScroll
          dataLength={messageMap?.length || 0}
          next={onLoadMore}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          inverse={true}
          hasMore={selectedRoomData?.nextCursor}
          scrollableTarget="messageContainer"
        >
          {messageMap?.map((message, index) => (
            <RoomMessage {...message} key={index} />
          ))}
        </InfiniteScroll>
      </StyledMessagesContainer>
      <ChatInput
        roomId={selectedRoom?.id}
        pushedMessage={pushedMessage}
        setPushedMessage={setPushedMessage}
      />
    </Box>
  );
};

RoomMessages.propTypes = {
  ownerId: PropTypes.string.isRequired,
};

export default RoomMessages;
