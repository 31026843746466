import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { INPUT_TYPES, QUESTIONNAIRES } from 'utils/constants';
import { getFieldProps } from 'utils/forms';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';
import Select from 'components/forms/select';

import { cronMacroEnum, ruleTypeEnum } from '../utils';

import {
  cronMacroOptions,
  dataTypeOptions,
  dayOptions,
  formFields,
  ruleTypeOptions,
} from './utils';

const TaskRuleForm = ({ formik, disabled, detailsEdit }) => {
  const typeValue = formik.values[formFields.TYPE];
  const repeatValue = formik.values[formFields.REPEATS];
  const { patientId } = useParams();

  const { data, doRequest: getQuestionnaires } = useRequest(
    api.getQuestionnaires,
  );

  useEffect(() => {
    getQuestionnaires({ patientId });
  }, [getQuestionnaires, patientId]);

  const questionnaireOptions = useMemo(
    () =>
      Object.keys(data?.questionnaires ?? {}).map((type) => ({
        value: type,
        label: QUESTIONNAIRES[type],
      })) || [],
    [data?.questionnaires],
  );

  const typeComponent = useMemo(() => {
    switch (typeValue) {
      case ruleTypeEnum.DATA_ENTRY:
        return (
          <Grid item xs={12} md={4}>
            <Select
              label="Data Type"
              options={dataTypeOptions}
              isRequired
              {...getFieldProps({
                formik,
                name: formFields.DATA_ENTRY,
                disabled,
              })}
            />
          </Grid>
        );
      case ruleTypeEnum.QUESTIONNAIRE:
        return (
          <Grid item xs={12} md={4}>
            <Select
              label="Questionnaire"
              options={questionnaireOptions}
              isRequired
              {...getFieldProps({
                formik,
                name: formFields.QUESTIONNAIRE,
                disabled,
              })}
            />
          </Grid>
        );
      case ruleTypeEnum.GENERIC:
        return (
          <Grid item xs={12} md={6}>
            <Input
              label="Task Details"
              type={INPUT_TYPES.COMMENT}
              isRequired
              {...getFieldProps({
                formik,
                name: formFields.GENERIC,
                disabled,
              })}
            />
          </Grid>
        );
      default:
        return null;
    }
  }, [typeValue, formik, disabled, questionnaireOptions]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Select
            label="Task Type"
            options={ruleTypeOptions}
            isRequired
            {...getFieldProps({
              formik,
              name: formFields.TYPE,
              disabled: disabled || detailsEdit,
            })}
          />
        </Grid>
        {typeValue && (
          <>
            <Grid container item spacing={2}>
              {typeComponent}
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={4}>
                <Select
                  label="Repeats"
                  options={cronMacroOptions}
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: formFields.REPEATS,
                    disabled,
                  })}
                />
              </Grid>
              {repeatValue === cronMacroEnum.WEEKLY && (
                <Grid item xs={12} md={8}>
                  <Chips
                    label="On Following Days"
                    options={dayOptions}
                    isRequired
                    isMultiple
                    {...getFieldProps({
                      formik,
                      name: formFields.REPEAT_DAYS,
                      disabled,
                    })}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={4}>
                <DateSelector
                  label="Starting On"
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: formFields.START_DATE,
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DateSelector
                  label="Expiring On"
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: formFields.END_DATE,
                    disabled,
                  })}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

TaskRuleForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  detailsEdit: PropTypes.bool,
};

export default TaskRuleForm;
