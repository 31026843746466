import * as actionTypes from './actionTypes';

export const initialState = {
  details: {
    data: {},
    error: null,
    loading: false,
  },
  logoUrl: {
    data: null,
    error: null,
    loading: false,
  },
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          data: {},
          error: null,
          loading: true,
        },
      };
    case actionTypes.GET_CURRENT_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          data: {},
          error: action.payload.error,
          loading: false,
        },
      };
    case actionTypes.GET_CURRENT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          data: action.payload.details,
          error: null,
          loading: false,
        },
      };

    case actionTypes.GET_CURRENT_ACCOUNT_LOGO_URL_REQUEST:
      return {
        ...state,
        logoUrl: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case actionTypes.GET_CURRENT_ACCOUNT_LOGO_URL_FAILURE:
      return {
        ...state,
        logoUrl: {
          data: null,
          error: action.payload.error,
          loading: false,
        },
      };
    case actionTypes.GET_CURRENT_ACCOUNT_LOGO_URL_SUCCESS:
      return {
        ...state,
        logoUrl: {
          data: action.payload.logoUrl,
          error: null,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default user;
