import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Input from 'components/forms/input/Input';
import StyledIconButton from 'components/buttons/StyledIconButton';

const SearchInput = ({ onFilter }) => {
  const searchRef = useRef(null);

  const setSearch = () => onFilter('search', searchRef?.current?.value);

  const resetSearch = () => {
    searchRef.current.value = '';
    setSearch();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearch();
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Input
        id="search"
        inputRef={searchRef}
        name="search"
        placeholder="Search..."
        onKeyPress={handleKeyPress}
        sx={{ width: { xs: '100%', sm: '25vw' }, backgroundColor: 'white' }}
      />
      <StyledIconButton
        name="search"
        color="grey"
        hasMargin
        onClick={setSearch}
      />
      <StyledIconButton
        name="close"
        color="grey"
        hasMargin
        onClick={resetSearch}
      />
    </Box>
  );
};

SearchInput.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default SearchInput;
