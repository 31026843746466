import { css } from '@emotion/react';
import normalize from 'emotion-normalize';

import muiTheme from './muiTheme';

const disabledFocusOutlineStyles = css`
  ::-moz-focus-inner {
    border: 0;
  }

  :focus {
    outline: 0;
  }

  input:focus + label,
  input:active + label {
    outline: 0;
  }

  input:focus + label::after,
  input:active + label::after {
    outline: 0;
  }
`;

export default css`
  ${normalize};
  ${disabledFocusOutlineStyles}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    background-color: ${muiTheme.palette.grey[100]};
    color: ${muiTheme.palette.base.black};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    margin: 0 0 0.5rem;
    color: ${muiTheme.palette.base.black};
  }

  button {
    padding: 0;
  }

  span {
    letter-spacing: initial;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;
