import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import useHospitalSystemListing from 'hooks/useHospitalSystemListing';
import useHospitalSystemSiteList from 'hooks/useHospitalSystemSiteList';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import CountrySelector from 'components/forms/country-selector';
import Input from 'components/forms/input';
import InputPhoneNumber from 'components/forms/input-phone-number';
import Toggle from 'components/toggle/Toggle';

import UserRoles from './UserRoles';

const sortFilters = { Sort: 'Name', SortDirection: 'Asc' };

const UserForm = ({ formik, readOnly = false }) => {
  const { getHospitalSystemSites, isLoading: isHospitalSystemSitesLoading } =
    useHospitalSystemSiteList({
      HospitalSystemId: formik.values.practitionerSystem,
      ...sortFilters,
    });

  const { getHospitalSystems, isLoading: isHospitalSystemsLoading } =
    useHospitalSystemListing(sortFilters);

  useEffect(() => {
    if (!isHospitalSystemsLoading) {
      getHospitalSystems(sortFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.practitionerSystem && !isHospitalSystemSitesLoading) {
      getHospitalSystemSites(formik.values.practitionerSystem, sortFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.practitionerSystem]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSectionTitle>Information</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              label="First Name"
              isRequired
              {...getFieldProps({
                formik,
                name: 'firstName',
                disabled: readOnly,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Last Name"
              isRequired
              {...getFieldProps({
                formik,
                name: 'lastName',
                disabled: readOnly,
              })}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              label="Address"
              {...getFieldProps({
                formik,
                name: 'street',
                disabled: readOnly,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="City"
              {...getFieldProps({ formik, name: 'city', disabled: readOnly })}
            />
          </Grid>
          <CountrySelector formik={formik} disabled={readOnly} />
        </Grid>
      </Grid>
      <FormSectionTitle>Contact</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Input
            label="Email"
            isRequired
            {...getFieldProps({ formik, name: 'email', disabled: readOnly })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputPhoneNumber
            label="Phone Number"
            isRequired
            {...getFieldProps({
              formik,
              name: 'phoneNumber',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputPhoneNumber
            label="Fax Number"
            {...getFieldProps({
              formik,
              name: 'faxNumber',
              disabled: readOnly,
            })}
          />
        </Grid>
      </Grid>
      <FormSectionTitle>Security and Login</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Toggle
            label="Enable Auto Logout"
            disabled={readOnly}
            defaultChecked={formik.values.autoLogout}
            onChange={({ target: { checked } }) => {
              formik.setFieldValue('autoLogout', checked);
              if (!checked) formik.setFieldValue('logoutAfterMinutes', '');
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {formik.values.autoLogout && (
            <Input
              label="Logout After"
              isRequired
              {...getFieldProps({
                formik,
                name: 'logoutAfterMinutes',
                disabled: readOnly,
              })}
              endAdornment={<InputAdornment position="end">min</InputAdornment>}
            />
          )}
        </Grid>
      </Grid>
      <FormSectionTitle>User Type</FormSectionTitle>
      {<UserRoles formik={formik} readOnly={readOnly} />}
    </form>
  );
};

UserForm.propTypes = {
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      practitionerSystem: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      country: PropTypes.string,
      autoLogout: PropTypes.bool,
    }),
  }),
  readOnly: PropTypes.bool,
};

export default UserForm;
