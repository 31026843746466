import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import useTableState from 'hooks/useTableState';
import useWoundImageListing from 'hooks/useWoundImageListing';
import { useTheme } from 'context/theme';
import { ROOT_PATHS } from 'navigation/paths';
import { woundImagesStatus, DEFAULT_PAGE_SIZE } from 'utils/constants';
import { formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/error';
import { getSurfaceAreaString } from 'utils/woundImages';

import Table from 'components/table';

import Header from './Header';
import { Section, Wrapper, columnStyles } from './ImageListing.styles';

const ImageListing = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { pathname, search } = useLocation();
  const {
    page,
    handlePageChange,
    sortBy,
    sortOrder,
    handleSortChange,
    filters,
    handleFilterChange,
  } = useTableState('Date', 'Desc', { status: woundImagesStatus.open });
  const { getWoundImages, woundImages, isLoading, error, pageCount } =
    useWoundImageListing();

  const listingFilters = useMemo(
    () => ({
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE,
      sort: sortBy,
      sortDirection: sortOrder,
      ...filters,
    }),
    [page, sortBy, sortOrder, filters],
  );

  const handleRefresh = useCallback(() => {
    getWoundImages(listingFilters);
  }, [getWoundImages, listingFilters]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleRowClick = (id) =>
    navigate({
      pathname: `${pathname}/image/${id}`,
      state: { search },
    });

  const isImageTracer = React.useMemo(
    () => pathname === ROOT_PATHS.woundImageTracer,
    [pathname],
  );

  const woundImageTableAttributes = useMemo(() => {
    const extraFields = isImageTracer
      ? [
          { title: 'Size', handleClick: handleSortChange, id: 'Size' },
          { title: 'Flag' },
        ]
      : [];
    return [
      { title: 'Date Added', handleClick: handleSortChange, id: 'Date' },
      { title: 'Image ID' },
      ...extraFields,
    ];
  }, [handleSortChange, isImageTracer]);

  const rows = useMemo(
    () =>
      woundImages?.map(
        ({ createdAt, id, flag, totalSurfaceArea, reasonForNoTracing }) => {
          const extraFields = isImageTracer
            ? [
                getSurfaceAreaString(totalSurfaceArea, reasonForNoTracing),
                flag && <ErrorOutlineIcon sx={{ color: palette.error.main }} />,
              ]
            : [];
          return {
            data: [formatDate(new Date(createdAt)), id, ...extraFields],
            id,
          };
        },
      ),
    [woundImages, isImageTracer, palette.error.main],
  );

  return (
    <Wrapper>
      <Section>
        <Header
          imageStatus={filters.status}
          handleRefresh={handleRefresh}
          handleFilterChange={handleFilterChange}
          isImageTracer={isImageTracer}
        />
        <Table
          header={woundImageTableAttributes}
          rows={rows}
          page={page}
          totalPages={pageCount}
          handlePageChange={handlePageChange}
          error={error && getErrorMessage(error)}
          isLoading={isLoading}
          handleRowClick={handleRowClick}
          columnStyles={columnStyles}
          orderBy={sortBy}
          orderDirection={sortOrder}
        />
      </Section>
    </Wrapper>
  );
};

export default ImageListing;
