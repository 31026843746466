import styled from '@emotion/styled';

import Button from '@mui/material/Button';

import { weight } from 'theme/fonts';

export const AddWoundButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: weight.bold,
}));
