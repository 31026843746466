import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage, getErrorDetails } from 'utils/error';

import { AssessmentsElements } from '../utils';

const AssessmentForm = ({ assessment, values }) => {
  const { patientId } = useParams();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const { user } = useUserDetails(patientId);

  const diseases = React.useMemo(
    () =>
      user.patient.diseases?.reduce(
        (res, { disease, endedAt }) => ({
          ...res,
          [disease]: !endedAt,
        }),
        {},
      ),
    [user.patient.diseases],
  );

  const handleSubmit = async (data) => {
    clearFeedback();

    const submitData = { [assessment]: data };

    api
      .updateAssessmentECRF(patientId, submitData)
      .then(() => {
        setSuccessFeedback(
          `${AssessmentsElements[assessment].name} updated with success`,
        );
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  const Form = AssessmentsElements[assessment].form;

  return (
    <Formik
      initialValues={values[assessment]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Form formik={formik} diseases={diseases} />
          <Box display="flex" justifyContent="flex-end" mr={4}>
            <Button
              variant="contained"
              onClick={() => handleSubmit(formik.values)}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

AssessmentForm.propTypes = {
  assessment: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default AssessmentForm;
