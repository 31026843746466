import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import useUser from 'hooks/useUser';
import AppStoreButton from 'theme/media/app-store.png';
import PlayStoreButton from 'theme/media/google-play.png';

import {
  Section,
  Card,
} from 'pages/account/roles-dashboard/RolesDashboard.styles';
import { Title, SubTitle } from 'pages/account/AppAuth.styles';

import { Link, Img } from './Dashboard.styles';

const Dashboard = () => {
  const { logout } = useUser();

  return (
    <Section>
      <Card>
        <Title>Welcome,</Title>
        <SubTitle>
          Please sign in on the iPhone or Android app to begin tracking your
          healing process. <br />
          If you need to download the app, follow one of the two links below.
          Thank you!
        </SubTitle>
        <Box display="flex" gap={2} mb={2}>
          <Link href="https://www.apple.com/app-store/">
            <Img src={AppStoreButton} alt="AppStore Button" />
          </Link>
          <Link href="https://play.google.com/store">
            <Img src={PlayStoreButton} alt="PlayStore Button" />
          </Link>
        </Box>
        <Button variant="contained" fullWidth onClick={logout}>
          Log Out
        </Button>
      </Card>
    </Section>
  );
};

export default Dashboard;
