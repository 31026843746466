import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { woundImagesStatus } from 'utils/constants';
import { actions as woundImageActions, selectors } from 'store/wound-images';

const useWoundImageDetails = (imageId) => {
  const dispatch = useDispatch();

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getWoundImage: woundImageActions.doGetWoundImage,
          updateWoundImage: woundImageActions.doUpdateImageWoundImage,
          getWoundImageNotes: woundImageActions.doGetWoundImageNotes,
        },
        dispatch,
      ),
    [dispatch],
  );

  const isDetailsLoading = useSelector(selectors.isDetailsLoading);
  const detailsError = useSelector(selectors.getDetailsError);
  const woundImage = useSelector((state) =>
    selectors.getWoundImageDetails(state, imageId),
  );
  const isFlagged = woundImage?.flag;
  const isOpen = woundImage?.status === woundImagesStatus.open;
  const isSubmitted = woundImage?.status === woundImagesStatus.submitted;
  const isDeleted = woundImage?.status === woundImagesStatus.deleted;
  const isApproved = woundImage?.status === woundImagesStatus.approved;

  const notes = useSelector((state) => selectors.getNotes(state, imageId));
  const isNotesLoading = useSelector(selectors.isNotesLoading);
  const notesError = useSelector(selectors.getNotesError);

  const isUpdating = useSelector(selectors.isDetailsUpdating);
  const updateError = useSelector(selectors.updateError);

  return {
    ...actions,
    isDetailsLoading,
    detailsError,
    updateError,
    isUpdating,
    woundImage,
    isFlagged,
    isOpen,
    isSubmitted,
    isDeleted,
    isApproved,
    notes,
    isNotesLoading,
    notesError,
  };
};

export default useWoundImageDetails;
