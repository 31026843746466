import * as Yup from 'yup';

export const stateRegex =
  /^AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY$/;

const phoneNumberRegex =
  /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

export const phoneValidation = (type = 'phone') =>
  Yup.string().matches(phoneNumberRegex, `Invalid ${type} number`);

export const passwordValidations = {
  lowercase: {
    regex: /^(?=.*[a-z]).*$/,
    message: 'Must contain at least one lowercase letter',
  },
  uppercase: {
    regex: /^(?=.*[A-Z]).*$/,
    message: 'Must contain at least one uppercase letter',
  },
  number: {
    regex: /^(?=.*[0-8]).*$/,
    message: 'Must contain at least one number',
  },
  specialCharacter: {
    regex: /^(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]).*$/,
    message:
      'Must contain at least one special character \n = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `',
  },
};

export const hospitalSystemSchema = Yup.object({
  hospitalSystemName: Yup.string()
    .required('Required')
    .max(255, 'Name too long'),
  managedDiseaseStates: Yup.array(),
  headquartersAddress: Yup.string().max(255, 'Address too long'),
  city: Yup.string().max(50, 'City too long'),
  country: Yup.string().max(50, 'Country too long'),
  state: Yup.string().max(50, 'State too long'),
});

export const userValidation = Yup.object({
  firstName: Yup.string().required('Required').max(100, 'First name too long'),
  lastName: Yup.string().required('Required').max(100, 'Last name too long'),
  street: Yup.string().max(180, 'Address too long'),
  city: Yup.string().max(100, 'City too long'),
  state: Yup.string().max(50, 'State too long'),
  country: Yup.string().max(3, 'Country too long'),
  email: Yup.string()
    .required('Required')
    .email('Invalid Email')
    .max(2048, 'Email too long'),
  phoneNumber: phoneValidation().required('Required'),
  faxNumber: phoneValidation('fax'),
});
