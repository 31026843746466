import api from 'core/api';

import {
  GET_HOSPITAL_SYSTEM_SITES_REQUEST,
  GET_HOSPITAL_SYSTEM_SITES_SUCCESS,
  GET_HOSPITAL_SYSTEM_SITES_FAILURE,
  GET_HOSPITAL_SYSTEM_SITE_REQUEST,
  GET_HOSPITAL_SYSTEM_SITE_SUCCESS,
  GET_HOSPITAL_SYSTEM_SITE_FAILURE,
} from './actionTypes';

export const doGetHospitalSystemSites =
  (hospitalSystemId, options) => async (dispatch) => {
    dispatch({
      type: GET_HOSPITAL_SYSTEM_SITES_REQUEST,
    });

    try {
      const { data } = await api.getHospitalSystemSites(
        hospitalSystemId,
        options,
      );

      const { pageCount, data: hospitalSystems } = data;

      dispatch({
        payload: {
          hospitalSystems,
          currentPage: options?.page || 1,
          pageCount,
        },
        type: GET_HOSPITAL_SYSTEM_SITES_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: { error },
        type: GET_HOSPITAL_SYSTEM_SITES_FAILURE,
      });
    }
  };

export const doGetHospitalSystemSite =
  (hospitalSystemId, id) => async (dispatch) => {
    dispatch({
      type: GET_HOSPITAL_SYSTEM_SITE_REQUEST,
    });

    try {
      const { data } = await api.getHospitalSystemSite(hospitalSystemId, id);

      dispatch({
        payload: data,
        type: GET_HOSPITAL_SYSTEM_SITE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: { error },
        type: GET_HOSPITAL_SYSTEM_SITE_FAILURE,
      });
    }
  };
