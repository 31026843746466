import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import { INPUT_TYPES, OTHER_VALUE, yesNoOptions } from 'utils/constants';
import { encounterFormsEnum, limbOptions } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';
import Select from 'components/forms/select';
import {
  DeviceUDISection,
  ProcedureGeneralSection,
  EndOfProcedureAssessments,
} from 'components/encounters/procedures-sections';

const GLUE_VALUE = 'GlueCyanoacrylate';

const modalitySVIOptions = [
  { value: 'LaserAblation', label: 'Laser Ablation' },
  { value: 'RadiofrequencyAblation', label: 'Radiofrequency Ablation' },
  { value: GLUE_VALUE, label: 'Glue (Cyanoacrylate)' },
  { value: 'MechanochemicalAblation', label: 'Mechanochemical Ablation' },
  {
    value: 'VarithenaMicrofoamSclerotherapy',
    label: 'Varithena Microfoam Sclerotherapy',
  },
  {
    value: 'PhysicianCompoundedSclerotherapy',
    label: 'Physician Compounded Sclerotherapy',
  },
  { value: 'Phlebectomy', label: 'Phlebectomy' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const treatedAnatomyOptions = [
  { value: 'GreatSaphenousVein', label: 'GSV - Great Saphenous Vein' },
  {
    value: 'GreaterSaphenousTributaries',
    label: 'Greater Saphenous Tributaries',
  },
  { value: 'SmallSaphenousVein', label: 'SSV - Small Saphenous Vein' },
  { value: 'SmallSaphenousTributaries', label: 'Small Saphenous Tributaries' },
  { value: 'Perforators', label: 'Perforators' },
  { value: 'SpiderVaricosities', label: 'Spider Varicosities' },
  {
    value: 'SuperficialTruncalVaricosities',
    label: 'Superficial Truncal Varicosities',
  },
  { value: 'UlcerBed', label: 'Ulcer Bed' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const siteOfCannulationOptions = [
  { value: 'GSVAtAnkle', label: 'GSV at ankle' },
  { value: 'GSVAtKnee', label: 'GSV at knee' },
  { value: 'SSVAtAnkle', label: 'SSV at ankle' },
  { value: 'SSVAtMidCalf', label: 'SSV at mid-calf' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const wavelengthOptions = [
  { value: 'EVL810', label: 'EVL 810' },
  { value: 'EVL940', label: 'EVL 940' },
  { value: 'EVL980', label: 'EVL 980' },
  { value: 'EVL1064', label: 'EVL 1064' },
  { value: 'EVL1319', label: 'EVL 1319' },
  { value: 'EVL1320', label: 'EVL 1320' },
  { value: 'EVL1470', label: 'EVL 1470' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const catheterSizeOptions = [
  { value: 'F6', label: '6 F' },
  { value: 'F7', label: '7 F' },
  { value: 'F8', label: '8 F' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const sclerosantAgentOptions = [
  { value: 'SodiumTetradecylSulphate', label: 'Sodium tetradecyl sulphate' },
  { value: 'Polidocanol', label: 'Polidocanol' },
  { value: 'HypertonicSaline', label: 'Hypertonic saline' },
  { value: 'Glycerin', label: 'Glycerin' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const sclerosantFormulationOptions = [
  { value: 'Liquid', label: 'Liquid' },
  { value: 'Foam', label: 'Foam' },
];

const liquidToGasRatioOptions = [
  { value: 'OneToOne50Percent', label: '1:1 (50%)' },
  { value: 'OneToTwo33Percent', label: '1:2 (33%)' },
  { value: 'OneToThree25Percent', label: '1:3 (25%)' },
  { value: 'OneToFour20', label: '1:4 (20%)' },
  { value: 'OneToFive17Percent', label: '1:5 (17%)' },
];

const gasUsedOptions = [
  { value: 'RoomAir', label: 'Room air' },
  { value: 'GasMixCO2AndO2', label: 'Gas mix (CO2 & O2)' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const patientMobilizedOptions = [
  { value: 'Minutes0', label: '0 Minutes' },
  { value: 'Minutes2', label: '2 Minutes' },
  { value: 'Minutes5', label: '5 Minutes' },
  { value: 'Minutes10', label: '10 Minutes' },
  { value: 'MinutesOver10', label: '>10 Minutes' },
];

const fields = {
  SVI_PROCEDURE_LIMB: 'limbType',
  SVI_TREATMENT_MODALITIES_USED: 'Modalities',
  TREATED_ANATOMY: 'treatedAnatomy',
  TREATED_ANATOMY_OTHER: 'treatedAnatomyOther',
  SITE_OF_CANNULATION: 'site',
  SITE_OF_CANNULATION_OTHER: 'siteOther',
  PRODUCT_NAME: 'productName',
  PRODUCT_NAME_OTHER: 'productNameOther',
  LARGEST_DIAMETER_OF_TREATED_VEIN: 'largestDiameterOfTreatedVeinMm',
  LENGTH_OF_VEIN_TREATED: 'lengthOfVeinTreatedCm',
  POWER_SETTINGS: 'powerSettingsWatts',
  TOTAL_ENERGY_USED: 'totalEnergyUsedJ',
  PULLBACK_TIME: 'pullbackTimeSeconds',
  WAVELENGTH: 'wavelength',
  WAVELENGTH_OTHER: 'wavelengthOther',
  CATHETER_SIZE: 'catheterSizeF',
  CATHETER_SIZE_OTHER: 'catheterSizeFOther',
  TEMPERATURE: 'temperatureCelsius',
  TOTAL_VOLUME_GLUE: 'totalVolumeOfGlueMl',
  TOTAL_VOLUME_SCLEROSANT: 'totalVolumeOfSclerosantMl',
  SCLEROSANT_AGENT: 'sclerosantAgent',
  SCLEROSANT_AGENT_OTHER: 'sclerosantAgentOther',
  SCLEROSANT_CONCENTRATION: 'sclerosantConcentrationPercent',
  TOTAL_VOLUME_MICROFOAM: 'totalVolumeOfMicrofoamMl',
  SCLEROSANT_FORMULATION: 'sclerosantFormulation',
  LIQUID_TO_GAS_RATIO: 'liquidToGasRatio',
  GAS_USED: 'gasUsed',
  GAS_USED_OTHER: 'gasUsedOther',
  LEG_ELEVATED: 'legElevated',
  PATIENT_MOBILIZIED: 'patientMobilized',
  ULTRASOUND_CONTROLLED: 'ultrasoundControlled',
  ADDITIONAL_NOTES: 'additionalNotes',
};

export const sviInitialValues = modalitySVIOptions?.reduce((acc, cur) => {
  const modality = cur.value.charAt(0).toLowerCase() + cur.value.slice(1);
  return { ...acc, [modality]: {} };
}, {});

export const SVI = ({ formik, disabled }) => {
  const firstSection = (section) => (
    <>
      <Grid item xs={12} md={8}>
        <Chips
          label="Treated Anatomy"
          options={treatedAnatomyOptions}
          isMultiple
          otherField={{
            name: `${section}.${fields.TREATED_ANATOMY_OTHER}`,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: `${section}.${fields.TREATED_ANATOMY}`,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [section]: {
                      [fields.TREATED_ANATOMY]: { $set: value },
                      [fields.TREATED_ANATOMY_OTHER]: { $set: '' },
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="Site of Cannulation"
          options={siteOfCannulationOptions}
          otherField={{
            name: `${section}.${fields.SITE_OF_CANNULATION_OTHER}`,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: `${section}.${fields.SITE_OF_CANNULATION}`,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [section]: {
                      [fields.SITE_OF_CANNULATION]: { $set: value },
                      [fields.SITE_OF_CANNULATION_OTHER]: { $set: null },
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Largest Diameter of Treated Vein"
          type={INPUT_TYPES.NUMERIC}
          endAdornment={<InputAdornment position="end">mm</InputAdornment>}
          {...getFieldProps({
            formik,
            name: `${section}.${fields.LARGEST_DIAMETER_OF_TREATED_VEIN}`,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Length of Vein Treated"
          type={INPUT_TYPES.NUMERIC}
          endAdornment={<InputAdornment position="end">cm</InputAdornment>}
          {...getFieldProps({
            formik,
            name: `${section}.${fields.LENGTH_OF_VEIN_TREATED}`,
            disabled,
          })}
        />
      </Grid>
    </>
  );

  const sections = [
    {
      title: 'Laser Ablation',
      section: 'laserAblation',
      items: (section) => (
        <>
          <Grid item xs={12} md={4}>
            <Input
              label="Power Settings"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={
                <InputAdornment position="end">watts</InputAdornment>
              }
              {...getFieldProps({
                formik,
                name: `${section}.${fields.POWER_SETTINGS}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Total Energy Used"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">J</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.TOTAL_ENERGY_USED}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Pullback Time"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">s</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.PULLBACK_TIME}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Laser Ablation Brand or Product Name(s)"
              options={[{ value: OTHER_VALUE, label: OTHER_VALUE }]}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.PRODUCT_NAME}`,
                disabled,
              })}
            />
          </Grid>
          {formik.values[section][fields.PRODUCT_NAME] === OTHER_VALUE && (
            <Grid item xs={12} md={4}>
              <Input
                label="Please specify"
                {...getFieldProps({
                  formik,
                  name: `${section}.${fields.PRODUCT_NAME_OTHER}`,
                  disabled,
                })}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Chips
              label="Wavelength"
              options={wavelengthOptions}
              otherField={{
                name: `${section}.${fields.WAVELENGTH_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.WAVELENGTH}`,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [section]: {
                          [fields.WAVELENGTH]: { $set: value },
                          [fields.WAVELENGTH_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
        </>
      ),
    },
    {
      title: 'Radiofrequency Ablation',
      section: 'radiofrequencyAblation',
      items: (section) => (
        <>
          <Grid item xs={12} md={4}>
            <Chips
              label="Catheter Size (F)"
              options={catheterSizeOptions}
              otherField={{
                name: `${section}.${fields.CATHETER_SIZE_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.CATHETER_SIZE}`,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [section]: {
                          [fields.CATHETER_SIZE]: { $set: value },
                          [fields.CATHETER_SIZE_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Temperature"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={
                <InputAdornment position="end">Celsius</InputAdornment>
              }
              {...getFieldProps({
                formik,
                name: `${section}.${fields.TEMPERATURE}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="RFA Brand or Product Name(s)"
              options={[{ value: OTHER_VALUE, label: OTHER_VALUE }]}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.PRODUCT_NAME}`,
                disabled,
              })}
            />
          </Grid>
          {formik.values[section][fields.PRODUCT_NAME] === OTHER_VALUE && (
            <Grid item xs={12} md={4}>
              <Input
                label="Please specify"
                {...getFieldProps({
                  formik,
                  name: `${section}.${fields.PRODUCT_NAME_OTHER}`,
                  disabled,
                })}
              />
            </Grid>
          )}
        </>
      ),
    },
    {
      title: 'Mechanochemical Ablation',
      section: 'mechanochemicalAblation',
      items: (section) => (
        <>
          <Grid item xs={12} md={4}>
            <Input
              label="Total Volume of Sclerosant"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">ml</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.TOTAL_VOLUME_SCLEROSANT}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Sclerosant Agent"
              options={sclerosantAgentOptions}
              otherField={{
                name: `${section}.${fields.SCLEROSANT_AGENT_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.SCLEROSANT_AGENT}`,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [section]: {
                          [fields.SCLEROSANT_AGENT]: { $set: value },
                          [fields.SCLEROSANT_AGENT_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Sclerosant Concentration"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.SCLEROSANT_CONCENTRATION}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="MOCA Brand or Product Name(s)"
              options={[{ value: OTHER_VALUE, label: OTHER_VALUE }]}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.PRODUCT_NAME}`,
                disabled,
              })}
            />
          </Grid>
          {formik.values[section][fields.PRODUCT_NAME] === OTHER_VALUE && (
            <Grid item xs={12} md={4}>
              <Input
                label="Please specify"
                {...getFieldProps({
                  formik,
                  name: `${section}.${fields.PRODUCT_NAME_OTHER}`,
                  disabled,
                })}
              />
            </Grid>
          )}
        </>
      ),
    },
    {
      title: 'Glue (Cyanoacrylate) ',
      section: 'glueCyanoacrylate',
      items: (section) => (
        <>
          <Grid item xs={12} md={4}>
            <Input
              label="Total Volume of Glue"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">ml</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.TOTAL_VOLUME_GLUE}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Glue Brand or Product Name(s)"
              options={[{ value: OTHER_VALUE, label: OTHER_VALUE }]}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.PRODUCT_NAME}`,
                disabled,
              })}
            />
          </Grid>
          {formik.values[section][fields.PRODUCT_NAME] === OTHER_VALUE && (
            <Grid item xs={12} md={4}>
              <Input
                label="Please specify"
                {...getFieldProps({
                  formik,
                  name: `${section}.${fields.PRODUCT_NAME_OTHER}`,
                  disabled,
                })}
              />
            </Grid>
          )}
        </>
      ),
    },
    {
      title: 'Varithena Microfoam Sclerotherapy',
      section: 'varithenaMicrofoamSclerotherapy',
      items: (section) => (
        <Grid item xs={12} md={4}>
          <Input
            label="Total Volume of Microfoam"
            type={INPUT_TYPES.NUMERIC}
            endAdornment={<InputAdornment position="end">ml</InputAdornment>}
            {...getFieldProps({
              formik,
              name: `${section}.${fields.TOTAL_VOLUME_MICROFOAM}`,
              disabled,
            })}
          />
        </Grid>
      ),
    },
    {
      title: 'Physician Compounded Sclerotherapy',
      section: 'physicianCompoundedSclerotherapy',
      items: (section) => (
        <>
          <Grid item xs={12} md={4}>
            <Input
              label="Total Volume of Sclerosant"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">ml</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.TOTAL_VOLUME_SCLEROSANT}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Sclerosant Agent"
              options={sclerosantAgentOptions}
              otherField={{
                name: `${section}.${fields.SCLEROSANT_AGENT_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.SCLEROSANT_AGENT}`,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [section]: {
                          [fields.SCLEROSANT_AGENT]: { $set: value },
                          [fields.SCLEROSANT_AGENT_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Sclerosant Concentration"
              type={INPUT_TYPES.NUMERIC}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.SCLEROSANT_CONCENTRATION}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Sclerosant Formulation"
              options={sclerosantFormulationOptions}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.SCLEROSANT_FORMULATION}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Liquid to Gas Ratio"
              options={liquidToGasRatioOptions}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.LIQUID_TO_GAS_RATIO}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Gas Used"
              options={gasUsedOptions}
              otherField={{
                name: `${section}.${fields.GAS_USED_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.GAS_USED}`,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [section]: {
                          [fields.GAS_USED]: { $set: value },
                          [fields.GAS_USED_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Patient Mobilized"
              options={patientMobilizedOptions}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.PATIENT_MOBILIZIED}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Leg Elevated"
              options={yesNoOptions}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.LEG_ELEVATED}`,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="Ultrasound Controlled"
              options={yesNoOptions}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.ULTRASOUND_CONTROLLED}`,
                disabled,
              })}
            />
          </Grid>
        </>
      ),
    },
    {
      title: 'Phlebectomy',
      section: 'phlebectomy',
      otherSections: false,
      items: (section) => (
        <>
          <Grid item xs={12} md={8}>
            <Chips
              label="Treated Anatomy"
              options={treatedAnatomyOptions}
              isMultiple
              otherField={{
                name: `${section}.${fields.TREATED_ANATOMY_OTHER}`,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.TREATED_ANATOMY}`,
                disabled,
                changeOverride: (value) => {
                  if (!value.includes(OTHER_VALUE)) {
                    formik?.setValues(
                      update(formik?.values, {
                        [section]: {
                          [fields.TREATED_ANATOMY]: { $set: value },
                          [fields.TREATED_ANATOMY_OTHER]: { $set: '' },
                        },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Input
              label="Additional Notes"
              type={INPUT_TYPES.COMMENT}
              {...getFieldProps({
                formik,
                name: `${section}.${fields.ADDITIONAL_NOTES}`,
                disabled,
              })}
            />
          </Grid>
        </>
      ),
    },
    {
      title: 'Other SVI Treatment',
      section: OTHER_VALUE,
      items: (section) => (
        <Grid item xs={12} md={4}>
          <Input
            label="Product Name & Details"
            {...getFieldProps({
              formik,
              name: `${section}.${fields.PRODUCT_NAME}`,
              disabled,
            })}
          />
        </Grid>
      ),
    },
  ];

  return (
    <>
      <ProcedureGeneralSection disabled={disabled} />
      <FormSectionTitle>Procedure Details</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Chips
            label="SVI Procedure Limb"
            options={limbOptions}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.SVI_PROCEDURE_LIMB,
              disabled,
              changeOverride: (value) => {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.SVI_PROCEDURE_LIMB]: { $set: value },
                    ['left' + fields.SVI_TREATMENT_MODALITIES_USED]: {
                      $set: [],
                    },
                    ['right' + fields.SVI_TREATMENT_MODALITIES_USED]: {
                      $set: [],
                    },
                  }),
                );
              },
            })}
          />
        </Grid>
        {['Left', 'Right'].map(
          (side) =>
            [side, 'Both'].includes(
              formik.values[fields.SVI_PROCEDURE_LIMB],
            ) && (
              <Grid
                item
                xs={12}
                md={formik.values[fields.SVI_PROCEDURE_LIMB] === side ? 8 : 4}
                key={side}
              >
                <Chips
                  label={`SVI Treatment Modalities Used (${side} Limb)`}
                  options={modalitySVIOptions}
                  isRequired
                  isMultiple
                  {...getFieldProps({
                    formik,
                    name:
                      side.toLowerCase() + fields.SVI_TREATMENT_MODALITIES_USED,
                    disabled,
                  })}
                />
              </Grid>
            ),
        )}
      </Grid>
      {sections.map(({ title, section, items, otherSections = true }) => {
        const sectionValue = title.replace(/\s/g, '');

        return (
          (formik.values[
            'left' + fields.SVI_TREATMENT_MODALITIES_USED
          ]?.includes(sectionValue) ||
            formik.values[
              'right' + fields.SVI_TREATMENT_MODALITIES_USED
            ]?.includes(sectionValue)) && (
            <React.Fragment key={title}>
              <FormSectionTitle>{title} Modality Details</FormSectionTitle>
              <Grid container spacing={2} sx={{ py: 2 }}>
                {otherSections && firstSection(section)}
                {items(section)}
                {otherSections && (
                  <DeviceUDISection section={section} disabled={disabled} />
                )}
              </Grid>
            </React.Fragment>
          )
        );
      })}
      <EndOfProcedureAssessments
        encounter={encounterFormsEnum.SVI_PROCEDURE}
        disabled={disabled}
      />
    </>
  );
};

export default SVI;

SVI.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
