import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const buildArray = (currentValue, newValue) => {
  if (Array.isArray(currentValue)) return [...currentValue, newValue];
  return [currentValue, newValue];
};

const useQueryParams = () => {
  const { search } = useLocation();

  const query = useMemo(() => {
    if (!search || !search.startsWith('?')) {
      return {};
    }

    return search
      .slice(1)
      .split('&')
      .reduce((query, param) => {
        const [key, value] = param.split('=');
        if (!key) return query;

        query[key] = query[key]
          ? buildArray(query[key], value)
          : decodeURIComponent(value);

        return query;
      }, {});
  }, [search]);

  return { search, query };
};

export default useQueryParams;
