import MedicalStatus from '../care-track/encounters/details/data/patient-medical-history';
import SuspectedVLU from '../care-track/encounters/details/data/suspected-vlu';
import WoundAssessment from '../care-track/encounters/details/data/wound-assessment';

export const AssessmentsElements = {
  patientScreening: {
    name: 'Patient screening',
    form: SuspectedVLU,
  },
  patientMedicalStatus: {
    name: 'Patient medical status',
    form: MedicalStatus,
  },
  woundAssessment: {
    name: 'Wound assessment',
    form: WoundAssessment,
  },
};
