import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import api from 'core/api';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { USER_GROUPS } from 'utils/constants';
import { getErrorMessage, getErrorDetails } from 'utils/error';

import Template from 'pages/system-administrator/template';
import ConfirmDialog from 'components/confirm-dialog';
import ErrorMessage from 'components/error-message';
import FormWrapper from 'components/forms/form-wrapper';

import UserForm, { getUserFormValues } from '../form';
import { userSchema } from '../form/validations';

const UserDetails = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { userId } = useParams();
  const { user, isLoading, error } = useUserDetails(userId, {
    shouldLoad: true,
  });
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const handleSubmit = async (data) => {
    const { roles } = data;

    const submitData = {
      firstName: data.firstName,
      lastName: data.lastName,
      street: data.street,
      city: data.city,
      country: data.country,
      state: data.state,
      email: data.email,
      phoneNumber: data.phoneNumber,
      faxNumber: data.faxNumber,
      logoutAfterMinutes: data.logoutAfterMinutes || null,
      roles: roles,
    };

    if (roles?.includes(USER_GROUPS.practitioner)) {
      submitData.practitioner = {
        hospitalSystemId: data.practitionerSystem,
        hospitalSites: data.practitionerSites,
        diseases: data.practitionerManagedDiseaseStates,
        global: data.global,
      };
    }

    if (roles.includes(USER_GROUPS.hospitalAdministrator)) {
      submitData.hospitalAdministrator = {
        hospitalSystemId: data.hospitalAdminSystem,
      };
    }

    if (roles.includes(USER_GROUPS.reporter)) {
      submitData.hospitalReporter = {
        hospitalSystemId: data.reporterSystem,
      };
    }

    if (roles.includes(USER_GROUPS.eCRF)) {
      submitData.hospitalECRFUser = {
        hospitalSystemId: data.eCRFSystem,
      };
    }

    clearFeedback();
    api
      .updateUser(userId, submitData)
      .then(() => {
        setSuccessFeedback('User updated successfully');
        setDisabled(true);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  const deleteUser = () => {
    setShowDeleteModal(false);
    clearFeedback();
    api
      .deleteUser(userId)
      .then(() => {
        setSuccessFeedback('User deleted successfully');
        navigate(SYS_ADMIN_PATHS.users);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  return (
    <Template
      isLoading={isLoading}
      title={user ? `${user.firstName} ${user.lastName}` : 'User Details'}
      backUrl={SYS_ADMIN_PATHS.users}
      actions={
        <>
          {disabled && (
            <Button
              variant="contained"
              onClick={() => setDisabled(false)}
              data-test="edit-form-button"
            >
              Edit
            </Button>
          )}
          {process.env.REACT_APP_ENV !== 'production' && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </Button>
          )}
          <Button variant="contained">Reset Password</Button>
        </>
      }
    >
      {!user && error ? (
        <ErrorMessage message="User not found" />
      ) : (
        <>
          <ConfirmDialog
            open={showDeleteModal}
            handleCancel={() => setShowDeleteModal(false)}
            handleConfirm={() => deleteUser()}
            title={`Are you sure you want to delete user ${user?.email}?`}
          />
          <FormWrapper
            disabled={disabled}
            enableReinitialize
            initialValues={getUserFormValues(user)}
            onCancel={() => setDisabled(true)}
            onSubmit={handleSubmit}
            validationSchema={userSchema}
          >
            {({ formik }) => <UserForm formik={formik} readOnly={disabled} />}
          </FormWrapper>
        </>
      )}
    </Template>
  );
};

export default UserDetails;
