import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'core/api';
import Template from 'pages/practitioner/template';
import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import useECRF from 'hooks/useECRF';
import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import { DiseaseEnum, PathwayStepsEnum } from 'utils/enums';
import { getErrorMessage, getErrorDetails } from 'utils/error';
import { USER_GROUPS } from 'utils/constants';
import { StyledCard } from 'utils/styles';

import PatientForm from '../form';
import validationSchema from '../form/validationSchema';

const getInitValues = (practitionerId) => ({
  active: true,
  firstName: '',
  lastName: '',
  street: '',
  city: '',
  country: '',
  ianaTimezone: '',
  state: '',
  email: '',
  phoneNumber: '',
  birthDate: '',
  gender: '',
  practitioners: [practitionerId],
  diseases: [],
  diseasePathwaySteps: {
    [DiseaseEnum.VLU]: PathwayStepsEnum.WOUND_INTAKE,
  },
});

const PatientInvite = () => {
  const navigate = useNavigate();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const { isECRF, rootPath } = useECRF();
  const { userDetails } = useUser();

  const hospitalSystemId = useMemo(() => {
    return isECRF
      ? userDetails?.hospitalECRFUser?.hospitalSystemId
      : userDetails?.practitioner?.hospitalSystemId;
  }, [isECRF, userDetails]);

  const onSubmit = (formData) => {
    const submitData = {
      active: formData.active,
      firstName: formData.firstName,
      lastName: formData.lastName,
      birthDate: formData.birthDate,
      gender: formData.gender,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      ianaTimezone: formData.ianaTimezone,
      street: formData.street,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      patient: {
        hospitalSystemId: hospitalSystemId,
        diseases: formData.diseases.map((disease) => ({
          disease,
          diseasePathwayStep: formData.diseasePathwaySteps[disease],
        })),
        practitioners: formData.practitioners,
      },
      roles: [USER_GROUPS.patient],
    };

    clearFeedback();

    api
      .createUser(submitData)
      .then(({ data }) => {
        setSuccessFeedback('Patient invited with success');
        navigate(`${rootPath}/${data.id}/home`);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  return (
    <Template>
      {userDetails?.id ? (
        <StyledCard>
          <FormWrapper
            initialValues={getInitValues(userDetails?.id)}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onCancel={() => navigate(rootPath)}
            cancelStr="Back"
            confirmStr="Invite"
            title="Invite Patient"
          >
            {({ formik }) => (
              <PatientForm
                formik={formik}
                forcedPractitioner={userDetails?.id}
              />
            )}
          </FormWrapper>
        </StyledCard>
      ) : (
        <Loader />
      )}
    </Template>
  );
};

export default PatientInvite;
