import React from 'react';
import PropTypes from 'prop-types';
import { isSameDay, startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';

import { DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';

import { CustomPickersDay } from './WeekPicker.styles';

const Day = ({ day, selectedDay, ...other }) => {
  if (selectedDay == null) return <PickersDay day={day} {...other} />;

  const start = startOfWeek(selectedDay, { weekStartsOn: 1 });
  const end = endOfWeek(selectedDay, { weekStartsOn: 1 });
  const dayIsBetween = isWithinInterval(day, { start, end });
  const isFirstDay = isSameDay(start, day);
  const isLastDay = isSameDay(end, day);

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
};

Day.propTypes = {
  day: PropTypes.instanceOf(Date),
  selectedDay: PropTypes.instanceOf(Date),
};

const WeekPicker = ({ value, onChange }) => {
  const handleWeekChange = (date) =>
    onChange(startOfWeek(date, { weekStartsOn: 1 }));

  return (
    <DesktopDatePicker
      value={value}
      onChange={handleWeekChange}
      format="'Week of 'MMM dd"
      slots={{ day: Day }}
      slotProps={{
        day: { selectedDay: value },
        textField: { size: 'small' },
      }}
    />
  );
};

WeekPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

export default WeekPicker;
