import styled from '@emotion/styled';

export const NotesContainer = styled('div')(({ theme }) => ({
  height: '13rem',
  maxHeight: '13rem',
  overflowY: 'auto',
  padding: '1rem 1rem 0',
  border: '1px solid ' + theme.palette.grey[300],
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
}));

export const NoteTitle = styled('p')(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.grey[500],
}));
