import React, { useState, useMemo, useCallback, useEffect } from 'react';
import addMinutes from 'date-fns/addMinutes';
import find from 'lodash/find';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
import useUserDetails from 'hooks/useUserDetails';
import { PATIENT_DASHBOARD_PATHS } from 'navigation/paths';
import { encounterTypeLabels } from 'utils/encounters';
import { DiseaseEnum } from 'utils/enums';

import Template from 'pages/practitioner/patients/details/dashboard/Template';
import HeaderTitle from 'components/titles';

import { EncounterTypeButton } from './AddEncounter.styles';

const AddEncounter = () => {
  const [encounterType, setEncounterType] = useState(null);
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { user: patientUser } = useUserDetails(patientId);
  const { doRequest: getEncounterTypes, data: encounterTypesData } = useRequest(
    api.getEncounterTypes,
  );
  const {
    doRequest: addEncounter,
    data: encounterData,
    isLoading,
  } = useRequest(api.addEncounter);
  const { userDetails } = useUser();

  const hospitalSystemSiteId = useMemo(
    () => userDetails?.practitioner?.hospitalSites[0]?.id,
    [userDetails],
  );

  const handleEncounterStart = useCallback(() => {
    addEncounter({
      // TODO: Remove fake encounter date
      startedAt: new Date(),
      endedAt: addMinutes(new Date(), 5),
      type: encounterType,
      patientId,
      hospitalSystemSiteId,
    });
  }, [addEncounter, hospitalSystemSiteId, patientId, encounterType]);

  const handleBtnClick = useCallback(
    (type) => setEncounterType(encounterType === type ? null : type),
    [encounterType],
  );

  useEffect(() => {
    const encounter = encounterData?.encounter;
    if (encounter)
      navigate(`../${PATIENT_DASHBOARD_PATHS.ENCOUNTERS}/${encounter.id}`);
  }, [encounterData, navigate]);

  const fetchEncounterTypes = useCallback(() => {
    const vlu = find(patientUser?.patient?.diseases, {
      disease: DiseaseEnum.VLU,
    });
    getEncounterTypes({ PathwayStep: vlu.diseasePathwayStep });
  }, [getEncounterTypes, patientUser?.patient?.diseases]);

  useEffect(fetchEncounterTypes, [fetchEncounterTypes]);

  return (
    <Template>
      <HeaderTitle title="Select Visit Type" backUrl=".." />
      <Grid container spacing={2} sx={{ py: 2 }}>
        {encounterTypesData?.encounterTypes?.map((type, index) => (
          <Grid item xs={12} md={4} key={type + '_' + index}>
            <EncounterTypeButton
              selected={encounterType === type}
              onClick={() => handleBtnClick(type)}
              fullWidth
            >
              {encounterTypeLabels[type]}
            </EncounterTypeButton>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        disabled={!encounterType || isLoading}
        onClick={handleEncounterStart}
        sx={{ display: 'flex', ml: 'auto' }}
      >
        Start
      </Button>
    </Template>
  );
};

export default AddEncounter;
