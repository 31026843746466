import React, { useMemo } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

import useFeedback from 'hooks/useFeedback';

const Feedback = () => {
  const { feedback, clearFeedback } = useFeedback();

  return useMemo(() => {
    return (
      feedback?.message && (
        <Snackbar
          open={true}
          autoHideDuration={10000}
          onClose={clearFeedback}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={clearFeedback}
            severity={feedback.type}
          >
            <AlertTitle mb={0}>{feedback.message}</AlertTitle>
            {feedback?.details && (
              <Box
                component="ul"
                sx={{
                  maxHeight: '12rem',
                  overflowY: 'auto',
                  marginTop: '0.5rem',
                }}
              >
                {feedback.details.map((detail) => (
                  <li key={detail}>{detail}</li>
                ))}
              </Box>
            )}
          </Alert>
        </Snackbar>
      )
    );
  }, [feedback, clearFeedback]);
};

export default Feedback;
