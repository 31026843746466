import React from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'core/api';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import { getErrorMessage, getErrorDetails } from 'utils/error';
import { USER_GROUPS } from 'utils/constants';
import useFeedback from 'hooks/useFeedback';

import FormWrapper from 'components/forms/form-wrapper';

import Template from '../../template';

import UserForm, { getUserFormValues } from '../form';
import { userSchema } from '../form/validations';

const NewUser = () => {
  const navigate = useNavigate();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const handleSubmit = async (data) => {
    const { roles } = data;

    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      street: data.street,
      city: data.city,
      country: data.country,
      state: data.state,
      email: data.email,
      phoneNumber: data.phoneNumber,
      faxNumber: data.faxNumber,
      logoutAfterMinutes: data.logoutAfterMinutes,
      roles: data.roles,
    };

    if (roles?.includes(USER_GROUPS.practitioner)) {
      userData.practitioner = {
        hospitalSystemId: data.practitionerSystem,
        hospitalSites: data.practitionerSites,
        diseases: data.practitionerManagedDiseaseStates,
        global: data.global,
      };
    }

    if (roles.includes(USER_GROUPS.hospitalAdministrator)) {
      userData.hospitalAdministrator = {
        hospitalSystemId: data.hospitalAdminSystem,
      };
    }

    if (roles.includes(USER_GROUPS.reporter)) {
      userData.hospitalReporter = {
        hospitalSystemId: data.reporterSystem,
      };
    }

    if (roles.includes(USER_GROUPS.eCRF)) {
      userData.hospitalECRFUser = {
        hospitalSystemId: data.eCRFSystem,
      };
    }

    clearFeedback();

    api
      .createUser(userData)
      .then(({ data }) => {
        setSuccessFeedback('User created with success');
        navigate(`${SYS_ADMIN_PATHS.users}/${data.id}`, { replace: true });
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  return (
    <Template title="New User">
      <FormWrapper
        initialValues={getUserFormValues({})}
        validationSchema={userSchema}
        onSubmit={handleSubmit}
        onCancel={() => navigate(SYS_ADMIN_PATHS.users)}
      >
        {({ formik }) => <UserForm formik={formik} />}
      </FormWrapper>
    </Template>
  );
};

export default NewUser;
