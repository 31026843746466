import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import { DEFAULT_PAGE_SIZE, userRolesOptions } from 'utils/constants';
import useUserListing from 'hooks/useUserListing';
import useTableState from 'hooks/useTableState';

import Table from 'components/table';

import Template from 'pages/system-administrator/template';

import { columnStyles } from './UserListing.styles';
import UserListingFilters from './UserListingFilters';

const DEFAULT_SORT_BY = 'LastName';
const DEFAULT_SORT_DIRECTION = 'Asc';
const defaultFilters = {
  search: '',
  roles: [],
  hospitalSystems: '',
  hospitalSystemSites: [],
};

const getRolesHeader = (roles) => {
  return userRolesOptions
    .reduce((acc, cur) => {
      if (roles.includes(cur?.value)) acc.push(cur.label);
      return acc;
    }, [])
    .join(', ');
};

const UserListing = () => {
  const navigate = useNavigate();
  const { getUsers, users, isLoading, errorMessage, pageCount } =
    useUserListing();
  const {
    page,
    sortBy,
    sortOrder,
    filters,
    handlePageChange,
    handleSortChange,
    handleFilterChange,
    clearTableState,
  } = useTableState(DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION, defaultFilters);

  const listingFilters = useMemo(
    () => ({
      sort: sortBy,
      sortDirection: sortOrder,
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...filters,
      roles: filters?.roles?.length ? filters?.roles : [],
    }),
    [filters, page, sortBy, sortOrder],
  );

  useEffect(() => {
    getUsers(listingFilters);
  }, [getUsers, listingFilters]);

  const userTableAttributes = [
    { title: 'Last Name', handleClick: handleSortChange, id: 'LastName' },
    {
      title: 'First Name',
      handleClick: handleSortChange,
      id: 'FirstName',
    },
    { title: 'ID', handleClick: handleSortChange, id: 'Id' },
    { title: 'User Type' },
  ];

  const rows = useMemo(
    () =>
      users?.map(({ id, firstName, lastName, roles = [] }) => ({
        data: [lastName, firstName, id, getRolesHeader(roles)],
        id,
      })) ?? [],
    [users],
  );

  return (
    <Template
      title="Users"
      handleSearch={(value) => handleFilterChange('search', value)}
      searchValue={filters?.search}
      actions={
        <Button
          variant="contained"
          onClick={() => navigate('new')}
          sx={{ minWidth: 'fit-content' }}
        >
          Add User
        </Button>
      }
    >
      <UserListingFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        clearTableState={clearTableState}
      />
      <Table
        header={userTableAttributes}
        page={page}
        rows={rows}
        minimumRows={20}
        totalPages={pageCount}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        error={errorMessage}
        handleRowClick={(id) => navigate(id)}
        orderBy={sortBy}
        orderDirection={sortOrder}
        columnStyles={columnStyles}
      />
    </Template>
  );
};

export default UserListing;
