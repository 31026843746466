import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import { INPUT_TYPES, NONE_VALUE, OTHER_VALUE } from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import DateTimeSelector from 'components/forms/date-time-selector';
import Input from 'components/forms/input';

const operationLocationOptions = [
  { value: 'OperatingRoom', label: 'Operating Room' },
  { value: 'TreatmentClinicRoom', label: 'Treatment/Clinic Room' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const typeOfAnesthesiaOptions = [
  { value: NONE_VALUE, label: NONE_VALUE },
  { value: 'Sedation', label: 'Sedation' },
  { value: 'Local', label: 'Local' },
  { value: 'Regional', label: 'Regional' },
  { value: 'General', label: 'General' },
];

const proceduralAnticoagulationTherapyOptions = [
  { value: NONE_VALUE, label: NONE_VALUE },
  { value: 'Warfarin', label: 'Warfarin' },
  { value: 'LMWH', label: 'LMWH' },
  { value: 'UFH', label: 'UFH' },
  { value: 'FactorXaInhibitor', label: 'Factor Xa Inhibitor' },
  { value: 'DirectThrombinInhibitor', label: 'Direct Thrombin Inhibitor' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const fields = {
  OPERATION_LOCATION: 'operationLocation',
  OPERATION_LOCATION_OTHER: 'operationLocationOther',
  LENGTH_OF_TIME_IN_ROOM: 'numberOfMinutesInRoom',
  FIRST_NAME_OF_PERSON_PERFORMING_INTERVENTION:
    'firstNameOfPersonPerformingIntervention',
  SURNAME_OF_PERSON_PERFORMING_INTERVENTION:
    'surnameOfPersonPerformingIntervention',
  SPECIALITY_OF_PERSON_PERFORMING_INTERVENTION:
    'specialtyOfPersonPerformingIntervention',
  NPI_OF_PERSON_PERFORMING_INTERVENTION: 'npiOfPersonPerformingIntervention',
  PROCEDURE_DATE: 'procedureDate',
  PROCEDURE_START_TIME: 'procedureStartTime',
  PROCEDURE_END_TIME: 'procedureEndTime',
  TYPE_OF_ANESTHESIA: 'typeOfAnesthesia',
  PROCEDURAL_ANTICOAGULATION_THERAPY: 'anticoagulationTherapy',
  PROCEDURAL_ANTICOAGULATION_THERAPY_OTHER: 'anticoagulationTherapyOther',
};

export const ProcedureGeneralSection = ({ disabled }) => {
  const formik = useFormikContext();

  return (
    <>
      <FormSectionTitle>Location & Personnel</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Operation Location"
            options={operationLocationOptions}
            isRequired
            otherField={{
              name: fields.OPERATION_LOCATION_OTHER,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: fields.OPERATION_LOCATION,
              disabled,
              changeOverride: (value) => {
                if (value === OTHER_VALUE) {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.OPERATION_LOCATION]: {
                        $set: value,
                      },
                      [fields.OPERATION_LOCATION_OTHER]: {
                        $set: '',
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Length of time in room"
            type={INPUT_TYPES.NUMERIC}
            endAdornment={<InputAdornment position="end">min</InputAdornment>}
            {...getFieldProps({
              formik,
              name: fields.LENGTH_OF_TIME_IN_ROOM,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="First name of person performing intervention"
            isRequired
            {...getFieldProps({
              formik,
              name: fields.FIRST_NAME_OF_PERSON_PERFORMING_INTERVENTION,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Surname of person performing intervention"
            isRequired
            {...getFieldProps({
              formik,
              name: fields.SURNAME_OF_PERSON_PERFORMING_INTERVENTION,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Specialty of person performing intervention"
            {...getFieldProps({
              formik,
              name: fields.SPECIALITY_OF_PERSON_PERFORMING_INTERVENTION,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Physician Identification Number (NPI) of person performing intervention"
            {...getFieldProps({
              formik,
              name: fields.NPI_OF_PERSON_PERFORMING_INTERVENTION,
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <FormSectionTitle>Procedure Initiation</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Date of Procedure"
            isRequired
            {...getFieldProps({
              formik,
              name: fields.PROCEDURE_DATE,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateTimeSelector
            label="Procedure Start Time (First puncture)"
            {...getFieldProps({
              formik,
              name: fields.PROCEDURE_START_TIME,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateTimeSelector
            label="Procedure End Time (Final device removal)"
            {...getFieldProps({
              formik,
              name: fields.PROCEDURE_END_TIME,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Type of Anesthesia"
            options={typeOfAnesthesiaOptions}
            isRequired
            isMultiple
            {...getFieldProps({
              formik,
              name: fields.TYPE_OF_ANESTHESIA,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Chips
            label="Procedural Anticoagulation Therapy"
            options={proceduralAnticoagulationTherapyOptions}
            isRequired
            isMultiple
            otherField={{
              name: fields.PROCEDURAL_ANTICOAGULATION_THERAPY_OTHER,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: fields.PROCEDURAL_ANTICOAGULATION_THERAPY,
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.PROCEDURAL_ANTICOAGULATION_THERAPY]: {
                        $set: value,
                      },
                      [fields.PROCEDURAL_ANTICOAGULATION_THERAPY_OTHER]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
      </Grid>
    </>
  );
};

ProcedureGeneralSection.propTypes = { disabled: PropTypes.bool };
