import styled from '@emotion/styled';

import InputAdornment from '@mui/material/InputAdornment';

export const StyledAdornment = styled(InputAdornment)(() => ({
  marginRight: 0,

  '& p': {
    margin: 0,
  },
}));

export const StyledLink = styled('a')(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,
}));

export const StyledP = styled('p')(({ theme }) => ({
  margin: '0.25rem 0',
  color: theme.palette.base.white,
  fontSize: '0.75rem',
  lineHeight: 'normal',
}));
