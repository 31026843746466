import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import useUser from 'hooks/useUser';
import { ROOT_PATHS, SYS_ADMIN_PATHS } from 'navigation/paths';
import { StyledCard } from 'utils/styles';

import HeaderTitle from 'components/titles';
import Input from 'components/forms/input';
import Loader from 'components/loader';
import StyledIconButton from 'components/buttons/StyledIconButton';

import {
  AppName,
  CompanyName,
  Main,
  Sidebar,
  SidebarButton,
  Wrapper,
} from './Template.styles';

const sidebarOptions = [
  { name: 'Patients', path: 'patients' },
  { name: 'Users', path: 'users' },
  { name: 'Organizations', path: 'organizations' },
  { name: 'System', path: 'system' },
];

const Template = ({
  children,
  title,
  handleSearch,
  searchValue,
  backUrl,
  isLoading,
  actions,
}) => {
  const [internalSearchValue, setInternalSearchValue] = useState(
    searchValue || '',
  );
  const navigate = useNavigate();
  const { logout } = useUser();
  const { pathname } = useLocation();

  const selectedTab = React.useMemo(
    () => pathname.slice(1).split('/')?.[1],
    [pathname],
  );

  // reset search input
  useEffect(() => {
    if (searchValue === '' && searchValue !== internalSearchValue) {
      setInternalSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter' && typeof handleSearch === 'function') {
      handleSearch(internalSearchValue);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((text) => {
        if (typeof handleSearch === 'function') {
          handleSearch(text);
        }
      }, 500),
    [handleSearch],
  );

  const handleInputChange = (ev) => {
    let { value } = ev.target;
    setInternalSearchValue(value);
    debouncedSearch(value);
  };

  return (
    <Wrapper>
      <Sidebar>
        <AppName>
          Disease State <br />
          Management Portal
        </AppName>
        <CompanyName>By Impact Health</CompanyName>
        {sidebarOptions.map((option, index) => (
          <SidebarButton
            key={index}
            variant="contained"
            color={selectedTab === option.path ? 'primary' : 'secondary'}
            component={Link}
            to={`${ROOT_PATHS.systemAdministrator}/${option.path}`}
            data-test={option.path}
          >
            {option.name}
          </SidebarButton>
        ))}
      </Sidebar>
      <Main>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledCard sx={{ width: '100%' }}>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              mb={2}
            >
              <HeaderTitle title={title} backUrl={backUrl} />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}
              >
                {actions}
                {handleSearch && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Input
                      name="search"
                      placeholder="Search..."
                      value={internalSearchValue}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                    />
                    <StyledIconButton
                      name="search"
                      color="grey"
                      onClick={() => handleSearch(internalSearchValue)}
                      data-test="search-button"
                    />
                  </Box>
                )}
                <StyledIconButton
                  name="settings"
                  color="grey"
                  onClick={() => navigate(SYS_ADMIN_PATHS.settings)}
                />
                <StyledIconButton name="exit" color="grey" onClick={logout} />
              </Box>
            </Box>
            {children}
          </StyledCard>
        )}
      </Main>
    </Wrapper>
  );
};

Template.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  backUrl: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Template;
