import React, { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import useECRF from 'hooks/useECRF';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';

import Template from 'pages/practitioner/template';
import ErrorMessage from 'components/error-message';

import HeaderActions from './dashboard/header-actions';
import PatientDashboard from './dashboard';

const PatientDetails = () => {
  const { patientId } = useParams();
  const { state } = useLocation();
  const { isECRF, rootPath } = useECRF();

  const backUrl = useMemo(
    () => `${rootPath}${state?.onBackParams ?? ''}`,
    [rootPath, state],
  );

  const { user, error } = useUserDetails(patientId, { shouldLoad: true });

  const title = useMemo(() => {
    if (error) return 'Failed to load patient';
    if (user) return `${user?.firstName} ${user?.lastName}`;
    return <CircularProgress />;
  }, [error, user]);

  return (
    <Template
      backUrl={backUrl}
      title={title}
      headerRight={!isECRF && <HeaderActions />}
    >
      {error && <ErrorMessage message={getErrorMessage(error)} />}
      {user?.id && <PatientDashboard />}
    </Template>
  );
};

export default PatientDetails;
