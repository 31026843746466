import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { StyledCard } from 'utils/styles';

const ConfirmDialog = ({
  open,
  title,
  subtitle = 'This cannot be undone.',
  disableConfirm = false,
  handleConfirm,
  handleCancel,
  confirmLabel = 'Yes, Delete',
  cancelLabel = 'No, Cancel',
}) => (
  <Dialog open={open}>
    <StyledCard>
      <Box display="flex" flexDirection="column" textAlign="center">
        <span style={{ marginBottom: '0.25rem', fontWeight: '600' }}>
          {title}
        </span>
        <span>{subtitle}</span>
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Box mr={1}>
          <Button
            variant="contained"
            disabled={disableConfirm}
            onClick={handleConfirm}
          >
            {confirmLabel}
          </Button>
        </Box>
        <Button variant="outlined" color="primary" onClick={handleCancel}>
          {cancelLabel}
        </Button>
      </Box>
    </StyledCard>
  </Dialog>
);

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  disableConfirm: PropTypes.bool,
  open: PropTypes.bool,
};

export default ConfirmDialog;
