import React from 'react';
import { useNavigate } from 'react-router-dom';

import useUserListing from 'hooks/useUserListing';
import useTableState from 'hooks/useTableState';
import { formatBirthDate } from 'utils/date';
import { USER_GROUPS, DEFAULT_PAGE_SIZE } from 'utils/constants';

import Table from 'components/table';

import Template from 'pages/system-administrator/template';

import PatientListingFilters from './PatientListingFilters';
import { columnStyles } from './PatientListing.styles';

const DEFAULT_SORT_BY = 'LastName';
const DEFAULT_SORT_DIRECTION = 'Asc';
const defaultFilters = {
  search: '',
  hospitalSystems: [],
  assignedPractitioners: [],
  active: '',
};

const PatientListing = () => {
  const navigate = useNavigate();

  const {
    page,
    sortBy,
    sortOrder,
    filters,
    handleFilterChange,
    handlePageChange,
    handleSortChange,
    clearTableState,
  } = useTableState(DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION, defaultFilters);
  const { getUsers, users, isLoading, errorMessage, pageCount } =
    useUserListing();

  const listingFilters = React.useMemo(
    () => ({
      roles: [USER_GROUPS.patient],
      sort: sortBy,
      sortDirection: sortOrder,
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...filters,
    }),
    [page, sortBy, sortOrder, filters],
  );

  React.useEffect(() => {
    getUsers(listingFilters);
  }, [getUsers, listingFilters]);

  const patientTableAttributes = [
    { title: 'Last Name', handleClick: handleSortChange, id: 'LastName' },
    { title: 'First Name', handleClick: handleSortChange, id: 'FirstName' },
    { title: 'ID', handleClick: handleSortChange, id: 'Id' },
    { title: 'DOB', handleClick: handleSortChange, id: 'Dob' },
    {
      title: 'Hospital System',
      handleClick: handleSortChange,
      id: 'HospitalSystem',
    },
    { title: 'Active', handleClick: handleSortChange, id: 'Active' },
  ];

  const rows = React.useMemo(
    () =>
      users?.map(({ id, firstName, lastName, birthDate, patient, active }) => ({
        data: [
          lastName,
          firstName,
          id,
          formatBirthDate(birthDate),
          patient?.hospitalSystemName,
          active ? 'Yes' : 'No',
        ],
        id,
      })),
    [users],
  );

  return (
    <Template
      title="Patients"
      handleSearch={(value) => handleFilterChange('search', value)}
      searchValue={filters?.search}
    >
      <PatientListingFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        clearTableState={clearTableState}
      />
      <Table
        style={columnStyles}
        header={patientTableAttributes}
        page={page}
        rows={rows}
        minimumRows={20}
        totalPages={pageCount}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        error={errorMessage}
        handleRowClick={(id) => navigate(id)}
        orderBy={sortBy}
        orderDirection={sortOrder}
      />
    </Template>
  );
};

export default PatientListing;
