import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';

import useChat from 'hooks/useChat';
import useECRF from 'hooks/useECRF';

import SidebarOption from './SidebarOption';
import { StyledBadge } from './Template.styles';

const SidebarOptions = () => {
  const { pathname } = useLocation();
  const { totalUnreadCount } = useChat();
  const { isECRF } = useECRF();

  const selectedTab = useMemo(
    () => pathname.slice(1).split('/')?.[1],
    [pathname],
  );

  return (
    <>
      <SidebarOption
        icon={<HomeIcon />}
        path="patients"
        selectedTab={selectedTab}
      >
        Patients
      </SidebarOption>
      {!isECRF ? (
        <SidebarOption
          icon={<MailIcon />}
          path="messages"
          selectedTab={selectedTab}
        >
          <StyledBadge
            badgeContent={totalUnreadCount}
            overlap="rectangular"
            color={selectedTab === 'messages' ? 'primary' : 'secondary'}
          >
            Messages
          </StyledBadge>
        </SidebarOption>
      ) : null}
      <SidebarOption
        icon={<SettingsIcon />}
        path="settings"
        selectedTab={selectedTab}
      >
        Settings
      </SidebarOption>
      <SidebarOption
        icon={<EqualizerIcon />}
        path="dashboards"
        selectedTab={selectedTab}
      >
        Dashboards
      </SidebarOption>
    </>
  );
};

export default SidebarOptions;
