import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import useWidgetModal, { modalTypeEnum } from 'hooks/useWidgetModal';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { formatISOString } from 'utils/date';

import Table from 'components/table';
import { RowActions } from 'components/table/RowActions';
import WidgetContainer from 'components/widgets/widget-container';
import DeleteComplianceLog from 'components/compliance-questions/DeleteComplianceLog';

import NewPainMobility from './NewPainMobility';
import EditPainMobility from './EditPainMobility';
import { columnStyles } from './PainMobility.styles';
import { formFieldEnum, painLabels, mobilityLabels } from './utils';

const header = [
  { title: 'Date' },
  { title: 'Pain Value' },
  { title: 'Mobility Value' },
  { title: 'Leg Swelling Value' },
  { title: '' },
];

const PainMobilityList = () => {
  const { patientId } = useParams();
  const { modal, handleAdd, handleEdit, handleDelete, handleClose } =
    useWidgetModal();

  const { page, handlePageChange } = useTableState('Timestamp', 'Desc');
  const {
    data: listData,
    doRequest: getList,
    isLoading,
  } = useRequest(api.getPainMobilityList);

  const handleRefresh = useCallback(() => {
    getList({
      PatientId: patientId,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
    });
  }, [page, getList, patientId]);

  useEffect(handleRefresh, [handleRefresh]);

  const renderModalContent = useMemo(() => {
    switch (modal?.type) {
      case modalTypeEnum.ADD:
        return (
          <NewPainMobility
            handleCancel={handleClose}
            handleRefresh={handleRefresh}
          />
        );
      case modalTypeEnum.EDIT:
        return (
          <EditPainMobility
            painMobilityId={modal.item.id}
            handleCancel={handleClose}
            handleRefresh={handleRefresh}
          />
        );
      case modalTypeEnum.DELETE:
        return (
          <DeleteComplianceLog
            handleCancel={handleClose}
            deleteFunc={async () => api.deletePainMobility(modal.item.id)}
            handleRefresh={handleRefresh}
          />
        );
      default:
        return null;
    }
  }, [modal, handleRefresh, handleClose]);

  const rows = useMemo(() => {
    return listData?.data.map((obj) => {
      return {
        data: [
          formatISOString(obj.timestamp),
          painLabels[obj[formFieldEnum.PAIN]],
          mobilityLabels[obj[formFieldEnum.MOBILITY]],
          obj[formFieldEnum.LEG_SWELLING],
          <RowActions
            key={obj.id}
            handleEdit={() => handleEdit(obj)}
            handleDelete={() => handleDelete(obj)}
          />,
        ],
      };
    });
  }, [listData, handleDelete, handleEdit]);

  return (
    <WidgetContainer
      title="Pain, Mobility, Leg Swelling Logs"
      onAddClick={handleAdd}
      modalContent={renderModalContent}
      onModalClose={handleClose}
      isLogsList
    >
      <Table
        header={header}
        page={page}
        rows={rows}
        isLoading={isLoading}
        totalPages={listData?.pageCount || 1}
        handlePageChange={handlePageChange}
        columnStyles={columnStyles}
      />
    </WidgetContainer>
  );
};

export default PainMobilityList;
