import React from 'react';
import PropTypes from 'prop-types';

import useHospitalSystemListing from 'hooks/useHospitalSystemListing';

import SelectSearchable from 'components/forms/select-searchable';

import { getFieldProps, normalizeOptions } from 'utils/forms';

const sortFilters = { Sort: 'Name', SortDirection: 'Asc' };

const SystemRoleFields = ({ formik, readOnly, fieldName }) => {
  const { hospitalSystems } = useHospitalSystemListing(sortFilters);

  return (
    <SelectSearchable
      options={normalizeOptions(hospitalSystems)}
      placeholder="System"
      {...getFieldProps({ formik, name: fieldName, disabled: readOnly })}
    />
  );
};

SystemRoleFields.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      hospitalAdminSystem: PropTypes.string,
      reporterSystem: PropTypes.string,
      eCRFSystem: PropTypes.string,
    }),
  }),
  readOnly: PropTypes.bool,
  fieldName: PropTypes.string,
};

export default SystemRoleFields;
