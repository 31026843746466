export const DEFAULT_PAGE_SIZE = 20;

export const OTHER_VALUE = 'Other';
export const NONE_VALUE = 'None';

export const genderOptions = [
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const yesNoOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

/*
 * Cognito Groups/Roles Names
 * Group Name            Precedence  Description
 * system_administrator	 10          This user type manages the Organizations/Systems and User base.
 * practitioner	         30          A person who is directly or indirectly involved in the provisioning of healthcare (aka Clinician/Care Provider).
 * patient	             40          This user type only has the ability to login on the mobile app and will have access to all parts of the mobile app.
 * wound_image_tracer	   50          This user type will ONLY be able to access their Settings page and a list of wound measurements to review.
 */

export const USER_GROUPS = {
  systemAdministrator: 'SystemAdministrators',
  practitioner: 'Practitioners',
  patient: 'Patients',
  woundImageTracer: 'WoundImageTracers',
  woundDetector: 'WoundEdgeDetectors',
  hospitalAdministrator: 'HospitalAdministrators',
  reporter: 'HospitalReporters',
  eCRF: 'HospitalECRFUsers',
};

export const userGroupsLabels = {
  [USER_GROUPS.systemAdministrator]: 'System Administrator',
  [USER_GROUPS.practitioner]: 'Practitioner',
  [USER_GROUPS.patient]: 'Patient',
  [USER_GROUPS.woundImageTracer]: 'Wound Image Tracer',
  [USER_GROUPS.woundDetector]: 'Wound Edge Detection',
  [USER_GROUPS.hospitalAdministrator]: 'Hospital Administrator',
  [USER_GROUPS.reporter]: 'Executive Dashboard',
  [USER_GROUPS.eCRF]: 'eCRF',
};

// User roles values for form validation
export const userRolesValues = Object.values(USER_GROUPS);

// User roles select options
export const userRolesOptions = userRolesValues.map((value) => ({
  value,
  label: userGroupsLabels[value],
}));

const adminRoles = [
  USER_GROUPS.systemAdministrator,
  USER_GROUPS.practitioner,
  USER_GROUPS.woundImageTracer,
  USER_GROUPS.woundDetector,
  USER_GROUPS.hospitalAdministrator,
  USER_GROUPS.reporter,
  USER_GROUPS.eCRF,
];

export const adminUserRolesOptions = Object.values(adminRoles).map((value) => ({
  value,
  label: userGroupsLabels[value],
}));

export const woundImagesStatus = {
  open: 'Open',
  submitted: 'Submitted',
  approved: 'Approved',
  deleted: 'Deleted',
};

export const QUESTIONNAIRES = {
  SF36: 'SF-36',
  VeinesQolSym: 'VEINES-QoL/Sym',
  PembQol: 'PEmb-QoL',
  WoundQol: 'Wound-QoL',
  EQ5D5L: 'EQ-5D-5L',
};

export const INPUT_TYPES = {
  SHORT_TEXT: 'SHORT_TEXT',
  COMMENT: 'COMMENT',
  NUMERIC: 'NUMERIC',
  DATE_SELECTOR: 'DATE_SELECTOR',
  DATE_SELECTOR_LIST: 'DATE_SELECTOR_LIST',
  YEAR_MONTH_SELECTOR: 'YEAR_MONTH_SELECTOR',
  PASSWORD: 'PASSWORD',
  CHIPS: 'CHIPS',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  RADIO: 'RADIO',
  TABLE: 'TABLE',
  SCALE: 'SCALE',
  INFO: 'INFO',
};
