export const mapDataToForm = (data) => ({
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  email: data?.email ?? '',
  phoneNumber: data?.phoneNumber ?? '',
  faxNumber: data?.faxNumber ?? '',
  doxyTelehealthLink: data?.practitioner?.doxyTelehealthLink ?? '',
  profileImageAssetId: data.profileImageAssetId ?? '',
});

export const mapForm2Api = ({ practitioner, ...userData }) => ({
  ...userData,
  practitioner: {
    ...practitioner,
    hospitalSites: practitioner?.hospitalSites?.map(({ id }) => id) ?? [],
  },
});
