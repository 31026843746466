import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { CARE_TRACK_PATHS } from 'navigation/paths';
import { formatDate } from 'utils/date';

import WidgetContainer from 'components/widgets/widget-container';
import WidgetTypeMenu from 'components/widgets/widget-type-menu';
import WoundSizeTimeSelector from 'components/widgets/WoundSizeTimeSelector';

import { NewWoundSize, NewWound, modalEnum } from '../wound-size-logs';

import WoundSizeGraph from './WoundSizeGraph';
import { getTimeInterval, listViewMode } from './utils';

const WoundSizeWidget = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = React.useState(listViewMode[0]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [modal, setModal] = React.useState(null);

  const { data: woundsData, doRequest: getWoundSizes } = useRequest(
    api.getCareWoundSizes,
  );

  const graphTimeInteval = React.useMemo(
    () => getTimeInterval({ selectedDate, viewMode }),
    [selectedDate, viewMode],
  );

  const fetchData = React.useCallback(() => {
    getWoundSizes({
      patientId,
      dateFrom: formatDate(graphTimeInteval.since),
      dateTo: formatDate(graphTimeInteval.until),
    });
  }, [graphTimeInteval, patientId, getWoundSizes]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderModalContent = React.useMemo(() => {
    switch (modal?.type) {
      case modalEnum.ADD:
        return (
          <NewWoundSize
            handleCancel={() => setModal(null)}
            handleRefresh={fetchData}
            handleAddWound={() => setModal({ type: modalEnum.ADD_WOUND })}
          />
        );
      case modalEnum.ADD_WOUND:
        return (
          <NewWound
            handleSuccess={() => setModal({ type: modalEnum.ADD })}
            handleCancel={() => setModal({ type: modalEnum.ADD })}
          />
        );
      default:
        return null;
    }
  }, [modal, fetchData]);

  return (
    <WidgetContainer
      title="Wound Size"
      onLogsClick={() => navigate(CARE_TRACK_PATHS.WOUND_SIZE)}
      onAddClick={() => setModal({ type: modalEnum.ADD })}
      modalContent={renderModalContent}
      onModalClose={() => setModal(null)}
    >
      <WoundSizeGraph
        patientId={patientId}
        woundSizes={woundsData?.woundSizes ?? []}
        timeInterval={graphTimeInteval}
      />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={8}>
          <WoundSizeTimeSelector
            viewMode={viewMode}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <WidgetTypeMenu
            options={listViewMode}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default WoundSizeWidget;
