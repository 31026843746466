import styled from '@emotion/styled';
import { Authenticator } from '@aws-amplify/ui-react';

import { weight, family } from 'theme/fonts';

export const Title = styled('h1')(() => ({
  font: `${weight.semibold} 1.5rem ${family.openSans}`,
}));

export const SubTitle = styled('h2')(({ theme }) => ({
  margin: '0.5rem 0 2rem',
  color: theme.palette.grey[700],
  font: `${weight.semibold} 1rem ${family.openSans}`,
}));

export const StyledAuthenticator = styled(Authenticator)(({ theme }) => ({
  minHeight: '100vh',
  backgroundImage: theme.palette.gradient.main,

  '.amplify-heading': {
    color: theme.palette.base.black,
    textAlign: 'center',
  },

  '[data-amplify-container]': {
    width: 'fit-content',
    minWidth: '30rem',
    padding: '2rem',
    borderRadius: '0.25rem',
    background: theme.palette.base.white,
    boxShadow: `0 0.2rem 0.4rem ${theme.palette.grey[300]}`,

    '.amplify-field': {
      '.amplify-input': {
        height: '2.5rem',
        borderColor: theme.palette.grey[300],
        borderEndStartRadius: '0.25rem',
        borderStartStartRadius: '0.25rem',
      },

      button: {
        borderColor: theme.palette.grey[300],
        borderInlineStartColor: 'transparent',
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },

    '[data-amplify-router]': {
      backgroundColor: theme.palette.base.white,
      border: 'none',
      boxShadow: 'none',

      '[data-amplify-form]': {
        padding: 0,
      },

      '[data-amplify-footer]': {
        padding: '1rem 0 0 0',
        border: 'none',
        boxShadow: 'none',
      },
    },
  },

  '.amplify-button--primary': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.base.black,
    },
  },

  '.amplify-button--link': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
