import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const WidgetTypeMenu = ({ viewMode, setViewMode, options = [] }) => {
  const [anchorMenuView, setAnchorMenuView] = useState(null);
  return (
    <Box ml="auto">
      <Button
        style={{ textTransform: 'initial' }}
        onClick={(event) => setAnchorMenuView(event.currentTarget)}
      >
        {viewMode}
        <ExpandMoreIcon />
      </Button>
      <Menu
        anchorEl={anchorMenuView}
        keepMounted
        open={Boolean(anchorMenuView)}
        onClose={() => setAnchorMenuView(null)}
      >
        {options?.map((vm) => (
          <MenuItem
            key={vm}
            onClick={() => {
              setViewMode(vm);
              setAnchorMenuView(null);
            }}
          >
            {vm}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

WidgetTypeMenu.propTypes = {
  viewMode: PropTypes.string.isRequired,
  setViewMode: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WidgetTypeMenu;
