export const getUserFormValues = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  faxNumber,
  street,
  city,
  state,
  country,
  roles = [],
  practitioner,
  hospitalAdministrator,
  hospitalReporter,
  hospitalECRFUser,
  logoutAfterMinutes,
} = {}) => ({
  email: email ?? '',
  phoneNumber: phoneNumber ?? '',
  faxNumber: faxNumber ?? '',
  firstName: firstName ?? '',
  lastName: lastName ?? '',
  street: street ?? '',
  city: city ?? '',
  state: state ?? '',
  country: country ?? '',
  roles: roles ?? [],
  autoLogout: Boolean(logoutAfterMinutes),
  logoutAfterMinutes: logoutAfterMinutes ?? null,
  practitionerSystem: practitioner?.hospitalSystemId ?? '',
  practitionerSites: (practitioner?.hospitalSites || []).map(({ id }) => id),
  practitionerManagedDiseaseStates: practitioner?.diseases ?? [],
  hospitalAdminSystem: hospitalAdministrator?.hospitalSystemId ?? '',
  reporterSystem: hospitalReporter?.hospitalSystemId ?? '',
  eCRFSystem: hospitalECRFUser?.hospitalSystemId ?? '',
  global: practitioner?.global ?? false,
});
