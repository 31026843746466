import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const FormBox = styled('form')(() => ({
  width: '50vw',
  minHeight: '50vh',

  [breakpointsTheme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
