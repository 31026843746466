import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { TASKS_PATHS } from 'navigation/paths';

import Dashboard from './dashboard';
import NewTaskRule from './task-rule-new';
import TaskRuleDetails from './task-rule-details';

const Home = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path={TASKS_PATHS.NEW_RULE} element={<NewTaskRule />} />
    <Route path={TASKS_PATHS.EDIT_RULE} element={<TaskRuleDetails />} />
  </Routes>
);

export default Home;
