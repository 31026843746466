import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isSameDay, startOfMonth, endOfMonth } from 'date-fns';
import { useParams } from 'react-router-dom';

import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useTheme } from 'context/theme';
import { localWeekStartsOn1 } from 'utils/date';

const MonthlyDisplay = ({ data, fetchData }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const { patientId } = useParams();
  const { palette } = useTheme();

  useEffect(() => {
    fetchData({
      PatientId: patientId,
      DateFrom: startOfMonth(new Date(selectedMonth)).toISOString(),
      DateTo: endOfMonth(new Date(selectedMonth)).toISOString(),
    });
  }, [selectedMonth, fetchData, patientId]);

  const renderDay = ({ day, ...other }) => {
    const itemValue = data
      .filter((element) => isSameDay(new Date(element.timestamp), day))
      .reduce((res, { value }) => {
        return res || value;
      }, null);

    if (itemValue !== null) {
      return (
        <PickersDay
          day={day}
          sx={{
            backgroundColor: itemValue
              ? palette.success.main
              : palette.error.main,
            color: palette.base.white,
          }}
          {...other}
        />
      );
    }

    return <PickersDay day={day} {...other} />;
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localWeekStartsOn1}
    >
      <DateCalendar
        defaultValue={null}
        onMonthChange={setSelectedMonth}
        slots={{ day: renderDay }}
        readOnly={true}
      />
    </LocalizationProvider>
  );
};

MonthlyDisplay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  fetchData: PropTypes.func.isRequired,
};

export default MonthlyDisplay;
