import * as Yup from 'yup';

import {
  LegEnum,
  LimbLocationEnum,
  LimbLocationLabel,
  LimbSideEnum,
  mapEnumToOptions,
} from 'utils/enums';
import { OTHER_VALUE } from 'utils/constants';

export const woundSizeSchema = Yup.object({
  woundId: Yup.string().required('Required'),
  size: Yup.number()
    .positive('Wound size must be a positive value')
    .required('Required'),
});

export const woundFields = {
  NAME: 'name',
  WOUND_LEG: 'leg',
  WOUND_LIMB_LOCATION: 'limbLocation',
  WOUND_LIMB_LOCATION_OTHER: 'limbLocationOther',
  WOUND_LIMB_SIDE: 'limbSide',
};

export const woundSchema = Yup.object({
  [woundFields.WOUND_LEG]: Yup.string().required('Required'),
  [woundFields.WOUND_LIMB_LOCATION]: Yup.string().required('Required'),
  [woundFields.WOUND_LIMB_LOCATION_OTHER]: Yup.string().when(
    woundFields.WOUND_LIMB_LOCATION,
    {
      is: OTHER_VALUE,
      then: (schema) => schema.required('Required'),
    },
  ),
  [woundFields.WOUND_LIMB_SIDE]: Yup.string().required('Required'),
});

export const modalEnum = {
  ADD: 'add',
  EDIT: 'edit',
  ADD_WOUND: 'add-wound',
  DELETE: 'delete',
};

export const woundLegOptions = mapEnumToOptions({
  data: LegEnum,
});

export const woundLimbOptions = mapEnumToOptions({
  data: LimbLocationEnum,
  labels: LimbLocationLabel,
});

export const woundLimbSideOptions = mapEnumToOptions({
  data: LimbSideEnum,
});
