import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store/hospital-systems';

const useHospitalSystemListing = () => {
  const dispatch = useDispatch();

  const bindedActions = useMemo(
    () =>
      bindActionCreators(
        {
          getHospitalSystems: actions.doGetHospitalSystems,
        },
        dispatch,
      ),
    [dispatch],
  );

  const {
    error,
    loading: isLoading,
    pageCount,
    items: hospitalSystems,
  } = useSelector((state) => state.hospitalSystems.list);

  return {
    ...bindedActions,
    isLoading,
    error,
    hospitalSystems,
    pageCount,
  };
};

export default useHospitalSystemListing;
