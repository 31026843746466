import React from 'react';
import styled from '@emotion/styled';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import useAccount from 'hooks/useAccount';
import useChat from 'hooks/useChat';
import useUser from 'hooks/useUser';
import { USER_GROUPS } from 'utils/constants';

import { RolesDashboard } from 'pages/account';
import Feedback from 'components/feedback';
import Loader from 'components/loader';

import ProtectedRoute from './components/ProtectedRoute';
import {
  COMMON_PATH,
  ROOT_PATHS,
  PRACTITIONER_PATHS,
  SYS_ADMIN_PATHS,
  ECRF_PATHS,
} from './paths';
import {
  SYSTEM_ADMINISTRATOR_ROUTES,
  PRACTITIONER_ROUTES,
  PATIENT_ROUTES,
  WOUND_IMAGE_TRACER_ROUTES,
  WOUND_IMAGE_DETECTORS_ROUTES,
  ECRF_ROUTES,
  ERROR_ROUTES,
} from './routes';

const LoadingContainer = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

const AppRoutes = () => {
  const {
    userDetails: { logoutAfterMinutes },
    logout,
  } = useUser();

  const { start } = useIdleTimer({
    timeout: logoutAfterMinutes * 60000 || 1,
    onIdle: logout,
    debounce: 250,
    startOnMount: false,
    startManually: true,
  });

  React.useEffect(() => {
    if (!logoutAfterMinutes) return;
    start();
  }, [logoutAfterMinutes, start]);

  return (
    <Routes>
      <Route path={COMMON_PATH} exact element={<RolesDashboard />} />
      <Route path="/" exact element={<Navigate replace to={COMMON_PATH} />} />
      {SYSTEM_ADMINISTRATOR_ROUTES.map((routeProps) => (
        <Route
          key={routeProps.path}
          path={routeProps.path}
          exact={routeProps.exact}
          element={
            <ProtectedRoute
              groupAllowed={USER_GROUPS.systemAdministrator}
              component={routeProps.component}
            />
          }
        />
      ))}
      <Route
        path={ROOT_PATHS.systemAdministrator}
        element={<Navigate replace to={SYS_ADMIN_PATHS.patients} />}
      />
      {PATIENT_ROUTES.map((routeProps) => (
        <Route
          key={routeProps.path}
          path={routeProps.path}
          exact={routeProps.exact}
          element={
            <ProtectedRoute
              groupAllowed={USER_GROUPS.patient}
              component={routeProps.component}
            />
          }
        />
      ))}
      {WOUND_IMAGE_TRACER_ROUTES.map((routeProps) => (
        <Route
          key={routeProps.path}
          path={routeProps.path}
          exact={routeProps.exact}
          element={
            <ProtectedRoute
              groupAllowed={USER_GROUPS.woundImageTracer}
              component={routeProps.component}
            />
          }
        />
      ))}
      {WOUND_IMAGE_DETECTORS_ROUTES.map((routeProps) => (
        <Route
          key={routeProps.path}
          path={routeProps.path}
          exact={routeProps.exact}
          element={
            <ProtectedRoute
              groupAllowed={USER_GROUPS.woundDetector}
              component={routeProps.component}
            />
          }
        />
      ))}
      {ECRF_ROUTES.map((routeProps) => (
        <Route
          key={routeProps.path}
          path={routeProps.path}
          exact={routeProps.exact}
          element={
            <ProtectedRoute
              groupAllowed={USER_GROUPS.eCRF}
              component={routeProps.component}
            />
          }
        />
      ))}
      <Route
        path={ROOT_PATHS.eCRF}
        element={<Navigate to={ECRF_PATHS.patients} />}
      />
      {PRACTITIONER_ROUTES.map((routeProps) => (
        <Route
          key={routeProps.path}
          path={routeProps.path}
          exact={routeProps.exact}
          element={
            <ProtectedRoute
              groupAllowed={USER_GROUPS.practitioner}
              component={routeProps.component}
            />
          }
        />
      ))}
      <Route
        path={ROOT_PATHS.practitioner}
        element={<Navigate to={PRACTITIONER_PATHS.patients} />}
      />
      {ERROR_ROUTES.map((routeProps) => (
        <Route key={routeProps.path} {...routeProps} />
      ))}
    </Routes>
  );
};

const RoleSwitch = () => {
  const { pathname } = useLocation();
  const { getCurrentUserDetails, userGroups } = useUser();
  const { startSocket, closeSocket, fetchRooms } = useChat();
  const { accountDetails, getCurrentAccountDetails, getCurrentAccountLogoUrl } =
    useAccount();

  React.useEffect(
    () => {
      getCurrentUserDetails();
      getCurrentAccountDetails();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    accountDetails && getCurrentAccountLogoUrl(accountDetails?.assetId);
  }, [accountDetails, getCurrentAccountLogoUrl]);

  const rootPath = React.useMemo(
    () => pathname.substring(1).split('/')[0],
    [pathname],
  );

  React.useEffect(
    () => {
      switch (rootPath) {
        case USER_GROUPS.practitioner: {
          fetchRooms();
          startSocket();
          break;
        }
        default: {
          closeSocket();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootPath],
  );

  return (
    <>
      {userGroups?.length ? (
        <AppRoutes />
      ) : (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
      <Feedback />
    </>
  );
};

export default RoleSwitch;
