import styled from '@emotion/styled';

import IconButton from '@mui/material/IconButton';

const StyledBackIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: '1rem',
  padding: '0.5rem',
  backgroundColor: theme.palette.primary.light,
  fontSize: '1.5rem',

  '&:hover': {
    color: theme.palette.base.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default StyledBackIconButton;
