import React from 'react';

import {
  PATIENT_DASHBOARD_PATHS,
  ECRF_DASHBOARD_PATHS,
} from 'navigation/paths';

import HomeSection from './home';
import CareTrack from './care-track';
import Manage from './manage';
import TimeTrack from './time-track';
import ECRF from './ecrf';

export const dashboardPathElements = {
  [PATIENT_DASHBOARD_PATHS.HOME]: <HomeSection />,
  [PATIENT_DASHBOARD_PATHS.CARE_TRACK]: <CareTrack />,
  [PATIENT_DASHBOARD_PATHS.MANAGE]: <Manage />,
  [PATIENT_DASHBOARD_PATHS.TIME_TRACK]: <TimeTrack />,
};

export const dashboardPathElementsECRF = {
  [ECRF_DASHBOARD_PATHS.ECRF]: <ECRF />,
  [ECRF_DASHBOARD_PATHS.MANAGE]: <Manage />,
};

export const dashboardPathButtons = [
  { route: 'home', label: 'Home' },
  { route: 'care-track', label: 'Care Track' },
];

export const dashboardPathButtonsECRF = [{ route: 'home', label: 'eCRF' }];
