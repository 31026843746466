import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { encounterFormsPathEnum } from 'utils/encounters';

import EncounterForm from './encounter-form';

const EncounterFormsGroup = ({ forms, encounter }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    !forms.some(
      ({ type }) => encounterFormsPathEnum[type] === pathname.split('/').pop(),
    ) && navigate(encounterFormsPathEnum[forms[0].type]);
  }, [forms, navigate, pathname]);

  return (
    <Routes>
      {forms.map(
        (form) =>
          form && (
            <Route
              key={encounterFormsPathEnum[form.type]}
              path={encounterFormsPathEnum[form.type]}
              element={
                <EncounterForm {...form} encounter={encounter} forms={forms} />
              }
            />
          ),
      )}
    </Routes>
  );
};

EncounterFormsGroup.propTypes = {
  forms: PropTypes.array.isRequired,
  encounter: PropTypes.shape({}).isRequired,
};

export default EncounterFormsGroup;
