import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileIcon from '@mui/icons-material/DescriptionOutlined';

const options = { target: { url: '_blank' } };

const ChatMessageContent = ({ body, attachment }) => (
  <>
    <Box mb={attachment ? 0 : -1}>
      {body?.split('\n').map((txt, key) => (
        <Linkify key={key} tagName="p" options={options}>
          {txt}
        </Linkify>
      ))}
    </Box>
    {attachment && (
      <Button
        variant="contained"
        href={attachment.url}
        target="_blank"
        startIcon={<FileIcon />}
      >
        {attachment.fileName}
      </Button>
    )}
  </>
);

ChatMessageContent.propTypes = {
  body: PropTypes.string,
  attachment: PropTypes.shape({
    url: PropTypes.string,
    fileName: PropTypes.string,
  }),
};

export default ChatMessageContent;
