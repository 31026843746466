import React from 'react';

import {
  ROOT_PATHS,
  SYS_ADMIN_PATHS,
  PRACTITIONER_PATHS,
  ECRF_PATHS,
} from 'navigation/paths';

import { RolesDashboard } from 'pages/account';

import {
  HospitalSystemDetails,
  HospitalSystemListing,
  NewHospitalSystem,
  HospitalSystemSiteDetails,
  NewHospitalSystemSite,
  NewUser,
  UserListing,
  UserDetails,
  PatientListing,
  PatientDetails,
  System,
  UserSettings,
} from 'pages/system-administrator';

import { Dashboard as PatientDashboard } from 'pages/patient';

import {
  PatientListing as PractitionerPatientListing,
  PatientInvite as PractitionerPatientInvite,
  PatientDetails as PractitionerPatientDetails,
  Chat as PractitionerChat,
  SettingsDetails as PractitionerSettingsDetails,
  Dashboards as PractitionerDashboards,
} from 'pages/practitioner';

import { ImageListing, ImageDetails } from 'pages/wound-images';

import { NotFound } from 'pages/error';

import NewQuestionnaireResponse from 'pages/questionnaires';

export const PUBLIC_ROUTES = [
  {
    path: ROOT_PATHS.questionnaires,
    element: <NewQuestionnaireResponse />,
  },
];

export const COMMON_PROTECTED_ROUTES = [
  {
    path: '/dashboard',
    component: RolesDashboard,
    exact: true,
  },
];

export const SYSTEM_ADMINISTRATOR_ROUTES = [
  {
    path: SYS_ADMIN_PATHS.patients,
    component: PatientListing,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.patients}/:patientId`,
    component: PatientDetails,
    exact: true,
  },
  {
    path: SYS_ADMIN_PATHS.users,
    component: UserListing,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.users}/new`,
    component: NewUser,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.users}/:userId`,
    component: UserDetails,
    exact: true,
  },
  {
    path: SYS_ADMIN_PATHS.hospitalSystems,
    component: HospitalSystemListing,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.hospitalSystems}/new`,
    component: NewHospitalSystem,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.hospitalSystems}/:hospitalSystemId`,
    component: HospitalSystemDetails,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.hospitalSystems}/:hospitalSystemId/sites/new`,
    component: NewHospitalSystemSite,
    exact: true,
  },
  {
    path: `${SYS_ADMIN_PATHS.hospitalSystems}/:hospitalSystemId/sites/:siteId`,
    component: HospitalSystemSiteDetails,
    exact: true,
  },
  {
    path: SYS_ADMIN_PATHS.system,
    component: System,
    exact: true,
  },
  {
    path: SYS_ADMIN_PATHS.settings,
    component: UserSettings,
    exact: true,
  },
];

export const PRACTITIONER_ROUTES = [
  {
    path: PRACTITIONER_PATHS.patients,
    component: PractitionerPatientListing,
    exact: true,
  },
  {
    path: `${PRACTITIONER_PATHS.patients}/invite`,
    component: PractitionerPatientInvite,
    exact: true,
  },
  {
    path: `${PRACTITIONER_PATHS.patients}/:patientId/*`,
    component: PractitionerPatientDetails,
  },
  {
    path: PRACTITIONER_PATHS.messages,
    component: PractitionerChat,
    exact: true,
  },
  {
    path: PRACTITIONER_PATHS.settings,
    component: PractitionerSettingsDetails,
    exact: true,
  },
  {
    path: PRACTITIONER_PATHS.dashboards,
    component: PractitionerDashboards,
    exact: true,
  },
];

export const PATIENT_ROUTES = [
  {
    path: ROOT_PATHS.patient,
    component: PatientDashboard,
    exact: true,
  },
];

export const WOUND_IMAGE_TRACER_ROUTES = [
  {
    path: `${ROOT_PATHS.woundImageTracer}`,
    component: ImageListing,
    exact: true,
  },
  {
    path: `${ROOT_PATHS.woundImageTracer}/image/:imageId`,
    component: ImageDetails,
    exact: true,
  },
];

export const WOUND_IMAGE_DETECTORS_ROUTES = [
  {
    path: `${ROOT_PATHS.woundDetector}`,
    component: ImageListing,
    exact: true,
  },
  {
    path: `${ROOT_PATHS.woundDetector}/image/:imageId`,
    component: ImageDetails,
    exact: true,
  },
];

export const ERROR_ROUTES = [{ path: '*', element: <NotFound />, exact: true }];

export const ECRF_ROUTES = [
  {
    path: ECRF_PATHS.patients,
    component: PractitionerPatientListing,
    exact: true,
  },
  {
    path: `${ECRF_PATHS.patients}/invite`,
    component: PractitionerPatientInvite,
    exact: true,
  },
  {
    path: `${ECRF_PATHS.patients}/:patientId/*`,
    component: PractitionerPatientDetails,
  },
  {
    path: ECRF_PATHS.settings,
    component: PractitionerSettingsDetails,
    exact: true,
  },
];
