import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES } from 'utils/constants';
import { deviceFields, deviceUDIContent } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSubSectionTitle } from 'utils/styles';

import Input from 'components/forms/input';

export const DeviceUDISection = ({ section, disabled }) => {
  const formik = useFormikContext();

  return (
    <>
      <Grid item xs={12}>
        <FormSubSectionTitle>Device UDI (Bar Code)</FormSubSectionTitle>
      </Grid>
      {deviceUDIContent.map(({ name, label, mask }) => (
        <Grid item xs={12} md={4} key={name}>
          <InputMask
            mask={mask}
            maskChar=" "
            {...getFieldProps({
              formik,
              name: `${section}.${name}`,
              disabled,
            })}
          >
            {() => (
              <Input
                label={label}
                name={`${section}.${name}`}
                disabled={disabled}
              />
            )}
          </InputMask>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Input
          label="Additional Notes"
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: `${section}.${deviceFields.ADDITIONAL_NOTES}`,
            disabled,
          })}
        />
      </Grid>
    </>
  );
};

DeviceUDISection.propTypes = {
  section: PropTypes.string,
  disabled: PropTypes.bool,
};
