import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import {
  INPUT_TYPES,
  NONE_VALUE,
  OTHER_VALUE,
  yesNoOptions,
} from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';

const currentCompressionUseOptions = [
  { value: NONE_VALUE, label: NONE_VALUE },
  { value: 'Frequently', label: 'Frequently (6-7 days a week)' },
  { value: 'Occasionally', label: 'Occasionally (3-5 days a week)' },
  { value: 'Infrequently', label: 'Infrequently (1-2 days a week)' },
];

const compressionTypeAtPresentationOptions = [
  { value: NONE_VALUE, label: NONE_VALUE },
  { value: 'FullStrengthBandaging', label: 'Full Strength (40mmHg) Bandaging' },
  {
    value: 'PartialStrengthBandaging',
    label: 'Partial Strength (<40mmHg) Bandaging',
  },
  { value: 'HosieryStrockings', label: 'Hosiery/Stockings' },
  { value: 'Wraps', label: 'Wraps' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const whenWornOptions = [
  { value: 'Day', label: 'Day' },
  { value: 'Night', label: 'Night' },
];

const ulcerDressingOptions = [
  { value: NONE_VALUE, label: NONE_VALUE },
  { value: 'Inadine', label: 'Inadine' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const fields = {
  COMPRESSION_USE: 'currentCompressionUse',
  COMPRESSION_TYPE: 'compressionTypeAtPresentation',
  COMPRESSION_TYPE_OTHER: 'compressionTypeAtPresentationOther',
  WHEN_WORN: 'whenWorn',
  ULCER_DRESSING: 'ulcerDressing',
  ULCER_DRESSING_OTHER: 'ulcerDressingOther',
  NEW_COMPRESSION: 'newCompressionApplied',
  COMPRESSION_BRAND: 'compressionBrand',
  BANDAGES_COUNT: 'compressionBandagesCount',
  COMPRESSION_APPLIED: 'compressionTypeApplied',
  COMPRESSION_APPLIED_OTHER: 'compressionTypeAppliedOther',
  ADDITIONAL_NOTES: 'compressionAppointmentNotes',
};

const CompressionForm = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>Current Compression Use</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <Chips
          label="Current Compression Use"
          options={currentCompressionUseOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.COMPRESSION_USE,
            disabled,
            changeOverride: (value) => {
              if (value === NONE_VALUE) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.COMPRESSION_USE]: { $set: value },
                    [fields.COMPRESSION_TYPE]: { $set: null },
                    [fields.COMPRESSION_TYPE_OTHER]: { $set: null },
                    [fields.WHEN_WORN]: { $set: [] },
                    [fields.ULCER_DRESSING]: { $set: null },
                    [fields.ULCER_DRESSING_OTHER]: { $set: null },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {![NONE_VALUE, null, undefined].includes(
        formik.values[fields.COMPRESSION_USE],
      ) && (
        <>
          <Grid item xs={12} md={8}>
            <Chips
              label="Compression Type at Presentation"
              options={compressionTypeAtPresentationOptions}
              isRequired
              otherField={{
                name: fields.COMPRESSION_TYPE_OTHER,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: fields.COMPRESSION_TYPE,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [fields.COMPRESSION_TYPE]: { $set: value },
                        [fields.COMPRESSION_TYPE_OTHER]: { $set: '' },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Chips
              label="When Worn"
              options={whenWornOptions}
              isMultiple
              {...getFieldProps({
                formik,
                name: fields.WHEN_WORN,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Chips
              label="Ulcer Dressing"
              options={ulcerDressingOptions}
              otherField={{
                name: fields.ULCER_DRESSING_OTHER,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: fields.ULCER_DRESSING,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [fields.ULCER_DRESSING]: { $set: value },
                        [fields.ULCER_DRESSING_OTHER]: { $set: '' },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
        </>
      )}
    </Grid>
    <FormSectionTitle>New Compression Applied</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <Chips
          label="New Compression Applied"
          options={yesNoOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.NEW_COMPRESSION,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.NEW_COMPRESSION]: { $set: value },
                    [fields.BANDAGES_COUNT]: { $set: null },
                    [fields.COMPRESSION_BRAND]: { $set: null },
                    [fields.COMPRESSION_APPLIED]: { $set: null },
                    [fields.COMPRESSION_APPLIED_OTHER]: { $set: null },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {formik.values[fields.NEW_COMPRESSION] && (
        <>
          <Grid item xs={12} md={4}>
            <Input
              label="Compression Brand / Type"
              isRequired
              {...getFieldProps({
                formik,
                name: fields.COMPRESSION_BRAND,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="# Compression Bandages"
              type={INPUT_TYPES.NUMERIC}
              min={0}
              {...getFieldProps({
                formik,
                name: fields.BANDAGES_COUNT,
                disabled,
              })}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Chips
              label="Type of Compression Applied"
              options={compressionTypeAtPresentationOptions}
              isRequired
              otherField={{
                name: fields.COMPRESSION_APPLIED_OTHER,
                value: OTHER_VALUE,
              }}
              {...getFieldProps({
                formik,
                name: fields.COMPRESSION_APPLIED,
                disabled,
                changeOverride: (value) => {
                  if (value === OTHER_VALUE) {
                    formik?.setValues(
                      update(formik?.values, {
                        [fields.COMPRESSION_APPLIED]: { $set: value },
                        [fields.COMPRESSION_APPLIED_OTHER]: { $set: '' },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Input
          label="Compression Appointment Notes"
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: fields.ADDITIONAL_NOTES,
            disabled,
          })}
        />
      </Grid>
    </Grid>
  </>
);

export default CompressionForm;

CompressionForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
