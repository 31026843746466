import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import useUserDetails from 'hooks/useUserDetails';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';
import { StyledCard } from 'utils/styles';

import FormWrapper from 'components/forms/form-wrapper';

import PatientForm, {
  validationSchema,
  mapApiToForm,
} from 'pages/practitioner/patients/form';

const Manage = () => {
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { patientId } = useParams();
  const { user, updateUser } = useUserDetails(patientId);
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = async (data) => {
    clearFeedback();

    const submitData = {
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: data.birthDate,
      gender: data.gender,
      email: data.email,
      phoneNumber: data.phoneNumber,
      street: data.street,
      city: data.city,
      state: data.state,
      country: data.country,
      ianaTimezone: data.ianaTimezone,
      active: data.active,
      roles: user.roles,
      patient: {
        hospitalSystemId: user?.patient?.hospitalSystemId,
        diseases: data.diseases.map((disease) => ({
          disease,
          diseasePathwayStep: data.diseasePathwaySteps[disease],
        })),
        practitioners: data.practitioners,
      },
    };

    try {
      await updateUser(patientId, submitData);
      setDisabled(true);
      setSuccessFeedback('Updated with success');
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  const initialValues = useMemo(() => mapApiToForm(user), [user]);

  return (
    <StyledCard>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onEdit={() => setDisabled(false)}
        onCancel={() => setDisabled(true)}
        disabled={disabled}
        enableReinitialize
        controlsOnTop
        title="Manage Patient Information"
      >
        {({ formik }) => <PatientForm formik={formik} readOnly={disabled} />}
      </FormWrapper>
    </StyledCard>
  );
};

export default Manage;
