import React from 'react';
import {
  NavLink,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledCard } from 'utils/styles';
import { breakpointsTheme } from 'theme/muiTheme';

import StyledBackIconButton from 'components/buttons/StyledBackIconButton';

import TaskRuleInfo from './TaskRuleInfo';
import TaskRuleHistory from './TaskRuleHistory';

const paths = {
  INFO: 'info',
  HISTORY: 'history',
};

const TaskRuleDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matches = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

  const currentPath = React.useMemo(() => {
    const paths = pathname.split('/');
    return paths[paths.length - 1];
  }, [pathname]);

  React.useEffect(() => {
    !Object.values(paths).includes(pathname.split('/').pop()) &&
      navigate(paths.INFO);
  }, [pathname, navigate]);

  return (
    <StyledCard>
      <Box mb={2}>
        <StyledBackIconButton onClick={() => navigate('..')}>
          <ArrowBackIcon fontSize="inherit" />
        </StyledBackIconButton>
        <ButtonGroup
          fullWidth={matches}
          orientation={matches ? 'vertical' : 'horizontal'}
          style={{ marginTop: matches ? '1rem' : 0 }}
        >
          <Button
            variant={currentPath === paths.INFO ? 'contained' : 'outlined'}
            component={NavLink}
            to={pathname.replace(currentPath, paths.INFO)}
            replace
          >
            Task Rule Info
          </Button>
          <Button
            variant={currentPath === paths.HISTORY ? 'contained' : 'outlined'}
            component={NavLink}
            to={pathname.replace(currentPath, paths.HISTORY)}
            replace
          >
            History
          </Button>
        </ButtonGroup>
      </Box>
      <Routes>
        <Route path={paths.INFO} element={<TaskRuleInfo />} />
        <Route path={paths.HISTORY} element={<TaskRuleHistory />} />
      </Routes>
    </StyledCard>
  );
};

export default TaskRuleDetails;
