import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';

import Loader from 'components/loader';
import FormWrapper from 'components/forms/form-wrapper';

import PainMobilityForm from './PainMobilityForm';
import { painAndMobilitySchema, mapFormToApi } from './utils';

const EditPainMobility = ({ painMobilityId, handleRefresh, handleCancel }) => {
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const { patientId } = useParams();
  const { getUser } = useUserDetails();

  const {
    data,
    doRequest: getPainMobility,
    error,
  } = useRequest(api.getPainMobility);

  useEffect(() => {
    getPainMobility(painMobilityId);
  }, [getPainMobility, painMobilityId]);

  useEffect(() => {
    if (!error) return;
    handleCancel();
    setErrorFeedback('Error loading pain, mobility and leg swelling log', [
      error,
    ]);
  }, [handleCancel, setErrorFeedback, error]);

  const handleSubmit = (values) => {
    clearFeedback();
    api
      .updatePainMobility({ id: painMobilityId, data: mapFormToApi(values) })
      .then(() => {
        getUser(patientId);
        setSuccessFeedback(
          'Updated pain, mobility and leg swelling log successfully',
        );
        handleCancel();
        handleRefresh();
      })
      .catch(() => {
        setErrorFeedback('Error saving pain, mobility and leg swelling log');
      });
  };

  if (!data) return <Loader />;

  return (
    <FormWrapper
      initialValues={data}
      validationSchema={painAndMobilitySchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => <PainMobilityForm formik={formik} titlePrefix="Edit" />}
    </FormWrapper>
  );
};

EditPainMobility.propTypes = {
  painMobilityId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};

export default EditPainMobility;
