import React from 'react';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES, OTHER_VALUE } from 'utils/constants';
import { DiseaseEnum } from 'utils/enums';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';

const medications = {
  UNFRACTIONATED_HEPARIN: {
    value: 'UnfractionatedHeparin',
    label: 'Unfractionated Heparin (UFH)',
  },
  LOW_MOLECULAR_WEIGHT_HEPARIN: {
    value: 'LowMolecularWeightHeparin',
    label: 'Low Molecular Weight Heparin (LMWH)',
  },
  FONDAPARINUX: { value: 'Fondaparinux', label: 'Fondaparinux' },
  ARGATROBAN: { value: 'Argatroban', label: 'Argatroban' },
  BIVALIRUDIN: { value: 'Bivalirudin', label: 'Bivalirudin' },
  WARFARIN: { value: 'Warfarin', label: 'Warfarin' },
  RIVAROXABAN: { value: 'Rivaroxaban', label: 'Rivaroxaban (Xarelto)' },
  APIXABAN: { value: 'Apixaban', label: 'Apixaban (Eliquis)' },
  EDOXABAN: { value: 'Edoxaban', label: 'Edoxaban (Savaysa)' },
  DABIGATRAN: { value: 'Dabigatran', label: 'Dabigatran (Pradaxa)' },
  LOVENOX: { value: 'Lovenox', label: 'Lovenox (LMWH)' },
  ASPIRIN: { value: 'Aspirin', label: 'Aspirin' },
  CLOPIDOGRELBISULFATE: {
    value: 'ClopidogrelBisulfate',
    label: 'Clopidogrel Bisulfate (Plavix)',
  },
  TICAGRELOR: { value: 'Ticagrelor', label: 'Ticagrelor (Brilinta)' },
  PRASUGREL: { value: 'Prasugrel', label: 'Prasugrel (Effient)' },
  AGGRENOX: { value: 'Aggrenox', label: 'Aggrenox' },
  AMOXICILLIN: { value: 'Amoxicillin', label: 'Amoxicillin' },
  DOXYCYCLINE: { value: 'Doxycycline', label: 'Doxycycline' },
  CEPHALEXIN: { value: 'Cephalexin', label: 'Cephalexin' },
  CIPROFLOXACIN: { value: 'Ciprofloxacin', label: 'Ciprofloxacin' },
  CLINDAMYCIN: { value: 'Clindamycin', label: 'Clindamycin' },
  METRONIDAZOLE: { value: 'Metronidazole', label: 'Metronidazole' },
  AZITHROMYCIN: { value: 'Azithromycin', label: 'Azithromycin' },
  SULFAMETHOXAZOLEANDTRIMETHOPRIM: {
    value: 'SulfamethoxazoleAndTrimethoprim',
    label: 'Sulfamethoxazole and Trimethoprim',
  },
  AMOXICILLINANDCLAVULANATE: {
    value: 'AmoxicillinAndClavulanate',
    label: 'Amoxicillin and Clavulanate',
  },
  LEVOFLOXACIN: { value: 'Levofloxacin', label: 'Levofloxacin' },
  OTHER: { value: OTHER_VALUE, label: 'Other' },
};

const acuteAnticoagulationsOptions = [
  medications.UNFRACTIONATED_HEPARIN,
  medications.LOW_MOLECULAR_WEIGHT_HEPARIN,
  medications.FONDAPARINUX,
  medications.ARGATROBAN,
  medications.BIVALIRUDIN,
  medications.OTHER,
];

const oralAnticoagulations = [
  medications.WARFARIN,
  medications.RIVAROXABAN,
  medications.APIXABAN,
  medications.EDOXABAN,
  medications.DABIGATRAN,
  medications.LOVENOX,
  medications.FONDAPARINUX,
  medications.OTHER,
];

const antiplateletsOptions = [
  medications.ASPIRIN,
  medications.CLOPIDOGRELBISULFATE,
  medications.TICAGRELOR,
  medications.PRASUGREL,
  medications.AGGRENOX,
  medications.OTHER,
];

const antibioticsOptions = [
  medications.AMOXICILLIN,
  medications.DOXYCYCLINE,
  medications.CEPHALEXIN,
  medications.CIPROFLOXACIN,
  medications.CLINDAMYCIN,
  medications.METRONIDAZOLE,
  medications.AZITHROMYCIN,
  medications.SULFAMETHOXAZOLEANDTRIMETHOPRIM,
  medications.AMOXICILLINANDCLAVULANATE,
  medications.LEVOFLOXACIN,
  medications.OTHER,
];

const pharmaceuticalGroups = ({ diseases }) => ({
  acuteAnticoagulationsUsed: {
    subTitle: 'Anticoagulation Administered Upon Entry',
    hide: !diseases[DiseaseEnum.DVT] && !diseases[DiseaseEnum.PE],
    options: acuteAnticoagulationsOptions,
  },
  oralAnticoagulationsPrescribed: {
    subTitle: 'Oral Anticoagulation Prescribed',
    hide: !diseases[DiseaseEnum.DVT] && !diseases[DiseaseEnum.PE],
    options: oralAnticoagulations,
  },
  antiplateletsPrescribed: {
    subTitle: 'Antiplatelet Prescribed',
    options: antiplateletsOptions,
  },
  antibioticsPrescribed: {
    subTitle: 'Antibiotic Prescribed',
    hide: !diseases[DiseaseEnum.VLU],
    options: antibioticsOptions,
  },
});

export const Pharmaceutical = ({ diseases, disabled, formik }) => {
  return Object.entries(pharmaceuticalGroups({ diseases })).map(
    ([key, { options, hide, title }]) => {
      if (hide) return null;
      return (
        <React.Fragment key={key}>
          <FormSectionTitle>{title}</FormSectionTitle>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid item xs={12} md={8}>
              <Chips
                options={options}
                isMultiple
                otherField={{ name: `${key}.otherText`, value: OTHER_VALUE }}
                {...getFieldProps({
                  formik,
                  name: `${key}.medication`,
                  disabled,
                  changeOverride: (value) => {
                    if (!value.includes(OTHER_VALUE)) {
                      formik?.setValues(
                        update(formik?.values, {
                          [key]: {
                            medication: { $set: value },
                            otherText: { $set: '' },
                          },
                        }),
                      );
                    }
                  },
                })}
              />
            </Grid>
          </Grid>
          {options.map(({ label, value }) => {
            if (formik?.values[key]?.medication?.includes(value)) {
              return (
                <React.Fragment key={value}>
                  <FormSectionTitle>{label}</FormSectionTitle>
                  <Grid container spacing={2} sx={{ py: 2 }}>
                    <Grid item xs={12} md={4}>
                      <Input
                        label={`Dose of ${label}`}
                        type={INPUT_TYPES.NUMERIC}
                        {...getFieldProps({
                          formik,
                          name: `${key}.${value}.dose`,
                          disabled,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <DateSelector
                        label={`Start Date for ${label}`}
                        {...getFieldProps({
                          formik,
                          name: `${key}.${value}.startDate`,
                          disabled,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        label={`Duration of ${label}`}
                        type={INPUT_TYPES.NUMERIC}
                        {...getFieldProps({
                          formik,
                          name: `${key}.${value}.doseDuration`,
                          disabled,
                        })}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            }
          })}
        </React.Fragment>
      );
    },
  );
};

export default Pharmaceutical;

export const parseApi2Form = (values) =>
  values
    ? Object.entries(values).reduce(
        (res, [key, el]) => ({
          ...res,
          [key]: Object.values(el).reduce(
            (res2, med) => ({
              ...res2,
              medication: [...res2.medication, med.medication],
              otherText: res2.otherText || med.otherText,
              [med.medication]: {
                dose: med.dose ?? '',
                doseDuration: med.doseDuration ?? '',
                startDate: med.startDate ?? '',
              },
            }),
            { medication: [], otherText: '' },
          ),
        }),
        {},
      )
    : {};

export const parseForm2Api = (values) =>
  Object.entries(values).reduce(
    (res, [key, fields]) => ({
      ...res,
      [key]: fields.medication.map((medication) => ({
        medication,
        otherText: medication === OTHER_VALUE ? fields.otherText : null,
        startDate: fields[medication]?.startDate ?? null,
        dose: fields[medication]?.dose ?? '',
        doseDuration: fields[medication]?.doseDuration ?? '',
      })),
    }),
    {},
  );
