import React from 'react';

import { Button, Tabs, Tab } from '@mui/material';

import Template from '../template';

import ClinicalOutcomes from './components/ClinicalOutcomes';
import Demographics from './components/Demographics';
import EconomicOutcomes from './components/EconomicOutcomes';

const Dashboards = () => {
  const [tab, setTab] = React.useState(0);

  return (
    <Template
      title="Venous Leg Ulcer - Care Dashboard"
      headerRight={<Button variant="contained">Select Disease State</Button>}
    >
      <Tabs
        value={tab}
        onChange={(_, newTab) => setTab(newTab)}
        variant="scrollable"
        scrollButtons={false}
        sx={{ mb: 2 }}
      >
        <Tab label="Clinical Outcomes" />
        <Tab label="Economic Outcomes" />
        <Tab label="Population Demographics" />
      </Tabs>
      {tab === 0 && <ClinicalOutcomes />}
      {tab === 1 && <EconomicOutcomes />}
      {tab === 2 && <Demographics />}
    </Template>
  );
};

export default Dashboards;
