import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import useCachedImage, {
  ImageSizeEnum,
  ImageFitEnum,
} from 'hooks/useCachedImage';

import ChatMessageContent from 'components/chat/chat-message-content';

import {
  MessageContainer,
  MessageUser,
  StyledBody,
  StyledContainer,
  StyledTimeAgo,
} from 'components/chat/chat-window/ChatWindow.styles';

const RoomMessage = ({
  user,
  body,
  attachment,
  date,
  received,
  lastMessageRead,
}) => {
  const { cachedUrl } = useCachedImage({
    url: user.profileImageUrl,
    size: ImageSizeEnum.SIZE_64,
    fit: ImageFitEnum.COVER,
  });

  const AvatarImage = useMemo(
    () => <Avatar alt={user?.name} src={cachedUrl} />,
    [cachedUrl, user],
  );

  return (
    <MessageContainer>
      <StyledContainer sent={!received}>
        {received && AvatarImage}
        <Box ml={received ? '1rem' : 0}>
          {received && <MessageUser align="left">{user?.name}</MessageUser>}
          <StyledBody sent={!received}>
            <ChatMessageContent body={body} attachment={attachment} />
          </StyledBody>
        </Box>
      </StyledContainer>
      <StyledTimeAgo
        sent={!received ? 1 : 0}
        align={!received ? 'right' : 'left'}
      >
        <TimeAgo date={date} />
        <span>{lastMessageRead}</span>
      </StyledTimeAgo>
    </MessageContainer>
  );
};

RoomMessage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string,
  }),
  date: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  attachment: PropTypes.shape({}),
  received: PropTypes.bool,
  lastMessageRead: PropTypes.object,
};

export default RoomMessage;
