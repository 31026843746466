import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import { woundImagesStatus } from 'utils/constants';
import { formatISOString } from 'utils/date';
import { getSurfaceAreaString, getWoundName } from 'utils/woundImages';

import StyledBackIconButton from 'components/buttons/StyledBackIconButton';

import VluImageDisplay from '../vlu-image-display';
import { ImageInfo } from '../VluImages.styles';

import { StyledModal } from './VluImageModal.styles';

const VluImageModal = ({ selectedWoundImage, handleClose, setWoundAction }) => (
  <Modal open={!!selectedWoundImage} onClose={handleClose}>
    <StyledModal>
      <Box ml="auto" mb={1}>
        <StyledBackIconButton
          onClick={() => handleClose()}
          style={{ marginRight: 0 }}
        >
          <CloseIcon fontSize="inherit" />
        </StyledBackIconButton>
      </Box>
      <VluImageDisplay
        url={selectedWoundImage?.asset?.url}
        maskedUrl={selectedWoundImage?.maskedAsset?.url}
        markerUrl={selectedWoundImage?.markerAsset?.url}
        size="big"
      />
      <Box display="flex" justifyContent="space-between" gap={2} mt={1}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <ImageInfo date>
            {formatISOString(
              selectedWoundImage?.totalSurfaceAreaDate ||
                selectedWoundImage?.createdAt,
            )}
          </ImageInfo>
          <ImageInfo name>
            {getWoundName(selectedWoundImage?.wound || {})}
          </ImageInfo>
          <ImageInfo>
            {getSurfaceAreaString(
              selectedWoundImage?.totalSurfaceArea,
              selectedWoundImage?.reasonForNoTracing,
              selectedWoundImage?.status,
            )}
          </ImageInfo>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Button
            variant="contained"
            onClick={() => setWoundAction(woundImagesStatus.open)}
            disabled={selectedWoundImage?.status === woundImagesStatus.open}
          >
            Submit for Correction
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setWoundAction(woundImagesStatus.deleted)}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </StyledModal>
  </Modal>
);

VluImageModal.propTypes = {
  selectedWoundImage: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  setWoundAction: PropTypes.func.isRequired,
};

export default VluImageModal;
