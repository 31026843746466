export const GET_CURRENT_ACCOUNT_DETAILS_REQUEST =
  'GET_CURRENT_ACCOUNT_DETAILS_REQUEST';
export const GET_CURRENT_ACCOUNT_DETAILS_SUCCESS =
  'GET_CURRENT_ACCOUNT_DETAILS_SUCCESS';
export const GET_CURRENT_ACCOUNT_DETAILS_FAILURE =
  'GET_CURRENT_ACCOUNT_DETAILS_FAILURE';

export const GET_CURRENT_ACCOUNT_LOGO_URL_REQUEST =
  'GET_CURRENT_ACCOUNT_LOGO_URL_REQUEST';
export const GET_CURRENT_ACCOUNT_LOGO_URL_SUCCESS =
  'GET_CURRENT_ACCOUNT_LOGO_URL_SUCCESS';
export const GET_CURRENT_ACCOUNT_LOGO_URL_FAILURE =
  'GET_CURRENT_ACCOUNT_LOGO_URL_FAILURE';
