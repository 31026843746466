import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import useUserDetails from 'hooks/useUserDetails';
import { QUESTIONNAIRES, DEFAULT_PAGE_SIZE } from 'utils/constants';
import { formatISOString } from 'utils/date';
import { getErrorMessage } from 'utils/error';

import Template from 'pages/practitioner/patients/details/dashboard/Template';
import Table from 'components/table';
import HeaderTitle from 'components/titles';

const patientTableAttributes = [{ title: 'Response Date' }];

const Questionnaire = () => {
  const navigate = useNavigate();
  const { page, handlePageChange } = useTableState();
  const { patientId, questionnaireType } = useParams();
  const { clearFeedback, setSuccessFeedback, setErrorFeedback } = useFeedback();
  const { user } = useUserDetails(patientId);

  const {
    data: responses,
    doRequest: getQuestionnairesResponses,
    error,
    isLoading,
  } = useRequest(api.getQuestionnairesResponses);

  const rows = useMemo(
    () =>
      responses?.data?.map(({ id, submittedAt }) => ({
        id,
        data: [formatISOString(submittedAt)],
      })),
    [responses],
  );

  const sendEmail = () => {
    clearFeedback();

    if (!user?.email) {
      setErrorFeedback('Patient does not have an Associated Email');
      return;
    }

    api
      .getQuestionnaireForm({
        type: questionnaireType,
        patientId,
        notifyPatient: true,
      })
      .then(() => setSuccessFeedback('Survey Link sent to Patient'))
      .catch((error) => setErrorFeedback(getErrorMessage(error)));
  };

  useEffect(() => {
    getQuestionnairesResponses({
      PatientId: patientId,
      Type: questionnaireType,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
    });
  }, [page, patientId, questionnaireType, getQuestionnairesResponses]);

  return (
    <Template>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '1rem',
          mb: '1rem',
        }}
      >
        <HeaderTitle title={QUESTIONNAIRES[questionnaireType]} backUrl=".." />
        <Button
          variant="contained"
          onClick={sendEmail}
          sx={{ marginLeft: { sm: 'initial', md: 'auto' } }}
        >
          Email to Patient
        </Button>
        <Button
          variant="contained"
          to="add"
          component={Link}
          startIcon={<AddIcon />}
        >
          New Response
        </Button>
      </Box>
      <Table
        header={patientTableAttributes}
        page={page}
        rows={rows}
        error={error}
        isLoading={isLoading}
        totalPages={responses?.pageCount}
        handlePageChange={handlePageChange}
        handleRowClick={(id) => navigate(id)}
      />
    </Template>
  );
};

export default Questionnaire;
