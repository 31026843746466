import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import useUserDetails from 'hooks/useUserDetails';
import { DiseaseEnum } from 'utils/enums';

import Template from 'pages/practitioner/patients/details/dashboard/Template';

import EncounterList from '../encounters/list';
import Graphs from '../graphs';
import PatientData from '../patient-data';
import PatientHistory from '../patient-history';
import QuestionnairesList from '../questionnaires/questionnaires-list';
import VluImages from '../vlu-images';

const Dashboard = () => {
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);

  const hasVlu = useMemo(
    () =>
      user?.patient?.diseases.find(
        ({ disease }) => disease === DiseaseEnum.VLU,
      ),
    [user],
  );

  return (
    <Grid container spacing={2}>
      {hasVlu && (
        <Grid container item spacing={2}>
          <Grid item md={8} xs={12}>
            <Graphs />
          </Grid>
          <Grid item md={4} xs={12}>
            <VluImages />
          </Grid>
        </Grid>
      )}
      <PatientData />
      <Grid item xs={12}>
        <EncounterList />
      </Grid>
      <Grid item xs={12}>
        <QuestionnairesList />
      </Grid>
      <Grid item xs={12}>
        <Template title="Patient History">
          <PatientHistory />
        </Template>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
