import styled from '@emotion/styled';

import DateTimeSelect from 'components/forms/date-time-selector';

import { breakpointsTheme } from 'theme/muiTheme';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: '1rem 0',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'initial',
  },
}));

export const StyledBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  marginRight: '1rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 0 2rem 0',
  },
}));

export const StyledDateTimeSelect = styled(DateTimeSelect)(() => ({
  marginLeft: '1rem',
}));

export const StyledMinutes = styled('div')(() => ({
  width: '6rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    alignSelf: 'flex-end',
  },
}));
