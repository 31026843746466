import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import api from 'core/api';
import useWoundImageDetails from 'hooks/useWoundImageDetails';
import { formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/error';
import { Label, StyledTextField } from 'utils/styles';
import { actionTypes } from 'store/wound-images';

import { NotesContainer, NoteTitle } from './Notes.styles';

const Notes = ({ imageId }) => {
  const { getWoundImageNotes, woundImage, notes, isNotesLoading } =
    useWoundImageDetails(imageId);

  useEffect(() => {
    if (woundImage && !notes && !isNotesLoading) {
      getWoundImageNotes(imageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [woundImage, notes]);

  const [noteText, setNoteText] = useState('');
  const [noteError, setNoteError] = useState('');
  const dispatch = useDispatch();

  const handleNoteSubmit = () => {
    if (noteText) {
      api
        .createWoundImageNote(imageId, { text: noteText })
        .then(() => {
          dispatch({
            type: actionTypes.RESET_WOUND_IMAGE_NOTES,
          });
          setNoteText('');
          setNoteError('');
        })
        .catch((error) => {
          setNoteError(getErrorMessage(error));
        });
    }
  };

  const [notesPage, setNotesPage] = useState(1);

  const loadNotes = debounce((ev) => {
    const { scrollTop, offsetHeight, scrollHeight } = ev.target;
    const totalPages = notes.pageCount;
    const newPage = notesPage + 1;
    const missingScrollDistance = Math.abs(
      scrollHeight - (scrollTop + offsetHeight),
    );

    if (missingScrollDistance < 50 && newPage <= totalPages) {
      getWoundImageNotes(imageId, { page: newPage });
      setNotesPage(newPage);
    }
  }, 500);

  const handleNotesScroll = (ev) => {
    ev.persist();
    loadNotes(ev);
  };

  return (
    <>
      <StyledTextField
        id="patientNote"
        label="New Patient Note:"
        multiline
        rows={6}
        value={noteText}
        onChange={(ev) => setNoteText(ev.target.value)}
        error={!!noteError}
        helperText={noteError}
        placeholder={'Insert note text here'}
      />
      <Button sx={{ margin: '0.5rem 0 0 auto' }} onClick={handleNoteSubmit}>
        Add Note
      </Button>
      <Label>Past Patient Notes:</Label>
      <NotesContainer onScroll={handleNotesScroll}>
        {notes?.pageCount > 0 ? (
          Array.from({ length: notesPage || 0 }, (_, i) => {
            const page = notes?.pages[i + 1];

            if (page) {
              return page.map((note, index) => (
                <div key={index}>
                  <NoteTitle>
                    {formatDate(new Date(note.createdAt))} - Image ID:{' '}
                    {note.woundImageId}
                  </NoteTitle>
                  <Typography component="p" sx={{ marginBottom: '1rem' }}>
                    {note.text}
                  </Typography>
                </div>
              ));
            }

            return <CircularProgress key={i} />;
          })
        ) : isNotesLoading ? (
          <CircularProgress />
        ) : (
          <NoteTitle>No notes available</NoteTitle>
        )}
      </NotesContainer>
    </>
  );
};

Notes.propTypes = {
  imageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Notes;
