import * as Yup from 'yup';

import { phoneValidation, passwordValidations } from 'utils/validations';

const VALID_IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const MAX_UPLOAD_SIZE = 2 * 1024 * 1024;

const {
  lowercase: lowercaseValidation,
  uppercase: uppercaseValidation,
  number: numberValidation,
  specialCharacter: specialCharacterValidation,
} = passwordValidations;

export const userValidation = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid Email').required('Required'),
  phoneNumber: phoneValidation().required('Required'),
  faxNumber: phoneValidation('fax'),
  profileImageAssetId: Yup.mixed()
    .required('Required')
    .test('fileSize', 'File too large', (value) => {
      if (typeof value === 'string') return true;
      return value && value.size <= MAX_UPLOAD_SIZE;
    })
    .test('fileFormat', 'Invalid image type', (value) => {
      if (typeof value === 'string') return true;
      return value && VALID_IMAGE_TYPES.includes(value.type);
    }),
});

export const passwordValidation = Yup.object({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(64, 'Maximum 64 characters')
    .matches(lowercaseValidation.regex, lowercaseValidation.message)
    .matches(uppercaseValidation.regex, uppercaseValidation.message)
    .matches(numberValidation.regex, numberValidation.message)
    .matches(
      specialCharacterValidation.regex,
      specialCharacterValidation.message,
    )
    .required('Required'),
});
