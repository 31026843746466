import React from 'react';

import { StyledCard } from 'utils/styles';

import Template from 'pages/practitioner/template';

import SettingsUser from './SettingsUser';
import SettingsPassword from './SettingsPassword';

const sections = [
  { id: 'information', Component: SettingsUser },
  { id: 'password', Component: SettingsPassword },
];

const SettingsDetails = () => {
  const [editSection, setEditSection] = React.useState(null);

  return (
    <Template>
      <StyledCard>
        {sections.map(({ id, Component }) => (
          <Component
            key={id}
            editing={id === editSection}
            setEditing={(status) => setEditSection(status ? id : null)}
            showButton={editSection === null}
          />
        ))}
      </StyledCard>
    </Template>
  );
};

export default SettingsDetails;
