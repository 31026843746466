import { formatISOString } from 'utils/date';

export const getPatientFormValues = (data) => ({
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  street: data?.street ?? '',
  city: data?.city ?? '',
  country: data?.country ?? '',
  ianaTimezone: data?.ianaTimezone,
  state: (data?.state ?? '').toUpperCase(),
  email: data?.email ?? '',
  phoneNumber: data?.phoneNumber ?? '',
  birthDate: data?.birthDate ?? null,
  gender: data?.gender ?? '',
  hospitalSystemName: data?.patient?.hospitalSystemName ?? '',
  hospitalSystemId: data?.patient?.hospitalSystemId ?? null,
  active: data?.active ?? false,
  activatedAt: formatISOString(data?.activatedAt),
  deactivatedAt: formatISOString(data?.deactivatedAt),
  practitioners: data?.patient?.practitioners?.map(({ id }) => id) ?? [],
  diseases: data?.patient?.diseases?.map(({ disease }) => disease) ?? [],
  diseasePathwaySteps:
    data?.patient?.diseases?.reduce((res, { disease, diseasePathwayStep }) => {
      return { ...res, [disease]: diseasePathwayStep };
    }, {}) ?? {},
});
