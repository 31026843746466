import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { yesNoOptions } from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import FileUpload from 'components/forms/file-upload';

const additionalDiagnosticTestsPerformedOptions = [
  { value: 'Ultrasound', label: 'Ultrasound' },
  { value: 'RefluxStudy', label: 'Reflux Study' },
  {
    value: 'VenographyCtMr',
    label: 'Venography (CT, MR)',
  },
  { value: 'XRay', label: 'X-Ray' },
  { value: 'Plethysmography', label: 'Plethysmography' },
  { value: 'IVUS', label: 'IVUS' },
];

const fields = {
  COMPLETE_WOUND_ASSESSMENT_DURING_VISIT: 'completedWoundAssessment',
  USE_COMPRESSION: 'completedCompressionForm',
  ADJUNCTIVE_WOUND_TREATMENTS_GIVEN: 'completedWoundTreatment',
  MEDICATION_STARTED_RESTARTED_CHANGED: 'completedMedication',
  ADDITIONAL_DIAGNOSTIC_TESTS_PERFORMED: 'additionalDiagnosticTestsPerformed',
  DIAGNOSTIC_IMAGING_RESULTS_RECEIVED:
    'diagnosticImagingResultsInterpretationReceived',
  TREATMENT_PLAN_DISCUSSED: 'treatmentPlanDiscussion',
  SUBJECT_ADMITTED_HOSPITAL: 'hospitalAddmittedOrSeen',
  PATIENT_EXPERIENCE_ADVERSE_EVENTS: 'adverseEventsExperience',
  PATIENT_EXPERIENCE_SERIOUS_ADVERSE_EVENTS: 'seriousAdverseEventsExperience',
  PROTOCOL_DEVIATIONS: 'protocolDeviation',
  ADDITIONAL_DOCUMENTS: 'assetId',
};

const AdditionalInformationForm = ({ formik, disabled }) => {
  return (
    <>
      <FormSectionTitle>Additional Information</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Able to complete a Wound Assessment during this visit?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.COMPLETE_WOUND_ASSESSMENT_DURING_VISIT,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Does the patient currently use Compression and/or was Compression applied during this visit?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.USE_COMPRESSION,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Were Adjunctive Wound Treatments given during this visit? This includes treatments such as dressings, biologic therapies, skin grafting, debridement, NPWT, skin care, etc."
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.ADJUNCTIVE_WOUND_TREATMENTS_GIVEN,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Was Medication started, restarted, or changed during this visit?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.MEDICATION_STARTED_RESTARTED_CHANGED,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Were any of these additional Diagnostic Tests performed?"
            options={additionalDiagnosticTestsPerformedOptions}
            isMultiple
            {...getFieldProps({
              formik,
              name: fields.ADDITIONAL_DIAGNOSTIC_TESTS_PERFORMED,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Were diagnostic imaging results/interpretation received?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.DIAGNOSTIC_IMAGING_RESULTS_RECEIVED,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Was a Treatment Plan discussed?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.TREATMENT_PLAN_DISCUSSED,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Was the subject admitted to or seen in a hospital?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.SUBJECT_ADMITTED_HOSPITAL,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Did the patient experience any Adverse Events or device deficiencies?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.PATIENT_EXPERIENCE_ADVERSE_EVENTS,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Did the patient experience any Serious Adverse Events?"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.PATIENT_EXPERIENCE_SERIOUS_ADVERSE_EVENTS,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Were there Protocol Deviations? If yes, fill out Protocol Deviation form."
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.PROTOCOL_DEVIATIONS,
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <FormSectionTitle>Additional Document Upload</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12}>
          <FileUpload
            label="If applicable, please upload any additional documents relevant to this visit."
            {...getFieldProps({
              formik,
              name: fields.ADDITIONAL_DOCUMENTS,
              disabled,
            })}
            accept="image/*,.csv,.xml,.pdf,.txt,.xls,.xlsx,.doc,.docx"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalInformationForm;

AdditionalInformationForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
