import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Tooltip from './Tooltip';

const ItemsTooltip = ({ items = [], children }) => {
  if (!items?.length) return children;

  return (
    <Tooltip
      content={items.map((txt) => (
        <Box key={txt}>{txt}</Box>
      ))}
    >
      {children}
    </Tooltip>
  );
};

ItemsTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default ItemsTooltip;
