import styled from '@emotion/styled';

import { PickersDay } from '@mui/x-date-pickers';

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '0.25rem',
    borderBottomLeftRadius: '0.25rem',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '0.25rem',
    borderBottomRightRadius: '0.25rem',
  }),
}));
