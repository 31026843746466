import styled from '@emotion/styled';

import { weight } from 'theme/fonts';

export const EncounterItem = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  marginBottom: '0.25rem',
}));

export const getComponents = (palette) => ({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 'initial',
          maxHeight: 'initial',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          justifyContent: 'space-around',
        },
        weekContainer: {
          margin: 0,

          '&[aria-rowindex="1"] button': {
            borderTop: '1px solid' + palette.grey[300],
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          color: palette.primary.main,
          fontWeight: weight.semibold,
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          padding: '0.5rem',
          color: palette.primary.main,
        },
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          minHeight: '35rem !important',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '7rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          overflowX: 'auto',
          padding: '0.5rem',
          borderRight: '1px solid' + palette.grey[300],
          borderBottom: '1px solid' + palette.grey[300],
          borderRadius: 0,
          color: palette.grey[300],

          '&[aria-colindex="1"]': {
            borderLeft: '1px solid' + palette.grey[300],
          },
        },
        dayOutsideMonth: {
          color: palette.base.black,
        },
      },
    },
  },
});
