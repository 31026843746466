import React from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'core/api';
import Template from 'pages/system-administrator/template';
import FormWrapper from 'components/forms/form-wrapper';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import { getErrorMessage } from 'utils/error';
import { hospitalSystemSchema } from 'utils/validations';
import useFeedback from 'hooks/useFeedback';

import HospitalSystemForm from '../form';

const initialValues = {
  hospitalSystemName: '',
  managedDiseaseStates: [],
  headquartersAddress: '',
  city: '',
  state: '',
  country: '',
};

const NewHospitalSystem = () => {
  const navigate = useNavigate();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const onSubmit = async (data) => {
    const hospitalSystemData = {
      name: data.hospitalSystemName,
      street: data.headquartersAddress,
      city: data.city,
      state: data.state,
      country: data.country,
      diseases: data.managedDiseaseStates,
    };
    clearFeedback();
    api
      .createHospitalSystem(hospitalSystemData)
      .then(({ data }) => {
        setSuccessFeedback('Hospital System created with success');
        navigate(`${SYS_ADMIN_PATHS.hospitalSystems}/${data.id}`, {
          replace: true,
        });
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error));
      });
  };

  return (
    <Template title="Create Hospital System">
      <FormWrapper
        initialValues={initialValues}
        validationSchema={hospitalSystemSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate(SYS_ADMIN_PATHS.hospitalSystems)}
      >
        {({ formik }) => <HospitalSystemForm formik={formik} />}
      </FormWrapper>
    </Template>
  );
};

export default NewHospitalSystem;
