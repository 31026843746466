import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTheme } from 'context/theme';
import { StyledTextField } from 'utils/styles';

import StyledIconButton from 'components/buttons/StyledIconButton';
import Tooltip from 'components/tooltip';

import {
  ValueContainer,
  StyledMenuItem,
  numberStyle,
  TagWrapper,
} from './Select.styles';

const Select = ({
  name,
  label,
  value,
  onChange,
  options,
  isRequired,
  error,
  classes,
  placeholder,
  disabled = false,
  multiple = false,
  noTags = false,
  displayEmptyOption,
  dataTest,
  ...remainingProps
}) => {
  const { palette } = useTheme();

  const renderValue = () => {
    if (multiple) {
      const selectedOptions = options.filter((option) =>
        value?.includes(option.value),
      );

      const getValues = ([first, ...rest]) =>
        rest.reduce((acc, { label }) => acc + ', ' + label, first?.label);

      const values = getValues(selectedOptions);

      return (
        <ValueContainer>
          {(disabled || values) && (
            <Tooltip
              content={selectedOptions.map(({ value, label }) => (
                <Box key={value}>{label}</Box>
              ))}
              childrenStyle={numberStyle(disabled, palette)}
              disabled={!value?.length}
            >
              {value?.length}
            </Tooltip>
          )}
          <span>{values ?? placeholder}</span>
        </ValueContainer>
      );
    }

    if (value === '' && (placeholder || displayEmptyOption)) {
      return <span>{placeholder}</span>;
    }

    return (
      <span>{options.find((option) => option.value === value)?.label}</span>
    );
  };

  const removeOption = (option) => {
    if (!disabled) {
      onChange({
        target: {
          name,
          value: value.filter((item) => item !== option),
        },
      });
    }
  };

  return (
    <Box width="100%">
      <StyledTextField
        select
        id={name}
        name={name}
        label={label}
        value={(multiple && []) || value?.toString() || ''}
        error={!!error}
        helperText={error}
        onChange={onChange}
        disabled={disabled}
        className={classes}
        required={isRequired}
        variant="outlined"
        size="small"
        fullWidth
        SelectProps={{
          multiple,
          renderValue,
          labelId: `${name}-label`,
          displayEmpty: multiple || !!placeholder || displayEmptyOption,
          'data-test': dataTest,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        }}
        {...remainingProps}
      >
        {!multiple && (placeholder || displayEmptyOption) && (
          <StyledMenuItem value="">
            {displayEmptyOption ? '' : placeholder}
          </StyledMenuItem>
        )}
        {options.map(({ value, label }, index) => (
          <StyledMenuItem value={value} key={index}>
            {label}
          </StyledMenuItem>
        ))}
      </StyledTextField>
      {Boolean(multiple && !noTags && value?.length && options?.length) && (
        <Grid container spacing={1} sx={{ mt: '0.25rem' }}>
          {value.map((optionValue, index) => {
            const option = options.find((o) => o.value === optionValue);
            return (
              option && (
                <Grid item key={index}>
                  <TagWrapper key={index}>
                    <span>{option?.label}</span>
                    {!disabled && (
                      <StyledIconButton
                        onClick={() => removeOption(option?.value)}
                        data-test={`multi-select-${name}-remove-${option?.value}`}
                        name="close"
                        color="grey"
                        isSmall
                        hasMargin
                      />
                    )}
                  </TagWrapper>
                </Grid>
              )
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  multiple: PropTypes.bool,
  noTags: PropTypes.bool,
  displayEmptyOption: PropTypes.bool,
  dataTest: PropTypes.string,
};

export default Select;
