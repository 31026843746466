import styled from '@emotion/styled';

import MenuItem from '@mui/material/MenuItem';

import { breakpointsTheme } from 'theme/muiTheme';

export const ValueContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '1rem',
  alignItems: 'center',
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  [breakpointsTheme.breakpoints.down('xl')]: {
    minHeight: '2rem',
  },
}));

export const numberStyle = (disabled, palette) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1rem',
  height: '1rem',
  borderRadius: '50%',
  background: disabled ? palette.grey[300] : palette.primary.main,
  fontSize: '0.75rem',
  WebkitTextFillColor: palette.base.white,
});

export const TagWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '2.5rem',
  padding: '0 0.5rem',
  border: '1px solid ' + theme.palette.grey[300],
  borderRadius: '0.25rem',
}));
