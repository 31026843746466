import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'utils/styles';

import TableRow from './Table-Row';
import { StyledRow } from './TableChoice.styles';

const TableChoice = ({ element, formik, disabled }) => (
  <>
    <Label>{element.label}</Label>
    <div>
      <StyledRow>
        <div />
        {element.options?.map((option) => (
          <div key={option.value}>{option.label}</div>
        ))}
      </StyledRow>
      {element.fields?.map((field, index) => (
        <TableRow
          key={field.name}
          question={field.label}
          name={field.name}
          value={formik.values[field.name]?.toString() || ''}
          onChange={formik.handleChange}
          error={formik.submitCount > 0 && formik.errors[field.name]}
          disabled={disabled}
          options={element.options}
          isFirstRow={index === 0}
        />
      ))}
    </div>
  </>
);

TableChoice.propTypes = {
  element: PropTypes.object.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      date: PropTypes.string,
    }),
    errors: PropTypes.shape({}),
    submitCount: PropTypes.number,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default TableChoice;
