import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button, Chip, Dialog } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import useUserDetails from 'hooks/useUserDetails';
import { DiseaseEnum, ProcessSteps, ProcessStepsEnum } from 'utils/enums';
import { StyledCard } from 'utils/styles';
import PathwaySteps from 'theme/media/pathwaySteps.png';

import Title from 'components/titles/Title';

const PathwayTracker = () => {
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);

  const [isOpen, setIsOpen] = useState(false);

  const vlu = useMemo(
    () =>
      user?.patient?.diseases?.find(
        ({ disease }) => disease === DiseaseEnum.VLU,
      ),
    [user],
  );

  return (
    <StyledCard height="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Title type="h2" isBold>
          Patient Care Pathway Tracker
        </Title>
        <Button variant="contained" onClick={() => setIsOpen(true)}>
          View Pathway Steps
        </Button>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <img
            style={{ height: '80vh' }}
            src={PathwaySteps}
            alt="Pathway Steps"
          />
        </Dialog>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr) 16px)"
        alignItems="center"
        rowGap={0.5}
      >
        {Object.values(ProcessStepsEnum).map((step, index) => {
          const isCurrent = step === ProcessSteps[vlu?.diseasePathwayStep];
          return (
            <React.Fragment key={step}>
              {index > 0 && <ArrowForwardIcon fontSize="inherit" />}
              <Chip
                label={step}
                color="primary"
                variant={isCurrent ? 'contained' : 'outlined'}
                sx={{
                  minHeight: '3rem',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    textAlign: 'center',
                  },
                }}
              />
            </React.Fragment>
          );
        })}
      </Box>
    </StyledCard>
  );
};

export default PathwayTracker;
