import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

export const initialState = {
  details: null,
  error: null,
  loading: false,
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_USER_DETAILS_REQUEST:
    case actionTypes.LOGOUT_REQUEST:
      return update(state, {
        error: { $set: null },
        loading: { $set: true },
      });
    case actionTypes.GET_CURRENT_USER_DETAILS_FAILURE:
    case actionTypes.LOGOUT_FAILURE:
      return update(state, {
        error: { $set: action.payload.error },
        loading: { $set: false },
      });
    case actionTypes.LOGOUT_SUCCESS:
      return initialState;
    case actionTypes.GET_CURRENT_USER_DETAILS_SUCCESS:
      return update(state, {
        details: { $set: action.payload.details },
        error: { $set: null },
        loading: { $set: false },
      });
    case actionTypes.UPDATE_CURRENT_USER_DETAILS: {
      const { firstName, lastName, contact } = action.payload;
      return update(state, {
        details: {
          firstName: { $set: firstName },
          lastName: { $set: lastName },
        },
        contact: { $set: contact },
      });
    }

    default:
      return state;
  }
};

export default user;
