import styled from '@emotion/styled';

import Button from '@mui/material/Button';

import { weight } from 'theme/fonts';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.grey[100],
}));

export const Sidebar = styled('aside')(({ theme }) => ({
  position: 'fixed',
  width: '15rem',
  minWidth: '15rem',
  height: '100%',
  backgroundColor: theme.palette.base.white,
  boxShadow: `0 0.2rem 0.4rem ${theme.palette.grey[300]}`,
}));

export const Main = styled('main')(() => ({
  width: 'calc(100% - 15rem)',
  margin: '2rem 2rem 2rem 17rem',
}));

export const AppName = styled('p')(() => ({
  margin: '2rem 0 0.5rem',
  textAlign: 'center',
  fontWeight: weight.bold,
}));

export const CompanyName = styled('p')(({ theme }) => ({
  marginBottom: '2rem',
  color: theme.palette.grey[500],
  textAlign: 'center',
  fontSize: '0.75rem',
  fontWeight: weight.semibold,
}));

export const SidebarButton = styled(Button)(() => ({
  width: '100%',
  justifyContent: 'start',
  paddingLeft: '2rem',
  borderRadius: 0,
  textTransform: 'none',
}));
