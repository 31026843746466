import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import api from 'core/api';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import useHospitalSystemSiteDetails from 'hooks/useHospitalSystemSiteDetails';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';

import Template from 'pages/system-administrator/template';
import FormWrapper from 'components/forms/form-wrapper/FormWrapper';
import ErrorMessage from 'components/error-message';

import HospitalSystemSiteForm from '../form';
import validationSchema from '../form/validationSchema';

const HospitalSystemSiteDetails = () => {
  const [disabled, setDisabled] = useState(true);
  const { hospitalSystemId, siteId } = useParams();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const { data, isLoading, error, hospitalSystemName, getSystemSiteData } =
    useHospitalSystemSiteDetails(hospitalSystemId, siteId);

  const backUrl = `${SYS_ADMIN_PATHS.hospitalSystems}/${hospitalSystemId}`;

  if (isLoading)
    return <Template title="Loading Site" isLoading backUrl={backUrl} />;

  if (error) {
    return (
      <Template title="Not found" backUrl={backUrl}>
        <ErrorMessage message="Hospital System Site not found" />
      </Template>
    );
  }

  const onSubmit = async (data) => {
    clearFeedback();
    api
      .updateHospitalSystemSite(hospitalSystemId, siteId, data)
      .then(() => {
        setSuccessFeedback('Hospital System Site updated with success');
        getSystemSiteData(hospitalSystemId, siteId);
        setDisabled(true);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error));
      });
  };

  return (
    <Template
      title={`${hospitalSystemName} - ${data?.name}`}
      backUrl={backUrl}
      actions={
        disabled && (
          <Button
            variant="contained"
            onClick={() => setDisabled(false)}
            data-test="edit-form-button"
          >
            Edit
          </Button>
        )
      }
    >
      <FormWrapper
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        disabled={disabled}
        onCancel={() => setDisabled(true)}
        enableReinitialize
      >
        {({ formik }) => (
          <HospitalSystemSiteForm formik={formik} readOnly={disabled} />
        )}
      </FormWrapper>
    </Template>
  );
};

export default HospitalSystemSiteDetails;
