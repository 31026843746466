import React from 'react';

import Grid from '@mui/material/Grid';

import { StyledCard } from 'utils/styles';
import DemographicsImage from 'theme/media/demographics.png';

import Title from 'components/titles/Title';

import { InfoCard } from './InfoCard';

const Demographics = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6} lg={3}>
      <Grid container spacing={2} direction="column">
        <InfoCard label="VLU Patient Count" content="120 Patients" />
        <InfoCard label="Male / Female" content="55M / 65F" />
        <InfoCard label="Avg. Ulcer Duration" content="3+ years" />
      </Grid>
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <Grid container spacing={2} direction="column">
        <InfoCard label="% Over 55" content="70%" isBlue />
        <InfoCard label="History of DVT" content="60%" />
        <InfoCard label="Avg. Comorbidities" content="2" />
      </Grid>
    </Grid>
    <Grid item xs={12} lg={6}>
      <StyledCard textAlign="center">
        <Title type="h6" hasGutter>
          VLU Healing Rate - Time from Enrollment
        </Title>
        <img
          style={{ width: '100%' }}
          src={DemographicsImage}
          alt="Demographics"
        />
      </StyledCard>
    </Grid>
  </Grid>
);

export default Demographics;
