export const getWoundImageDetails = (state, imageId) =>
  state.woundImages.details.images[imageId];

export const isDetailsLoading = (state) => state.woundImages.details.loading;

export const getDetailsError = (state) => state.woundImages.details.error;

export const isDetailsUpdating = (state) => state.woundImages.details.updating;

export const updateError = (state) => state.woundImages.details.updateError;

export const getNotes = (state, listingId) =>
  state.woundImages.notes.listings[listingId];

export const isNotesLoading = (state) => state.woundImages.notes.loading;

export const getNotesError = (state) => state.woundImages.notes.error;
