import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { StyledCard } from 'utils/styles';

import WidgetHeader from '../widget-header';

import { StyledDialog } from './WidgetContainer.styles';

const WidgetContainer = ({
  title,
  onAddClick,
  onLogsClick,
  children,
  modalContent,
  onModalClose,
  isLogsList,
}) => (
  <>
    <StyledDialog open={Boolean(modalContent)} onClose={onModalClose}>
      <Box>{modalContent}</Box>
    </StyledDialog>
    <StyledCard>
      <WidgetHeader
        title={title}
        onAddClick={onAddClick}
        onLogsClick={onLogsClick}
        isLogsList={isLogsList}
      />
      {children}
    </StyledCard>
  </>
);

WidgetContainer.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  onLogsClick: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,
  modalContent: PropTypes.node,
  onModalClose: PropTypes.func,
  isLogsList: PropTypes.bool,
};

export default WidgetContainer;
