export const yesNoOptions = [
  {
    value: '1',
    label: 'Yes',
  },
  {
    value: '2',
    label: 'No',
  },
];

export const howSevereOptions = [
  {
    value: '1',
    label: 'None',
  },
  {
    value: '2',
    label: 'Very mild',
  },
  {
    value: '3',
    label: 'Mild',
  },
  {
    value: '4',
    label: 'Moderate',
  },
  {
    value: '5',
    label: 'Severe',
  },
  {
    value: '6',
    label: 'Very severe',
  },
];

export const howOftenTimeOptions = [
  {
    value: '1',
    label: 'All of the time',
  },
  {
    value: '2',
    label: 'Most of the time',
  },
  {
    value: '3',
    label: 'A good bit of the time',
  },
  {
    value: '4',
    label: 'Some of the time',
  },
  {
    value: '5',
    label: 'A little bit of the time',
  },
  {
    value: '6',
    label: 'None of the time',
  },
];

export const howOftenOptions = [
  {
    value: '1',
    label: 'Every day',
  },
  {
    value: '2',
    label: 'Several times a week',
  },
  {
    value: '3',
    label: 'About once a week',
  },
  {
    value: '4',
    label: 'Less than once a week',
  },
  {
    value: '5',
    label: 'Never',
  },
];

export const timeOfDayOptions = [
  {
    value: '1',
    label: 'On waking',
  },
  {
    value: '2',
    label: 'At mid-day',
  },
  {
    value: '3',
    label: 'At the end of the day',
  },
  {
    value: '4',
    label: 'During the night',
  },
  {
    value: '5',
    label: 'At any time of day',
  },
  {
    value: '6',
    label: 'Never',
  },
];

export const howMuchOptions = [
  {
    value: '1',
    label: 'Not at all',
  },
  {
    value: '2',
    label: 'Slightly',
  },
  {
    value: '3',
    label: 'Moderately',
  },
  {
    value: '4',
    label: 'Quite a bit',
  },
  {
    value: '5',
    label: 'Extremely',
  },
];

export const getHowLimitedOptions = (hasWorkOption) => {
  const options = [
    {
      value: '1',
      label: 'Yes, limited a lot',
    },
    {
      value: '2',
      label: 'Yes, limited a little',
    },
    {
      value: '3',
      label: 'No, not limited at all',
    },
  ];

  if (hasWorkOption)
    options.unshift({
      value: '0',
      label: 'I do not work',
    });

  return options;
};

export const getOneYearAgoOptions = (type) => {
  const options = [
    {
      value: '1',
      label: 'Much better now than one year ago',
    },
    {
      value: '2',
      label: 'Somewhat better now than one year ago',
    },
    {
      value: '3',
      label: `About the same ${type !== 'SF36' ? 'now as one year ago' : ''}`,
    },
    {
      value: '4',
      label: 'Somewhat worse now than one year ago',
    },
    {
      value: '5',
      label: 'Much worse than one year ago',
    },
  ];

  if (type === 'PembQol')
    options.push({
      value: '6',
      label: 'I did not have any problems with my lungs',
    });

  if (type === 'VeinesQolSym')
    options.push({
      value: '6',
      label: 'I did not have any leg problem last year',
    });

  return options;
};

export const eq5d5lOptions = (type, lastOption) => {
  const options = [
    {
      value: '1',
      label: `I have no problems ${type}`,
    },
    {
      value: '2',
      label: `I have slight problems ${type}`,
    },
    {
      value: '3',
      label: `I have moderate problems ${type}`,
    },
    {
      value: '4',
      label: `I have severe problems ${type}`,
    },
  ];

  options.push(lastOption);

  return options;
};
