import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import api from 'core/api';
import useHospitalSystemDetails from 'hooks/useHospitalSystemDetails';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';
import { hospitalSystemSchema } from 'utils/validations';

import Template from 'pages/system-administrator/template';
import HospitalSystemSiteList from 'pages/system-administrator/organizations/hospital-system-sites/list';
import ErrorMessage from 'components/error-message';
import FormWrapper from 'components/forms/form-wrapper';

import HospitalSystemForm, { getHospitalSystemFormValues } from '../form';

const HospitalSystemDetails = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState(true);
  const { hospitalSystemId } = useParams();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const { hospitalSystemDetails, isLoading, error } =
    useHospitalSystemDetails(hospitalSystemId);

  const onSubmit = (data) => {
    clearFeedback();
    const submitDataData = {
      name: data.hospitalSystemName,
      street: data.headquartersAddress,
      city: data.city,
      state: data.state,
      country: data.country,
      diseases: data.managedDiseaseStates,
    };
    api
      .updateHospitalSystem(hospitalSystemId, submitDataData)
      .then(() => {
        setSuccessFeedback('Hospital System updated with success');
        setDisabled(true);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error));
      });
  };

  return (
    <Template
      backUrl={SYS_ADMIN_PATHS.hospitalSystems}
      title={hospitalSystemDetails?.name ?? 'Hospital System'}
      isLoading={isLoading}
      actions={
        <>
          {disabled && (
            <Button
              variant="contained"
              onClick={() => setDisabled(false)}
              data-test="edit-form-button"
            >
              Edit System
            </Button>
          )}
          <Button variant="contained" onClick={() => navigate('sites/new')}>
            Add Site
          </Button>
        </>
      }
    >
      {!hospitalSystemDetails && error ? (
        <ErrorMessage message="Hospital System not found" />
      ) : (
        <>
          <FormWrapper
            initialValues={getHospitalSystemFormValues(hospitalSystemDetails)}
            validationSchema={hospitalSystemSchema}
            onSubmit={onSubmit}
            disabled={disabled}
            onCancel={() => setDisabled(true)}
            enableReinitialize
          >
            {({ formik }) => (
              <HospitalSystemForm formik={formik} readOnly={disabled} />
            )}
          </FormWrapper>
          <HospitalSystemSiteList hospitalSystemId={hospitalSystemId} />
        </>
      )}
    </Template>
  );
};

export default HospitalSystemDetails;
