import * as actionTypes from './actionTypes';

export const initialState = {
  list: {
    error: null,
    loading: false,
    data: {},
  },
  details: {
    error: null,
    loading: false,
    data: {},
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*LIST*/
    case actionTypes.GET_HOSPITAL_SYSTEM_SITES_REQUEST:
      return {
        ...state,
        list: {
          error: null,
          loading: true,
          data: {},
        },
      };
    case actionTypes.GET_HOSPITAL_SYSTEM_SITES_FAILURE:
      return {
        ...state,
        list: {
          error: action.payload.error,
          loading: false,
          data: {},
        },
      };

    case actionTypes.GET_HOSPITAL_SYSTEM_SITES_SUCCESS:
      return {
        ...state,
        list: {
          error: null,
          loading: false,
          data: action.payload,
        },
      };

    case actionTypes.GET_HOSPITAL_SYSTEM_SITE_REQUEST:
      return {
        ...state,
        details: {
          error: null,
          loading: true,
          data: {},
        },
      };

    case actionTypes.GET_HOSPITAL_SYSTEM_SITE_FAILURE:
      return {
        ...state,
        details: {
          error: action.payload.error,
          loading: false,
          data: {},
        },
      };
    case actionTypes.GET_HOSPITAL_SYSTEM_SITE_SUCCESS:
      return {
        ...state,
        details: {
          error: null,
          loading: false,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
