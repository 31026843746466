import React, { useCallback, useMemo } from 'react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';

import useECRF from 'hooks/useECRF';
import {
  PATIENT_DASHBOARD_PATHS,
  ECRF_DASHBOARD_PATHS,
} from 'navigation/paths';

import ChatWindow from 'components/chat/chat-window';

import PatientHeader from './header';
import {
  dashboardPathButtons,
  dashboardPathButtonsECRF,
  dashboardPathElements,
  dashboardPathElementsECRF,
} from './sections/utils';

const PatientDashboard = () => {
  const { patientId } = useParams();
  const { pathname, state } = useLocation();
  const { isECRF, rootPath } = useECRF();

  const pathArray = useMemo(
    () => (isECRF ? ECRF_DASHBOARD_PATHS : PATIENT_DASHBOARD_PATHS),
    [isECRF],
  );

  const currentPath = useMemo(
    () => pathname.replace(`${rootPath}/${patientId}/`, '').split('/')[0],
    [pathname, patientId, rootPath],
  );

  const renderDashboardMenu = useMemo(() => {
    const buttonsArray = isECRF
      ? dashboardPathButtonsECRF
      : dashboardPathButtons;

    return buttonsArray.map(({ route, label }) => (
      <Tab
        key={route}
        label={label}
        value={route}
        component={Link}
        to={{
          pathname: `${rootPath}/${patientId}/./${route}`,
          state,
        }}
      />
    ));
  }, [isECRF, patientId, rootPath, state]);

  const getElement = useCallback(
    (routePath) =>
      isECRF
        ? dashboardPathElementsECRF[routePath]
        : dashboardPathElements[routePath],
    [isECRF],
  );

  return (
    <>
      <PatientHeader />
      <Tabs value={currentPath} sx={{ margin: '1rem 0' }}>
        {renderDashboardMenu}
      </Tabs>
      <Routes>
        {Object.values(pathArray).map((routePath) => (
          <Route
            key={routePath}
            path={`${routePath}/*`}
            element={getElement(routePath)}
          />
        ))}
      </Routes>
      <ChatWindow />
    </>
  );
};

export default PatientDashboard;
