import styled from '@emotion/styled';

import { weight } from 'theme/fonts';

export const ImageList = styled('div')(() => ({
  overflow: 'auto',
  margin: '1rem 0',
}));

export const ImageInfo = styled('span')(({ theme, name, date }) => ({
  color: date ? theme.palette.grey[500] : theme.palette.base.black,
  fontSize: date ? '0.75rem' : '1rem',
  fontWeight: name ? weight.bold : weight.regular,
}));
