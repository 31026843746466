import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import ListItemButton from '@mui/material/ListItemButton';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useWoundImageDetails from 'hooks/useWoundImageDetails';
import { woundImagesStatus } from 'utils/constants';
import { formatISOString } from 'utils/date';
import { StyledCard } from 'utils/styles';
import { getSurfaceAreaString, getWoundName } from 'utils/woundImages';

import ConfirmDialog from 'components/confirm-dialog/ConfirmDialog';
import Loader from 'components/loader';
import Title from 'components/titles/Title';

import ImageDisplay from './vlu-image-display';
import VluImageModal from './vlu-image-modal';
import { ImageInfo, ImageList } from './VluImages.styles.js';

const woundActionInfo = {
  [woundImagesStatus.open]: {
    title: 'Are you sure you want to submit this image for correction?',
    subtitle: 'This will remove any existing measurements.',
  },
  [woundImagesStatus.deleted]: {
    title: 'Are you sure you want to delete this image?',
    subtitle: 'This cannot be undone.',
  },
};

const VluImages = () => {
  const { patientId } = useParams();
  const [selectedWoundImage, setSelectedWoundImage] = useState(null);
  const [woundAction, setWoundAction] = React.useState(null);
  const { updateWoundImage } = useWoundImageDetails(selectedWoundImage?.id);

  const {
    data: imageData,
    doRequest: getImages,
    isLoading,
  } = useRequest(api.getWoundImages);

  const [page, setPage] = useState(imageData?.page || 1);

  const handlePageChange = (_e, value) => setPage(value);

  const listParams = useMemo(
    () => ({
      patientId,
      Sort: 'Date',
      SortDirection: 'Desc',
      Page: page - 1,
      PageSize: 3,
    }),
    [patientId, page],
  );

  const getWoundImages = useCallback(
    () => getImages(listParams),
    [getImages, listParams],
  );

  useEffect(() => {
    getWoundImages();
  }, [getWoundImages]);

  const imageListRender = useMemo(
    () =>
      imageData?.data?.map((item) => (
        <ListItemButton
          key={item.id}
          onClick={() => setSelectedWoundImage(item)}
          sx={{ display: 'flex', gap: '1rem' }}
        >
          <ImageDisplay
            url={item.asset.url}
            maskedUrl={item?.maskedAsset?.url}
            markerUrl={item?.markerAsset?.url}
            size="small"
          />
          <Box display="flex" flexDirection="column" gap={0.5}>
            <ImageInfo date>
              {formatISOString(item?.totalSurfaceAreaDate || item.createdAt)}
            </ImageInfo>
            <ImageInfo name>{getWoundName(item.wound)}</ImageInfo>
            <ImageInfo>
              {getSurfaceAreaString(
                item.totalSurfaceArea,
                item.reasonForNoTracing,
                item.status,
              )}
            </ImageInfo>
          </Box>
        </ListItemButton>
      )),
    [imageData],
  );

  const vluImagesContentRender = useMemo(() => {
    if (isLoading || !imageData) {
      return <Loader />;
    } else if (imageData?.data?.length === 0) {
      return <Box mt={1}>No images available.</Box>;
    } else {
      return (
        <>
          <ImageList>{imageListRender}</ImageList>
          {imageData.pageCount > 1 && (
            <Pagination
              page={page}
              count={imageData.pageCount}
              color="primary"
              onChange={handlePageChange}
              sx={{ margin: 'auto' }}
            />
          )}
        </>
      );
    }
  }, [imageData, imageListRender, isLoading, page]);

  return (
    <StyledCard sx={{ display: 'flex', flexDirection: 'column' }}>
      <Title type="h2" isBold>
        VLU Images
      </Title>
      {vluImagesContentRender}
      <VluImageModal
        selectedWoundImage={selectedWoundImage}
        handleClose={() => setSelectedWoundImage(null)}
        setWoundAction={setWoundAction}
      />
      {woundAction && (
        <ConfirmDialog
          open={!!woundAction}
          title={woundActionInfo[woundAction]?.title}
          subtitle={woundActionInfo[woundAction]?.subtitle}
          handleConfirm={async () => {
            await updateWoundImage({
              imageId: selectedWoundImage?.id,
              surfaces: [],
              status: woundAction,
              flag: false,
            });
            await getWoundImages();
            setWoundAction(null);
            setSelectedWoundImage(null);
          }}
          handleCancel={() => setWoundAction(null)}
          confirmLabel="Yes"
          cancelLabel="No"
        />
      )}
    </StyledCard>
  );
};

export default VluImages;
