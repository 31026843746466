import styled from '@emotion/styled';

import Avatar from '@mui/material/Avatar';

import { family, weight } from 'theme/fonts';
import { breakpointsTheme } from 'theme/muiTheme';

export const DayOfWeekHeader = styled('div')(({ theme }) => ({
  marginBottom: '0.5rem',
  color: theme.palette.grey[700],
  font: `${weight.semibold} 1rem ${family.openSans}`,
}));

export const StyledIcon = styled(Avatar)(({ theme, color }) => ({
  width: '2rem',
  height: '2rem',
  fontFamily: family.openSans,
  color:
    color === 'red'
      ? theme.palette.error.dark
      : color === 'green'
        ? theme.palette.success.dark
        : theme.palette.grey[700],
  backgroundColor:
    color === 'red'
      ? theme.palette.error.light
      : color === 'green'
        ? theme.palette.success.main
        : theme.palette.grey[100],
}));

export const WeekContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem auto',

  [breakpointsTheme.breakpoints.down('xs')]: {
    flexWrap: 'wrap',
  },
}));

export const WeekDayContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 1%',

  [breakpointsTheme.breakpoints.down('xs')]: {
    margin: '3%',
  },
}));
