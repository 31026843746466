import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { PeriodEnum } from 'utils/enums';

const gapParams = { weekStartsOn: 1 };

export const listViewMode = [
  PeriodEnum.WEEK,
  PeriodEnum.MONTH,
  PeriodEnum.YEAR,
];

export function getTimeInterval({ selectedDate, viewMode }) {
  switch (viewMode) {
    case PeriodEnum.WEEK:
      return {
        since: startOfWeek(selectedDate, gapParams),
        until: endOfWeek(selectedDate, gapParams),
        tickFormat: 'iii',
      };
    case PeriodEnum.MONTH:
      return {
        since: startOfMonth(selectedDate, gapParams),
        until: endOfMonth(selectedDate, gapParams),
        tickFormat: 'MM/dd',
      };
    case PeriodEnum.YEAR:
      return {
        since: startOfYear(selectedDate, gapParams),
        until: endOfYear(selectedDate, gapParams),
        tickFormat: 'MM/dd',
      };
    default:
      return null;
  }
}
