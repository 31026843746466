import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isSameDay, add, startOfWeek, endOfWeek } from 'date-fns';
import { useParams } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import {
  DayOfWeekHeader,
  StyledIcon,
  WeekContainer,
  WeekDayContainer,
} from './WeeklyDisplay.styles';

const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const DayDisplay = ({ value }) => {
  switch (value) {
    case false:
      return (
        <StyledIcon color="red">
          <CloseIcon />
        </StyledIcon>
      );
    case true:
      return (
        <StyledIcon color="green">
          <CheckIcon />
        </StyledIcon>
      );
    default:
      return <StyledIcon>?</StyledIcon>;
  }
};

DayDisplay.propTypes = {
  value: PropTypes.bool,
};

const WeeklyDisplay = ({ data, fetchData, selectedDate }) => {
  const { patientId } = useParams();

  const lastFullWeek = useMemo(
    () => ({
      from: startOfWeek(new Date(selectedDate), { weekStartsOn: 1 }),
      to: endOfWeek(new Date(selectedDate), { weekStartsOn: 1 }),
    }),
    [selectedDate],
  );

  useEffect(() => {
    fetchData({
      PatientId: patientId,
      DateFrom: lastFullWeek.from.toISOString(),
      DateTo: lastFullWeek.to.toISOString(),
    });
  }, [patientId, fetchData, lastFullWeek]);

  const weekData = useMemo(() => {
    if (data === null) {
      return [];
    }

    return weekDays.map((title, index) => {
      const date = add(lastFullWeek.from, { days: index });

      const dayValue = data
        .filter((element) => isSameDay(new Date(element.timestamp), date))
        .reduce((res, { value }) => {
          return res || value;
        }, null);

      return {
        title,
        date,
        value: dayValue,
      };
    });
  }, [data, lastFullWeek]);

  return (
    <WeekContainer>
      {weekData.map((obj) => {
        return (
          <WeekDayContainer key={obj.date}>
            <DayOfWeekHeader>{obj.title}</DayOfWeekHeader>
            <DayDisplay value={obj.value} />
          </WeekDayContainer>
        );
      })}
    </WeekContainer>
  );
};

WeeklyDisplay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  fetchData: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
};

export default WeeklyDisplay;
