import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import useUserDetails from 'hooks/useUserDetails';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { StyledCard } from 'utils/styles';

import Title from 'components/titles/Title';

import FollowUpSchedule from './follow-up-schedule';
import PastPresentFuture from './past-present-future';
import PatientNotes from './patient-notes';
import PractitionerList from './practitioner-list';
import TaskRuleList from './task-rule-list/TaskRuleList';

const defaultFilters = {
  active: 'true',
};

const Dashboard = () => {
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);

  const { doRequest: getTaskRules } = useRequest(api.getTaskRules);
  const { page, filters } = useTableState('', '', defaultFilters);

  const listFilters = useMemo(
    () => ({
      PatientId: patientId,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
      active: filters.active,
    }),
    [patientId, page, filters],
  );

  useEffect(() => {
    getTaskRules(listFilters);
  }, [getTaskRules, listFilters]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledCard>
          <PastPresentFuture />
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <FollowUpSchedule />
        </StyledCard>
      </Grid>
      <Grid item md={8} xs={12}>
        <StyledCard>
          <PatientNotes />
        </StyledCard>
      </Grid>
      <Grid item md={4} xs={12}>
        <StyledCard height="100%">
          <Title type="h2" isBold hasGutter>
            Care Team
          </Title>
          <PractitionerList user={user} />
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <TaskRuleList />
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
