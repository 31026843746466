import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MuiCard from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useUserDetails from 'hooks/useUserDetails';
import { PATIENT_DASHBOARD_PATHS, PRACTITIONER_PATHS } from 'navigation/paths';

import Title from 'components/titles/Title';

import { CardHeader, CardContent } from './PastPresentFuture.styles.js';

const PastPresentFuture = () => {
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);
  const { data, doRequest } = useRequest(api.getPPF);

  useEffect(() => {
    doRequest(patientId);
  }, [doRequest, patientId, user]);

  const addEncounterPath = React.useMemo(
    () =>
      `${PRACTITIONER_PATHS.patients}/${patientId}/${PATIENT_DASHBOARD_PATHS.CARE_TRACK}/${PATIENT_DASHBOARD_PATHS.ENCOUNTERS}/add`,
    [patientId],
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Title type="h2" isBold>
          Past Present Future (PPF)
        </Title>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          to={{ pathname: addEncounterPath }}
          component={Link}
        >
          Add Encounter
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <MuiCard style={{ height: '100%' }}>
            <CardHeader title="Past" />
            <CardContent>
              <div
                dangerouslySetInnerHTML={{ __html: data?.contentPast || '-' }}
              />
            </CardContent>
          </MuiCard>
        </Grid>
        <Grid item md={4} xs={12}>
          <MuiCard style={{ height: '100%' }}>
            <CardHeader title="Present" />
            <CardContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.contentPresent || '-',
                }}
              />
            </CardContent>
          </MuiCard>
        </Grid>
        <Grid item md={4} xs={12}>
          <MuiCard style={{ height: '100%' }}>
            <CardHeader title="Future" />
            <CardContent>
              <div
                dangerouslySetInnerHTML={{ __html: data?.contentFuture || '-' }}
              />
            </CardContent>
          </MuiCard>
        </Grid>
      </Grid>
    </>
  );
};

export default PastPresentFuture;
