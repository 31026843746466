import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  marginBottom: '1rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'initial',
  },
}));
