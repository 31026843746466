import * as Yup from 'yup';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

export const formFieldEnum = {
  MOBILITY: 'mobility',
  PAIN: 'pain',
  LEG_SWELLING: 'legSwelling',
};

export const painEnum = {
  NO_PAIN: 'NoPain',
  SLIGHT_PAIN: 'SlightPain',
  MODERATE_PAIN: 'ModeratePain',
  SEVERE_PAIN: 'SeverePain',
  EXTREME_PAIN: 'ExtremePain',
};

export const mobilityEnum = {
  NO_PROBLEMS: 'NoProblems',
  SLIGHT_PROBLEMS: 'SlightProblems',
  MODERATE_PROBLEMS: 'ModerateProblems',
  SEVERE_PROBLEMS: 'SevereProblems',
  UNABLE_WALK: 'UnableWalk',
};

export const legSwellingEnum = {
  NO: 'No',
  MODERATE: 'Moderate',
  EXTREME: 'Extreme',
};

export const painOptions = Object.values(painEnum).map((value) => ({
  value: value,
  label: `The patient has ${lowerCase(value)} or discomfort`,
}));

export const painLabels = Object.values(painEnum).reduce(
  (res, value) => ({ ...res, [value]: startCase(value) }),
  {},
);

const getGraphValue = (value, valueEnum) => {
  const foundIndex = Object.values(valueEnum).indexOf(value);
  return foundIndex === -1 ? null : foundIndex + 1;
};

export const getGraphPainValue = (item) =>
  getGraphValue(item[formFieldEnum.PAIN], painEnum);

export const getGraphMobilityValue = (item) =>
  getGraphValue(item[formFieldEnum.MOBILITY], mobilityEnum);

export const getGraphLegSwellingValue = (item) => {
  const foundIndex = Object.values(legSwellingEnum).indexOf(
    item[formFieldEnum.LEG_SWELLING],
  );
  return foundIndex * 2 + 1;
};

export const mobilityOptions = [
  {
    value: mobilityEnum.NO_PROBLEMS,
    label: 'The patient has no problems walking about',
  },
  {
    value: mobilityEnum.SLIGHT_PROBLEMS,
    label: 'The patient has slight problems in walking about',
  },
  {
    value: mobilityEnum.MODERATE_PROBLEMS,
    label: 'The patient has moderate problems in walking about',
  },
  {
    value: mobilityEnum.SEVERE_PROBLEMS,
    label: 'The patient has severe problems in walking about',
  },
  {
    value: mobilityEnum.UNABLE_WALK,
    label: 'The patient is unable to walk about',
  },
];

export const mobilityLabels = {
  [mobilityEnum.NO_PROBLEMS]: 'No problems walking',
  [mobilityEnum.SLIGHT_PROBLEMS]: 'Slight problems walking',
  [mobilityEnum.MODERATE_PROBLEMS]: 'Moderate problems walking',
  [mobilityEnum.SEVERE_PROBLEMS]: 'Severe problems walking',
  [mobilityEnum.UNABLE_WALK]: 'Unable to walk',
};

export const legSwellingOptions = Object.values(legSwellingEnum).map(
  (value) => ({
    value: value,
    label: `The patient has ${lowerCase(value)} leg swelling`,
  }),
);

export const formFields = [
  {
    name: formFieldEnum.MOBILITY,
    label: 'Mobility',
    options: mobilityOptions,
    required: true,
  },
  {
    name: formFieldEnum.PAIN,
    label: 'Pain/Discomfort',
    options: painOptions,
    required: true,
  },
  {
    name: formFieldEnum.LEG_SWELLING,
    label: 'Leg Swelling',
    options: legSwellingOptions,
    required: true,
  },
];

export const painAndMobilitySchema = Yup.object({
  [formFieldEnum.PAIN]: Yup.string().required('Required'),
  [formFieldEnum.MOBILITY]: Yup.string().required('Required'),
});

export const mapFormToApi = ({ timestamp, ...moreData }) => ({
  ...moreData,
  timestamp: new Date(timestamp).toISOString(),
});
