import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

export const initialState = {
  byId: {},
  listings: {},
  list: {
    items: [],
    pageCount: 0,
    error: null,
    loading: false,
  },
  details: {
    error: null,
    loading: false,
  },
};

const users = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_USERS_REQUEST:
      return update(state, {
        list: {
          error: { $set: null },
          loading: { $set: true },
          items: { $set: [] },
          pageCount: { $set: 0 },
        },
      });

    case actionTypes.GET_USERS_FAILURE:
      return update(state, {
        list: {
          error: { $set: action.payload.error },
          loading: { $set: false },
        },
      });

    case actionTypes.GET_USERS_SUCCESS:
      return update(state, {
        list: {
          items: { $set: action.payload.data },
          pageCount: { $set: action.payload.pageCount },
          error: { $set: null },
          loading: { $set: false },
        },
      });

    case actionTypes.GET_USER_REQUEST:
      return update(state, {
        details: {
          error: { $set: null },
          loading: { $set: true },
        },
      });
    case actionTypes.GET_USER_FAILURE:
      return update(state, {
        details: {
          error: { $set: action.payload.error },
          loading: { $set: false },
        },
      });
    case actionTypes.GET_USER_SUCCESS:
      return update(state, {
        byId: {
          [action.payload.id]: { $set: action.payload.user },
        },
        details: {
          error: { $set: null },
          loading: { $set: false },
        },
      });
    default:
      return state;
  }
};

export default users;
