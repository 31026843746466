import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { reducer as userReducer } from './user';
import { reducer as usersReducer } from './users';
import { reducer as woundImagesReducer } from './wound-images';
import { reducer as hospitalSystemsReducer } from './hospital-systems';
import { reducer as hospitalSystemSiteReducer } from './hospital-system-sites';
import feedbackReducer from './feedback/reducer';
import { reducer as chatReducer } from './chat';
import { reducer as timeTrackingReducer } from './time-tracking';
import { reducer as cachedImagesReducer } from './cached-images';
import { reducer as accountReducer } from './account';

import { sessionMiddleware } from './middlewares';

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const buildStore = (history, initialState) => {
  const reducer = combineReducers({
    router: connectRouter(history),
    user: userReducer,
    users: usersReducer,
    woundImages: woundImagesReducer,
    hospitalSystems: hospitalSystemsReducer,
    hospitalSystemSites: hospitalSystemSiteReducer,
    feedback: feedbackReducer,
    chat: chatReducer,
    timeTracking: timeTrackingReducer,
    cachedImages: cachedImagesReducer,
    account: accountReducer,
  });

  const middlewares = [thunk, routerMiddleware(history), sessionMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  return createStore(reducer, initialState, composeEnhancers(...enhancers));
};

export default buildStore;
