import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import ConfirmDialog from 'components/confirm-dialog';

const EncounterFormSubmit = ({ onSubmit }) => {
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  return (
    <>
      <Button variant="contained" onClick={() => setShowSubmitModal(true)}>
        Finalize and submit
      </Button>
      <ConfirmDialog
        open={showSubmitModal}
        handleCancel={() => setShowSubmitModal(false)}
        handleConfirm={() => {
          setShowSubmitModal(false);
          onSubmit();
        }}
        title="Are you sure you wish to complete this Encounter?"
        confirmLabel="Yes, Submit"
      />
    </>
  );
};

EncounterFormSubmit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EncounterFormSubmit;
