export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_CURRENT_USER_DETAILS_REQUEST =
  'GET_CURRENT_USER_DETAILS_REQUEST';
export const GET_CURRENT_USER_DETAILS_SUCCESS =
  'GET_CURRENT_USER_DETAILS_SUCCESS';
export const GET_CURRENT_USER_DETAILS_FAILURE =
  'GET_CURRENT_USER_DETAILS_FAILURE';

export const UPDATE_CURRENT_USER_DETAILS = 'UPDATE_CURRENT_USER_DETAILS';
