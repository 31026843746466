import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FormWrapper from 'components/forms/form-wrapper';

import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';

import { mapDataToForm, mapForm2Api } from '../form/utils';
import SettingsUserForm from '../form/SettingsUserForm';
import { userValidation } from '../form/validations';

const SettingsUser = ({ editing, setEditing, showButton }) => {
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const { userDetails, updateCurrentUserDetails } = useUser();

  const data = useMemo(() => mapDataToForm(userDetails), [userDetails]);

  const onSettingsSave = async (formData) => {
    clearFeedback();

    try {
      await updateCurrentUserDetails(
        mapForm2Api({
          ...userDetails,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          faxNumber: formData.faxNumber,
          doxyTelehealthLink: formData.doxyTelehealthLink,
          profileImageAssetId: formData.profileImageAssetId,
        }),
      );
      setSuccessFeedback('Details updated with success');
      setEditing(false);
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  return (
    <FormWrapper
      initialValues={data}
      validationSchema={userValidation}
      disabled={!editing}
      confirmStr="Save Details"
      onSubmit={onSettingsSave}
      onCancel={() => setEditing(false)}
      onEdit={showButton ? () => setEditing(true) : null}
      controlsOnTop
      title="Settings"
    >
      {({ formik }) => <SettingsUserForm formik={formik} disabled={!editing} />}
    </FormWrapper>
  );
};

SettingsUser.propTypes = {
  setEditing: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  showButton: PropTypes.bool.isRequired,
};

export default SettingsUser;
