import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const Footer = ({
  buttonText = 'Submit',
  isSubmitting,
  handleSubmit,
  handleBackClick,
  hideSubmit = false,
}) => (
  <Box display="flex" justifyContent="flex-end" mt={2}>
    {!hideSubmit && (
      <Button
        variant="contained"
        disabled={isSubmitting}
        onClick={handleSubmit}
      >
        {buttonText}
      </Button>
    )}
    <Button
      variant="outlined"
      disabled={isSubmitting}
      onClick={handleBackClick}
      sx={{ marginLeft: '0.5rem' }}
    >
      Back
    </Button>
  </Box>
);

Footer.propTypes = {
  buttonText: PropTypes.string,
  hideSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Footer;
