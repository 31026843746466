import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';
import { buildQueryStringFromObject } from 'utils/queryParams';

const renderArray = (queryValue) =>
  Array.isArray(queryValue) ? queryValue : [queryValue];

export const useTableState = (
  initialSortBy,
  initialSortOrder = 'Asc',
  defaultFilters = {},
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { query } = useQueryParams();

  const {
    page: routePage,
    sortOrder: routeSortOrder,
    sortBy: routeSortBy,
  } = query;

  const [page, setPage] = useState(routePage ? Number(routePage) : 1);
  const [sortOrder, setSortOrder] = useState(
    routeSortOrder || initialSortOrder,
  );
  const [sortBy, setSortBy] = useState(routeSortBy || initialSortBy);

  const [filters, setFilters] = useState(
    Object.entries(defaultFilters).reduce(
      (res, [key, defaultvalue]) =>
        (Object.prototype.hasOwnProperty.call(query, key) && {
          ...res,
          [key]: Array.isArray(defaultvalue)
            ? renderArray(query[key])
            : query[key],
        }) ||
        res,
      defaultFilters,
    ),
  );

  const handleFilterChange = (name, value) => {
    setPage(1);
    setFilters({ ...filters, [name]: value });
  };

  const handlePageChange = (_e, value) => setPage(value);

  const handleSortChange = (sortField) => {
    if (sortField === sortBy) {
      setSortOrder(sortOrder === 'Asc' ? 'Desc' : 'Asc');
    } else {
      setSortBy(sortField);
      setSortOrder(initialSortOrder);
    }
  };

  const clearTableState = (newFilters) => {
    setPage(1);
    setFilters(newFilters || defaultFilters);
  };

  useEffect(() => {
    const newRouteQuery = buildQueryStringFromObject({
      page,
      sortBy,
      sortOrder,
      ...filters,
    });
    navigate(`${pathname}${newRouteQuery}`, { replace: true });
  }, [navigate, page, sortBy, sortOrder, filters, pathname]);

  return {
    page,
    sortBy,
    sortOrder,
    filters,
    handleFilterChange,
    handlePageChange,
    handleSortChange,
    clearTableState,
  };
};

export default useTableState;
