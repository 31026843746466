import * as Yup from 'yup';
import pick from 'lodash/pick';

import { INPUT_TYPES, yesNoOptions } from 'utils/constants';

const anotherReasonValue = 'AnotherReason';

export const formFieldEnum = {
  STOCKINGS: 'wornStockings',
  STOCKINGS_REASONS: 'notWornStockingsReasons',
  STOCKINGS_OTHER_REASON: 'notWornStockingsReasonOther',
};

export const stockingReasonOptions = {
  TooPainful: 'Too Painful',
  Discomfort: 'Discomfort (Not Pain)',
  DifficultyPerformingDailyActivities:
    'Difficulty Performing Daily Activities (e.g. Showering)',
  OdorOrSmell: 'Odor or Smell',
  [anotherReasonValue]: 'Another Reason',
};

export const formFields = ({ values, setFieldValue }) => [
  {
    name: formFieldEnum.STOCKINGS,
    label: 'Is the patient wearing their compression stockings?',
    type: INPUT_TYPES.CHIPS,
    options: yesNoOptions,
    isVisible: true,
    required: true,
    changeOverride: () => {
      setFieldValue(formFieldEnum.STOCKINGS_REASONS, []);
      setFieldValue(formFieldEnum.STOCKINGS_OTHER_REASON, '');
    },
  },
  {
    name: formFieldEnum.STOCKINGS_REASONS,
    label: 'Why did the patient remove their stockings?',
    type: INPUT_TYPES.MULTIPLE_CHOICE,
    options: Object.entries(stockingReasonOptions).map(([value, text]) => ({
      value,
      label: text,
    })),
    isVisible: values[formFieldEnum.STOCKINGS] === false,
    required: true,
    changeOverride: (e) => {
      if (!e?.includes(anotherReasonValue))
        setFieldValue(formFieldEnum.STOCKINGS_OTHER_REASON, '');
    },
  },
  {
    name: formFieldEnum.STOCKINGS_OTHER_REASON,
    placeholder: 'Please Describe Reason',
    type: INPUT_TYPES.COMMENT,
    required: true,
    isVisible:
      values[formFieldEnum.STOCKINGS_REASONS]?.includes(anotherReasonValue),
  },
];

export const compressionComplianceSchema = Yup.object({
  [formFieldEnum.STOCKINGS]: Yup.bool().nullable().required('Required'),
  [formFieldEnum.STOCKINGS_REASONS]: Yup.array().when(formFieldEnum.STOCKINGS, {
    is: false,
    then: (schema) => schema.min(1, 'Select at least one option'),
  }),
  [formFieldEnum.STOCKINGS_OTHER_REASON]: Yup.string().when(
    formFieldEnum.STOCKINGS_REASONS,
    {
      is: (values) => values.includes(anotherReasonValue),
      then: (schema) => schema.required('Required'),
    },
  ),
});

export const mapForm2Api = ({ timestamp, ...data }) => ({
  ...pick(data, [
    formFieldEnum.STOCKINGS,
    formFieldEnum.STOCKINGS_REASONS,
    formFieldEnum.STOCKINGS_OTHER_REASON,
  ]),
  timestamp: new Date(timestamp).toISOString(),
});
