import React from 'react';
import PropTypes from 'prop-types';

import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';

import FormSubmit from 'components/forms/form-submit';
import Title from 'components/titles/Title';

const DeleteComplianceLog = ({ deleteFunc, handleCancel, handleRefresh }) => {
  const { doRequest: doDelete } = useRequest(deleteFunc);
  const { setSuccessFeedback, setErrorFeedback } = useFeedback();

  const handleSubmit = async () => {
    try {
      await doDelete();
      setSuccessFeedback('Entry deleted with success.');
      if (handleRefresh) handleRefresh();
      handleCancel();
    } catch (error) {
      setErrorFeedback('Failed to delete entry.');
    }
  };

  return (
    <>
      <Title type="p">Are you sure you want to delete this entry?</Title>
      <FormSubmit
        onConfirm={handleSubmit}
        onCancel={handleCancel}
        confirmStr="Delete"
        justifyContent="center"
      />
    </>
  );
};

DeleteComplianceLog.propTypes = {
  deleteFunc: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func,
};

export default DeleteComplianceLog;
