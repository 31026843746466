import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, isSameDay, startOfMonth, endOfMonth } from 'date-fns';
import { useParams } from 'react-router-dom';
import { createTheme } from '@mui/material';

import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';

import { useTheme } from 'context/theme';
import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { formatDate } from 'utils/date';
import { encounterTypeLabels } from 'utils/encounters';

import StatusIcon from '../status-icon';

import { EncounterItem, getComponents } from './Calendar.styles';

const EncounterList = () => {
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(new Date()));
  const { patientId } = useParams();
  const theme = useTheme();

  const materialTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        ...getComponents(theme.palette),
      }),
    [theme],
  );

  const { data: encountersList, doRequest: getEncounters } = useRequest(
    api.getEncounters,
  );

  useEffect(() => {
    getEncounters({
      PatientId: patientId,
      DateFrom: formatDate(selectedMonth),
      DateTo: formatDate(endOfMonth(selectedMonth)),
    });
  }, [selectedMonth, patientId, getEncounters]);

  const Day = ({ day, ...other }) => {
    const itemValue = encountersList?.data.filter(({ startedAt }) =>
      isSameDay(new Date(startedAt), day),
    );

    if (itemValue?.length) {
      return (
        <PickersDay day={day} disableMargin {...other}>
          <span>{format(day, 'd')}</span>
          {itemValue?.map(({ id, type, status }) => (
            <EncounterItem key={id}>
              <StatusIcon status={status} />
              <span>{encounterTypeLabels[type]}</span>
            </EncounterItem>
          ))}
        </PickersDay>
      );
    }

    return <PickersDay day={day} disableMargin {...other} />;
  };

  Day.propTypes = { day: PropTypes.instanceOf(Date) };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={materialTheme}>
        <DateCalendar
          defaultValue={null}
          onMonthChange={setSelectedMonth}
          readOnly
          views={['day']}
          slots={{ day: Day }}
          showDaysOutsideCurrentMonth
          disableHighlightToday
          dayOfWeekFormatter={(day) => day}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default EncounterList;
