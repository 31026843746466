import { signOut } from 'aws-amplify/auth';

import api from 'core/api';

import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  GET_CURRENT_USER_DETAILS_REQUEST,
  GET_CURRENT_USER_DETAILS_SUCCESS,
  GET_CURRENT_USER_DETAILS_FAILURE,
  UPDATE_CURRENT_USER_DETAILS,
} from './actionTypes';

export const doLogout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_REQUEST,
  });

  try {
    await signOut();

    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: LOGOUT_FAILURE,
    });
  }
};

export const doGetCurrentUserDetails = () => async (dispatch) => {
  dispatch({
    type: GET_CURRENT_USER_DETAILS_REQUEST,
  });
  try {
    const result = await api.getCurrentUserDetails();
    dispatch({
      payload: {
        details: result.data,
      },
      type: GET_CURRENT_USER_DETAILS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_CURRENT_USER_DETAILS_FAILURE,
    });
  }
};

export const doUpdateCurrentUserDetails = (data) => async (dispatch) => {
  await api.updateCurrentUserDetails(data);
  dispatch({
    payload: data,
    type: UPDATE_CURRENT_USER_DETAILS,
  });
};
