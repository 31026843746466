import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { INPUT_TYPES } from 'utils/constants';
import { US_DATE_FORMAT } from 'utils/date';
import { StyledTextField } from 'utils/styles';

const getDatePickerProps = (type) => {
  switch (type) {
    case INPUT_TYPES.YEAR_MONTH_SELECTOR:
      return {
        format: 'MMMM yyyy',
        views: ['year', 'month'],
      };
    default:
      return { format: US_DATE_FORMAT };
  }
};
const DateSelector = ({
  name,
  value,
  onChange,
  type = INPUT_TYPES.DATE_SELECTOR,
  disabled,
  label,
  error,
  isRequired,
}) => {
  const handleChange = (newValue) => {
    onChange({
      target: {
        value: newValue.toISOString(),
        name,
      },
    });
  };

  const datePickerProps = getDatePickerProps(type);

  const selectedValue = React.useMemo(() => {
    if (!value) return null;
    return typeof value === 'string' ? new Date(value) : value;
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        id={name}
        name={name}
        label={label}
        value={selectedValue}
        onChange={handleChange}
        disabled={disabled}
        slots={{ textField: StyledTextField }}
        slotProps={{
          textField: {
            size: 'small',
            fullWidth: true,
            required: isRequired,
            error: error,
            helperText: error,
          },
        }}
        {...datePickerProps}
      />
    </LocalizationProvider>
  );
};

DateSelector.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    INPUT_TYPES.DATE_SELECTOR,
    INPUT_TYPES.YEAR_MONTH_SELECTOR,
  ]),
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
};

export default DateSelector;
