import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES } from 'utils/constants';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';
import MultipleChoice from 'components/forms/multiple-choice';

const ComplianceQuestion = ({ element, isVisible, ...props }) => {
  if (!isVisible) return null;

  switch (element.type) {
    case INPUT_TYPES.CHIPS:
      return (
        <Grid item xs={12}>
          <Chips {...props} {...element} />
        </Grid>
      );
    case INPUT_TYPES.MULTIPLE_CHOICE:
      return (
        <Grid item xs={12}>
          <MultipleChoice {...props} {...element} />
        </Grid>
      );
    case INPUT_TYPES.COMMENT:
      return (
        <Grid item xs={12}>
          <Input {...props} {...element} />
        </Grid>
      );
    default:
      return null;
  }
};

ComplianceQuestion.propTypes = {
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default ComplianceQuestion;
