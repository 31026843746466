import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import { OTHER_VALUE } from 'utils/constants';
import { LimbLocationLabel } from 'utils/enums';
import { getErrorMessage } from 'utils/error';
import { getFieldProps } from 'utils/forms';

import FormWrapper from 'components/forms/form-wrapper';
import Input from 'components/forms/input';
import Select from 'components/forms/select';
import StyledIconButton from 'components/buttons/StyledIconButton';
import Title from 'components/titles/Title';

import {
  woundFields,
  woundLegOptions,
  woundLimbOptions,
  woundLimbSideOptions,
  woundSchema,
} from './utils';

const initialValues = {
  [woundFields.WOUND_LEG]: '',
  [woundFields.WOUND_LIMB_LOCATION]: '',
  [woundFields.WOUND_LIMB_LOCATION_OTHER]: '',
  [woundFields.WOUND_LIMB_SIDE]: '',
};

export const NewWound = ({ handleCancel, handleSuccess }) => {
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { patientId } = useParams();

  const handleSubmit = async (data) => {
    clearFeedback();
    try {
      const location = data[woundFields.WOUND_LIMB_LOCATION];
      const saveData = {
        ...data,
        [woundFields.NAME]: `${data[woundFields.WOUND_LEG]} ${
          data[woundFields.WOUND_LIMB_SIDE]
        } ${
          location === OTHER_VALUE
            ? data[woundFields.WOUND_LIMB_LOCATION_OTHER]
            : (LimbLocationLabel && LimbLocationLabel[location]) ?? location
        }`,
      };
      await api.createWound({ patientId, ...saveData });
      setSuccessFeedback('Added with success');
      handleSuccess();
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  return (
    <FormWrapper
      initialValues={initialValues}
      validationSchema={woundSchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minWidth="50vw"
            mb={2}
          >
            <Title type="h1">Add Wound</Title>
            <StyledIconButton
              name="close"
              color="black"
              onClick={() => handleCancel({ close: true })}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Select
                label="Leg"
                options={woundLegOptions}
                required
                {...getFieldProps({
                  formik,
                  name: woundFields.WOUND_LEG,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label="Location on the Limb"
                options={woundLimbOptions}
                required
                {...getFieldProps({
                  formik,
                  name: woundFields.WOUND_LIMB_LOCATION,
                })}
              />
            </Grid>
            {formik.values[woundFields.WOUND_LIMB_LOCATION] === OTHER_VALUE && (
              <Grid item xs={12} md={4}>
                <Input
                  label="Please Describe"
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: woundFields.WOUND_LIMB_LOCATION_OTHER,
                  })}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <Select
                label="Side of the Limb "
                options={woundLimbSideOptions}
                required
                {...getFieldProps({
                  formik,
                  name: woundFields.WOUND_LIMB_SIDE,
                })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormWrapper>
  );
};

NewWound.propTypes = {
  handleCancel: PropTypes.func,
  handleSuccess: PropTypes.func,
};

export default NewWound;
