import styled from '@emotion/styled';

export const ChatWindowContainer = styled('div')(({ collapsed }) => ({
  position: 'fixed',
  bottom: '0',
  right: '3%',
  width: '25rem',
  height: collapsed ? 'initial' : '32rem',
  zIndex: '2',
}));

export const ChatWindowHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  borderRadius: '0.25rem 0.25rem 0 0',
  background: theme.palette.primary.main,

  '.MuiSvgIcon-root': {
    color: theme.palette.base.white,
  },
}));

export const ChatContainer = styled('div')(({ theme }) => ({
  height: 'calc(100% - 3rem)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  border: `1px solid ${theme.palette.primary.main}`,
  borderBottom: 'none',
  background: theme.palette.base.white,
}));

export const MessageDisplayContainer = styled('div')(() => ({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
}));

export const MessageInputContainer = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
}));

export const MessageContainer = styled('div')(() => ({
  padding: '1rem',
}));

export const MessageUser = styled('div')(({ theme }) => ({
  marginBottom: '0.25rem',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));

export const StyledContainer = styled('div')(({ sent }) => ({
  display: 'flex',
  flexDirection: sent ? 'row-reverse' : 'row',
}));

export const StyledBody = styled('div')(({ theme, sent }) => ({
  width: 'fit-content',
  padding: '0.5rem',
  borderRadius: sent
    ? '0.75rem 0rem 0.75rem 0.75rem'
    : '0rem 0.75rem 0.75rem 0.75rem',
  background: sent ? theme.palette.primary.light : theme.palette.grey[100],
}));

export const StyledTimeAgo = styled('div')(({ theme, sent }) => ({
  margin: !sent ? '0.25rem 0 0 3rem' : '0.25rem 0 0 0',
  textAlign: sent ? 'right' : 'left',
  color: theme.palette.grey[700],
  fontSize: '0.75rem',

  '& span': {
    color: theme.palette.base.black,
  },
}));

export const StyledTodayBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  margin: '1rem 0',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    top: '50%',
    backgroundColor: theme.palette.grey[700],
  },

  '& span': {
    zIndex: '1',
    padding: '0 0.5rem',
    backgroundColor: theme.palette.base.white,
    color: theme.palette.grey[700],
  },
}));
