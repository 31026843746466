import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useUserDetails from 'hooks/useUserDetails';
import { formatBirthDate } from 'utils/date';
import { DiseaseLabels } from 'utils/enums';

import ErrorMessage from 'components/error-message';
import Loader from 'components/loader';

import { CategoryContainer, Category } from './RoomHeader.styles';

export const RoomPatientInfo = ({ userId }) => {
  const { user, isLoading, error } = useUserDetails(userId, {
    shouldLoad: true,
  });

  const diseases = useMemo(
    () =>
      user?.patient?.diseases
        ?.map(({ disease }) => DiseaseLabels[disease])
        .join(', ') || '--',
    [user],
  );

  if (isLoading) return <Loader />;
  if (error) return <ErrorMessage message="Failed to load patient info" />;

  return user?.id ? (
    <CategoryContainer>
      <Category>
        <span>Name: </span>
        {user.firstName + ' ' + user.lastName}
      </Category>
      <Category>
        <span>DOB: </span>
        {formatBirthDate(user?.birthDate, true) || '--'}
      </Category>
      <Category>
        <span>ID: </span> {user.id}
      </Category>
      <Category>
        <span>Disease State: </span> {diseases}
      </Category>
    </CategoryContainer>
  ) : null;
};

RoomPatientInfo.propTypes = {
  userId: PropTypes.string,
};

export default RoomPatientInfo;
