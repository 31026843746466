import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import { INPUT_TYPES, OTHER_VALUE, yesNoOptions } from 'utils/constants';
import { sideSubtitle, sidesValues } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';
import Select from 'components/forms/select';

import { MultipleColumnsSection } from './MultipleColumnsSection';

const fields = {
  VEINS_ACCESSED: 'veinsAccessed',
  VEINS_ACCESSED_OTHER: 'veinAccessedOther',
  METHODS_TO_REMOVE_ACUTE_DVT_USED:
    'methodsToRemoveAcuteDVTUsedBeforeStentPlacement',
  ESTIMATED_MAXIMUM_STENOSIS: 'estimatedMaximumStenosisPercent',
  ESTIMATED_LENGTH_OF_STENOSIS: 'estimatedLengthOfStenosisCm',
  ADDITIONAL_CVT_VENOGRAPHY_NOTES: 'additionalCTVenographyNotes',
  IVUS_DEVICE_BRAND_USED: 'ivusDeviceBrandUsed',
  IVUS_DEVICE_BRAND_USED_OTHER: 'ivusDeviceBrandUsedOther',
  IVUS_FINDINGS: 'ivusFinding',
  ESTIMATED_TOTAL_LENGTH_OF_STENOSIS: 'estimatedTotalLengthOfStenosisCm',
  ADDITIONAL_IVUS_NOTES: 'additionalIVUSNotes',
};

const veinsAccessedOptions = [
  {
    value: 'IpsilateralPoplitealVein',
    label: 'Ipsilateral popliteal vein',
  },
  { value: 'IpsilateralTibialVein', label: 'Ipsilateral tibial vein' },
  {
    value: 'IpsilateralCommonFemoralVein',
    label: 'Ipsilateral common femoral vein',
  },
  { value: 'IpsilateralAnkleVein', label: 'Ipsilateral ankle vein' },
  { value: 'InternalJugularVein', label: 'Internal jugular vein' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const ivusDeviceBrandUsedivusOptions = [
  { value: 'Opticross', label: 'Opticross' },
  { value: 'Dualpro', label: 'Dualpro' },
  { value: 'EagleEye', label: 'Eagle Eye' },
  { value: 'Refinity', label: 'Refinity' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const yesNoUnknownOptions = [
  { value: 'Unknown', label: 'Unknown' },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
];

const ivusTableSections = [
  { name: 'inferiorVenaCava', label: 'Inferior Vena Cava', sides: ['ivc'] },
  { name: 'commonIliac', label: 'Common Iliac', sides: ['left', 'right'] },
  { name: 'internalIliac', label: 'Internal Iliac', sides: ['left', 'right'] },
  { name: 'externalIliac', label: 'External Iliac', sides: ['left', 'right'] },
  { name: 'commonFemoral', label: 'Common Femoral', sides: ['left', 'right'] },
  {
    name: 'deepFemoral',
    label: 'Deep Femoral (Profunda)',
    sides: ['left', 'right'],
  },
  { name: 'femoral', label: 'Femoral', sides: ['left', 'right'] },
  { name: 'popliteal', label: 'Popliteal', sides: ['left', 'right'] },
  {
    name: 'anteriorTibial',
    label: 'Anterior Tibial',
    sides: ['left', 'right'],
  },
  {
    name: 'posteriorTibial',
    label: 'Posterior Tibial',
    sides: ['left', 'right'],
  },
  { name: 'peroneal', label: 'Peroneal', sides: ['left', 'right'] },
];

export const parseApi2Form = (values) => {
  const parsedValues = Object.values(sidesValues).reduce(
    (res, side) => ({
      ...res,
      [side]: values[side] || {
        [fields.IVUS_FINDINGS]: values[side]?.[fields.IVUS_FINDINGS] || {
          [side]: values[side]?.[fields.IVUS_FINDINGS]?.[side] || {
            obstruction: values[side]?.[fields.IVUS_FINDINGS]?.[side]
              ?.obstruction || {
              ...ivusTableSections?.reduce((names, section) => {
                if (!section.sides.includes(side)) return { ...names };
                return {
                  ...names,
                  [section.name]: {
                    selected:
                      values[side]?.[fields.IVUS_FINDINGS]?.[side]
                        ?.obstruction?.[section?.name]?.selected || 'Unknown',
                    score:
                      values[side]?.[fields.IVUS_FINDINGS]?.[side]
                        ?.obstruction?.[section?.name]?.score || null,
                  },
                };
              }, {}),
            },
          },
        },
      },
    }),
    {},
  );
  return {
    ...values,
    ...parsedValues,
  };
};

export const ivusTableInitialValues = Object.values(sidesValues).reduce(
  (res, side) => ({
    ...res,
    [side]: {
      [fields.IVUS_FINDINGS]: {
        [side]: {
          obstruction: {
            ...ivusTableSections?.reduce((names, section) => {
              if (!section.sides.includes(side)) return { ...names };
              return {
                ...names,
                [section.name]: {
                  selected: 'Unknown',
                  score: null,
                },
              };
            }, {}),
          },
        },
      },
    },
  }),
  {},
);

export const ProcedureSidesSection = ({ sides, disabled }) => {
  const formik = useFormikContext();

  const procedureDetailsSection = [
    sideSubtitle,
    {
      label: 'Vein(s) Accessed',
      input: (side) => (
        <Chips
          options={veinsAccessedOptions}
          isRequired
          isMultiple
          otherField={{
            name: `${side}.${fields.VEINS_ACCESSED_OTHER}`,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.VEINS_ACCESSED}`,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [side]: {
                      [fields.VEINS_ACCESSED]: { $set: value },
                      [fields.VEINS_ACCESSED_OTHER]: { $set: '' },
                    },
                  }),
                );
              }
            },
          })}
        />
      ),
    },
  ];

  const acuteThrombusRemovalSection = [
    sideSubtitle,
    {
      label:
        'Were methods to remove acute DVT used before stent placement? If yes, please fill out the DVT Clot Debulking Form instead.',
      input: (side) => (
        <Chips
          options={yesNoOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: `${side}.${fields.METHODS_TO_REMOVE_ACUTE_DVT_USED}`,
            disabled,
          })}
        />
      ),
    },
  ];

  const ctVenographySection = [
    sideSubtitle,
    {
      label: 'Estimated Maximum Stenosis',
      input: (side) => (
        <Input
          type={INPUT_TYPES.NUMERIC}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ESTIMATED_MAXIMUM_STENOSIS}`,
            disabled,
          })}
        />
      ),
    },
    {
      label: 'Estimated Length of Stenosis',
      input: (side) => (
        <Input
          type={INPUT_TYPES.NUMERIC}
          endAdornment={<InputAdornment position="end">cm</InputAdornment>}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ESTIMATED_LENGTH_OF_STENOSIS}`,
            disabled,
          })}
        />
      ),
    },
    {
      label: 'Additional CT Venography Notes',
      input: (side) => (
        <Input
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ADDITIONAL_CVT_VENOGRAPHY_NOTES}`,
            disabled,
          })}
        />
      ),
    },
  ];

  const ivusTable = ivusTableSections.map(({ label, name, sides }, idx) => {
    const isPresent = (side) =>
      formik.values[side][fields.IVUS_FINDINGS][side]['obstruction'][name][
        'selected'
      ] === 'Yes';

    return {
      label,
      input: (side) => {
        if (!sides.includes(side)) return '—';
        return (
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={isPresent(side) ? 6 : 12}
              style={{ alignSelf: 'flex-end' }}
            >
              <Select
                label={idx === 0 ? 'Obstruction Present' : null}
                options={yesNoUnknownOptions}
                {...getFieldProps({
                  formik,
                  name: `${side}.${fields.IVUS_FINDINGS}.${side}.obstruction.${name}.selected`,
                  disabled,
                  changeOverride: (value) => {
                    formik?.setValues(
                      update(formik?.values, {
                        [side]: {
                          [fields.IVUS_FINDINGS]: {
                            [side]: {
                              obstruction: {
                                [name]: {
                                  selected: { $set: value },
                                  score: { $set: null },
                                },
                              },
                            },
                          },
                        },
                      }),
                    );
                  },
                })}
              />
            </Grid>
            {isPresent(side) && (
              <Grid item xs={12} md={6}>
                <Input
                  label="Lumen Reduction"
                  type={INPUT_TYPES.NUMERIC}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  {...getFieldProps({
                    formik,
                    name: `${side}.${fields.IVUS_FINDINGS}.${side}.obstruction.${name}.score`,
                    disabled,
                  })}
                />
              </Grid>
            )}
          </Grid>
        );
      },
    };
  });

  const IVUSSection = [
    {
      label: 'IVUS Device (Brand) Used?',
      input: (side) => (
        <Chips
          options={ivusDeviceBrandUsedivusOptions}
          otherField={{
            name: `${side}.${fields.IVUS_DEVICE_BRAND_USED_OTHER}`,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.IVUS_DEVICE_BRAND_USED}`,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [side]: {
                      [fields.IVUS_DEVICE_BRAND_USED]: { $set: value },
                      [fields.IVUS_DEVICE_BRAND_USED_OTHER]: { $set: '' },
                    },
                  }),
                );
              }
            },
          })}
        />
      ),
    },
    {
      label: 'Intraprocedural IVUS Findings',
      input: () => <span></span>,
    },
    ...ivusTable,
    {
      label: 'Estimated Total Length of Stenosis',
      input: (side) => (
        <Input
          type={INPUT_TYPES.NUMERIC}
          endAdornment={<InputAdornment position="end">cm</InputAdornment>}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ESTIMATED_TOTAL_LENGTH_OF_STENOSIS}`,
            disabled,
          })}
        />
      ),
    },
    {
      label: 'Additional IVUS Notes',
      input: (side) => (
        <Input
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ADDITIONAL_IVUS_NOTES}`,
            disabled,
          })}
        />
      ),
    },
  ];

  const sidesSections = [
    {
      title: 'Procedure Details',
      items: procedureDetailsSection,
    },
    {
      title: 'Acute Thrombus Removal',
      items: acuteThrombusRemovalSection,
    },
    {
      title: 'Intraprocedural Diagnostics',
      subtitle: 'CT Venography',
      items: ctVenographySection,
    },
    {
      subtitle: 'Intravascular Ultrasound (IVUS)',
      items: IVUSSection,
    },
  ];

  return <MultipleColumnsSection sides={sides} sidesSections={sidesSections} />;
};

ProcedureSidesSection.propTypes = {
  sides: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};
