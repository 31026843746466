import { INPUT_TYPES } from 'utils/constants';

import { eq5d5lOptions } from './utils';

const mobilityQuestionOption = {
  value: '5',
  label: 'I am unable to walk about',
};

const selfCareQuestionOption = {
  value: '5',
  label: 'I am unable to wash or dress myself',
};

const usualActivitiesQuestionOption = {
  value: '5',
  label: 'I am unable to do my usual activities',
};

const painDiscomfortQuestionOptions = [
  {
    value: '1',
    label: 'I have no pain or discomfort',
  },
  {
    value: '2',
    label: 'I have slight pain or discomfort',
  },
  {
    value: '3',
    label: 'I have moderate pain or discomfort',
  },
  {
    value: '4',
    label: 'I have severe pain or discomfort',
  },
  {
    value: '5',
    label: 'I have extreme pain or discomfort',
  },
];

const anxietyDepressionQuestionOptions = [
  {
    value: '1',
    label: 'I am not anxious or depressed',
  },
  {
    value: '2',
    label: 'I am slightly anxious or depressed',
  },
  {
    value: '3',
    label: 'I am moderately anxious or depressed',
  },
  {
    value: '4',
    label: 'I am severely anxious or depressed',
  },
  {
    value: '5',
    label: 'I am extremely anxious or depressed',
  },
];

export const EQ5D5L = [
  [
    {
      text: 'Under each heading, please tick the ONE box that best describes your health TODAY.',
      type: INPUT_TYPES.INFO,
    },
    {
      name: 'mobility',
      label: 'MOBILITY',
      type: INPUT_TYPES.RADIO,
      options: eq5d5lOptions('in walking about', mobilityQuestionOption),
    },
    {
      name: 'selfCare',
      label: 'SELF-CARE',
      type: INPUT_TYPES.RADIO,
      options: eq5d5lOptions(
        'washing or dressing myself',
        selfCareQuestionOption,
      ),
    },
    {
      name: 'usualActivities',
      label:
        'USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)',
      type: INPUT_TYPES.RADIO,
      options: eq5d5lOptions(
        'doing my usual activities',
        usualActivitiesQuestionOption,
      ),
    },
    {
      name: 'painDiscomfort',
      label: 'PAIN / DISCOMFORT',
      type: INPUT_TYPES.RADIO,
      options: painDiscomfortQuestionOptions,
    },
    {
      name: 'anxietyDepression',
      label: 'ANXIETY / DEPRESSION',
      type: INPUT_TYPES.RADIO,
      options: anxietyDepressionQuestionOptions,
    },
  ],
  [
    {
      name: 'eqVas',
      type: INPUT_TYPES.SCALE,
    },
  ],
];
