import styled from '@emotion/styled';

export const StyledModal = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem 1.5rem 1rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
}));
