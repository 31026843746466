import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage, getErrorDetails } from 'utils/error';

import FormWrapper from 'components/forms/form-wrapper';

import MedicationComplianceForm from './MedicationComplianceForm';
import {
  medicationComplianceSchema,
  formFieldEnum,
  mapForm2Api,
} from './utils';

const NewMedicationCompliance = ({ handleCancel, handleRefresh }) => {
  const { patientId } = useParams();
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { getUser } = useUserDetails();

  const handleSubmit = (data) => {
    clearFeedback();
    api
      .addMedication({ patientId, ...mapForm2Api(data) })
      .then(() => {
        getUser(patientId);
        setSuccessFeedback('Medication complience created with success.');
        if (handleRefresh) handleRefresh();
        handleCancel();
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  const initialValues = {
    [formFieldEnum.MEDICATIONS]: null,
    [formFieldEnum.MEDICATIONS_REASONS]: [],
    [formFieldEnum.MEDICATIONS_OTHER_REASON]: '',
    timestamp: new Date().toString(),
  };

  return (
    <FormWrapper
      initialValues={initialValues}
      validationSchema={medicationComplianceSchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => (
        <MedicationComplianceForm formik={formik} titlePrefix="Add" />
      )}
    </FormWrapper>
  );
};

NewMedicationCompliance.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};

export default NewMedicationCompliance;
