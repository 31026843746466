import React from 'react';
import { Global } from '@emotion/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { StyledEngineProvider } from '@mui/material/styles';

import AppSwitch from 'navigation';

import { MuiThemeProvider } from 'context/theme';

import globalStyles from 'theme/global';

import buildStore from 'store/buildStore';

import 'core/amplifySetup';

const history = createBrowserHistory();
const store = buildStore(history);

const CustomRouter = ({ ...props }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), []);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <CustomRouter history={history}>
          <Global styles={globalStyles} />
          <MuiThemeProvider>
            <AppSwitch />
          </MuiThemeProvider>
        </CustomRouter>
      </Provider>
    </StyledEngineProvider>
  );
}

export default App;
