import isEmpty from 'lodash/isEmpty';

export const buildQueryStringFromObject = (obj, useQuestionMark = true) => {
  if (isEmpty(obj)) {
    return '';
  }

  const urlParams = Object.entries(obj).reduce((res, [key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        return value.reduce(
          (acc, val) =>
            `${acc}&${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
          res,
        );
      }

      return `${res}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }

    return res;
  }, '');

  const prefix = useQuestionMark ? '?' : '';

  return urlParams.length ? `${prefix}${urlParams}` : '';
};

export const parseParams = (params) => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === 'object';
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      if (params[key]) options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element) => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};
