import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import { ErrorText, Label } from 'utils/styles';

import { StyledRadio } from './SingleChoice.styles';

const SingleChoice = ({
  name,
  label,
  value,
  options,
  onChange,
  disabled,
  required,
  error,
}) => {
  const handleChange = (newValue) => () => {
    onChange({
      target: {
        value: newValue,
        name,
      },
    });
  };

  return (
    <>
      {label && (
        <Label
          htmlFor={name}
          error={!!error}
          required={required}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
      {options.map(({ label: optionLabel, value: optionValue }) => (
        <FormControlLabel
          key={optionValue}
          label={optionLabel}
          style={{ display: 'flex', width: '100%' }}
          control={
            <StyledRadio
              name={name}
              disabled={disabled}
              checked={value == optionValue}
              onChange={handleChange(optionValue)}
            />
          }
        />
      ))}
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

SingleChoice.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SingleChoice;
