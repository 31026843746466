import React from 'react';
import PropTypes from 'prop-types';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useTheme } from 'context/theme';

const icons = {
  account: <AccountCircleIcon fontSize="inherit" />,
  add: <AddIcon fontSize="inherit" />,
  cancel: <CancelIcon fontSize="inherit" />,
  close: <CloseIcon fontSize="inherit" />,
  delete: <DeleteIcon fontSize="inherit" />,
  edit: <EditIcon fontSize="inherit" />,
  exit: <ExitToAppIcon fontSize="inherit" />,
  menu: <MenuIcon fontSize="inherit" />,
  search: <SearchIcon fontSize="inherit" />,
  settings: <SettingsIcon fontSize="inherit" />,
  sync: <SyncIcon fontSize="inherit" />,
  video: <VideoCallIcon fontSize="inherit" />,
  visibility: <VisibilityOffIcon fontSize="inherit" />,
  visibilityOff: <VisibilityIcon fontSize="inherit" />,
};

const StyledIconButton = ({
  color = 'primary',
  disabled = false,
  hasMargin = false,
  isSmall = false,
  name,
  onClick,
  ...rest
}) => {
  const { palette } = useTheme();

  const iconColors = React.useMemo(
    () => ({
      dark: palette.base.black,
      lightgrey: palette.grey[300],
      primary: palette.primary.main,
      red: palette.error.main,
      white: palette.base.white,
    }),
    [palette],
  );

  return (
    <IconButton
      {...rest}
      disabled={disabled}
      onClick={onClick}
      style={{
        marginLeft: hasMargin ? '0.5rem' : 0,
        padding: isSmall ? '0.25rem' : '0.5rem',
        fontSize: isSmall ? '1.25rem' : '1.5rem',
        color: disabled ? iconColors.lightgrey : iconColors[color],
      }}
    >
      {icons[name]}
    </IconButton>
  );
};

StyledIconButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  hasMargin: PropTypes.bool,
  isSmall: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default StyledIconButton;
