import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { INPUT_TYPES } from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { getWoundName } from 'utils/woundImages';

import Loader from 'components/loader';
import LogForm from 'components/log-form';
import Input from 'components/forms/input';
import SingleChoice from 'components/forms/single-choice';
import { AddWoundButton } from 'components/compliance-questions/ComplianceQuestions.styles';

import VluImageDisplay from '../../vlu-images/vlu-image-display';

const WoundSizeForm = ({ formik, handleAddWound }) => {
  const { patientId } = useParams();
  const {
    doRequest: getWounds,
    data: wounds,
    isLoading,
  } = useRequest(api.getWounds);
  const { doRequest: getWoundsImages, data: woundsImages } = useRequest(
    api.getWoundImages,
  );

  useEffect(() => {
    getWounds(patientId);
  }, [getWounds, patientId]);

  useEffect(() => {
    if (formik.values.woundId) {
      getWoundsImages({ woundId: formik.values.woundId });
    }
  }, [getWoundsImages, formik.values.woundId]);

  const woundOptions = useMemo(() => {
    if (!wounds?.data) return [];

    return wounds.data.map((wound) => {
      return { label: getWoundName(wound), value: wound.id };
    });
  }, [wounds]);

  const imgRender = useMemo(
    () => (
      <VluImageDisplay
        url={
          'woundImage' in formik.values
            ? formik?.values?.woundImage?.asset?.url
            : woundsImages?.data[0]?.asset?.url
        }
        maskedUrl={
          'woundImage' in formik.values
            ? formik?.values?.woundImage?.maskedAsset?.url
            : woundsImages?.data[0]?.maskedAsset?.url
        }
        markerUrl={
          'woundImage' in formik.values
            ? formik?.values?.woundImage?.markerAsset?.url
            : woundsImages?.data[0]?.markerAsset?.url
        }
        size="normal"
      />
    ),
    [formik?.values, woundsImages?.data],
  );

  if (isLoading) return <Loader />;

  return (
    <LogForm title="Wound Size" rightColumn={imgRender}>
      <Grid item xs={12} style={{ height: 'fit-content' }}>
        <SingleChoice
          label="Please select a wound:"
          options={woundOptions}
          required
          {...getFieldProps({ formik, name: 'woundId' })}
        />
      </Grid>
      <Grid item xs={12}>
        <AddWoundButton onClick={handleAddWound}>+ ADD WOUND</AddWoundButton>
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Size of wound in cm²"
          type={INPUT_TYPES.NUMERIC}
          style={{ width: '50%' }}
          min={0}
          {...getFieldProps({ formik, name: 'size' })}
        />
      </Grid>
    </LogForm>
  );
};

WoundSizeForm.propTypes = {
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    values: PropTypes.shape({
      woundId: PropTypes.string,
      size: PropTypes.number,
      woundImage: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string,
        }),
        maskedAsset: PropTypes.shape({
          url: PropTypes.string,
        }),
        markerAsset: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    errors: PropTypes.shape({
      woundId: PropTypes.string,
      size: PropTypes.number,
    }),
  }),
  handleAddWound: PropTypes.func.isRequired,
};

export default WoundSizeForm;
