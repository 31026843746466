import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { yesNoOptions } from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';

const fields = {
  ULCER_STATUS: 'ulcerStatus',
  PREVIOUS_ULCERS_HEALED: 'haveAllPreviouslyActiveUlcersHealed',
  HEALING_DATE: 'dateOfCompleteHealing',
  RECURRENT_DISEASE: 'isRecurrentDisease',
  RECURRENCE_DATE: 'dateOfRecurrence',
  CAPTURE_INFO: 'captureInformation',
};

const ulcerStatusOptions = [
  { value: 'Healing', label: 'Healing' },
  { value: 'Healed', label: 'Healed' },
  { value: 'NoHealing', label: 'No Healing' },
  { value: 'Worsening', label: 'Worsening' },
  { value: 'Recurrence', label: 'Recurrence' },
];

export const FollowUpVLU = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>VLU healing and recurrence tracking</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Chips
          label="Ulcer status (overall)"
          options={ulcerStatusOptions}
          {...getFieldProps({
            formik,
            name: fields.ULCER_STATUS,
            disabled,
          })}
        />
      </Grid>
    </Grid>
    <FormSectionTitle>Healing</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={8}>
        <Chips
          label="Have ALL previously active ulcers healed?"
          options={yesNoOptions}
          {...getFieldProps({
            formik,
            name: fields.PREVIOUS_ULCERS_HEALED,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.PREVIOUS_ULCERS_HEALED]: { $set: value },
                    [fields.HEALING_DATE]: { $set: null },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {formik.values[fields.PREVIOUS_ULCERS_HEALED] && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Date of complete healing"
            {...getFieldProps({
              formik,
              name: fields.HEALING_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
    </Grid>
    <FormSectionTitle>Recurrence</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={8}>
        <Chips
          label="Has a previously fully healed patient developed one or more new VLU(s)?"
          options={yesNoOptions}
          {...getFieldProps({
            formik,
            name: fields.RECURRENT_DISEASE,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.RECURRENT_DISEASE]: { $set: value },
                    [fields.RECURRENCE_DATE]: { $set: null },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {formik.values[fields.RECURRENT_DISEASE] && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Date of recurrence"
            {...getFieldProps({
              formik,
              name: fields.RECURRENCE_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
    </Grid>
  </>
);

export default FollowUpVLU;

FollowUpVLU.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
