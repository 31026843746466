import React from 'react';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import api from 'core/api';
import useAccount from 'hooks/useAccount';
import useFeedback from 'hooks/useFeedback';
import { AssetTypeEnum } from 'utils/enums';
import { getErrorDetails, getErrorMessage } from 'utils/error';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Template from 'pages/system-administrator/template';
import Input from 'components/forms/input';
import FileUpload from 'components/forms/file-upload';
import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

const validationSchema = Yup.object({
  assetId: Yup.string().nullable(),
  primaryColor: Yup.string().nullable(),
  primaryDarkColor: Yup.string().nullable(),
  primaryLightColor: Yup.string().nullable(),
});

const System = () => {
  const [disabled, setDisabled] = React.useState(true);
  const { accountDetails, accountDetailsLoading } = useAccount();
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();

  const handleSubmit = (values) => {
    clearFeedback();
    api
      .updateAccount(values)
      .then(() => {
        setSuccessFeedback('Updated with success');
        setDisabled(true);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  if (accountDetailsLoading) return <Loader />;

  return (
    <Template
      title="System Settings"
      actions={
        disabled && (
          <Button
            variant="contained"
            onClick={() => setDisabled(false)}
            data-test="edit-form-button"
          >
            Edit
          </Button>
        )
      }
    >
      <FormWrapper
        initialValues={accountDetails}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        disabled={disabled}
        onCancel={() => setDisabled(true)}
        enableReinitialize
      >
        {({ formik }) => (
          <form onSubmit={formik.handleSubmit}>
            <FormSectionTitle>Account Logo</FormSectionTitle>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12}>
                <FileUpload
                  label="Account Logo Upload"
                  type={AssetTypeEnum.ACCOUNT}
                  {...getFieldProps({
                    formik,
                    name: 'assetId',
                    disabled,
                  })}
                />
              </Grid>
            </Grid>
            <FormSectionTitle>Look and Feel</FormSectionTitle>
            <Grid container spacing={2} sx={{ py: 2, pb: 0 }}>
              <Grid item xs={12} md={4}>
                <Input
                  label="Primary Color"
                  placeholder="#000000"
                  {...getFieldProps({
                    formik,
                    name: 'primaryColor',
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Primary Light Color"
                  placeholder="#000000"
                  {...getFieldProps({
                    formik,
                    name: 'primaryLightColor',
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Primary Dark Color"
                  placeholder="#000000"
                  {...getFieldProps({
                    formik,
                    name: 'primaryDarkColor',
                    disabled,
                  })}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </FormWrapper>
    </Template>
  );
};

export default System;
