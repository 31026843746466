import styled from '@emotion/styled';

import IconButton from '@mui/material/IconButton';

export const TrackTimeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 1rem',
  border: '1px solid' + theme.palette.primary.main,
  borderRadius: '0.25rem',
  color: theme.palette.base.black,
}));

export const PlayStopButton = styled(IconButton)(({ theme, stop }) => ({
  marginLeft: '0.5rem',
  padding: '0.5rem',
  backgroundColor: stop ? theme.palette.error.main : theme.palette.primary.main,
  color: theme.palette.base.white,

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '& svg': {
    fontSize: '1.5rem',
  },
}));
