import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const StyledControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '0.5rem',

    '& button': {
      width: '100%',
    },
  },
}));
