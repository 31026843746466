import get from 'lodash/get';

import { mapEnumToOptions, DiseaseLabels } from 'utils/enums';
import { getFieldError } from 'utils/error';

export const normalizeOptions = (options) =>
  (options ?? []).map((option) => ({
    value: option.id,
    label: option.name,
  }));

export const normalizeDiseases = (diseases) =>
  mapEnumToOptions({
    data: diseases ?? {},
    labels: DiseaseLabels,
  });

export const getFieldProps = ({
  formik: { handleChange, handleBlur, values, touched, errors },
  name,
  disabled,
  changeOverride,
}) => {
  return {
    name,
    value: get(values, name),
    onChange: (e) => {
      handleChange(e);
      if (changeOverride) changeOverride(e.target.value);
    },
    onBlur: handleBlur,
    error: getFieldError({ name, touched, errors }),
    disabled,
  };
};
