import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import {
  INPUT_TYPES,
  NONE_VALUE,
  OTHER_VALUE,
  yesNoOptions,
} from 'utils/constants';
import { sideOptions } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle, FormSubSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';

const spanishOriginsOptions = [
  { value: 'No', label: 'No' },
  {
    value: 'YesMexicanMexicanAmChicano',
    label: 'Yes, Mexican, Mexican Am., Chicano',
  },
  { value: 'YesPuertoRican', label: 'Yes, Puerto Rican' },
  { value: 'YesCuban', label: 'Yes, Cuban' },
  {
    value: 'YesAnotherHispanicLatinoSpanish',
    label: 'Yes, another Hispanic, Latino, or Spanish Origin (Specify)',
  },
  { value: 'PreferNotToSay', label: 'I prefer not to say' },
];

const raceOptions = [
  { value: 'White', label: 'White' },
  { value: 'BlackOrAfricanAmerican', label: 'Black or African American' },
  {
    value: 'AmericanIndianOrAlaskaNative',
    label: 'American Indian or Alaska Native',
  },
  { value: 'AsianOrAsianAmerican', label: 'Asian or Asian American' },
  {
    value: 'NativeHawaiianOrOtherPacificIslander',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  { value: 'PreferNotToSay', label: 'Prefer not to say' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const employmentStatusOptions = [
  { value: 'FullTimeEmployed', label: 'Full-time employed' },
  { value: 'PartTimeEmployed', label: 'Part-time employed' },
  {
    value: 'UnemployedLookingForWork',
    label: 'Unemployed / Looking for work',
  },
  {
    value: 'UnemployedNotLookingForWork',
    label: 'Unemployed / Not looking for work',
  },
  { value: 'Student', label: 'Student' },
  { value: 'Retired', label: 'Retired' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const historyOfCovid19Options = [
  { value: 'No', label: 'No' },
  { value: 'YesActive', label: 'Yes, Active' },
  { value: 'YesPrevious', label: 'Yes, Previous' },
];

const vaccinatedForCovid19Options = [
  { value: 'No', label: 'No' },
  { value: 'YesPartiallyVaccinated', label: 'Yes, Partially Vaccinated' },
  { value: 'YesFullyVaccinated', label: 'Yes, Fully Vaccinated' },
];

const vaccinationReceivedOptions = [
  { value: 'PfizerBioNTech ', label: 'Pfizer-BioNTech ' },
  { value: 'Moderna ', label: 'Moderna ' },
  { value: 'JohnsonAndJohnsonsJanssen', label: "Johnson & Johnson's Janssen" },
  { value: 'Other', label: 'Other' },
];

const FeetInches = 'FeetInches';
const Centimeters = 'Centimeters';

const heightTypeOptions = [
  { value: FeetInches, label: 'Feet, Inches' },
  { value: Centimeters, label: Centimeters },
];

const Pounds = 'Pounds';
const Kilograms = 'Kilograms';

const weightTypeOptions = [
  { value: Pounds, label: Pounds },
  { value: Kilograms, label: Kilograms },
];

const bloodTypeOptions = [
  { value: 'OPlus', label: 'O+' },
  { value: 'OMinus', label: 'O-' },
  { value: 'APlus', label: 'A+' },
  { value: 'AMinus', label: 'A-' },
  { value: 'BPlus', label: 'B+' },
  { value: 'BMinus', label: 'B-' },
  { value: 'ABPlus', label: 'AB+' },
  { value: 'ABMinus', label: 'AB-' },
  { value: 'Unknown', label: 'Unknown' },
];

const currentUseOrHistoryOfHormoneTherapyOptions = [
  { value: NONE_VALUE, label: NONE_VALUE },
  { value: 'PreviousHRT', label: 'Previous HRT' },
  { value: 'CurrentHRT', label: 'Current HRT' },
  {
    value: 'PreviousOralContraceptives',
    label: 'Previous Oral Contraceptives',
  },
  { value: 'CurrentOralContraceptives', label: 'Current Oral Contraceptives' },
];

const historyBloodRelatedMedicalConditionsOptions = [
  { value: 'AntiphospholipidSyndrome', label: 'Antiphospholipid Syndrome' },
  { value: 'SickleCellAnemia', label: 'Sickle Cell Anemia' },
  { value: 'NonSickleCellAnemia', label: 'Non-Sickle Cell Anemia' },
  {
    value: 'CurrentUseOfSteroidTherapy',
    label: 'Current Use of Steroid Therapy',
  },
  {
    value: 'KnownContraindicationToThrombolysis',
    label: 'Known Contraindication to Thrombolysis',
  },
];

const historyMedicalConditionsOptions = [
  { value: 'Obesity', label: 'Obesity' },
  { value: 'Hypertension', label: 'Hypertension' },
  { value: 'SevereMigraines', label: 'Severe Migraines' },
  { value: 'Arthritis', label: 'Arthritis' },
  { value: 'HeartFailure', label: 'Heart Failure' },
  {
    value: 'CoronaryArteryDiseaseOrAnginaOrMyocardialInfarction',
    label: 'Coronary Artery Disease / Angina / Myocardial Infarction',
  },
  { value: 'StrokeCVATIA', label: 'Stroke (CVA / TIA)' },
  { value: 'LiverFailure', label: 'Liver Failure' },
  {
    value: 'ReducedRenalFunctionOrFailure',
    label: 'Reduced Renal Function / Failure',
  },
];

const OtherThrombophilia = 'OtherThrombophilia';

const thrombophiliaOptions = [
  { value: 'FactorVLeiden', label: 'Factor V Leiden' },
  { value: OtherThrombophilia, label: 'Other Thrombophilia (Specify)' },
];

const YesType2 = 'YesType2';

const diabetesOptions = [
  { value: 'No', label: 'No' },
  { value: 'YesType1', label: 'Yes, Type 1' },
  { value: YesType2, label: 'Yes, Type 2' },
];

const useFrequencyOptions = (hasPassiveExposure = false) => {
  const options = [
    { value: 'CurrentEveryDay', label: 'Current Every Day' },
    { value: 'CurrentSomeDays', label: 'Current Some Days' },
    { value: 'Rarely', label: 'Rarely' },
    { value: 'Former', label: 'Former' },
    { value: 'Unknown', label: 'Unknown' },
    { value: 'Never', label: 'Never' },
  ];
  if (hasPassiveExposure)
    options.splice(4, 0, {
      value: 'PassiveExposure',
      label: 'Passive Exposure',
    });
  return options;
};

const typeOfTobaccoNicotineUseOptions = [
  { value: 'Cigarettes', label: 'Cigarettes' },
  { value: 'ECigarettes', label: 'e-Cigarettes' },
  { value: 'Cigars', label: 'Cigars' },
  { value: 'ChewingTobacco', label: 'Chewing Tobacco' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const typeOfMarijuanaUseOptions = [
  { value: 'SmokingBud', label: 'Smoking (Bud)' },
  { value: 'SmokingConcentrate', label: 'Smoking (Concentrate)' },
  { value: 'SmokingVaporizer', label: 'Smoking (Vaporizer)' },
  { value: 'IngestionEdible', label: 'Ingestion (Edible)' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const activityOptions = [
  { value: 'Weekly0', label: '0x / week' },
  { value: 'Weekly1To2', label: '1-2x / week' },
  { value: 'Weekly3To4', label: '3-4x / week' },
  { value: 'Weekly5Plus', label: '5+x / week' },
];

const sleepLocationOptions = [
  { value: 'Bed', label: 'Bed' },
  { value: 'Chair', label: 'Chair' },
  { value: 'Couch', label: 'Couch' },
  { value: 'Settee', label: 'Settee' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const fields = {
  SPANISH_ORIGINS: 'hispanicLatinoOrSpanishOrigin',
  SPANISH_ORIGINS_OTHER: 'hispanicLatinoOrSpanishOriginOther',
  RACE: 'race',
  RACE_OTHER: 'raceOther',
  EMPLOYMENT_STATUS: 'employmentStatus',
  EMPLOYMENT_STATUS_OTHER: 'employmentStatusOther',

  HISTORY_OF_COVID19: 'historyOfCOVID19',
  DATE_OF_FIRST_COVID_19_SYMPTOMS: 'dateOfFirstCOVID19Symptoms',
  VACCINATED_FOR_COVID19: 'vaccinatedForCOVID19',
  DATE_OF_FIRST_VACCINATION: 'dateOfFirstVaccination',
  VACCINATION_RECEIVED: 'vaccinationReceived',
  RECEIVED_COVID19_BOOSTER: 'receivedCOVID19Booster',
  DATE_OF_BOOSTER: 'dateOfBooster',
  BOOSTER_RECEIVED: 'boosterReceived',

  HISTORY_OF_DEEP_VEIN_THROMBOSIS: 'historyOfDeepVeinThrombosis',
  HISTORY_OF_DEEP_VEIN_THROMBOSIS_DATE: 'estimateDateOfDeepVeinThrombosis',
  HISTORY_OF_CURRENT_CHRONIC_VENOUS_DISEASE:
    'historyOfOrCurrentChronicVenousDisease',
  HISTORY_OF_OR_CURRENT_SUPERFICIAL_VEIN_INSUFFICIENCY:
    'historyOfOrCurrentSuperficialVeinInsufficiency',
  HISTORY_OF_PREVIOUS_HEALED_LEG_ULCER: 'historyOfPreviousHealedUlcers',
  FIXED_ANKLE_JOINT: 'fixedAnkleJoint',
  PELVIC_CONGESTION_SYNDROME_OR_VEIN_INCOMPETENCE:
    'pelvicCongestionSyndromeOrVeinIncompetence',
  FAMILY_HISTORY_OF_VTE: 'familyHistoryOfVTE',

  HEIGHT_TYPE: 'heightType',
  HEIGHT_FEET: 'heightFt',
  HEIGHT_INCHES: 'heightIn',
  HEIGHT_CENTIMETERS: 'heightCm',
  WEIGHT_TYPE: 'weightType',
  WEIGHT_POUNDS: 'weightLbs',
  WEIGHT_KILOGRAMS: 'weightKgs',
  BLOOD_TYPE: 'bloodType',
  CURRENTLY_PREGNANT: 'currentlyPregnant',
  CURRENT_USE_OR_HISTORY_OF_HORMONE_THERAPY:
    'currentUseOrHistoryOfHormoneTherapy',
  HISTORY_BLOOD_RELATED_MEDICAL_CONDITIONS:
    'bloodRelatedMedicalConditionsHistory',
  HISTORY_MEDICAL_CONDITIONS: 'medicalConditions',
  THROMBOPHILIA: 'thrombophilia',
  THROMBOPHILIA_OTHER: 'thrombophiliaOther',
  PERIPHERAL_ARTERY_DISEASE: 'peripheralArteryDisease',
  CRITICAL_LIMB_ISCHEMIA: 'criticalLimbIschemia',
  DIABETES: 'diabetes',
  DIABETES_DATE: 'diabetesType2Date',
  INTERMITTENT_CLAUDICATION: 'intermittentClaudication',
  PERSISTENT_LEG_PAIN_AT_REST: 'persistentLegPainAtRest',
  LYMPHOEDEMA: 'lymphoedema',
  CELLULITIS: 'cellulitis',
  UTERINE_FIBROIDS: 'uterineFibroids',
  PELVIC_INFLAMMATORY_DISEASE: 'pelvicInflammatoryDisease',
  OTHER_DISEASE: 'other',

  PRIOR_SVI_PROCEDURE: 'priorSVIProcedure',
  PRIOR_SVI_PROCEDURE_DATE: 'estimateDateOfMostRecentSVIProcedure',
  PRIOR_VENOUS_ANGIOPLASTY: 'priorVenousAngioplasty',
  PRIOR_VENOUS_ANGIOPLASTY_DATE:
    'estimateDateOfMostRecentVenousAngioplastProcedure',
  PRIOR_VENOUS_STENTING: 'priorVenousStenting',
  PRIOR_VENOUS_STENTING_DATE: 'estimateDateOfMostRecentVenousStentingProcedure',
  PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING:
    'priorPeripheralArteryAngioplastyOrStenting',
  PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING_DATE:
    'estimateDateOfMostRecentPAAOrStentingProcedure',
  PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY: 'priorPeripheralArteryAtherectomy',
  PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY_DATE:
    'estimateDateOfPriorPeripheralArteryAtherectomy',
  RECENT_90_DAY_HIP_OR_KNEE_SURGERY: 'recent90DayHipOrKneeSurgery',
  RECENT_90_DAY_HIP_OR_KNEE_SURGERY_DATE:
    'estimateDateOfRecent90DayHipOrKneeSurgery',
  OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE:
    'otherPriorPeripheralVascularProcedure',
  OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE_DATE:
    'estimateDateOfOtherPriorPeripheralVascularProcedure',

  TOBACCO_NICOTINE_USE: 'tobaccoNicotineUse',
  TYPE_OF_TOBACCO_NICOTINE_USE: 'typeOfTobaccoNicotineUse',
  TOBACCO_NICOTINE_UNITS_PER_DAY: 'tobaccoOrNicotineUnitsPerDay',
  TOBACCO_NICOTINE_YEARS_OF_USE: 'tobaccoOrNicotineYearsOfUse',
  TOBACCO_NICOTINE_DATE_WHEN_USE_CEASED: 'dateWhenTobaccoOrNicotineUseCeased',
  ALCOHOL_USE: 'alcoholUse',
  ALCOHOL_UNITS_PER_WEEK: 'alcoholUnitsPerWeek',
  ALCOHOL_DATE_WHEN_USE_CEASED: 'dateWhenAlcoholUseCeased',
  MARIJUANA_USE: 'marijuanaUse',
  TYPE_OF_MARIJUANA_USE: 'marijuanaUseType',
  MARIJUANA_USE_PER_DAY: 'marijuanaUsePerDay',
  MARIJUANA_YEARS_OF_USE: 'marijuanaYearsOfUse',
  MARIJUANA_DATE_WHEN_USE_CEASED: 'dateWhenMarijuanaUseCeased',
  RECREATIONAL_DRUG_USE: 'recreationalDrugUse',
  RECREATIONAL_DRUG_DESCRIBE: 'describeRecreationalDrugUse',
  RECREATIONAL_DRUG_DATE_WHEN_USE_CEASED: 'dateWhenRecreationalDrugUseCeased',
  IV_DRUG_USE: 'ivDrugUse',
  IV_DRUG_DESCRIBE: 'describeIvDrugUse',
  IV_DRUG_DATE_WHEN_USE_CEASED: 'dateWhenIvDrugUseCeased',

  MODERATE_TO_VIGOROUS_WEEKLY_ACTIVITY: 'moderateToVigorousWeeklyActivity',
  SLEEP_HOURS_PER_NIGHT: 'sleepHoursPerNight',
  SLEEP_HOURS_LOCATION: 'locationsOfSleep',
  SLEEP_HOURS_LOCATION_OTHER: 'locationOther',
};

export const MedicalStatus = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>Demographics</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Chips
          label="Are you of Hispanic, Latino, or Spanish Origin?"
          options={spanishOriginsOptions}
          isRequired
          otherField={{
            name: fields.SPANISH_ORIGINS_OTHER,
            value: 'YesAnotherHispanicLatinoSpanish',
          }}
          {...getFieldProps({
            formik,
            name: fields.SPANISH_ORIGINS,
            disabled,
            changeOverride: (value) => {
              if (value === 'YesAnotherHispanicLatinoSpanish') {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.SPANISH_ORIGINS]: { $set: value },
                    [fields.SPANISH_ORIGINS_OTHER]: { $set: '' },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Chips
          label="Race"
          options={raceOptions}
          isRequired
          isMultiple
          otherField={{
            name: fields.RACE_OTHER,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: fields.RACE,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.RACE]: { $set: value },
                    [fields.RACE_OTHER]: { $set: '' },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Chips
          label="Employment Status"
          options={employmentStatusOptions}
          isRequired
          otherField={{
            name: fields.EMPLOYMENT_STATUS_OTHER,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: fields.EMPLOYMENT_STATUS,
            disabled,
            changeOverride: (value) => {
              if (value === OTHER_VALUE) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.EMPLOYMENT_STATUS]: { $set: value },
                    [fields.EMPLOYMENT_STATUS_OTHER]: { $set: '' },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
    </Grid>
    <FormSectionTitle>COVID-19</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FormSubSectionTitle>COVID-19 Status</FormSubSectionTitle>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="History of COVID-19 (Active or History)"
            options={historyOfCovid19Options}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.HISTORY_OF_COVID19,
              disabled,
              changeOverride: (value) => {
                if (value === 'No') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.HISTORY_OF_COVID19]: { $set: value },
                      [fields.DATE_OF_FIRST_COVID_19_SYMPTOMS]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['No', null, undefined].includes(
          formik.values[fields.HISTORY_OF_COVID19],
        ) && (
          <Grid item xs={12} md={4}>
            <DateSelector
              label="Date of First COVID-19 Symptoms"
              isRequired
              {...getFieldProps({
                formik,
                name: fields.DATE_OF_FIRST_COVID_19_SYMPTOMS,
                disabled,
              })}
            />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Vaccinated for COVID-19?"
            options={vaccinatedForCovid19Options}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.VACCINATED_FOR_COVID19,
              disabled,
              changeOverride: (value) => {
                if (value === 'No') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.VACCINATED_FOR_COVID19]: { $set: value },
                      [fields.DATE_OF_FIRST_VACCINATION]: { $set: null },
                      [fields.VACCINATION_RECEIVED]: { $set: null },
                      [fields.RECEIVED_COVID19_BOOSTER]: { $set: null },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['No', null, undefined].includes(
          formik.values[fields.VACCINATED_FOR_COVID19],
        ) && (
          <>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date of First Vaccination"
                isRequired
                {...getFieldProps({
                  formik,
                  name: fields.DATE_OF_FIRST_VACCINATION,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Chips
                label="Vaccination Received"
                options={vaccinationReceivedOptions}
                isRequired
                {...getFieldProps({
                  formik,
                  name: fields.VACCINATION_RECEIVED,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Chips
                label="Received COVID-19 Booster?"
                options={yesNoOptions}
                isRequired
                {...getFieldProps({
                  formik,
                  name: fields.RECEIVED_COVID19_BOOSTER,
                  disabled,
                  changeOverride: (value) => {
                    if (value) {
                      formik?.setValues(
                        update(formik?.values, {
                          [fields.RECEIVED_COVID19_BOOSTER]: {
                            $set: value,
                          },
                          [fields.DATE_OF_BOOSTER]: { $set: null },
                          [fields.BOOSTER_RECEIVED]: { $set: null },
                        }),
                      );
                    }
                  },
                })}
              />
            </Grid>
            {formik.values[fields.RECEIVED_COVID19_BOOSTER] && (
              <>
                <Grid item xs={12} md={4}>
                  <DateSelector
                    label="Date of Booster"
                    isRequired
                    {...getFieldProps({
                      formik,
                      name: fields.DATE_OF_BOOSTER,
                      disabled,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Chips
                    label="Booster Received"
                    options={vaccinationReceivedOptions}
                    isRequired
                    {...getFieldProps({
                      formik,
                      name: fields.BOOSTER_RECEIVED,
                      disabled,
                    })}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
    <FormSectionTitle>VLU Risk Factors</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <Chips
          label="History of Deep Vein Thrombosis (DVT)"
          options={sideOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.HISTORY_OF_DEEP_VEIN_THROMBOSIS,
            disabled,
            changeOverride: (value) => {
              if (value === 'No') {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.HISTORY_OF_DEEP_VEIN_THROMBOSIS]: {
                      $set: value,
                    },
                    [fields.HISTORY_OF_DEEP_VEIN_THROMBOSIS_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.HISTORY_OF_DEEP_VEIN_THROMBOSIS],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date"
            isRequired
            {...getFieldProps({
              formik,
              name: fields.HISTORY_OF_DEEP_VEIN_THROMBOSIS_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="History of or Current Chronic Venous Disease"
          options={sideOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.HISTORY_OF_CURRENT_CHRONIC_VENOUS_DISEASE,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="History of or Current Superficial Vein Insufficiency"
          options={sideOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.HISTORY_OF_OR_CURRENT_SUPERFICIAL_VEIN_INSUFFICIENCY,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="History of Previous (Healed) Leg Ulcer"
          options={sideOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.HISTORY_OF_PREVIOUS_HEALED_LEG_ULCER,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="Fixed ankle joint"
          options={sideOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.FIXED_ANKLE_JOINT,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="History of or Current Pelvic Congestion Syndrome / Pelvic Vein Incompetence"
          options={yesNoOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.PELVIC_CONGESTION_SYNDROME_OR_VEIN_INCOMPETENCE,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="Family history of VTE"
          options={yesNoOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.FAMILY_HISTORY_OF_VTE,
            disabled,
          })}
        />
      </Grid>
    </Grid>
    <FormSectionTitle>Medical status</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Height Type"
            options={heightTypeOptions}
            {...getFieldProps({
              formik,
              name: fields.HEIGHT_TYPE,
              disabled,
              changeOverride: (value) => {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.HEIGHT_TYPE]: { $set: value },
                    [fields.HEIGHT_FEET]: { $set: null },
                    [fields.HEIGHT_INCHES]: { $set: null },
                    [fields.HEIGHT_CENTIMETERS]: { $set: null },
                  }),
                );
              },
            })}
          />
        </Grid>
        {formik.values[fields.HEIGHT_TYPE] === FeetInches && (
          <>
            <Grid item xs={12} md={4}>
              <Input
                label="Height (ft)"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.HEIGHT_FEET,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Height (in)"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.HEIGHT_INCHES,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
        {formik.values[fields.HEIGHT_TYPE] === Centimeters && (
          <Grid item xs={12} md={4}>
            <Input
              label="Height (cm)"
              type={INPUT_TYPES.NUMERIC}
              {...getFieldProps({
                formik,
                name: 'heightCm',
                disabled,
              })}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Weight Type"
            options={weightTypeOptions}
            {...getFieldProps({
              formik,
              name: fields.WEIGHT_TYPE,
              disabled,
              changeOverride: (value) => {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.WEIGHT_TYPE]: { $set: value },
                    [fields.WEIGHT_POUNDS]: { $set: null },
                    [fields.WEIGHT_KILOGRAMS]: { $set: null },
                  }),
                );
              },
            })}
          />
        </Grid>
        {formik.values[fields.WEIGHT_TYPE] === Pounds && (
          <Grid item xs={12} md={4}>
            <Input
              label="Weight (lbs)"
              type={INPUT_TYPES.NUMERIC}
              {...getFieldProps({
                formik,
                name: fields.WEIGHT_POUNDS,
                disabled,
              })}
            />
          </Grid>
        )}
        {formik.values[fields.WEIGHT_TYPE] === Kilograms && (
          <Grid item xs={12} md={4}>
            <Input
              label="Weight (kgs)"
              type={INPUT_TYPES.NUMERIC}
              {...getFieldProps({
                formik,
                name: fields.WEIGHT_KILOGRAMS,
                disabled,
              })}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Blood type"
            options={bloodTypeOptions}
            {...getFieldProps({
              formik,
              name: fields.BLOOD_TYPE,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Currently pregnant"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.CURRENTLY_PREGNANT,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Current Use or History of Hormone Therapy"
            options={currentUseOrHistoryOfHormoneTherapyOptions}
            {...getFieldProps({
              formik,
              name: fields.CURRENT_USE_OR_HISTORY_OF_HORMONE_THERAPY,
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Chips
            label="Known History of Any of the Following Blood-Related Medical Conditions"
            options={historyBloodRelatedMedicalConditionsOptions}
            isMultiple
            {...getFieldProps({
              formik,
              name: fields.HISTORY_BLOOD_RELATED_MEDICAL_CONDITIONS,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Chips
            label="Known History of Any of the Following Medical Conditions"
            options={historyMedicalConditionsOptions}
            isMultiple
            {...getFieldProps({
              formik,
              name: fields.HISTORY_MEDICAL_CONDITIONS,
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Thrombophilia"
            options={thrombophiliaOptions}
            otherField={{
              name: fields.THROMBOPHILIA_OTHER,
              value: OtherThrombophilia,
            }}
            {...getFieldProps({
              formik,
              name: fields.THROMBOPHILIA,
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OtherThrombophilia)) {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.THROMBOPHILIA]: { $set: value },
                      [fields.THROMBOPHILIA_OTHER]: { $set: '' },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Peripheral Artery Disease"
            options={sideOptions}
            {...getFieldProps({
              formik,
              name: fields.PERIPHERAL_ARTERY_DISEASE,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Critical Limb Ischemia"
            options={sideOptions}
            {...getFieldProps({
              formik,
              name: fields.CRITICAL_LIMB_ISCHEMIA,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Diabetes"
            options={diabetesOptions}
            {...getFieldProps({
              formik,
              name: fields.DIABETES,
              disabled,
              changeOverride: (value) => {
                if (value !== YesType2) {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.DIABETES]: { $set: value },
                      [fields.DIABETES_DATE]: { $set: null },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {formik.values[fields.DIABETES] === YesType2 && (
          <Grid item xs={12} md={4}>
            <DateSelector
              label="When was the date of onset?"
              {...getFieldProps({
                formik,
                name: fields.DIABETES_DATE,
                disabled,
              })}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Chips
            label="Intermittent Claudication (Pain when walking)"
            options={sideOptions}
            {...getFieldProps({
              formik,
              name: fields.INTERMITTENT_CLAUDICATION,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Persistent Leg Pain at Rest"
            options={sideOptions}
            {...getFieldProps({
              formik,
              name: fields.PERSISTENT_LEG_PAIN_AT_REST,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Lymphoedema"
            options={sideOptions}
            {...getFieldProps({
              formik,
              name: fields.LYMPHOEDEMA,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Cellulitis"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.CELLULITIS,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Uterine Fibroids"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.UTERINE_FIBROIDS,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="PID (Pelvic Inflammatory Disease)"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: fields.PELVIC_INFLAMMATORY_DISEASE,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Other Disease (Specify)"
            type={INPUT_TYPES.COMMENT}
            {...getFieldProps({
              formik,
              name: fields.OTHER_DISEASE,
              disabled,
            })}
          />
        </Grid>
      </Grid>
    </Grid>
    <FormSectionTitle>Relevant Procedure History</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <Chips
          label="Prior SVI Procedure (e.g., Ablation)"
          options={sideOptions}
          {...getFieldProps({
            formik,
            name: fields.PRIOR_SVI_PROCEDURE,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.PRIOR_SVI_PROCEDURE]: {
                      $set: value,
                    },
                    [fields.PRIOR_SVI_PROCEDURE_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.PRIOR_SVI_PROCEDURE],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.PRIOR_SVI_PROCEDURE_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="Prior Venous Angioplasty"
          options={sideOptions}
          {...getFieldProps({
            formik,
            name: fields.PRIOR_VENOUS_ANGIOPLASTY,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.PRIOR_VENOUS_ANGIOPLASTY]: {
                      $set: value,
                    },
                    [fields.PRIOR_VENOUS_ANGIOPLASTY_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.PRIOR_VENOUS_ANGIOPLASTY],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.PRIOR_VENOUS_ANGIOPLASTY_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="Prior Venous Stenting"
          options={sideOptions}
          {...getFieldProps({
            formik,
            name: fields.PRIOR_VENOUS_STENTING,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.PRIOR_VENOUS_STENTING]: {
                      $set: value,
                    },
                    [fields.PRIOR_VENOUS_STENTING_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.PRIOR_VENOUS_STENTING],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.PRIOR_VENOUS_STENTING_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="Prior Peripheral Artery Angioplasty/Stenting"
          options={sideOptions}
          {...getFieldProps({
            formik,
            name: fields.PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING]: {
                      $set: value,
                    },
                    [fields.PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING_DATE]:
                      {
                        $set: null,
                      },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.PRIOR_PERIPHERAL_ARTERY_ANGIOPLASTY_OR_STENTING_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="Prior Peripheral Artery Atherectomy"
          options={sideOptions}
          {...getFieldProps({
            formik,
            name: fields.PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY]: {
                      $set: value,
                    },
                    [fields.PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.PRIOR_PERIPHERAL_ARTERY_ATHERECTOMY_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="Recent (90 day) Hip or Knee Surgery"
          options={sideOptions}
          {...getFieldProps({
            formik,
            name: fields.RECENT_90_DAY_HIP_OR_KNEE_SURGERY,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.RECENT_90_DAY_HIP_OR_KNEE_SURGERY]: {
                      $set: value,
                    },
                    [fields.RECENT_90_DAY_HIP_OR_KNEE_SURGERY_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {!['No', null, undefined].includes(
        formik.values[fields.RECENT_90_DAY_HIP_OR_KNEE_SURGERY],
      ) && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.RECENT_90_DAY_HIP_OR_KNEE_SURGERY_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Chips
          label="Other Prior Peripheral Vascular Procedure"
          options={yesNoOptions}
          {...getFieldProps({
            formik,
            name: fields.OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE,
            disabled,
            changeOverride: (value) => {
              if (!value) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE]: {
                      $set: value,
                    },
                    [fields.OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE_DATE]: {
                      $set: null,
                    },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
      {formik.values[fields.OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE] && (
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Estimate Date of Most Recent Procedure"
            {...getFieldProps({
              formik,
              name: fields.OTHER_PRIOR_PERIPHERAL_VASCULAR_PROCEDURE_DATE,
              disabled,
            })}
          />
        </Grid>
      )}
    </Grid>
    <FormSectionTitle>Substance Use</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Tobacco/Nicotine Use"
            options={useFrequencyOptions(true)}
            {...getFieldProps({
              formik,
              name: fields.TOBACCO_NICOTINE_USE,
              disabled,
              changeOverride: (value) => {
                if (value === 'Never') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.TOBACCO_NICOTINE_USE]: { $set: value },
                      [fields.TYPE_OF_TOBACCO_NICOTINE_USE]: { $set: [] },
                      [fields.TOBACCO_NICOTINE_UNITS_PER_DAY]: {
                        $set: null,
                      },
                      [fields.TOBACCO_NICOTINE_YEARS_OF_USE]: {
                        $set: null,
                      },
                      [fields.TOBACCO_NICOTINE_DATE_WHEN_USE_CEASED]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['Never', null, undefined].includes(
          formik.values[fields.TOBACCO_NICOTINE_USE],
        ) && (
          <>
            <Grid item xs={12} md={4}>
              <Chips
                label="Type of Tobacco/Nicotine Use"
                options={typeOfTobaccoNicotineUseOptions}
                isMultiple
                {...getFieldProps({
                  formik,
                  name: fields.TYPE_OF_TOBACCO_NICOTINE_USE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Units Per Day"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.TOBACCO_NICOTINE_UNITS_PER_DAY,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Years of Use"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.TOBACCO_NICOTINE_YEARS_OF_USE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date When Use Ceased, if applicable"
                {...getFieldProps({
                  formik,
                  name: fields.TOBACCO_NICOTINE_DATE_WHEN_USE_CEASED,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Alcohol Use"
            options={useFrequencyOptions()}
            {...getFieldProps({
              formik,
              name: fields.ALCOHOL_USE,
              disabled,
              changeOverride: (value) => {
                if (value === 'Never') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.ALCOHOL_USE]: { $set: value },
                      [fields.ALCOHOL_UNITS_PER_WEEK]: { $set: null },
                      [fields.ALCOHOL_DATE_WHEN_USE_CEASED]: { $set: null },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['Never', null, undefined].includes(
          formik.values[fields.ALCOHOL_USE],
        ) && (
          <>
            <Grid item xs={12} md={4}>
              <Input
                label="Units per Week"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.ALCOHOL_UNITS_PER_WEEK,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date When Use Ceased, if applicable"
                {...getFieldProps({
                  formik,
                  name: fields.ALCOHOL_DATE_WHEN_USE_CEASED,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Marijuana Use"
            options={useFrequencyOptions(true)}
            {...getFieldProps({
              formik,
              name: fields.MARIJUANA_USE,
              disabled,
              changeOverride: (value) => {
                if (value === 'Never') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.MARIJUANA_USE]: { $set: value },
                      [fields.TYPE_OF_MARIJUANA_USE]: { $set: [] },
                      [fields.MARIJUANA_USE_PER_DAY]: {
                        $set: null,
                      },
                      [fields.MARIJUANA_YEARS_OF_USE]: {
                        $set: null,
                      },
                      [fields.MARIJUANA_DATE_WHEN_USE_CEASED]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['Never', null, undefined].includes(
          formik.values[fields.MARIJUANA_USE],
        ) && (
          <>
            <Grid item xs={12} md={4}>
              <Chips
                label="Type of Marijuana Use"
                options={typeOfMarijuanaUseOptions}
                isMultiple
                {...getFieldProps({
                  formik,
                  name: fields.TYPE_OF_MARIJUANA_USE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Units Per Day"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.MARIJUANA_USE_PER_DAY,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Years of Use"
                type={INPUT_TYPES.NUMERIC}
                {...getFieldProps({
                  formik,
                  name: fields.MARIJUANA_YEARS_OF_USE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date When Use Ceased, if applicable"
                {...getFieldProps({
                  formik,
                  name: fields.MARIJUANA_DATE_WHEN_USE_CEASED,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Recreational Drug Use"
            options={useFrequencyOptions()}
            {...getFieldProps({
              formik,
              name: fields.RECREATIONAL_DRUG_USE,
              disabled,
              changeOverride: (value) => {
                if (value === 'Never') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.RECREATIONAL_DRUG_USE]: { $set: value },
                      [fields.RECREATIONAL_DRUG_DESCRIBE]: { $set: null },
                      [fields.RECREATIONAL_DRUG_DATE_WHEN_USE_CEASED]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['Never', null, undefined].includes(
          formik.values[fields.RECREATIONAL_DRUG_USE],
        ) && (
          <>
            <Grid item xs={12} md={4}>
              <Input
                label="Please Describe"
                type={INPUT_TYPES.SHORT_TEXT}
                {...getFieldProps({
                  formik,
                  name: fields.RECREATIONAL_DRUG_DESCRIBE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date When Use Ceased, if applicable"
                {...getFieldProps({
                  formik,
                  name: fields.RECREATIONAL_DRUG_DATE_WHEN_USE_CEASED,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={4}>
          <Chips
            label="IV Drug Use"
            options={useFrequencyOptions()}
            {...getFieldProps({
              formik,
              name: fields.IV_DRUG_USE,
              disabled,
              changeOverride: (value) => {
                if (value === 'Never') {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.IV_DRUG_USE]: { $set: value },
                      [fields.IV_DRUG_DESCRIBE]: { $set: null },
                      [fields.IV_DRUG_DATE_WHEN_USE_CEASED]: { $set: null },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        {!['Never', null, undefined].includes(
          formik.values[fields.IV_DRUG_USE],
        ) && (
          <>
            <Grid item xs={12} md={4}>
              <Input
                label="Please Describe"
                type={INPUT_TYPES.SHORT_TEXT}
                {...getFieldProps({
                  formik,
                  name: fields.IV_DRUG_DESCRIBE,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateSelector
                label="Date When Use Ceased, if applicable"
                {...getFieldProps({
                  formik,
                  name: fields.IV_DRUG_DATE_WHEN_USE_CEASED,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
    <FormSectionTitle>Lifestyle Habits</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Chips
          label="How often do you get 20-30 minutes of moderate to vigorous activity?"
          options={activityOptions}
          {...getFieldProps({
            formik,
            name: fields.MODERATE_TO_VIGOROUS_WEEKLY_ACTIVITY,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Hours of sleep per night"
          type={INPUT_TYPES.NUMERIC}
          {...getFieldProps({
            formik,
            name: fields.SLEEP_HOURS_PER_NIGHT,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Chips
          label="Location"
          options={sleepLocationOptions}
          isMultiple
          otherField={{
            name: fields.SLEEP_HOURS_LOCATION_OTHER,
            value: OTHER_VALUE,
          }}
          {...getFieldProps({
            formik,
            name: fields.SLEEP_HOURS_LOCATION,
            disabled,
            changeOverride: (value) => {
              if (!value.includes(OTHER_VALUE)) {
                formik?.setValues(
                  update(formik?.values, {
                    [fields.SLEEP_HOURS_LOCATION]: { $set: value },
                    [fields.SLEEP_HOURS_LOCATION_OTHER]: { $set: '' },
                  }),
                );
              }
            },
          })}
        />
      </Grid>
    </Grid>
  </>
);

export default MedicalStatus;

MedicalStatus.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
