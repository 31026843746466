import React from 'react';

import Grid from '@mui/material/Grid';

import PathwayWidget from './pathway-widget';
import PatientInfoWidget from './patient-info-widget';
import PathwayTracker from './pathway-tracker';

const PatientHeader = () => (
  <Grid container spacing={2}>
    <Grid item lg={4} xs={12}>
      <PatientInfoWidget />
    </Grid>
    <Grid item lg={8} xs={12}>
      <PathwayTracker />
    </Grid>
    <Grid item xs={12}>
      <PathwayWidget />
    </Grid>
  </Grid>
);

export default PatientHeader;
