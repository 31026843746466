import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { InfoText } from './Toggle.styles';

const Toggle = ({ id, label, value, disabled, info, ...remainingProps }) => (
  <FormGroup disabled={disabled}>
    <FormControlLabel
      label={label}
      labelPlacement="top"
      disabled={disabled}
      style={{ margin: 0, alignItems: 'flex-start' }}
      control={
        <Box display="flex" alignItems="center">
          <Switch
            {...remainingProps}
            id={id}
            checked={value}
            disabled={disabled}
          />
          {info && <InfoText disabled={disabled}>{info}</InfoText>}
        </Box>
      }
    />
  </FormGroup>
);

Toggle.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  info: PropTypes.string,
};

export default Toggle;
