import { INPUT_TYPES } from 'utils/constants';

import {
  yesNoOptions,
  howSevereOptions,
  howOftenTimeOptions,
  howMuchOptions,
  getHowLimitedOptions,
  getOneYearAgoOptions,
} from './utils';

const generalHealthQuestionOptions = [
  {
    value: '1',
    label: 'Excellent',
  },
  {
    value: '2',
    label: 'Very Good',
  },
  {
    value: '3',
    label: 'Good',
  },
  {
    value: '4',
    label: 'Fair',
  },
  {
    value: '5',
    label: 'Poor',
  },
];

const workPainQuestionOptions = [
  {
    value: '1',
    label: 'Not at all',
  },
  {
    value: '2',
    label: 'A little bit',
  },
  {
    value: '3',
    label: 'Moderately',
  },
  {
    value: '4',
    label: 'Quite a bit',
  },
  {
    value: '5',
    label: 'Extremely',
  },
];

const socialActivitiesQuestionOptions = [
  {
    value: '1',
    label: 'All of the time',
  },
  {
    value: '2',
    label: 'Most of the time',
  },
  {
    value: '3',
    label: 'Some of the time',
  },
  {
    value: '4',
    label: 'A little bit of the time',
  },
  {
    value: '5',
    label: 'None of the time',
  },
];

const trueFalseOptions = [
  {
    value: '1',
    label: 'Definitely true',
  },
  {
    value: '2',
    label: 'Mostly true',
  },
  {
    value: '3',
    label: "Don't know",
  },
  {
    value: '4',
    label: 'Mostly false',
  },
  {
    value: '5',
    label: 'Definitely false',
  },
];

const activitiesFields = [
  {
    name: 'limitationVigorousActivities',
    label:
      '3. Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports',
  },
  {
    name: 'limitationModerateActivities',
    label:
      '4. Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf',
  },
  {
    name: 'limitationLiftingCarryingGroceries',
    label: '5. Lifting or carrying groceries',
  },
  {
    name: 'limitationClimbingSeveralFlightsOfStairs',
    label: '6. Climbing several flights of stairs',
  },
  {
    name: 'limitationClimbingOneFlightsOfStairs',
    label: '7. Climbing one flight of stairs',
  },
  {
    name: 'limitationBendingKneelingOrStooping',
    label: '8. Bending, kneeling, or stooping',
  },
  {
    name: 'limitationWalkingMoreThanAMile',
    label: '9. Walking more than a mile',
  },
  {
    name: 'limitationWalkingSeveralBlocks',
    label: '10. Walking several blocks',
  },
  {
    name: 'limitationWalkingOneBlocks',
    label: '11. Walking one block',
  },
  {
    name: 'limitationBathingOrDressing',
    label: '12. Bathing or dressing yourself',
  },
];

const physicalHealthFields = [
  {
    name: 'physicalHealthCutDownTimeSpentOnWork',
    label:
      '13. Cut down the amount of time you spent on work or other activities',
  },
  {
    name: 'physicalHealthAccomplishedLess',
    label: '14. Accomplished less than you would like',
  },
  {
    name: 'physicalHealthLimitedWork',
    label: '15. Were limited in the kind of work or other activities',
  },
  {
    name: 'physicalHealthDifficultyPerformingWork',
    label:
      '16. Had difficulty performing the work or other activities (for example, it took extra effort)',
  },
];

const emotionalHealthFields = [
  {
    name: 'emotionalProblemsCutDownTimeSpentOnWork',
    label:
      '17. Cut down the amount of time you spent on work or other activities',
  },
  {
    name: 'emotionalProblemsAccomplishedLess',
    label: '18. Accomplished less than you would like',
  },
  {
    name: 'emotionalProblemsLessCareful',
    label: "19. Didn't do work or other activities as carefully as usual",
  },
];

const moodFields = [
  {
    name: 'feelingFullOfPep',
    label: '23. Did you feel full of pep?',
  },
  {
    name: 'feelingNervous',
    label: '24. Have you been a very nervous person?',
  },
  {
    name: 'feelingDown',
    label:
      '25. Have you felt so down in the dumps that nothing could cheer you up?',
  },
  {
    name: 'feelingCalm',
    label: '26. Have you felt calm and peaceful?',
  },
  {
    name: 'feelingEnergetic',
    label: '27. Did you have a lot of energy?',
  },
  {
    name: 'feelingDownhearted',
    label: '28. Have you felt downhearted and blue?',
  },
  {
    name: 'feelingWornOut',
    label: '29. Did you feel worn out?',
  },
  {
    name: 'feelingHappy',
    label: '30. Have you been a happy person?',
  },
  {
    name: 'feelingTired',
    label: '31. Did you feel tired?',
  },
];

const trueFalseFields = [
  {
    name: 'statementGetSickEasily',
    label: '33. I seem to get sick a little easier than other people',
  },
  {
    name: 'statementHealthyAsAnybody',
    label: '34. I am as healthy as anybody I know',
  },
  {
    name: 'statementExpectWorseHealth',
    label: '35. I expect my health to get worse',
  },
  {
    name: 'statementExcelentHealth',
    label: '36. My health is excellent',
  },
];

export const SF36 = [
  [
    {
      name: 'generalHealth',
      label: '1. In general, would you say your health is:',
      type: INPUT_TYPES.RADIO,
      options: generalHealthQuestionOptions,
    },
    {
      name: 'generalHealthComparedToOneYearAgo',
      label:
        '2. Compared to one year ago, how would you rate your health in general now?',
      type: INPUT_TYPES.RADIO,
      options: getOneYearAgoOptions('SF36'),
    },
  ],
  [
    {
      label:
        'The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?',
      type: INPUT_TYPES.TABLE,
      options: getHowLimitedOptions(false),
      fields: activitiesFields,
    },
  ],
  [
    {
      label:
        'During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?',
      type: INPUT_TYPES.TABLE,
      options: yesNoOptions,
      fields: physicalHealthFields,
    },
  ],
  [
    {
      label:
        'During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?',
      type: INPUT_TYPES.TABLE,
      options: yesNoOptions,
      fields: emotionalHealthFields,
    },
  ],
  [
    {
      name: 'socialActivitiesInterference',
      label:
        '20. During the past 4 weeks, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?',
      type: INPUT_TYPES.RADIO,
      options: howMuchOptions,
    },
  ],
  [
    {
      name: 'bodilyPain',
      label: '21. How much bodily pain have you had during the past 4 weeks?',
      type: INPUT_TYPES.RADIO,
      options: howSevereOptions,
    },
    {
      name: 'bodilyPainWorkInterference',
      label:
        '22. During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?',
      type: INPUT_TYPES.RADIO,
      options: workPainQuestionOptions,
    },
  ],
  [
    {
      label:
        'These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling. How much of the time during the past 4 weeks...',
      type: INPUT_TYPES.TABLE,
      options: howOftenTimeOptions,
      fields: moodFields,
    },
  ],
  [
    {
      name: 'socialActivitiesInterferenceFrequency',
      label:
        '32. During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)?',
      type: INPUT_TYPES.RADIO,
      options: socialActivitiesQuestionOptions,
    },
  ],
  [
    {
      label: 'How TRUE or FALSE is each of the following statements for you.',
      type: INPUT_TYPES.TABLE,
      options: trueFalseOptions,
      fields: trueFalseFields,
    },
    {
      text: 'The 36-Item Health Survey was developed at RAND as part of the Medical Outcomes Study.',
      type: INPUT_TYPES.INFO,
    },
  ],
];
