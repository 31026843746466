import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import useCachedImage, {
  ImageSizeEnum,
  ImageFitEnum,
} from 'hooks/useCachedImage';
import useECRF from 'hooks/useECRF';
import useUserDetails from 'hooks/useUserDetails';
import { formatISOString, formatBirthDate } from 'utils/date';
import { StyledCard } from 'utils/styles';

import {
  PatientAvatar,
  PatientInfolabel,
  StyledButton,
} from './PatientInfoWidget.styles';

const PathwayWidget = () => {
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);
  const { pathname, state } = useLocation();
  const { rootPath } = useECRF();
  const { cachedUrl } = useCachedImage({
    url: user.profileImageUrl,
    size: ImageSizeEnum.SIZE_128,
    fit: ImageFitEnum.COVER,
  });

  const currentSubPath = React.useMemo(
    () => `${rootPath}/${patientId}`,
    [patientId, rootPath],
  );

  const currentPath = React.useMemo(
    () => pathname.replace(`${currentSubPath}/`, '').split('/')[0],
    [pathname, currentSubPath],
  );

  return (
    <StyledCard height="100%">
      <Box display="flex" alignItems="center" mb={1}>
        <PatientAvatar src={cachedUrl} />
        <Box display="flex" flexDirection="column">
          <PatientInfolabel>
            <span>Gender: </span>
            {user?.gender ?? 'No gender info'}
          </PatientInfolabel>
          <PatientInfolabel>
            <span>DOB: </span>
            {formatBirthDate(user?.birthDate, true)}
          </PatientInfolabel>
          <PatientInfolabel>
            <span>Last App Login: </span>
            {user?.lastActiveAt ? formatISOString(user?.lastActiveAt) : '--'}
          </PatientInfolabel>
        </Box>
      </Box>
      <StyledButton
        variant={'manage' === currentPath ? 'contained' : 'outlined'}
        to={{
          pathname: `${currentSubPath}/manage`,
          state,
        }}
        component={Link}
        style={{ width: '100%', textAlign: 'center', alignSelf: 'flex-end' }}
      >
        Manage Patient Information
      </StyledButton>
    </StyledCard>
  );
};

export default PathwayWidget;
