import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import useWoundImageDetails from 'hooks/useWoundImageDetails';

import { listStyle } from './Controls.styles';

const Controls = ({
  imageId,
  selections,
  currentSelection,
  selectionMode,
  onSelectionClear,
  onSelectionAdd,
  onSelectionChoose,
}) => {
  const { isUpdating } = useWoundImageDetails(imageId);

  const handleSelectionClear = (e) => {
    e.preventDefault();
    onSelectionClear(e.target);
  };

  const handleSelectionAdd = (e) => {
    e.preventDefault();
    onSelectionAdd(e.target);
  };

  const handleSelectionChoose = (feature) => {
    onSelectionChoose(feature);
  };

  const woundAreaSelections = selections.map((selection, i) => {
    const selectionId = selection.getId();
    return (
      <ListItemButton
        key={i}
        dense
        onClick={() =>
          handleSelectionChoose(
            selectionId === currentSelection ? null : selection,
          )
        }
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={currentSelection === selectionId}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': selectionId }}
          />
        </ListItemIcon>
        <ListItemText id={selectionId} primary={selectionId} />
      </ListItemButton>
    );
  });

  return (
    <div>
      {woundAreaSelections.length > 0 && (
        <List style={listStyle}>{woundAreaSelections}</List>
      )}
      <ButtonGroup color="primary" style={{ width: '100%' }}>
        {!selectionMode && (
          <Button onClick={handleSelectionAdd} sx={{ flexGrow: 1 }}>
            {isUpdating ? <CircularProgress size="1.8rem" /> : 'Add New Trace'}
          </Button>
        )}
        {(selectionMode || currentSelection) && (
          <Button onClick={handleSelectionClear} sx={{ flexGrow: 1 }}>
            {selectionMode ? 'Abort selection' : 'Delete selection'}
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

Controls.propTypes = {
  imageId: PropTypes.string.isRequired,
  selections: PropTypes.array,
  currentSelection: PropTypes.string,
  selectionMode: PropTypes.bool.isRequired,
  onSelectionClear: PropTypes.func.isRequired,
  onSelectionAdd: PropTypes.func.isRequired,
  onSelectionChoose: PropTypes.func.isRequired,
};

export default Controls;
