import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { getFieldProps } from 'utils/forms';
import { FormSectionTitle, FormSubSectionTitle, Label } from 'utils/styles';
import DiseaseEtiologyAnatomy from 'theme/media/CVOClassifications.png';

import Select from 'components/forms/select';

const fields = {
  SHEATHS: 'sheaths',
  GUIDEWIRES: 'guidewires',
  CATHETERS: 'catheters',
  BALLONS: 'balloons',
  STENTS: 'stents',
  IVUS: 'ivus',
};

const medtronicAbreValue = 'medtronicAbre';

const stentsOptions = [
  { value: 'bdInterventionalVenovo', label: 'BD Interventional Venovo' },
  { value: 'cookMedicalZilverVena', label: 'Cook Medical Zilver Vena' },
  { value: medtronicAbreValue, label: 'Medtronic Abre' },
];

const treatmentPlanningFields = [
  { name: fields.SHEATHS },
  { name: fields.GUIDEWIRES },
  { name: fields.CATHETERS },
  { name: fields.BALLONS },
  {
    name: fields.STENTS,
    options: stentsOptions,
  },
  {
    name: fields.IVUS,
    label: 'IVUS',
  },
];

export const CVOInterventionPlanning = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>Disease Etiology & Anatomy</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FormSubSectionTitle>
          Type 2 Chronic Venous Obstruction: CVO of Iliac Segments
        </FormSubSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Label>
          Type 2 group consists of patients with CVO with extension of
          post-thrombotic changes limited to the pelvic veins
        </Label>
      </Grid>
      <Grid item md={6} xs={12}>
        <img
          style={{ width: '100%' }}
          src={DiseaseEtiologyAnatomy}
          alt="Disease Etiology & Anatomy"
        />
        <Link
          target="_blank"
          rel="noopener"
          href="https://www.hmpgloballearningnetwork.com/site/vdm/presentations/decoding-chronic-venous-obstruction-introducing-new-classification-system"
        >
          CVO Classification Criteria
        </Link>
      </Grid>
    </Grid>
    <FormSectionTitle>Treatment Planning</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FormSubSectionTitle>
          Suggested therapies for use in Type 2 Chronic Venous Obstruction
        </FormSubSectionTitle>
      </Grid>
      {treatmentPlanningFields.map(({ name, label, options }) => (
        <Grid key={name} item xs={12} md={4}>
          <Select
            label={label || startCase(name)}
            options={options || []}
            {...getFieldProps({
              formik,
              name,
              disabled,
            })}
          />
        </Grid>
      ))}
    </Grid>
    <FormSectionTitle>Evidence</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      {formik.values[fields.STENTS] && (
        <>
          <Grid item xs={12}>
            <FormSubSectionTitle>
              {
                stentsOptions.find(
                  (option) => option.value === formik.values[fields.STENTS],
                ).label
              }
            </FormSubSectionTitle>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Link href="#">Clinical Trials</Link>
              <Link href="#">Publications</Link>
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  </>
);

export default CVOInterventionPlanning;

CVOInterventionPlanning.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
