import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import api from 'core/api';
import useRequest from 'hooks/useRequest';

import { USER_GROUPS } from 'utils/constants';
import { normalizeOptions } from 'utils/forms';

import Select from 'components/forms/select';
import SearchableMultiSelect from 'components/forms/select-searchable';

const activeOptions = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
];

const PatientListingFilters = ({
  filters,
  onFilterChange,
  clearTableState,
}) => {
  const { data: systemsData, doRequest: getSystems } = useRequest(
    api.getHospitalSystems,
  );
  const { data: userData, doRequest: getUsers } = useRequest(api.getUsers);

  useEffect(
    () => {
      getSystems({ sort: 'Name', sortDirection: 'Asc' });
      getUsers({
        roles: [USER_GROUPS.practitioner],
        sort: 'FirstName',
        sortDirection: 'Asc',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const practitionerOptions = useMemo(
    () =>
      userData?.data.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) || [],
    [userData],
  );

  return (
    <Box display="flex" gap={2} mb={2}>
      <Select
        name="hospitalSystems"
        options={normalizeOptions(systemsData?.data)}
        placeholder="Hospital System"
        value={filters.hospitalSystems}
        onChange={({ target }) => {
          onFilterChange('hospitalSystems', target.value);
        }}
        multiple
        noTags
      />
      <SearchableMultiSelect
        name="assignedPractitioners"
        options={practitionerOptions}
        placeholder="Practitioner Assignment"
        value={filters.assignedPractitioners}
        onChange={({ target }) =>
          onFilterChange('assignedPractitioners', target.value)
        }
        isMultiple
        noTags
      />
      <Select
        name="active"
        options={activeOptions}
        placeholder="Active Status"
        value={filters.active}
        displayEmptyOption
        onChange={({ target: { value } }) => onFilterChange('active', value)}
      />
      <Button variant="contained" onClick={clearTableState}>
        Clear
      </Button>
    </Box>
  );
};

PatientListingFilters.propTypes = {
  filters: PropTypes.shape({
    hospitalSystems: PropTypes.array,
    assignedPractitioners: PropTypes.array,
    active: PropTypes.string,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  clearTableState: PropTypes.func.isRequired,
};

export default PatientListingFilters;
