import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

import useFeedback from 'hooks/useFeedback';
import { useMountEffect, useUpdateEffect } from 'hooks';
import useWoundImageDetails from 'hooks/useWoundImageDetails';
import { ROOT_PATHS } from 'navigation/paths';
import { woundImagesStatus } from 'utils/constants';
import { formatISOString } from 'utils/date';
import { reasonForNoTracingOptions } from 'utils/enums';
import { getErrorMessage } from 'utils/error';
import { getSurfaceAreaString, getWidthString } from 'utils/woundImages';

import Select from 'components/forms/select';

import {
  ContentContainer,
  DetailsInfoContainer,
  DetailsWrapper,
  Error,
  Img,
  ImageContainer,
  OutterWrapper,
  Section,
  Title,
} from './ImageDetails.styles.js';

import WoundImageDisplay from './components/WoundImageDisplay';
import WoundImageFooter from './components/footer';
import WoundImageHeader from './components/header';
import WoundImageNotes from './components/notes';

const DetailInfo = ({ title, isLoading, value }) => (
  <Box display="flex" flexDirection="column">
    <Title>{title}</Title>
    <span>
      {isLoading ? (
        <Skeleton animation="wave" height="2rem" variant="rectangular" />
      ) : (
        value
      )}
    </span>
  </Box>
);

DetailInfo.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function ImageDetails() {
  const navigate = useNavigate();
  const { imageId } = useParams();
  const { pathname, state: locationState } = useLocation();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const [imageObj, setImageObj] = React.useState(null);
  const [contours, setContours] = React.useState([]);
  const [reasonForNoTracing, setReasonForNoTracing] = React.useState('');
  const [reasonForNoTracingError, setReasonForNoTracingError] =
    React.useState(null);

  const {
    getWoundImage,
    woundImage,
    isDeleted,
    isOpen,
    isDetailsLoading,
    detailsError,
    updateWoundImage,
    isUpdating,
    updateError,
  } = useWoundImageDetails(imageId);

  const rootPath = React.useMemo(
    () => `/${pathname.split('/')[1]}`,
    [pathname],
  );

  const isImageTracer = React.useMemo(
    () => rootPath === ROOT_PATHS.woundImageTracer,
    [rootPath],
  );

  const handleBackClick = React.useCallback(() => {
    navigate({
      pathname: rootPath,
      search: locationState?.search || `?status=${woundImage?.status}`,
    });
  }, [navigate, locationState, woundImage, rootPath]);

  const handleUpdate = async () => {
    clearFeedback();
    if (contours.length === 0 && reasonForNoTracing === '') {
      window.scrollTo(0, 0);
      setReasonForNoTracingError("Required if there aren't any tracings");
    } else {
      await updateWoundImage({
        imageId,
        flag: woundImage.flag,
        surfaces: contours,
        status: isDeleted
          ? woundImagesStatus.deleted
          : woundImagesStatus.submitted,
        reasonForNoTracing:
          reasonForNoTracing === '' ? null : reasonForNoTracing,
      });
      window.scrollTo(0, 0);
      setSuccessFeedback(`Wound Image ${isOpen ? 'Submitted' : 'Updated'}`);
    }
  };

  const renderImageContent = React.useMemo(() => {
    if (woundImage && !woundImage.asset?.url)
      return <Error>Wound Image Not Found</Error>;
    if (!imageObj) return <CircularProgress size={40} />;
    return isDeleted ? (
      <Img src={woundImage.asset.url} />
    ) : (
      <WoundImageDisplay wound={imageObj} setCurrentContours={setContours} />
    );
  }, [woundImage, imageObj, isDeleted]);

  useMountEffect(() => {
    getWoundImage(imageId);
  });

  useUpdateEffect(() => {
    if (!isUpdating) getWoundImage(imageId);
  }, [isUpdating]);

  useUpdateEffect(() => {
    if (woundImage?.asset?.url) {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;

        if (width && height) {
          setImageObj({
            imageUrl: `${woundImage.asset.url}&d=800`,
            imageWidth: width,
            imageHeight: height,
            imageId: woundImage.id,
            selections:
              woundImage.surfaces?.map((surface) => {
                return [surface?.map((coord) => [coord.x, coord.y])];
              }) ?? [],
          });
        }
      };
      img.src = `${woundImage.asset.url}&d=800`;
    }
  }, [woundImage]);

  useUpdateEffect(() => {
    if (updateError) setErrorFeedback(getErrorMessage(updateError));
  }, [updateError, setErrorFeedback]);

  useUpdateEffect(() => {
    setReasonForNoTracing(woundImage?.reasonForNoTracing || '');
  }, [woundImage]);

  return (
    <Section>
      <OutterWrapper>
        <WoundImageHeader
          imageId={imageId}
          handleBackClick={handleBackClick}
          isImageTracer={isImageTracer}
        />
        <ContentContainer>
          <ImageContainer>{renderImageContent}</ImageContainer>
          <DetailsWrapper>
            {detailsError ? (
              <Error>Wound Image Not Found</Error>
            ) : (
              <>
                <DetailsInfoContainer>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <DetailInfo
                      title="Image ID:"
                      isLoading={isDetailsLoading}
                      value={woundImage?.id}
                    />
                    <DetailInfo
                      title="Date Added:"
                      isLoading={isDetailsLoading}
                      value={
                        woundImage?.createdAt
                          ? formatISOString(woundImage.createdAt)
                          : '-'
                      }
                    />
                  </Box>
                  {isImageTracer && (
                    <Box display="flex" flexDirection="column" gap={2}>
                      <DetailInfo
                        title="Size:"
                        isLoading={isDetailsLoading}
                        value={getSurfaceAreaString(
                          woundImage?.totalSurfaceArea,
                        )}
                      />
                      <DetailInfo
                        title="Marker Width:"
                        isLoading={isDetailsLoading}
                        value={getWidthString(woundImage?.markerWidth)}
                      />
                    </Box>
                  )}
                </DetailsInfoContainer>
                <Box my={2}>
                  <Select
                    value={reasonForNoTracing}
                    name="reasonsForNoTracing"
                    label="Reason for No Tracing"
                    options={reasonForNoTracingOptions}
                    onChange={({ target: { value } }) => {
                      setReasonForNoTracingError(null);
                      setReasonForNoTracing(value);
                    }}
                    displayEmptyOption
                    error={reasonForNoTracingError}
                  />
                </Box>
                {isImageTracer && <WoundImageNotes imageId={imageId} />}
              </>
            )}
          </DetailsWrapper>
        </ContentContainer>
        <WoundImageFooter
          buttonText={isOpen ? 'Submit' : 'Save edits'}
          hideSubmit={isDeleted}
          isSubmitting={isUpdating}
          handleSubmit={handleUpdate}
          handleBackClick={handleBackClick}
        />
      </OutterWrapper>
    </Section>
  );
}
