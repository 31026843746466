import { INPUT_TYPES } from 'utils/constants';

import {
  yesNoOptions,
  howOftenOptions,
  timeOfDayOptions,
  howMuchOptions,
  howOftenTimeOptions,
  getHowLimitedOptions,
  getOneYearAgoOptions,
} from './utils';

const howSeriousOptions = [
  {
    value: '1',
    label: 'None',
  },
  {
    value: '2',
    label: 'Very slight',
  },
  {
    value: '3',
    label: 'Slight',
  },
  {
    value: '4',
    label: 'Quite a bit',
  },
  {
    value: '5',
    label: 'Serious',
  },
  {
    value: '6',
    label: 'Very serious',
  },
];

const symptomsFields = [
  {
    name: 'frequencyShoulderBladesPain',
    label: 'Pain behind or between the shoulder blades?',
  },
  {
    name: 'frequencyChestPain',
    label: 'Pain on or in the chest?',
  },
  {
    name: 'frequencyBackPain',
    label: 'Pain in the back?',
  },
  {
    name: 'frequencySensationOfPressure',
    label: 'Sensation of pressure?',
  },
  {
    name: 'frequencyFeelingSomething',
    label: "Feeling that there is 'still something there'?",
  },
  {
    name: 'frequencyBurningSensation',
    label: "'Burning sensation' in the lungs?",
  },
  {
    name: 'frequencyNaggingFeeling',
    label: "'Nagging feeling' in the lungs?",
  },
  {
    name: 'frequencyDifficultyInBreathing',
    label: 'Difficulty in breathing or breathlessness?',
  },
];

const activitiesFields = [
  {
    name: 'limitationWorkDailyActivities',
    label: 'a. Daily activities at work',
  },
  {
    name: 'limitationHomeDailyActivities',
    label:
      'b. Daily activities at home (e.g. housework, ironing, doing odd jobs / repairs around the house, gardening, etc.…)',
  },
  {
    name: 'limitationSocialActivities',
    label:
      'c. Social or activities (such as travelling, going to the cinema, parties, shopping)',
  },
  {
    name: 'limitationVigorousActivities',
    label:
      'd. Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports',
  },
  {
    name: 'limitationModerateActivities',
    label:
      'e. Moderate activities, such as moving a table, hoovering, swimming or cycling',
  },
  {
    name: 'limitationLiftingCarryingGroceries',
    label: 'f. Lifting or carrying groceries',
  },
  {
    name: 'limitationClimbingSeveralFlightsOfStairs',
    label: 'g. Climbing several flights of stairs',
  },
  {
    name: 'limitationClimbingOneFlightOfStairs',
    label: 'h. Climbing one flight of stairs',
  },
  {
    name: 'limitationBendingKneelingSquatting',
    label: 'i. Bending, kneeling, or squatting',
  },
  {
    name: 'limitationWalkingMoreThanHalfMile',
    label: 'j. Walking more than half a mile',
  },
  {
    name: 'limitationWalkingCoupleOfHundredYards',
    label: 'k. Walking a couple of hundred yards',
  },
  {
    name: 'limitationWalkingAboutOneHundredYards',
    label: 'l. Walking about one hundred yards',
  },
  {
    name: 'limitationWashingDressingYourself',
    label: 'm. Washing or dressing yourself',
  },
];

const workFields = [
  {
    name: 'workProblemsCutDownAmountOfTime',
    label:
      'a. Cut down the amount of time you spent on work or other activities',
  },
  {
    name: 'workProblemsAccomplishedLess',
    label: 'b. Accomplished less than you would like',
  },
  {
    name: 'workProblemsLimitedWorkActivities',
    label: 'c. Were limited in the kind of work or other activities',
  },
  {
    name: 'workProblemsDifficultyPerforming',
    label:
      'd. Had difficulty performing the work or other activities (e.g. it took extra effort)',
  },
];

const moodFields = [
  {
    name: 'feelingWorriedAboutAnotherPE',
    label: 'Were you worried about having another pulmonary embolism?',
  },
  {
    name: 'feelingIrritable',
    label: 'Did you feel irritable?',
  },
  {
    name: 'feelingWorriedAboutAnticoagulantMedication',
    label:
      'Would you have been worried if you had to stop taking anticoagulant medication?',
  },
  {
    name: 'feelingEmotionalMoreReadily',
    label: 'Did you become emotional more readily?',
  },
  {
    name: 'feelingBotheredBecomingEmotionalMoreQuickly',
    label: 'Did it bother you that you became emotional more quickly?',
  },
  {
    name: 'feelingDepressed',
    label: 'Were you depressed or in low spirits?',
  },
  {
    name: 'feelingABurden',
    label: 'Did you feel that you were a burden to your family and friends?',
  },
  {
    name: 'feelingAfraidToExert',
    label: 'Were you afraid to exert yourself?',
  },
  {
    name: 'feelingLimitedTakingATrip',
    label: 'Did you feel limited in taking a trip?',
  },
  {
    name: 'feelingAfraidOfBeingAlone',
    label: 'Were you afraid of being alone?',
  },
];

export const PembQol = [
  [
    {
      text: 'Instructions how to answer: Answer every question by marking the answer as indicated. If you are unsure about how to answer a question, please give the best answer you can. These questions are about your lungs. The information you give should describe how you feel. You can also indicate how capable you are of carrying out your normal activities.',
      type: INPUT_TYPES.INFO,
    },
  ],
  [
    {
      label:
        '1. During the past 4 weeks, how often have you had any of the following symptoms from your lungs?',
      type: INPUT_TYPES.TABLE,
      options: howOftenOptions,
      fields: symptomsFields,
    },
  ],
  [
    {
      name: 'mostIntenseSymptomsTimeOfDay',
      label: '2. At what time of day are your lung symptoms most intense?',
      type: INPUT_TYPES.RADIO,
      options: timeOfDayOptions,
    },
  ],
  [
    {
      name: 'oneYearLungConditionComparison',
      label:
        '3. Compared to 1 year ago, how would you rate the condition of your lungs in general now?',
      type: INPUT_TYPES.RADIO,
      options: getOneYearAgoOptions('PembQol'),
    },
  ],
  [
    {
      label:
        '4. The following items are about activities that you might do in a typical day. Do your lung symptoms now limit you in these activities? If so, how much?',
      type: INPUT_TYPES.TABLE,
      options: getHowLimitedOptions(true),
      fields: activitiesFields,
    },
  ],
  [
    {
      label:
        '5. During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your lung symptoms?',
      type: INPUT_TYPES.TABLE,
      options: yesNoOptions,
      fields: workFields,
    },
  ],
  [
    {
      name: 'socialActivitiesInterference',
      label:
        '6. During the past 4 weeks, to what extent have your lung symptoms interfered with your normal social activities with family, friends, neighbours or groups ?',
      type: INPUT_TYPES.RADIO,
      options: howMuchOptions,
    },
  ],
  [
    {
      name: 'intensityShoulderBladeChestPain',
      label:
        '7. How much pain around your shoulder blades/pain in your chest have you experienced during the past 4 weeks?',
      type: INPUT_TYPES.RADIO,
      options: howSeriousOptions,
    },
  ],
  [
    {
      name: 'intensityBreathlessness',
      label:
        '8. How much breathlessness have you experienced in the past 4 weeks?',
      type: INPUT_TYPES.RADIO,
      options: howSeriousOptions,
    },
  ],
  [
    {
      label:
        '9. These questions are about how you feel and how things have been with you during the past 4 weeks as a result of your lung symptoms. For each question, please give the one answer that comes closest to the way you have been feeling. How much of the time during the past 4 weeks...',
      type: INPUT_TYPES.TABLE,
      options: howOftenTimeOptions,
      fields: moodFields,
    },
  ],
];
