import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isSameDay, isToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveIcon from '@mui/icons-material/Remove';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';

import useChat from 'hooks/useChat';
import useFeedback from 'hooks/useFeedback';
import useUser from 'hooks/useUser';
import { PRACTITIONER_PATHS } from 'navigation/paths';

import ChatInput from 'components/chat/chat-input';

import ChatMessage from './ChatMessage';
import {
  ChatContainer,
  ChatWindowContainer,
  ChatWindowHeader,
  MessageInputContainer,
  MessageDisplayContainer,
  StyledTodayBox,
} from './ChatWindow.styles';
import { findLastReadMessage } from './utils';

const ChatWindow = () => {
  const navigate = useNavigate();
  const { userDetails } = useUser();
  const { setErrorFeedback } = useFeedback();
  const {
    openRoomWindowId,
    closeChatWindow,
    chatRooms,
    fetchMessages,
    messagesData,
    onMessagesRead,
    onRoomSelect,
    pushedMessage,
    setPushedMessage,
    videoCallMessage,
    onCollapseWindow,
  } = useChat();

  const [collapsedWindow, setCollapsedWindow] = useState(false);

  const roomData = useMemo(
    () => messagesData[openRoomWindowId],
    [messagesData, openRoomWindowId],
  );

  useEffect(() => {
    if (openRoomWindowId && !collapsedWindow) {
      fetchMessages(openRoomWindowId);
      onMessagesRead(openRoomWindowId);
    }
  }, [
    openRoomWindowId,
    collapsedWindow,
    fetchMessages,
    onMessagesRead,
    roomData?.items?.length,
  ]);

  useEffect(() => {
    onCollapseWindow(collapsedWindow);
  }, [onCollapseWindow, collapsedWindow]);

  const currentRoom = useMemo(
    () => chatRooms?.find(({ id }) => id === openRoomWindowId),
    [chatRooms, openRoomWindowId],
  );

  const membersMap = useMemo(
    () =>
      (currentRoom?.members || [])?.reduce(
        (res, { name, userId, profileImageUrl, readReceipt }) => ({
          ...res,
          [userId]: { name, profileImageUrl, readReceipt },
        }),
        {},
      ),
    [currentRoom],
  );

  const roomUserId = useMemo(
    () =>
      currentRoom?.members.find(({ userId }) => userId !== userDetails?.id)
        ?.userId,
    [currentRoom, userDetails],
  );

  const roomMessages = useMemo(
    () =>
      (roomData?.items || []).map(
        ({ id, userId, content, attachment, createdAt }, index) => (
          <Fragment key={id}>
            <ChatMessage
              content={content}
              createdAt={createdAt}
              user={membersMap[userId]}
              sent={userId === userDetails?.id}
              attachment={attachment}
              lastMessageRead={findLastReadMessage(
                id,
                membersMap[roomUserId],
                roomData,
                userDetails?.id,
              )}
            />
            {isToday(new Date(createdAt)) &&
            !isSameDay(
              new Date(createdAt),
              new Date(roomData?.items[index + 1]?.createdAt),
            ) ? (
              <StyledTodayBox>
                <span>Today</span>
              </StyledTodayBox>
            ) : null}
          </Fragment>
        ),
      ),
    [roomData, membersMap, userDetails, roomUserId],
  );

  const onLoadMore = useCallback(() => {
    if (!roomData?.nextCursor) return;
    fetchMessages(openRoomWindowId, roomData.nextCursor);
  }, [fetchMessages, roomData, openRoomWindowId]);

  const onVideoCall = useCallback(() => {
    userDetails?.practitioner?.doxyTelehealthLink
      ? setPushedMessage(videoCallMessage)
      : setErrorFeedback(
          'Please add your free Doxy.me telehealth link in your profile settings',
        );
  }, [userDetails, videoCallMessage, setPushedMessage, setErrorFeedback]);

  const onOpenMessagesPage = useCallback(() => {
    onRoomSelect(openRoomWindowId);
    navigate(PRACTITIONER_PATHS.messages);
  }, [navigate, onRoomSelect, openRoomWindowId]);

  if (!openRoomWindowId) return null;

  return (
    <ChatWindowContainer collapsed={collapsedWindow}>
      <ChatWindowHeader>
        <IconButton onClick={onVideoCall}>
          <VoiceChatIcon />
        </IconButton>
        <IconButton onClick={onOpenMessagesPage}>
          <OpenInNewIcon />
        </IconButton>
        <IconButton onClick={() => setCollapsedWindow(!collapsedWindow)}>
          <RemoveIcon />
        </IconButton>
        <IconButton onClick={closeChatWindow}>
          <CloseIcon />
        </IconButton>
      </ChatWindowHeader>
      {!collapsedWindow ? (
        <ChatContainer>
          <MessageDisplayContainer id="chatWindow">
            <InfiniteScroll
              dataLength={roomMessages?.length || 0}
              next={onLoadMore}
              hasMore={roomData?.nextCursor}
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
              inverse={true}
              scrollableTarget="chatWindow"
            >
              {roomMessages}
            </InfiniteScroll>
          </MessageDisplayContainer>
          <MessageInputContainer>
            <ChatInput
              roomId={openRoomWindowId}
              pushedMessage={pushedMessage}
              setPushedMessage={setPushedMessage}
            />
          </MessageInputContainer>
        </ChatContainer>
      ) : null}
    </ChatWindowContainer>
  );
};

export default ChatWindow;
