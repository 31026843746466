import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'core/api';
import useECRF from 'hooks/useECRF';
import { USER_GROUPS } from 'utils/constants';
import { getFieldProps } from 'utils/forms';

import SelectSearchable from 'components/forms/select-searchable';

const SelectPractitioner = ({
  formik,
  name,
  label,
  placeholder,
  forcedPractitioner,
  disabled,
  hospitalSystemId,
}) => {
  const [practitionerData, setPractitionerData] = useState(null);
  const { isECRF } = useECRF();

  useEffect(() => {
    if (!hospitalSystemId) return;
    let isSubscribed = true;
    api
      .getUsers({
        roles: isECRF ? [USER_GROUPS.eCRF] : [USER_GROUPS.practitioner],
        sort: 'FirstName',
        sortDirection: 'Asc',
        hospitalSystems: [hospitalSystemId],
      })
      .then(({ data }) =>
        isSubscribed ? setPractitionerData(data.data) : null,
      );
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalSystemId]);

  const practitionerOptions = useMemo(() => {
    if (!practitionerData) return [];
    return practitionerData.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
      disabled: forcedPractitioner === id,
    }));
  }, [practitionerData, forcedPractitioner]);

  return (
    <SelectSearchable
      label={label}
      options={practitionerOptions}
      placeholder={placeholder}
      isMultiple
      {...getFieldProps({ formik, name, disabled })}
    />
  );
};

SelectPractitioner.propTypes = {
  formik: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  forcedPractitioner: PropTypes.string,
  hospitalSystemId: PropTypes.string,
};

export default SelectPractitioner;
