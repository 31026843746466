import { OTHER_VALUE } from 'utils/constants';

export const mapEnumToOptions = ({ data, labelKey = 'label', labels = null }) =>
  Object.values(data)?.map((val) => ({
    value: val,
    [labelKey]: (labels && labels[val]) ?? val,
  })) || [];

export const FeedbackTypeEnum = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const PeriodEnum = {
  WEEK: 'Weekly',
  MONTH: 'Monthly',
  YEAR: 'Yearly',
};

export const AssetTypeEnum = {
  CHAT: 'ChatAttachment',
  USER: 'UserProfileImage',
  WOUND: 'VluWoundImage',
  ASSESSMENT: 'AssessmentAsset',
  TRAINING_DATA: 'TrainingDataImage',
  ACCOUNT: 'AccountLogoImage',
};

export const DiseaseEnum = {
  VLU: 'LegUlceration',
  DVT: 'DeepThrombosis',
  PE: 'PulmonaryEmbolism',
};

export const DiseaseLabels = {
  [DiseaseEnum.VLU]: 'Venous Leg Ulceration',
  [DiseaseEnum.DVT]: 'Deep Vein Thrombosis',
  [DiseaseEnum.PE]: 'Pulmonary Embolism',
};

export const diseaseOptions = mapEnumToOptions({
  data: DiseaseEnum,
  labels: DiseaseLabels,
});

export const LegEnum = {
  LEFT: 'Left',
  RIGHT: 'Right',
};

export const LimbLocationEnum = {
  CALF: 'Calf',
  ANKLE: 'Ankle',
  FOOT_DORSAL: 'FootDorsalTop',
  FOOT_SOLE: 'FootSoleBottom',
  OTHER: OTHER_VALUE,
};

export const LimbLocationLabel = {
  [LimbLocationEnum.CALF]: 'Calf',
  [LimbLocationEnum.ANKLE]: 'Ankle',
  [LimbLocationEnum.FOOT_DORSAL]: 'Foot (Dorsal)',
  [LimbLocationEnum.FOOT_SOLE]: 'Foot (Sole)',
  [LimbLocationEnum.OTHER]: OTHER_VALUE,
};

export const LimbSideEnum = {
  Lateral: 'Lateral',
  Medial: 'Medial',
  Anterior: 'Anterior',
  Posterior: 'Posterior',
  Circumferential: 'Circumferential',
};

export const PathwayStepsEnum = {
  WOUND_INTAKE: 'WoundIntake',
  NON_VLU: 'NonVLUPathway',
  WOUND_ASSESSMENT: 'WoundAssessmentIntake',
  WOUND_ASSESSMENT_ONGOING: 'WoundAssessmentOngoing',
  CONTINUE_COMPRESSION: 'ContinueCompression',
  ULTRASOUND_AND_REFLUX_STUDY: 'UltrasoundAndRefluxStudy',
  VENOGRAPHY_CT_MR: 'VenographyCtMr',
  INTRAVASCULAR_ULTRASOUND: 'IntravascularUltrasound',
  CHRONIC_VENOUS_OBSTRUCTION: 'ChronicVenousObstruction',
  SUPERFICIAL_VEIN_INSUFFICIENCY: 'SuperficialVeinInsufficiency',
  DECLINED_INTERVENTION: 'DeclinedIntervention',
  SVI_PROCEDURE: 'SVIProcedure',
  SVI_FOLLOW_UP: 'SVIProcedureFollowUp',
  VENOUS_STENT: 'VenousStent',
  VENOUS_STENT_FOLLOW_UP: 'VenousStentFollowUp',
  VENOPLASTY: 'Venoplasty',
  VENOPLASTY_FOLLOW_UP: 'VenoplastyFollowUp',
  POST_OP_SYMPTOMS: 'PostOpSymptoms',
  ACUTE_STENT_THROMBOSIS: 'StentThrombosisAcute',
  VENOUS_STENT_OBSTRUCTION: 'VenousStentObstruction',
  STENT_RESTENOSIS: 'StentRestenosis',
  HEALED: 'HealedVLU',
  RECURRENCE: 'Recurrence',
};

export const PathwayStepsLabels = {
  [PathwayStepsEnum.WOUND_INTAKE]: 'Wound Intake',
  [PathwayStepsEnum.NON_VLU]: 'Non-VLU Pathway',
  [PathwayStepsEnum.WOUND_ASSESSMENT]: 'Wound Assessment (Intake)',
  [PathwayStepsEnum.WOUND_ASSESSMENT_ONGOING]: 'Wound Assessment (Ongoing)',
  [PathwayStepsEnum.CONTINUE_COMPRESSION]: 'Continue Compression',
  [PathwayStepsEnum.ULTRASOUND_AND_REFLUX_STUDY]: 'Ultrasound and Reflux Study',
  [PathwayStepsEnum.VENOGRAPHY_CT_MR]: 'Venography (CT, MR)',
  [PathwayStepsEnum.INTRAVASCULAR_ULTRASOUND]: 'Intravascular Ultrasound',
  [PathwayStepsEnum.DECLINED_INTERVENTION]: 'Declined Intervention',
  [PathwayStepsEnum.SVI_PROCEDURE]: 'SVI Procedure',
  [PathwayStepsEnum.SVI_FOLLOW_UP]: 'SVI Procedure Follow-Up',
  [PathwayStepsEnum.VENOUS_STENT]: 'Venous Stent',
  [PathwayStepsEnum.VENOUS_STENT_FOLLOW_UP]: 'Venous Stent Follow-Up',
  [PathwayStepsEnum.VENOPLASTY]: 'Venoplasty',
  [PathwayStepsEnum.VENOPLASTY_FOLLOW_UP]: 'Venoplasty Follow-Up',
  [PathwayStepsEnum.POST_OP_SYMPTOMS]: 'Post-Op Symptoms',
  [PathwayStepsEnum.ACUTE_STENT_THROMBOSIS]: 'Stent Thrombosis (Acute)',
  [PathwayStepsEnum.STENT_RESTENOSIS]: 'Stent Restenosis',
  [PathwayStepsEnum.HEALED]: 'Healed VLU',
  [PathwayStepsEnum.RECURRENCE]: 'Recurrence',
  [PathwayStepsEnum.CHRONIC_VENOUS_OBSTRUCTION]: 'Chronic Venous Obstruction',
  [PathwayStepsEnum.SUPERFICIAL_VEIN_INSUFFICIENCY]:
    'Superficial Vein Insufficiency',
  [PathwayStepsEnum.VENOUS_STENT_OBSTRUCTION]: 'Venous Stent Obstruction',
};

export const PathwayAdherenceLabels = {
  LookingGood: 'Looking Good',
  OpenTasks: 'Open Tasks',
  NotCompliant: 'Not Compliant',
};

export const PathwayStatusLabels = {
  InsufficientMeasures: 'Insufficient Measures',
  Healed: 'Healed',
  Healing: 'Healing',
  InsufficientHealing: 'Insufficient Healing',
};

export const pathwayStepsOptions = mapEnumToOptions({
  data: PathwayStepsEnum,
  labels: PathwayStepsLabels,
});

export const ProcessStepsEnum = {
  Intake: 'Intake',
  OngoingManagement: 'Ongoing Management',
  DiagnosticWorkup: 'Diagnostic Workup',
  InterventionalTreatment: 'Interventional Treatment',
  InterventionFollowUp: 'Intervention Follow-Up',
  PatientMonitoring: 'Patient Monitoring',
};

export const ProcessSteps = {
  [PathwayStepsEnum.WOUND_INTAKE]: ProcessStepsEnum.Intake,
  [PathwayStepsEnum.WOUND_ASSESSMENT]: ProcessStepsEnum.Intake,
  [PathwayStepsEnum.WOUND_ASSESSMENT_ONGOING]:
    ProcessStepsEnum.OngoingManagement,
  [PathwayStepsEnum.CONTINUE_COMPRESSION]: ProcessStepsEnum.OngoingManagement,
  [PathwayStepsEnum.ULTRASOUND_AND_REFLUX_STUDY]:
    ProcessStepsEnum.DiagnosticWorkup,
  [PathwayStepsEnum.VENOGRAPHY_CT_MR]: ProcessStepsEnum.DiagnosticWorkup,
  [PathwayStepsEnum.INTRAVASCULAR_ULTRASOUND]:
    ProcessStepsEnum.DiagnosticWorkup,
  [PathwayStepsEnum.CHRONIC_VENOUS_OBSTRUCTION]:
    ProcessStepsEnum.InterventionalTreatment,
  [PathwayStepsEnum.SUPERFICIAL_VEIN_INSUFFICIENCY]:
    ProcessStepsEnum.InterventionalTreatment,
  [PathwayStepsEnum.DECLINED_INTERVENTION]: ProcessStepsEnum.OngoingManagement,
  [PathwayStepsEnum.SVI_PROCEDURE]: ProcessStepsEnum.InterventionalTreatment,
  [PathwayStepsEnum.VENOUS_STENT]: ProcessStepsEnum.InterventionalTreatment,
  [PathwayStepsEnum.VENOPLASTY]: ProcessStepsEnum.InterventionalTreatment,
  [PathwayStepsEnum.SVI_FOLLOW_UP]: ProcessStepsEnum.InterventionFollowUp,
  [PathwayStepsEnum.VENOUS_STENT_FOLLOW_UP]:
    ProcessStepsEnum.InterventionFollowUp,
  [PathwayStepsEnum.VENOPLASTY_FOLLOW_UP]:
    ProcessStepsEnum.InterventionFollowUp,
  [PathwayStepsEnum.POST_OP_SYMPTOMS]: ProcessStepsEnum.PatientMonitoring,
  [PathwayStepsEnum.VENOUS_STENT_OBSTRUCTION]:
    ProcessStepsEnum.PatientMonitoring,
  [PathwayStepsEnum.ACUTE_STENT_THROMBOSIS]: ProcessStepsEnum.PatientMonitoring,
  [PathwayStepsEnum.STENT_RESTENOSIS]: ProcessStepsEnum.PatientMonitoring,
  [PathwayStepsEnum.HEALED]: ProcessStepsEnum.PatientMonitoring,
  [PathwayStepsEnum.RECURRENCE]: ProcessStepsEnum.PatientMonitoring,
};

export const ReasonForNoTracingEnum = {
  InsufficientDataToTrace: 'InsufficientDataToTrace',
  MissingMeasurementMarkers: 'MissingMeasurementMarkers',
};

export const ReasonForNoTracingLabels = {
  [ReasonForNoTracingEnum.InsufficientDataToTrace]:
    'Insufficient Data to Trace',
  [ReasonForNoTracingEnum.MissingMeasurementMarkers]:
    'Missing Measurement Markers',
};

export const reasonForNoTracingOptions = mapEnumToOptions({
  data: ReasonForNoTracingEnum,
  labels: ReasonForNoTracingLabels,
});
