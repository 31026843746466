import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import { formatISOString } from 'utils/date';
import { QUESTIONNAIRES } from 'utils/constants';

import Table from 'components/table';

import Template from 'pages/practitioner/patients/details/dashboard/Template';
import { PATIENT_DASHBOARD_PATHS } from 'navigation/paths';

const patientTableAttributes = [{ title: 'Name' }, { title: 'Last Completed' }];

const QuestionnairesList = () => {
  const { page, handlePageChange } = useTableState();
  const { patientId } = useParams();
  const navigate = useNavigate();
  const {
    data,
    doRequest: getQuestionnaires,
    error,
    isLoading,
  } = useRequest(api.getQuestionnaires);

  useEffect(() => {
    getQuestionnaires({
      PatientId: patientId,
    });
  }, [patientId, getQuestionnaires, page]);

  const rows = useMemo(() => {
    return Object.entries(data?.questionnaires ?? {}).map((questionnaire) => ({
      id: questionnaire[0],
      data: [
        QUESTIONNAIRES[questionnaire[0]],
        questionnaire[1] ? formatISOString(questionnaire[1]) : '-',
      ],
    }));
  }, [data]);

  const handleRowClick = (id) =>
    navigate(`${PATIENT_DASHBOARD_PATHS.QUESTIONNAIRES}/${id}`);

  return (
    <Template title="Questionnaires">
      <Table
        header={patientTableAttributes}
        page={page}
        rows={rows}
        error={error}
        isLoading={isLoading}
        totalPages={0}
        handlePageChange={handlePageChange}
        handleRowClick={handleRowClick}
      />
    </Template>
  );
};

export default QuestionnairesList;
