import { woundImagesStatus } from 'utils/constants';
import { LimbLocationLabel, ReasonForNoTracingLabels } from 'utils/enums';

export const getSurfaceAreaString = (
  surfaceArea,
  reasonForNoTracing,
  status,
) => {
  if (status === woundImagesStatus.open) return 'Processing';
  if (reasonForNoTracing) return ReasonForNoTracingLabels[reasonForNoTracing];

  return surfaceArea
    ? `${surfaceArea.toFixed(2)}cm² | ${(surfaceArea * 0.15500031).toFixed(
        2,
      )}in²`
    : '-- cm² | -- in²';
};

export const getWidthString = (width) =>
  width ? `${width.toFixed(2)}cm | ${(width / 2.54).toFixed(2)}in` : '-';

export const getWoundName = (wound) => {
  const limbLocation =
    (wound.limbLocation && LimbLocationLabel[wound.limbLocation]) ??
    wound.limbLocation;

  const otherLocation =
    wound.limbLocation === 'Other' ? `: ${wound.limbLocationOther}` : '';

  return `${wound.leg} ${wound.limbSide} ${limbLocation}${otherLocation}`;
};
