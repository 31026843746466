import { useMemo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import {
  actions as hospitalSystemActions,
  selectors,
} from 'store/hospital-systems';

const useHospitalSystemDetails = (id, options = { shouldLoad: true }) => {
  const dispatch = useDispatch();

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getHospitalSystem: hospitalSystemActions.doGetHospitalSystem,
        },
        dispatch,
      ),
    [dispatch],
  );

  const hospitalSystemDetails = useSelector((state) =>
    selectors.getHospitalSystemDetails(state, id),
  );
  const isLoading = useSelector(selectors.isDetailsLoading);
  const error = useSelector(selectors.getDetailsError);

  useEffect(() => {
    if (options.shouldLoad && id && !isLoading) {
      actions.getHospitalSystem(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    ...actions,
    hospitalSystemDetails,
    isLoading,
    error,
  };
};

export default useHospitalSystemDetails;
