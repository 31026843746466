import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { actions as woundImageActions } from 'store/wound-images';

const useWoundImageListing = () => {
  const dispatch = useDispatch();
  const { loading, error, data, pageCount } = useSelector(
    (state) => state.woundImages.list,
  );

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getWoundImages: woundImageActions.doGetWoundImages,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    ...actions,
    isLoading: loading,
    error,
    woundImages: data,
    pageCount,
  };
};

export default useWoundImageListing;
