import styled from '@emotion/styled';

export const Link = styled('a')(() => ({
  height: '3rem',
}));

export const Img = styled('img')(() => ({
  height: '100%',
  objectFit: 'contain',
}));
