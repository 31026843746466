import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTheme } from 'context/theme';
import { StyledTextField } from 'utils/styles';

import Tooltip from 'components/tooltip';
import StyledIconButton from 'components/buttons/StyledIconButton';
import { numberStyle, TagWrapper } from 'components/forms/select/Select.styles';

const SelectSearchable = ({
  name,
  label,
  value,
  onChange,
  options,
  placeholder,
  disabled = false,
  isMultiple,
  noTags,
  emptyValue = 0,
  ...remainingProps
}) => {
  const { palette } = useTheme();

  const selectedValues = useMemo(() => {
    if (isMultiple) {
      return (
        value?.reduce((res, val) => {
          const optionFound = options.find((opt) => opt.value === val);
          return optionFound ? [...res, optionFound] : res;
        }, []) || []
      );
    } else {
      return options.find((option) => option.value === value) ?? null;
    }
  }, [isMultiple, options, value]);

  const renderValue = () => {
    if (isMultiple) {
      return (
        <Tooltip
          disabled={!value.length}
          childrenStyle={numberStyle(disabled, palette)}
          content={selectedValues.map(({ value, label }) => (
            <Box key={value}>{label}</Box>
          ))}
        >
          {value.length}
        </Tooltip>
      );
    }
  };

  const handleOptionRemove = (option) => {
    if (!disabled) {
      onChange({
        target: {
          name,
          value: value.filter((item) => item !== option),
        },
      });
    }
  };

  const handleChange = (_e, selectedValue) => {
    onChange({
      target: {
        value: isMultiple
          ? selectedValue?.map((selected) => selected?.value)
          : selectedValue?.value ?? emptyValue,
        name,
      },
    });
  };

  return (
    <Box width="100%">
      <Autocomplete
        id={name}
        name={name}
        value={selectedValues}
        options={options}
        onChange={handleChange}
        disabled={disabled}
        multiple={isMultiple}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option) => value.includes(option.value)}
        renderTags={renderValue}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={label}
            size="small"
            placeholder={placeholder}
          />
        )}
        {...remainingProps}
      />
      {Boolean(isMultiple && !noTags && value?.length && options?.length) && (
        <Grid container spacing={1} sx={{ mt: '0.25rem' }}>
          {selectedValues.map((option, index) => (
            <Grid item key={index}>
              <TagWrapper key={index}>
                <span>{option?.label}</span>
                {!disabled && (
                  <StyledIconButton
                    name="close"
                    onClick={() => handleOptionRemove(option?.value)}
                    data-test={`multi-select-${name}-remove-${option?.value}`}
                    color="grey"
                    isSmall
                    hasMargin
                  />
                )}
              </TagWrapper>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

SelectSearchable.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    PropTypes.string,
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  noTags: PropTypes.bool,
  isMultiple: PropTypes.bool,
  emptyValue: PropTypes.number,
};

export default SelectSearchable;
