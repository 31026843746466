import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

export const initialState = {
  rooms: {
    data: [],
    error: null,
    loading: false,
  },
  selectedRoomId: null,
  windowRoomId: null,
  messages: {
    error: null,
    loading: false,
    sending: false,
    sendingError: null,
    data: {},
  },
  collapse: false,
};

const chat = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_CHAT_ROOMS_REQUEST: {
      return update(state, {
        rooms: {
          error: { $set: null },
          loading: { $set: true },
        },
      });
    }
    case actionTypes.GET_CHAT_ROOMS_SUCCESS: {
      return update(state, {
        rooms: {
          loading: { $set: false },
          data: { $set: action.payload.data },
        },
      });
    }
    case actionTypes.GET_CHAT_ROOMS_FAILURE: {
      return update(state, {
        rooms: {
          loading: { $set: false },
          error: { $set: action.payload.error },
        },
      });
    }
    case actionTypes.SELECTED_CHAT_ROOM: {
      return update(state, {
        selectedRoomId: { $set: action.payload.roomId },
      });
    }
    case actionTypes.GET_CHAT_MESSAGES_REQUEST: {
      return update(state, {
        messages: {
          error: { $set: null },
          loading: { $set: true },
        },
      });
    }
    case actionTypes.GET_CHAT_MESSAGES_SUCCESS: {
      const {
        roomId,
        data: { items, nextCursor },
        cursor,
      } = action.payload;

      const roomMessageData = state.messages.data[roomId];

      return update(state, {
        messages: {
          loading: { $set: false },
          data: {
            [roomId]: {
              $set: {
                items:
                  roomMessageData && cursor
                    ? [...roomMessageData.items, ...items]
                    : items,
                nextCursor,
              },
            },
          },
        },
      });
    }
    case actionTypes.GET_CHAT_MESSAGES_FAILURE: {
      return update(state, {
        messages: {
          loading: { $set: false },
          error: { $set: action.payload.error },
        },
      });
    }
    case actionTypes.SEND_CHAT_MESSAGE_REQUEST: {
      return update(state, {
        messages: {
          sending: { $set: true },
          sendingError: { $set: null },
        },
      });
    }
    case actionTypes.SEND_CHAT_MESSAGE_SUCCESS: {
      const { data } = action.payload;
      return update(state, {
        messages: {
          sending: { $set: false },
          data: {
            [data.roomId]: {
              items: { $unshift: [data] },
            },
          },
        },
      });
    }
    case actionTypes.SEND_CHAT_MESSAGE_FAILURE: {
      return update(state, {
        messages: {
          sending: { $set: false },
          sendingError: { $set: action.payload.error },
        },
      });
    }
    case actionTypes.CHAT_MESSAGE_RECEIVED: {
      const { data } = action.payload;
      if (
        (state.selectedRoomId === data?.roomId ||
          state.windowRoomId === data?.roomId) &&
        !state?.collapse
      ) {
        return update(state, {
          messages: {
            data: {
              [data.roomId]: {
                items: { $unshift: [data] },
              },
            },
          },
        });
      }

      const roomIndex = state.rooms.data.findIndex(
        (room) => room.id === data?.roomId,
      );

      if (roomIndex === -1) return state;

      const lastCount = state.rooms.data[roomIndex]?.unreadCount || 0;

      return update(state, {
        rooms: {
          data: {
            [roomIndex]: {
              unreadCount: { $set: lastCount + 1 },
            },
          },
        },
      });
    }

    case actionTypes.CHAT_MESSAGES_READ: {
      const roomIndex = state.rooms.data.findIndex(
        (room) => room.id === action.payload.roomId,
      );
      return update(state, {
        rooms: {
          data: {
            [roomIndex]: {
              unreadCount: { $set: 0 },
            },
          },
        },
      });
    }

    case actionTypes.CHAT_ROOM_WINDOW_OPEN: {
      return update(state, {
        windowRoomId: { $set: action.payload.roomId },
      });
    }

    case actionTypes.CHAT_ROOM_WINDOW_CLOSE: {
      return update(state, {
        windowRoomId: { $set: null },
      });
    }

    case actionTypes.SET_COLLAPSE_WINDOW: {
      return update(state, {
        collapse: { $set: action.payload.collapse },
      });
    }

    default:
      return state;
  }
};

export default chat;
