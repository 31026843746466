import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import useHospitalSystemDetails from 'hooks/useHospitalSystemDetails';
import { formatISOString } from 'utils/date';
import { getFieldProps, normalizeDiseases } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import CountrySelector from 'components/forms/country-selector';
import Input from 'components/forms/input';
import InputPhoneNumber from 'components/forms/input-phone-number';
import Select from 'components/forms/select';
import Toogle from 'components/toggle';

const renderActivationDate = ({ active, activeAt, inactiveAt }) => {
  if (active && activeAt) return `Activated on: ${formatISOString(activeAt)}`;
  if (!active && inactiveAt)
    return `Deactivated on: ${formatISOString(inactiveAt)}`;
  return null;
};

const HospitalSystemSiteForm = ({ formik, readOnly = false }) => {
  const { hospitalSystemId } = useParams();
  const { hospitalSystemDetails } = useHospitalSystemDetails(hospitalSystemId);

  const diseases = useMemo(
    () => normalizeDiseases(hospitalSystemDetails?.diseases),
    [hospitalSystemDetails],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSectionTitle>Information</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <Toogle
              label="Active"
              info={renderActivationDate(formik.values)}
              {...getFieldProps({
                formik,
                name: 'active',
                disabled: readOnly,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Site Name"
              isRequired
              {...getFieldProps({ formik, name: 'name', disabled: readOnly })}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              label="Address"
              autoComplete="street-address"
              {...getFieldProps({
                formik,
                name: 'street',
                disabled: readOnly,
              })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="City"
              autoComplete="address-level2"
              {...getFieldProps({ formik, name: 'city', disabled: readOnly })}
            />
          </Grid>
          <CountrySelector formik={formik} disabled={readOnly} />
        </Grid>
      </Grid>
      <FormSectionTitle>Details</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={6}>
          <Select
            label="Managed Disease State(s)"
            options={diseases}
            placeholder="Select the managed disease state(s)"
            multiple
            {...getFieldProps({
              formik,
              name: 'diseases',
              disabled: readOnly,
            })}
          />
        </Grid>
      </Grid>
      <FormSectionTitle>Contact</FormSectionTitle>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12} md={4}>
          <Input
            label="Contact Name"
            isRequired
            {...getFieldProps({
              formik,
              name: 'contactName',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Contact Email"
            {...getFieldProps({
              formik,
              name: 'contactEmail',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputPhoneNumber
            label="Contact Phone Number"
            {...getFieldProps({
              formik,
              name: 'contactPhone',
              disabled: readOnly,
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputPhoneNumber
            label="Contact Fax Number"
            {...getFieldProps({
              formik,
              name: 'contactFax',
              disabled: readOnly,
            })}
          />
        </Grid>
      </Grid>
    </form>
  );
};

HospitalSystemSiteForm.propTypes = {
  children: PropTypes.node,
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    values: PropTypes.shape({
      country: PropTypes.string,
      activeAt: PropTypes.string,
    }),
  }),
  readOnly: PropTypes.bool,
  activatedOnText: PropTypes.string,
};

export default HospitalSystemSiteForm;
