import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { INPUT_TYPES } from 'utils/constants';
import DICOMImages from 'theme/media/dicom-images.png';
import DiseaseEtiologyAnatomy from 'theme/media/CVOClassifications.png';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle, FormSubSectionTitle, Label } from 'utils/styles';

import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';
import { DxTable } from 'components/encounters';

const fields = {
  REASON_FOR_CHANGES: 'reasonForChange',
  REASON_FOR_CHANGES_OTHER: 'otherReasonForChange',
  DATE_OF_COMPRESSION_ULTRASOUND: 'dateOfCompressionUltrasound',
  DATE_OF_DUPLEX_ULTRASOUND: 'dateOfDuplexUltrasound',
  DATE_OF_REFLUX_STUDY: 'dateOfRefluxStudy',
  DATE_OF_VENOGRAPHY: 'dateOfVenography',
  DATE_OF_IVUS: 'dateOfIVUS',
  DATE_OF_XRAY: 'dateOfXRay',
  ADDITIONAL_COMMENTS: 'comments',
};

const dxTables = [
  {
    name: 'deepVeinsTorso',
    question: 'Deep Veins - Torso',
    limbs: ['ivc'],
    sections: [{ name: 'inferiorVenaCava', label: 'Inferior Vena Cava' }],
    columns: [{ key: 'obstruction', label: 'Obstruction' }],
  },
  {
    name: 'deepVeinsLowerExtremity',
    question: 'Deep Veins - Lower Extremity',
    limbs: ['left', 'right'],
    sections: [
      { name: 'commonIliac', label: 'Common Iliac' },
      { name: 'internalIliac', label: 'Internal Iliac' },
      { name: 'externalIliac', label: 'External Iliac' },
      { name: 'commonFemoral', label: 'Common Femoral' },
      { name: 'deepFemoral', label: 'Deep Femoral (Profunda)' },
      { name: 'femoral', label: 'Femoral' },
      { name: 'popliteal', label: 'Popliteal' },
      { name: 'anteriorTibial', label: 'Anterior Tibial' },
      { name: 'posteriorTibial', label: 'Posterior Tibial' },
      { name: 'peroneal', label: 'Peroneal' },
    ],
    columns: [
      { key: 'obstruction', label: 'Obstruction' },
      { key: 'veinCompression', label: 'Vein Compression' },
    ],
  },
  {
    name: 'superficialVeinsLowerExtremity',
    question: 'Superficial Veins - Lower Extremity',
    limbs: ['left', 'right'],
    sections: [
      {
        name: 'greatSaphenousVeinAboveKnee',
        label: 'Great Saphenous Vein (Above Knee)',
      },
      {
        name: 'greatSaphenousVeinBelowKnee',
        label: 'Great Saphenous Vein (Below Knee)',
      },
      { name: 'gsvTributaries', label: 'GSV Tributaries' },
      { name: 'smallSaphenousVein', label: 'Small Saphenous Vein' },
      { name: 'ssvTributaries', label: 'SSV Tributaries' },
    ],
    columns: [{ key: 'reflux', label: 'Reflux' }],
  },
  {
    name: 'perforatorVeins',
    question: 'Perforator Veins',
    limbs: ['left', 'right'],
    sections: [{ name: 'perforatorReflux', label: 'Perforator Reflux' }],
    columns: [{ key: 'reflux', label: 'Reflux' }],
  },
];

export const DiagnosticResults = ({ disabled, formik }) => (
  <>
    <FormSectionTitle>Diagnostic Tests Conducted</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of Compression Ultrasound"
          {...getFieldProps({
            formik,
            name: fields.DATE_OF_COMPRESSION_ULTRASOUND,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of Duplex Ultrasound"
          {...getFieldProps({
            formik,
            name: fields.DATE_OF_DUPLEX_ULTRASOUND,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of Reflux Study"
          {...getFieldProps({
            formik,
            name: fields.DATE_OF_REFLUX_STUDY,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of Venography"
          {...getFieldProps({
            formik,
            name: fields.DATE_OF_VENOGRAPHY,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of IVUS"
          {...getFieldProps({
            formik,
            name: fields.DATE_OF_IVUS,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of X-Ray"
          {...getFieldProps({
            formik,
            name: fields.DATE_OF_XRAY,
            disabled,
          })}
        />
      </Grid>
    </Grid>
    <FormSectionTitle>Diagnostic Results - Venous Etiology</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid container item xs={12} spacing={2}>
        <Grid item md={6} xs={12}>
          <Input
            label="Radiology Diagnostic Results"
            type={INPUT_TYPES.COMMENT}
            defaultValue="Venous lesion(s) noted in the right common iliac vein with max lesion stenosis of 75%. Lesion length 4 cm, right CIV diameter 16 mm.  Lesion characteristics: post-thrombotic changes with no extrinsic compression. Right internal iliac, external iliac, and femoral veins are all patent without stenosis."
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Label>DICOM Images</Label>
          <img style={{ width: '100%' }} src={DICOMImages} alt="DICOM Images" />
        </Grid>
      </Grid>
      {dxTables.map((table) => (
        <Grid item xs={12} key={table.name}>
          <DxTable disabled={disabled} formik={formik} {...table} />
        </Grid>
      ))}
    </Grid>
    <FormSectionTitle>Disease Etiology & Anatomy</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FormSubSectionTitle>
          Type 2 Chronic Venous Obstruction: CVO of Iliac Segments
        </FormSubSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Label>
          Type 2 group consists of patients with CVO with extension of
          post-thrombotic changes limited to the pelvic veins
        </Label>
      </Grid>
      <Grid item md={6} xs={12}>
        <img
          style={{ width: '100%' }}
          src={DiseaseEtiologyAnatomy}
          alt="Disease Etiology & Anatomy"
        />
        <Link
          target="_blank"
          rel="noopener"
          href="https://www.hmpgloballearningnetwork.com/site/vdm/presentations/decoding-chronic-venous-obstruction-introducing-new-classification-system"
        >
          CVO Classification Criteria
        </Link>
      </Grid>
    </Grid>
  </>
);

export const diagnosticResultsInitialValues = dxTables.reduce(
  (res, table) => ({
    ...res,
    [table.name]: {
      ...table.limbs.reduce(
        (limbRes, limb) => ({
          ...limbRes,
          [limb]: {
            ...table?.columns?.reduce(
              (columnsRest, column) => ({
                ...columnsRest,
                [column.key]: {
                  ...table.sections.reduce(
                    (sectionsRes, section) => ({
                      ...sectionsRes,
                      [section.name]: {
                        selected: 'Unknown',
                        score: null,
                      },
                    }),
                    {},
                  ),
                },
              }),
              {},
            ),
          },
        }),
        {},
      ),
    },
  }),
  {},
);

export default DiagnosticResults;

DiagnosticResults.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
