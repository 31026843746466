import React from 'react';

import { DiseaseEnum } from 'utils/enums';
import { encounterFormsEnum } from 'utils/encounters';

import {
  ivusTableInitialValues,
  parseApi2Form,
} from 'components/encounters/procedures-sections/ProcedureSidesSection';

import AdditionalInformation from './additional-information';
import AnkleBrachialPressureIndex from './ankle-brachial-pressure-index';
import CompressionForm from './compression-form';
import CVOInterventionPlanning from './cvo-intervention-planning';
import DiagnosticResults, {
  diagnosticResultsInitialValues,
} from './diagnostic-results';
import FollowUpVLU from './follow-up-vlu';
import PatientMedicalHistory from './patient-medical-history';
import SVI, { sviInitialValues } from './svi';
import SuspectedVLU from './suspected-vlu';
import StentRe from './stent-re';
import Ultrasound from './ultrasound';
import VenographyCtMr from './venography-ct-mr';
import Venoplasty from './venoplasty';
import VenousStent, { venousStentInitialValues } from './venousStent';
import WoundAssessment, { parseWoundAssessmentData } from './wound-assessment';
import XRay from './x-ray';

import Pharmaceutical, {
  parseApi2Form as pharmaParseApi2Form,
  parseForm2Api as pharmaParseForm2Api,
} from './pharmaceutical';

export const encountersForms = [
  {
    type: encounterFormsEnum.ADDITIONAL_INFORMATION,
    Form: AdditionalInformation,
  },
  {
    type: encounterFormsEnum.ANKLE_BRACHIAL_PRESSURE_INDEX,
    Form: AnkleBrachialPressureIndex,
  },
  {
    type: encounterFormsEnum.COMPRESSION_FORM,
    Form: CompressionForm,
    enforceDiseases: [DiseaseEnum.VLU],
  },
  {
    type: encounterFormsEnum.CVO_INTERVENTION_PLANNING,
    Form: CVOInterventionPlanning,
  },
  {
    type: encounterFormsEnum.DIAGNOSTIC_RESULTS,
    Form: DiagnosticResults,
    initialValues: diagnosticResultsInitialValues,
  },
  {
    type: encounterFormsEnum.IVUS,
    Form: () => <div></div>,
  },
  {
    type: encounterFormsEnum.MEDICATION_FORM,
    Form: Pharmaceutical,
    parseApi2Form: pharmaParseApi2Form,
    parseForm2Api: pharmaParseForm2Api,
  },
  {
    type: encounterFormsEnum.PATIENT_MEDICAL_HISTORY,
    Form: PatientMedicalHistory,
  },
  {
    type: encounterFormsEnum.PLETHYSMOGRAPHY,
    Form: () => <div></div>,
  },
  {
    type: encounterFormsEnum.REFLUX_STUDY,
    Form: () => <div></div>,
  },
  {
    type: encounterFormsEnum.SUSPECTED_VLU,
    Form: SuspectedVLU,
  },
  {
    type: encounterFormsEnum.SVI_INTERVENTION_PLANNING,
    Form: () => <div></div>,
  },
  {
    type: encounterFormsEnum.SVI_PROCEDURE,
    keyType: 'sviProcedure',
    Form: SVI,
    enforceDiseases: [DiseaseEnum.VLU],
    initialValues: sviInitialValues,
  },
  {
    type: encounterFormsEnum.ULTRASOUND,
    Form: Ultrasound,
  },
  {
    type: encounterFormsEnum.VENOGRAPHY_CT_MR,
    Form: VenographyCtMr,
  },
  {
    type: encounterFormsEnum.VENOPLASTY,
    Form: Venoplasty,
    enforceDiseases: [DiseaseEnum.VLU, DiseaseEnum.DVT],
    initialValues: ivusTableInitialValues,
    parseApi2Form: parseApi2Form,
  },
  {
    type: encounterFormsEnum.VENOUS_STENT,
    Form: VenousStent,
    enforceDiseases: [DiseaseEnum.VLU, DiseaseEnum.DVT],
    initialValues: venousStentInitialValues,
    parseApi2Form: parseApi2Form,
  },
  {
    type: encounterFormsEnum.VENOUS_STENT_REINTERVENTION,
    Form: StentRe,
    enforceDiseases: [DiseaseEnum.VLU, DiseaseEnum.DVT],
  },
  {
    type: encounterFormsEnum.VLU_FOLLOW_UP_VISIT,
    Form: FollowUpVLU,
    enforceDiseases: [DiseaseEnum.VLU],
  },
  {
    type: encounterFormsEnum.VLU_INTAKE,
    Form: () => <div></div>,
    enforceDiseases: [DiseaseEnum.VLU],
  },
  {
    type: encounterFormsEnum.WOUND_ASSESSMENT,
    Form: WoundAssessment,
    enforceDiseases: [DiseaseEnum.VLU],
    parseApi2Form: (values) => parseWoundAssessmentData(values, false),
    parseForm2Api: (values) => parseWoundAssessmentData(values, true),
  },
  {
    type: encounterFormsEnum.X_RAY,
    Form: XRay,
  },
];
