import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

import Loader from 'components/loader';

import ListItem from './ListItem';
import { StyledDivider, StyledList, StyledPagination } from './List.styles';

const List = ({ status }) => {
  const { patientId } = useParams();
  const { clearFeedback, setSuccessFeedback, setErrorFeedback } = useFeedback();

  const {
    data: encountersList,
    doRequest: getEncounters,
    isLoading,
  } = useRequest(api.getEncounters);

  const [page, setPage] = useState(encountersList?.page || 1);
  const handlePageChange = (_e, value) => setPage(value);

  const handleRefresh = useCallback(() => {
    getEncounters({
      PatientId: patientId,
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE / 4,
      Status: status,
    });
  }, [page, getEncounters, patientId, status]);

  useEffect(handleRefresh, [handleRefresh]);

  const handleDelete = useCallback(
    (encounterId) => {
      clearFeedback();
      api
        .deleteEncounter(encounterId)
        .then(() => {
          handleRefresh();
          setSuccessFeedback('Encounter Deleted');
        })
        .catch(() => {
          setErrorFeedback('Error deleting encounter');
        });
    },
    [handleRefresh, setSuccessFeedback, setErrorFeedback, clearFeedback],
  );

  if (isLoading || !encountersList) return <Loader />;

  if (encountersList.count === 0)
    return <p>{'There are no ' + status.toLowerCase() + ' encounters.'}</p>;

  return (
    <>
      <StyledList>
        {encountersList.data.map((encounter, idx) => (
          <React.Fragment key={encounter.id}>
            {idx > 0 && <StyledDivider />}
            <ListItem
              {...encounter}
              handleDelete={() => handleDelete(encounter.id)}
            />
          </React.Fragment>
        ))}
      </StyledList>
      {encountersList.pageCount > 1 ? (
        <StyledPagination
          page={page}
          count={encountersList.pageCount}
          color="primary"
          onChange={handlePageChange}
        />
      ) : null}
    </>
  );
};

List.propTypes = {
  status: PropTypes.string.isRequired,
};

export default List;
