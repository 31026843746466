import { createTheme } from '@mui/material/styles';

import { weight, family } from 'theme/fonts';

const defaultTypography = {
  fontSize: '1rem',
  fontFamily: family.openSans,
  fontWeight: weight.regular,
};

export default createTheme({
  palette: {
    primary: {
      main: '#1da9bb',
      light: '#c8f1f7',
      dark: '#1994a4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#1da9bb',
    },
    error: {
      main: '#e15240',
      light: '#eb8c80',
      dark: '#bb2f1d',
    },
    success: {
      main: '#a9eb80',
      dark: '#5abb1d',
    },
    warning: {
      main: '#FFC562',
    },
    table: {
      headerBackground: '#c8f1f7',
      headerText: '#1d1d1d',
    },
    base: {
      black: '#1d1d1d',
      white: '#ffffff',
    },
    grey: {
      100: '#f9f9f9',
      300: '#d7d7d7',
      500: '#8e8e8e',
      700: '#616161',
    },
    gradient: {
      main: `linear-gradient(45deg, #1da9bb, #1994a4, #c8f1f7)`,
    },
    wounds: {
      content: 'rgba(100, 255, 0, 0.1)',
      selected: 'rgba(100, 0, 255, 0.15)',
    },
    chartColorScale: ['#2e78b7', '#107B91', '#009FAE', '#3EA39E', '#82E9DE'],
    tonalOffset: 0.05,
  },
  typography: {
    button: defaultTypography,
    body1: defaultTypography,
    body2: defaultTypography,
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: { textTransform: 'none' },
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
