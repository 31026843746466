import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import {
  USER_GROUPS,
  adminUserRolesOptions,
  userRolesOptions,
} from 'utils/constants';

import Select from 'components/forms/select';
import StyledIconButton from 'components/buttons/StyledIconButton';

import PractitionerFields from './PractitionerFields';
import SystemRoleFields from './SystemRoleFields';

const ROLE_SPECIFIC_COMPONENTS = {
  [USER_GROUPS.practitioner]: {
    component: PractitionerFields,
  },
  [USER_GROUPS.hospitalAdministrator]: {
    component: SystemRoleFields,
    fieldName: 'hospitalAdminSystem',
  },
  [USER_GROUPS.reporter]: {
    component: SystemRoleFields,
    fieldName: 'reporterSystem',
  },
  [USER_GROUPS.eCRF]: {
    component: SystemRoleFields,
    fieldName: 'eCRFSystem',
  },
};

const UserRoles = ({ formik, readOnly }) => {
  const rolesOptions = readOnly ? userRolesOptions : adminUserRolesOptions;
  const selectedRoles = formik.values.roles || [];
  const selectedRolesLength = selectedRoles.length;

  const [showNew, setShowNew] = useState(!selectedRolesLength);

  useEffect(() => {
    if (selectedRolesLength === 0 && !showNew) {
      setShowNew(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRolesLength]);

  const addRole = (ev) => {
    const { value } = ev.target;
    if (!selectedRoles.includes(value)) {
      formik.setFieldValue('roles', [...selectedRoles, ev.target.value]);
      formik.setFieldTouched(`roles.${selectedRolesLength}`, true, false);

      setShowNew(false);
    }
  };

  const replaceRole = (index, ev) => {
    const { value } = ev.target;
    if (!selectedRoles.includes(value)) {
      formik.setFieldValue('roles', [
        ...selectedRoles.slice(0, index),
        ev.target.value,
        ...selectedRoles.slice(index + 1),
      ]);
    }
  };

  const removeRole = (index) => {
    const newRoles = [
      ...selectedRoles.slice(0, index),
      ...selectedRoles.slice(index + 1),
    ];
    formik.setFieldValue('roles', newRoles);
    if (!newRoles.length) {
      setShowNew(true);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item container spacing={2}>
          {selectedRoles.map((role, index) => {
            const ExtraFields = ROLE_SPECIFIC_COMPONENTS[role]?.component;
            return (
              <Grid item container spacing={2} key={index}>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Select
                      name={`roles.${index}`}
                      value={role}
                      options={rolesOptions}
                      onChange={(ev) => replaceRole(index, ev)}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.roles?.[index] &&
                        formik.errors.roles?.[index]
                      }
                      disabled={readOnly}
                    />
                    {!readOnly && (
                      <StyledIconButton
                        name="close"
                        color="primary"
                        onClick={() => removeRole(index)}
                        hasMargin
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  {ExtraFields && (
                    <ExtraFields
                      formik={formik}
                      readOnly={readOnly}
                      fieldName={ROLE_SPECIFIC_COMPONENTS[role]?.fieldName}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        {(selectedRolesLength === 0 ||
          selectedRolesLength < rolesOptions.length) &&
          showNew && (
            <Grid item container spacing={2} style={{ width: '100%' }}>
              <Grid item xs={6}>
                <Select
                  name={`roles.${selectedRolesLength}`}
                  options={rolesOptions.filter(
                    (role) => !selectedRoles.includes(role.value),
                  )}
                  onChange={addRole}
                  error={
                    formik.touched?.roles &&
                    typeof formik.errors.roles === 'string' &&
                    formik.errors.roles
                  }
                  disabled={readOnly}
                  dataTest="add-new-role"
                />
              </Grid>
            </Grid>
          )}
      </Grid>
      {selectedRolesLength < rolesOptions.length && !readOnly && (
        <Button
          type="button"
          onClick={() => setShowNew(true)}
          startIcon={<AddIcon />}
          sx={{ marginTop: '1rem' }}
        >
          Add New Type
        </Button>
      )}
    </>
  );
};

UserRoles.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    touched: PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.bool),
    }),
    errors: PropTypes.shape({
      roles: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  }),
  readOnly: PropTypes.bool,
};

export default UserRoles;
