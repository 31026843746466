import styled from '@emotion/styled';

import FormControlLabel from '@mui/material/FormControlLabel';

export const StyledRow = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '4fr repeat(6, 1fr)',
  gap: '0.5rem',
  marginBottom: '0.5rem',

  '&:last-child': {
    marginBottom: 0,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '.MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
}));
