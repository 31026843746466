import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FeedbackTypeEnum } from 'utils/enums';

import { doSetFeedback, doClearFeedback } from 'store/feedback/actions';

export const useFeedback = () => {
  const dispatch = useDispatch();
  const feedback = useSelector((state) => state.feedback);

  const clearFeedback = useCallback(() => {
    dispatch(doClearFeedback());
  }, [dispatch]);

  const setSuccessFeedback = useCallback(
    (message) => {
      message &&
        dispatch(doSetFeedback({ type: FeedbackTypeEnum.SUCCESS, message }));
    },
    [dispatch],
  );

  const setErrorFeedback = useCallback(
    (message, details) => {
      if (!message) return;
      dispatch(
        doSetFeedback({ type: FeedbackTypeEnum.ERROR, message, details }),
      );
    },
    [dispatch],
  );

  return {
    feedback,
    setSuccessFeedback,
    setErrorFeedback,
    clearFeedback,
  };
};

export default useFeedback;
