import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import lowerFirst from 'lodash/lowerFirst';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import api from 'core/api';
import useEncounterScreen from 'hooks/useEncounterScreen';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import useUserDetails from 'hooks/useUserDetails';
import { PRACTITIONER_PATHS } from 'navigation/paths';
import { encounterStatusEnum } from 'utils/encounters';
import { getErrorMessage, getErrorDetails } from 'utils/error';

import ErrorMessage from 'components/error-message';
import Loader from 'components/loader';

import EncounterControls from './EncounterControls';
import EncounterFormSubmit from './EncounterFormSubmit';
import EncounterTabs from './EncounterTabs';

export const submitActions = {
  SAVE: 'save',
  NEXT: 'next',
  PREVIOUS: 'previous',
  TAB: 'tab',
  SUBMIT: 'submit',
};

const EncounterForm = ({
  Form,
  parseApi2Form,
  parseForm2Api,
  encounter,
  forms,
  initialValues,
  type,
  keyType,
}) => {
  const navigate = useNavigate();
  const { patientId, encounterId } = useParams();

  const {
    doRequest: getForm,
    data: formData,
    error: formError,
    isLoading,
  } = useRequest(api.getEncounterNew);

  const { user, getUser } = useUserDetails(patientId);
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const { hasPrevious, hasNext, goBack, goNext, currentPath } =
    useEncounterScreen(forms);

  const isDraft = encounter?.status === encounterStatusEnum.DRAFT;

  const fetchForm = useCallback(() => {
    getForm(encounterId, { assessmentType: type });
  }, [encounterId, getForm, type]);

  useEffect(fetchForm, [fetchForm]);

  const diseases = useMemo(
    () =>
      user.patient.diseases?.reduce(
        (res, { disease, endedAt }) => ({
          ...res,
          [disease]: !endedAt,
        }),
        {},
      ),
    [user.patient.diseases],
  );

  const handleSubmit = () => {
    api
      .updateEncounter(encounterId, 'submit')
      .then(() => {
        /* updates Pathway Step form */
        getUser(patientId);
        navigate(`${PRACTITIONER_PATHS.patients}/${patientId}/home`);
        setSuccessFeedback('Submitted');
      })
      .catch((error) =>
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error)),
      );
  };

  const formKey = React.useMemo(
    () => keyType || lowerFirst(type),
    [keyType, type],
  );

  const handleSave =
    ({ action, tab }) =>
    async (data) => {
      clearFeedback();
      try {
        if (isDraft) {
          await api.updateEncounterNew(encounterId, {
            encounterType: encounter?.type,
            [formKey]: parseForm2Api ? parseForm2Api(data) : data,
          });
        }
        switch (action) {
          case submitActions.SAVE:
            fetchForm();
            setSuccessFeedback('Updated');
            break;
          case submitActions.NEXT:
            goNext();
            break;
          case submitActions.PREVIOUS:
            goBack();
            break;
          case submitActions.TAB:
            navigate(`../${tab}`);
            break;
          case submitActions.SUBMIT:
            handleSubmit();
            break;
        }
      } catch (error) {
        setErrorFeedback(getErrorMessage(error));
      }
    };

  const initValues = useMemo(() => {
    if (!formData) return {};
    if (formData[formKey])
      return parseApi2Form
        ? parseApi2Form(formData[formKey])
        : formData[formKey];

    return initialValues ?? {};
  }, [formData, parseApi2Form, formKey, initialValues]);

  const loadingInfo = useMemo(() => {
    if (formError) return <ErrorMessage message="Error loading form" />;
  }, [formError]);

  if (!formData || isLoading) return <Loader />;

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSave({ action: submitActions.SAVE })}
      enableReinitialize
    >
      {(formik) => (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              gap: '1rem',
              py: '1rem',
            }}
          >
            <EncounterTabs
              diseases={diseases}
              forms={forms}
              currentPath={currentPath}
              onTabClick={(tab) =>
                handleSave({ action: submitActions.TAB, tab })(formik.values)
              }
            />
            {isDraft && (
              <EncounterFormSubmit
                onSubmit={() =>
                  handleSave({ action: submitActions.SUBMIT })(formik.values)
                }
              />
            )}
          </Box>
          {loadingInfo || (
            <form onSubmit={formik.handleSubmit}>
              <Form formik={formik} disabled={!isDraft} diseases={diseases} />
              <EncounterControls
                handleSave={handleSave}
                isDraft={isDraft}
                hasPrevious={hasPrevious}
                hasNext={hasNext}
                formik={formik}
              />
            </form>
          )}
        </>
      )}
    </Formik>
  );
};

EncounterForm.propTypes = {
  Form: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  keyType: PropTypes.string,
  parseApi2Form: PropTypes.func,
  parseForm2Api: PropTypes.func,
  encounter: PropTypes.shape({
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  forms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialValues: PropTypes.shape({}),
};

export default EncounterForm;
