import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import StyledIconButton from 'components/buttons/StyledIconButton';

export const RowActions = ({ handleEdit, handleDelete }) => (
  <Box display="flex" justifyContent="flex-end" mr={2}>
    <StyledIconButton name="edit" isSmall onClick={handleEdit} />
    <StyledIconButton name="delete" isSmall hasMargin onClick={handleDelete} />
  </Box>
);

RowActions.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
