import styled from '@emotion/styled';

import Badge from '@mui/material/Badge';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '.MuiBadge-badge': {
    top: '-1rem',
    left: '2.75rem',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.base.white,
    fontSize: '0.5rem',
  },
}));
