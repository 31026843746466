import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import useHospitalSystemSiteList from 'hooks/useHospitalSystemSiteList';
import useTableState from 'hooks/useTableState';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { DiseaseLabels } from 'utils/enums';

import Table from 'components/table';
import ItemsTooltip from 'components/tooltip/ItemsTooltip';

import { siteTableAttributes } from './utils';

const DEFAULT_SORT_BY = 'Name';
const DEFAULT_SORT_DIRECTION = 'Asc';

const HospitalSystemSiteList = ({ hospitalSystemId }) => {
  const navigate = useNavigate();
  const { page, sortBy, sortOrder, handlePageChange, handleSortChange } =
    useTableState(DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION);

  const listingFilters = {
    HospitalSystemId: hospitalSystemId,
    Sort: sortBy,
    SortDirection: sortOrder,
    Page: page - 1,
    PageSize: DEFAULT_PAGE_SIZE,
  };

  const { getHospitalSystemSites, items, isLoading, pageCount } =
    useHospitalSystemSiteList(listingFilters);

  useEffect(() => {
    if (!hospitalSystemId || isLoading) return;

    getHospitalSystemSites(hospitalSystemId, listingFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalSystemId, page, sortBy, sortOrder]);

  const rows = useMemo(
    () =>
      items.map(({ id, name, active, diseases }) => ({
        id,
        data: [
          name,
          active ? 'Yes' : 'No',
          <ItemsTooltip
            key={id}
            items={diseases.map((name) => DiseaseLabels[name])}
          >
            {diseases.length}
          </ItemsTooltip>,
        ],
      })),
    [items],
  );

  return (
    <>
      <Box mt={2}></Box>
      <Table
        header={siteTableAttributes(handleSortChange)}
        rows={rows}
        page={page}
        isLoading={isLoading}
        totalPages={pageCount}
        orderBy={sortBy}
        orderDirection={sortOrder}
        handlePageChange={handlePageChange}
        handleRowClick={(id) => navigate(`sites/${id}`)}
      />
    </>
  );
};

HospitalSystemSiteList.propTypes = {
  hospitalSystemId: PropTypes.string.isRequired,
};

export default HospitalSystemSiteList;
