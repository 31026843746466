import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useFormikContext } from 'formik';

import { Button, Stack } from '@mui/material';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import { AssetTypeEnum } from 'utils/enums';
import { Label } from 'utils/styles';

import FilePreview from './FilePreview';

const FileUpload = ({
  disabled,
  label,
  name,
  onChange,
  type = AssetTypeEnum.ASSESSMENT,
  accept = 'image/*',
}) => {
  const [preview, setPreview] = React.useState(null);
  const { values } = useFormikContext();
  const { clearFeedback, setErrorFeedback } = useFeedback();

  // TODO: replace by useMountEffect
  React.useEffect(() => {
    const assetId = values?.[name];
    if (!assetId) return;
    api
      .getAssetDownloadUrl({ assetIds: [assetId] })
      .then(({ data: { assets } }) => {
        setPreview(assets[0]);
      });
  }, [values, name]);

  const handleFileChange = async (e) => {
    const [file] = e.target.files;
    clearFeedback();
    if (!file) return;
    try {
      const {
        data: { id, uploadUrl, ...asset },
      } = await api.getAssetUploadUrl({
        type,
        fileName: file.name,
        contentType: file.type,
      });

      await axios.put(uploadUrl, file, {
        headers: { 'Content-Type': file.type },
      });

      setPreview(asset);

      onChange({
        target: {
          name,
          value: id,
        },
      });
    } catch (error) {
      setErrorFeedback('Error uploading image');
    }
  };

  return (
    <Stack>
      {label && (
        <Label htmlFor={name} disabled={disabled}>
          {label}
        </Label>
      )}
      <input
        id={name}
        name={name}
        accept={accept}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <label htmlFor={name}>
        <Button
          variant="contained"
          component="span"
          disabled={disabled}
          sx={{ width: 'fit-content' }}
        >
          Upload File
        </Button>
      </label>
      {preview && <FilePreview asset={preview} />}
    </Stack>
  );
};

FileUpload.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  accept: PropTypes.string,
};

export default FileUpload;
