import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES, yesNoOptions, OTHER_VALUE } from 'utils/constants';
import { limbOptions } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import FileUpload from 'components/forms/file-upload';
import Input from 'components/forms/input';

const typeVenographyOptions = [
  { value: 'Ct', label: 'CT' },
  { value: 'MR', label: 'MR' },
  { value: 'Invasive', label: 'Invasive' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const fields = {
  DATE_VENOGRAPHY: 'dateOfVenography',
  LIMB_SIDE: 'limbAssessed',
  TYPE_VENOGRAPHY_LEFT: 'left',
  TYPE_VENOGRAPHY_OTHER_LEFT: 'leftOther',
  TYPE_VENOGRAPHY_RIGHT: 'right',
  TYPE_VENOGRAPHY_OTHER_RIGHT: 'rightOther',
  ASSET_ID: 'assetId',
  ADDITIONAL_NOTES: 'additionalNotes',
  RESULTS_DIAGNOSTIC_TEST: 'patientHasResultsFromThisDiagnosticTest',
};

const VenographyCtMr = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>Venography</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of Venography"
          isRequired
          {...getFieldProps({
            formik,
            name: fields.DATE_VENOGRAPHY,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="Limb(s) Assessed"
          options={limbOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.LIMB_SIDE,
            disabled,
            changeOverride: (value) => {
              formik?.setValues(
                update(formik?.values, {
                  [fields.LIMB_SIDE]: {
                    $set: value,
                  },
                  [fields.TYPE_VENOGRAPHY_LEFT]: {
                    $set: null,
                  },
                  [fields.TYPE_VENOGRAPHY_RIGHT]: {
                    $set: null,
                  },
                }),
              );
            },
          })}
        />
      </Grid>
      {['Left', 'Both'].includes(formik.values[fields.LIMB_SIDE]) && (
        <Grid item xs={12} md={4}>
          <Chips
            label="Type of Venography (Left Limb)"
            options={typeVenographyOptions}
            isRequired
            otherField={{
              name: fields.TYPE_VENOGRAPHY_OTHER_LEFT,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: fields.TYPE_VENOGRAPHY_LEFT,
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.TYPE_VENOGRAPHY_LEFT]: {
                        $set: value,
                      },
                      [fields.TYPE_VENOGRAPHY_OTHER_RIGHT]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
      )}
      {['Right', 'Both'].includes(formik.values[fields.LIMB_SIDE]) && (
        <Grid item xs={12} md={4}>
          <Chips
            label="Type of Venography (Right Limb)"
            options={typeVenographyOptions}
            isRequired
            otherField={{
              name: fields.TYPE_VENOGRAPHY_OTHER_RIGHT,
              value: OTHER_VALUE,
            }}
            {...getFieldProps({
              formik,
              name: fields.TYPE_VENOGRAPHY_RIGHT,
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      [fields.TYPE_VENOGRAPHY_RIGHT]: {
                        $set: value,
                      },
                      [fields.TYPE_VENOGRAPHY_OTHER_RIGHT]: {
                        $set: null,
                      },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
      )}
    </Grid>
    <FormSectionTitle>Additional Information</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FileUpload
          label="File Upload"
          {...getFieldProps({
            formik,
            name: fields.ASSET_ID,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Additional Notes"
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: fields.ADDITIONAL_NOTES,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Chips
          label="Do you have the results from this diagnostic test? If yes, please fill out the Diagnostic Results Form with findings from the Venography."
          options={yesNoOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.RESULTS_DIAGNOSTIC_TEST,
            disabled,
          })}
        />
      </Grid>
    </Grid>
  </>
);

export default VenographyCtMr;

VenographyCtMr.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
