import * as Yup from 'yup';
import { phoneValidation } from 'utils/validations';

export const patientSchema = Yup.object({
  active: Yup.bool().required('Required'),
  firstName: Yup.string().required('Required').max(100, 'First name too long'),
  lastName: Yup.string().required('Required').max(100, 'Last name too long'),
  street: Yup.string().max(180, 'Address too long'),
  city: Yup.string().max(100, 'City too long'),
  state: Yup.string().max(50, 'State too long'),
  country: Yup.string().max(3, 'Country too long'),
  email: Yup.string()
    .required('Required')
    .email('Invalid Email')
    .max(2048, 'Email too long'),
  phoneNumber: phoneValidation().required('Required'),
  birthDate: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  practitioners: Yup.array().min(1, 'Select at least one practitioner'),
  diseases: Yup.array().min(1, 'Select at least one disease state'),
});
