import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import { ErrorText, Label } from 'utils/styles';

import { StyledCheckbox } from './MultipleChoice.styles';

const MultipleChoice = ({
  label,
  options = [],
  name,
  value,
  onChange,
  disabled,
  error,
  required,
}) => {
  const handleChange = (option) => (ev) => {
    const { checked } = ev.target;

    const newValue = checked
      ? [...value, option]
      : value.filter((newOption) => newOption !== option);

    onChange({
      target: {
        value: newValue,
        name,
      },
    });
  };

  return (
    <>
      {label && (
        <Label htmlFor={name} error={!!error} required={required}>
          {label}
        </Label>
      )}
      {options.map(({ label: optionLabel, value: optionValue }) => (
        <FormControlLabel
          key={optionValue}
          label={optionLabel}
          style={{ display: 'flex' }}
          control={
            <StyledCheckbox
              name={name}
              disabled={disabled}
              checked={value.includes(optionValue)}
              onChange={handleChange(optionValue)}
            />
          }
        />
      ))}
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

MultipleChoice.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
};

export default MultipleChoice;
