import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';

import { INPUT_TYPES, yesNoOptions } from 'utils/constants';
import { encounterFormsEnum } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle, FormSubSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import Input from 'components/forms/input';

const fields = {
  VEINS_SUCCESSFULLY_OCCLUDED: 'allTreatedVeinsSuccessfullyOccluded',
  WAS_TECHNICAL_SUCCESS_ACHIEVIED: 'wasTechnicalSuccessAchieved',
  POST_PROCEDURAL_ESTIMATED_MAXIMUM_STENOSIS:
    'postProceduralEstimatedMaximumStenosisPercent',
  ADDITIONAL_NOTES_TECHNICAL_SUCCESS: 'technicalSuccessAdditionalNotes',
  ADVERSE_EVENTS_OR_DEVICE_DEFICIENCIES:
    'anyAdverseEventsOrDeviceDeficienciesRelatedToTheProcedure',
  SERIOUS_ADVERSE_EVENTS: 'anySeriousAdverseEventsRelatedToTheProcedure',
  PROCEDURE_STAGED: 'procedureStaged',
};

export const EndOfProcedureAssessments = ({ encounter, disabled }) => {
  const formik = useFormikContext();

  return (
    <>
      <FormSectionTitle>End of Procedure Assessments</FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12}>
          <FormSubSectionTitle>Technical Success</FormSubSectionTitle>
        </Grid>
        {encounter === encounterFormsEnum.SVI_PROCEDURE ? (
          <Grid item xs={12} md={4}>
            <Chips
              label="Were all treated veins successfully occluded?"
              options={yesNoOptions}
              isRequired
              {...getFieldProps({
                formik,
                name: fields.VEINS_SUCCESSFULLY_OCCLUDED,
                disabled,
              })}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={8}>
              <Chips
                label="Was Technical Success Achieved? Technical success is defined as successful recanalization restoring rapid anterograde flow in the targeted vessel."
                options={yesNoOptions}
                isRequired
                {...getFieldProps({
                  formik,
                  name: fields.WAS_TECHNICAL_SUCCESS_ACHIEVIED,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Post-Procedural Estimated Maximum Stenosis"
                type={INPUT_TYPES.NUMERIC}
                isRequired
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                {...getFieldProps({
                  formik,
                  name: fields.POST_PROCEDURAL_ESTIMATED_MAXIMUM_STENOSIS,
                  disabled,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Additional Notes"
                type={INPUT_TYPES.COMMENT}
                {...getFieldProps({
                  formik,
                  name: fields.ADDITIONAL_NOTES_TECHNICAL_SUCCESS,
                  disabled,
                })}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormSubSectionTitle>Adverse Events</FormSubSectionTitle>
        </Grid>
        <Grid item xs={12}>
          <Chips
            label="Were there any adverse events or device deficiencies related to the procedure? If yes, fill out Adverse Event form."
            options={yesNoOptions}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.ADVERSE_EVENTS_OR_DEVICE_DEFICIENCIES,
              disabled,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Chips
            label="Were there any serious adverse events related to the procedure? If yes, fill out Serious Adverse Event form."
            options={yesNoOptions}
            isRequired
            {...getFieldProps({
              formik,
              name: fields.SERIOUS_ADVERSE_EVENTS,
              disabled,
            })}
          />
        </Grid>
        {encounter === encounterFormsEnum.SVI_PROCEDURE && (
          <Grid item xs={12}>
            <Chips
              label="Was the procedure staged? If yes, please complete a separate SVI Procedure Form for each staged procedure."
              options={yesNoOptions}
              isRequired
              {...getFieldProps({
                formik,
                name: fields.PROCEDURE_STAGED,
                disabled,
              })}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

EndOfProcedureAssessments.propTypes = {
  encounter: PropTypes.string,
  disabled: PropTypes.bool,
};
