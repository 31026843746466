import api from 'core/api';

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from './actionTypes';

export const doGetUsers = (options) => async (dispatch) => {
  dispatch({
    type: GET_USERS_REQUEST,
  });

  try {
    const { data } = await api.getUsers(options);

    dispatch({
      payload: data,
      type: GET_USERS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_USERS_FAILURE,
    });
  }
};

export const doGetUser = (id) => async (dispatch) => {
  dispatch({
    type: GET_USER_REQUEST,
  });

  try {
    const { data } = await api.getUser(id);

    dispatch({
      payload: {
        id,
        user: data,
      },
      type: GET_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_USER_FAILURE,
    });
  }
};

export const doUpdateUser = (id, data) => async (dispatch) => {
  const { data: user } = await api.updateUser(id, data);

  dispatch({
    payload: {
      id,
      user,
    },
    type: GET_USER_SUCCESS,
  });
};
