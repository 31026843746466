import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';

const ErrorMessage = ({ message }) => (
  <Alert variant="filled" severity="error">
    {message}
  </Alert>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
