import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { StyledTooltip } from './Tooltip.styles';

const Tooltip = ({
  content,
  placement = 'bottom',
  useArrow = true,
  children,
  childrenStyle,
  disabled,
}) => {
  return (
    <StyledTooltip
      title={content}
      placement={placement}
      arrow={useArrow}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
    >
      <Box component="span" sx={childrenStyle}>
        {children}
      </Box>
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node,
  placement: PropTypes.string,
  useArrow: PropTypes.bool,
  childrenStyle: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default Tooltip;
