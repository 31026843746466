import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ConfirmDialog from 'components/confirm-dialog';
import StyledIconButton from 'components/buttons/StyledIconButton';

const ListItemDelete = ({ handleDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <StyledIconButton
        name="delete"
        hasMargin
        onClick={() => setShowDeleteModal(true)}
      />
      <ConfirmDialog
        open={showDeleteModal}
        handleCancel={() => setShowDeleteModal(false)}
        handleConfirm={() => {
          setShowDeleteModal(false);
          handleDelete();
        }}
        title="Are you sure you want to delete this Encounter draft?"
      />
    </>
  );
};

ListItemDelete.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};

export default ListItemDelete;
