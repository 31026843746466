import React from 'react';

import Grid from '@mui/material/Grid';

import CompressionWidget from './compression/CompressionWidget';
import PainMobilityWidget from './pain-mobility/widget';
import MedicationComplianceWidget from './medication-compliance/MedicationComplianceWidget';
import WoundSizeWidget from './wound-size-widget';

const DataDashboard = () => (
  <Grid container item spacing={2}>
    <Grid container item lg={4} md={12} spacing={2}>
      <Grid item xs={12}>
        <CompressionWidget />
      </Grid>
      <Grid item xs={12}>
        <MedicationComplianceWidget />
      </Grid>
    </Grid>
    <Grid container item lg={8} md={12} spacing={2}>
      <Grid item lg={6} md={12}>
        <PainMobilityWidget />
      </Grid>
      <Grid item lg={6} md={12}>
        <WoundSizeWidget />
      </Grid>
    </Grid>
  </Grid>
);

export default DataDashboard;
