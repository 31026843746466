import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useECRF from 'hooks/useECRF';
import { ROOT_PATHS } from 'navigation/paths';

import { SidebarButton, SidebarButtonText } from './Template.styles';

const SidebarOption = ({ icon, children, path, selectedTab }) => {
  const { isECRF } = useECRF();

  const selected = useMemo(() => path == selectedTab, [path, selectedTab]);

  const rootPaths = useMemo(() => {
    return isECRF ? ROOT_PATHS.eCRF : ROOT_PATHS.practitioner;
  }, [isECRF]);

  return (
    <SidebarButton
      startIcon={icon}
      variant="contained"
      color={selected ? 'secondary' : 'primary'}
      component={Link}
      to={`${rootPaths}/${path}`}
      data-test={path}
    >
      <SidebarButtonText>{children}</SidebarButtonText>
    </SidebarButton>
  );
};

SidebarOption.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  path: PropTypes.string,
  selectedTab: PropTypes.string,
};

export default SidebarOption;
