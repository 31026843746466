import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import useCachedImage, {
  ImageFitEnum,
  ImageSizeEnum,
} from 'hooks/useCachedImage';

import {
  Practitioner,
  PractitionerInfo,
  PractitionerTitle,
} from './PractitionerList.styles';

const renderSiteList = (sites) => {
  const showSites =
    sites.length > 2
      ? [...sites.slice(0, 2), `and ${sites.length - 2} more`]
      : sites;

  return showSites.map((site) => (
    <Box mt={0.5} key={site}>
      {site}
    </Box>
  ));
};

const PractitionerDisplay = ({ practitioner }) => {
  const { cachedUrl } = useCachedImage({
    url: practitioner.profileImageUrl,
    size: ImageSizeEnum.SIZE_64,
    fit: ImageFitEnum.COVER,
  });
  return (
    <Practitioner key={practitioner.id}>
      <Avatar src={cachedUrl} />
      <PractitionerInfo>
        <PractitionerTitle>{`${practitioner.firstName} ${practitioner.lastName}`}</PractitionerTitle>
        {renderSiteList(practitioner.sites)}
      </PractitionerInfo>
    </Practitioner>
  );
};

PractitionerDisplay.propTypes = {
  practitioner: PropTypes.shape({
    id: PropTypes.string,
    profileImageUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const PractitionerList = ({ user }) =>
  user?.patient?.practitioners?.map((practitioner) => (
    <PractitionerDisplay key={practitioner.id} practitioner={practitioner} />
  ));

export default PractitionerList;
