import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

export const initialState = {
  list: {
    error: null,
    loading: false,
    data: [],
    pageCount: 0,
  },
  details: {
    images: {},
    loading: false,
    error: null,
    updating: false,
    updateError: null,
  },
  notes: {
    listings: {},
    loading: false,
    error: null,
  },
};

const woundImages = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_WOUND_IMAGES_REQUEST:
      return update(state, {
        list: {
          error: { $set: null },
          loading: { $set: true },
        },
      });
    case actionTypes.GET_WOUND_IMAGES_FAILURE:
      return update(state, {
        list: {
          error: { $set: action.payload.error },
          loading: { $set: false },
          data: { $set: [] },
          pageCount: { $set: 0 },
        },
      });
    case actionTypes.GET_WOUND_IMAGES_SUCCESS:
      return update(state, {
        list: {
          error: { $set: null },
          loading: { $set: false },
          data: { $set: action.payload.data },
          pageCount: { $set: action.payload.pageCount },
        },
      });
    case actionTypes.GET_WOUND_IMAGE_REQUEST:
      return update(state, {
        details: {
          error: { $set: null },
          loading: { $set: true },
        },
      });
    case actionTypes.GET_WOUND_IMAGE_FAILURE:
      return update(state, {
        details: {
          error: { $set: action.payload.error },
          loading: { $set: false },
        },
      });
    case actionTypes.GET_WOUND_IMAGE_SUCCESS:
      return update(state, {
        details: {
          images: {
            [action.payload.imageId]: { $set: action.payload.image },
          },
          error: { $set: null },
          loading: { $set: false },
        },
      });

    case actionTypes.UPDATE_WOUND_IMAGE_REQUEST: {
      return update(state, {
        details: {
          updating: { $set: true },
          updateError: { $set: null },
        },
      });
    }
    case actionTypes.UPDATE_WOUND_IMAGE_FAILURE: {
      return update(state, {
        details: {
          updating: { $set: false },
          updateError: { $set: action.payload.error },
        },
      });
    }
    case actionTypes.UPDATE_WOUND_IMAGE_SUCCESS:
      return update(state, {
        details: {
          updating: { $set: false },
        },
      });
    case actionTypes.GET_WOUND_IMAGE_NOTES_REQUEST:
      return update(state, {
        notes: {
          error: { $set: null },
          loading: { $set: true },
        },
      });
    case actionTypes.GET_WOUND_IMAGE_NOTES_FAILURE:
      return update(state, {
        notes: {
          error: { $set: action.payload.error },
          loading: { $set: false },
        },
      });
    case actionTypes.GET_WOUND_IMAGE_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          listings: {
            ...state.notes.listings,
            [action.payload.listingId]: {
              pages: {
                ...state.notes.listings?.[action.payload.listingId]?.pages,
                [action.payload.currentPage]: action.payload.notes,
              },
              pageCount: action.payload.pageCount,
            },
          },
          loading: false,
          error: null,
        },
      };
    case actionTypes.RESET_WOUND_IMAGE_NOTES:
      return update(state, {
        notes: {
          listings: { $set: {} },
          error: { $set: null },
          loading: { $set: false },
        },
      });
    default:
      return state;
  }
};

export default woundImages;
