import { INPUT_TYPES } from 'utils/constants';

const chronicWoundOptions = [
  {
    value: '0',
    label: 'Not at all',
  },
  {
    value: '1',
    label: 'A little',
  },
  {
    value: '2',
    label: 'Moderately',
  },
  {
    value: '3',
    label: 'Quite a lot',
  },
  {
    value: '4',
    label: 'Very much',
  },
];

const chronicWoundFields = [
  {
    name: 'hurt',
    label: '...my wound hurt',
  },
  {
    name: 'badSmell',
    label: '...my wound had a bad smell',
  },
  {
    name: 'discharge',
    label: '...there was a disturbing discharge from the wound',
  },
  {
    name: 'affectedSleep',
    label: '...the wound has affected my sleep',
  },
  {
    name: 'treatmentBurden',
    label: '...the treatment of the wound has been a burden to me',
  },
  {
    name: 'unhappy',
    label: '...the wound has made me unhappy',
  },
  {
    name: 'frustrated',
    label:
      '...I have felt frustrated because the wound is taking so long to heal',
  },
  {
    name: 'worried',
    label: '...I have worried about my wound',
  },
  {
    name: 'afraidGettingWorse',
    label:
      '...I have been afraid of the wound getting worse or of new wounds appearing',
  },
  {
    name: 'afraidKnocking',
    label: '...I have been afraid of knocking the wound',
  },
  {
    name: 'troubleMovingAround',
    label: '...I have had trouble moving about because of the wound',
  },
  {
    name: 'troubleClimbingStairs',
    label: '...climbing stairs has been difficult because of the wound',
  },
  {
    name: 'troubleDayToDayActivities',
    label:
      '...I have had trouble with day-to-day activities because of the wound',
  },
  {
    name: 'limitedLeisureActivities',
    label: '...the wound has limited my leisure activities',
  },
  {
    name: 'limitedActivitiesWithOthers',
    label: '...the wound has forced me to limit my activities with others',
  },
  {
    name: 'dependentOnHelpFromOthers',
    label: '...I have felt dependent on help from others because of the wound',
  },
  {
    name: 'financialBurden',
    label: '...the wound has been a financial burden to me',
  },
];

export const WoundQol = [
  [
    {
      label:
        'With the following questions, we aim to find out how your chronic wound(s) affect(s) your quality of life. In the last seven days...',
      type: INPUT_TYPES.TABLE,
      options: chronicWoundOptions,
      fields: chronicWoundFields,
    },
  ],
];
