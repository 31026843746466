import { QUESTIONNAIRES } from 'utils/constants';

export const ruleTypeEnum = {
  DATA_ENTRY: 'DataEntry',
  QUESTIONNAIRE: 'Questionnaire',
  GENERIC: 'Generic',
};

export const ruleTypeLabel = {
  [ruleTypeEnum.DATA_ENTRY]: 'Data Entry',
  [ruleTypeEnum.QUESTIONNAIRE]: 'Questionnaire',
  [ruleTypeEnum.GENERIC]: 'Free Text',
};

export const dataTypeEnum = {
  COMPRESSION: 'CompressionCompliance',
  MEDICATION: 'MedicationCompliance',
  PAIN: 'PainAndMobility',
  WOUND: 'WoundSize',
};

export const dataTypeLabel = {
  [dataTypeEnum.COMPRESSION]: 'Compression Compliance',
  [dataTypeEnum.MEDICATION]: 'Medication Compliance',
  [dataTypeEnum.PAIN]: 'Pain, Mobility, Leg Swelling',
  [dataTypeEnum.WOUND]: 'Wound Size',
};

export const cronMacroEnum = {
  NEVER: 'never',
  DAILY: '@daily',
  WEEKLY: '@weekly',
  MONTHLY: '@monthly',
};

export const cronMacroLabel = {
  [cronMacroEnum.NEVER]: 'Never',
  [cronMacroEnum.DAILY]: 'Daily',
  [cronMacroEnum.WEEKLY]: 'Weekly',
  [cronMacroEnum.MONTHLY]: 'Monthly',
};

export const getCronDays = (cronStr) => cronStr?.substring(8);

export const getTaskName = (rowData) => {
  switch (rowData.type) {
    case ruleTypeEnum.DATA_ENTRY:
      return dataTypeLabel[rowData?.dataEntryParameters?.type];
    case ruleTypeEnum.QUESTIONNAIRE:
      return QUESTIONNAIRES[
        rowData?.questionnaireParameters?.questionnaireType
      ];
    case ruleTypeEnum.GENERIC:
      return rowData?.genericParameters?.detail;
    default:
      return null;
  }
};

export const getTaskRepeat = (cronExpression) => {
  switch (cronExpression) {
    case null:
      return 'Never';
    case cronMacroEnum.DAILY:
    case cronMacroEnum.MONTHLY:
      return cronMacroLabel[cronExpression];
    default: {
      return `${cronMacroLabel[cronMacroEnum.WEEKLY]} (${getCronDays(
        cronExpression,
      )})`;
    }
  }
};
