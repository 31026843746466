export const GET_WOUND_IMAGES_REQUEST = 'GET_WOUND_IMAGES_REQUEST';
export const GET_WOUND_IMAGES_SUCCESS = 'GET_WOUND_IMAGES_SUCCESS';
export const GET_WOUND_IMAGES_FAILURE = 'GET_WOUND_IMAGES_FAILURE';

export const GET_WOUND_IMAGE_REQUEST = 'GET_WOUND_IMAGE_REQUEST';
export const GET_WOUND_IMAGE_SUCCESS = 'GET_WOUND_IMAGE_SUCCESS';
export const GET_WOUND_IMAGE_FAILURE = 'GET_WOUND_IMAGE_FAILURE';

export const UPDATE_WOUND_IMAGE_REQUEST = 'UPDATE_WOUND_IMAGE_REQUEST';
export const UPDATE_WOUND_IMAGE_SUCCESS = 'UPDATE_WOUND_IMAGE_SUCCESS';
export const UPDATE_WOUND_IMAGE_FAILURE = 'UPDATE_WOUND_IMAGE_FAILURE';

export const GET_WOUND_IMAGE_NOTES_REQUEST = 'GET_WOUND_IMAGE_NOTES_REQUEST';
export const GET_WOUND_IMAGE_NOTES_SUCCESS = 'GET_WOUND_IMAGE_NOTES_SUCCESS';
export const GET_WOUND_IMAGE_NOTES_FAILURE = 'GET_WOUND_IMAGE_NOTES_FAILURE';

export const RESET_WOUND_IMAGE_NOTES = 'RESET_WOUND_IMAGE_NOTES';
