import api from 'core/api';

import {
  GET_CURRENT_ACCOUNT_DETAILS_REQUEST,
  GET_CURRENT_ACCOUNT_DETAILS_SUCCESS,
  GET_CURRENT_ACCOUNT_DETAILS_FAILURE,
  GET_CURRENT_ACCOUNT_LOGO_URL_REQUEST,
  GET_CURRENT_ACCOUNT_LOGO_URL_SUCCESS,
  GET_CURRENT_ACCOUNT_LOGO_URL_FAILURE,
} from './actionTypes';

export const doGetCurrentAccountDetails = () => async (dispatch) => {
  dispatch({
    type: GET_CURRENT_ACCOUNT_DETAILS_REQUEST,
  });
  try {
    const result = await api.getAccount();
    dispatch({
      payload: {
        details: result.data,
      },
      type: GET_CURRENT_ACCOUNT_DETAILS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_CURRENT_ACCOUNT_DETAILS_FAILURE,
    });
  }
};

export const doGetCurrentAccountLogoUrl = (assetId) => async (dispatch) => {
  dispatch({
    type: GET_CURRENT_ACCOUNT_LOGO_URL_REQUEST,
  });
  try {
    const result = await api.getAssetDownloadUrl({ assetIds: [assetId] });

    dispatch({
      payload: {
        logoUrl: result?.data?.assets[0]?.url,
      },
      type: GET_CURRENT_ACCOUNT_LOGO_URL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_CURRENT_ACCOUNT_LOGO_URL_FAILURE,
    });
  }
};
