import React from 'react';

import { Grid, Stack } from '@mui/material';

import { StyledCard } from 'utils/styles';
import RevenueCostVLU from 'theme/media/revenue-cost-vlu.png';

import Title from 'components/titles/Title';

import { InfoCard, SingleChoiceCard } from './InfoCard';

const EconomicOutcomes = () => (
  <Grid container spacing={2}>
    <InfoCard label="Avg. Time to Diagnosis" content="8 days" />
    <InfoCard label="Stent Procedures" content="50" />
    <InfoCard label="Ablation Procedures" content="75" />
    <InfoCard label="YTD Revenue" content="$1,525,000" />
    <Grid item lg={9} xs={12}>
      <StyledCard textAlign="center">
        <Title type="h6" hasGutter>
          Revenue/Cost from VLU Diagnosis and Procedures
        </Title>
        <img
          src={RevenueCostVLU}
          alt="Revenue Cost VLU"
          style={{ width: '100%' }}
        />
      </StyledCard>
    </Grid>
    <Grid item lg={3} xs={12}>
      <Stack direction={{ lg: 'column', xs: 'row' }} spacing={2}>
        <SingleChoiceCard
          name="population"
          label="Select Population"
          options={[
            { label: 'Improved PCP', value: 'improvedpcp' },
            { label: 'Baseline PCP', value: 'baselinepcp' },
          ]}
        />
        <SingleChoiceCard
          name="economic"
          label="Select Economic Variable"
          options={[
            { label: 'Revenue/Cost', value: 'revenueCost' },
            { label: 'Diagnoses', value: 'diagnoses' },
            { label: 'Procedures', value: 'procedures' },
          ]}
        />
      </Stack>
    </Grid>
  </Grid>
);

export default EconomicOutcomes;
