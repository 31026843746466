import styled from '@emotion/styled';

import { weight } from 'theme/fonts';

export const Description = styled('ul')(() => ({
  fontWeight: weight.semibold,
}));

export const ScaleLabel = styled('p')(() => ({
  margin: '1.5rem 0',
  textAlign: 'center',
  fontWeight: weight.semibold,
}));

export const ValueContainer = styled('div')(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem auto 0',
  border: `1px solid ${theme.palette.base.black}`,
  fontWeight: weight.semibold,
}));

export const Scale = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '10rem',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',

  '.slider': {
    position: 'absolute',
    left: '64px',
    top: '50%',
    margin: 0,
    width: '996px',
    outline: 'none',
    transform: 'rotate(-90deg) translate(-50%, -50%)',
    transformOrigin: '0 0',
    appearance: 'none',
  },
  '.slider:focus': {
    outline: 'none',
  },
  ".slider[value='']::-webkit-slider-thumb": {
    background: 'none',
  },
  '.slider::-webkit-slider-runnable-track': {
    height: '4px',
    cursor: 'pointer',
    background:
      'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0.7%, rgba(0, 0, 0, 1) 0.7%, rgba(0, 0, 0, 1) 99.3%, rgba(0, 0, 0, 0) 99.3%, rgba(0, 0, 0, 0) 100%)',
  },
  '.slider::-webkit-slider-thumb': {
    width: '18px',
    height: '18px',
    marginTop: '-7px',
    borderRadius: '50%',
    background: theme.palette.error.main,
    cursor: 'pointer',
    appearance: 'none',
  },
  '.numbered': {
    height: '9px',
  },
  '.scale-row': {
    display: 'flex',
    alignItems: 'center',
  },
  '.tick': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    padding: '4px 0px',
  },
  '.tick > div': {
    height: '100%',
    borderTop: `2px solid ${theme.palette.base.black}`,
  },
  '.line': {
    width: '30px',
  },
  '.lg': {
    width: '80px',
  },
  '.md': {
    width: '40px',
  },
  '.text': {
    fontSize: '1.25rem',
  },
}));
