import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import { PRACTITIONER_PATHS } from 'navigation/paths';
import { getErrorMessage, getErrorDetails } from 'utils/error';
import { StyledCard } from 'utils/styles';

import FormWrapper from 'components/forms/form-wrapper';
import HeaderTitle from 'components/titles';

import TaskRuleForm from '../forms/TaskRuleForm';
import { formFields, validationSchema, mapForm2Api } from '../forms/utils';
import { cronMacroEnum } from '../utils';

const initialValues = {
  [formFields.TYPE]: '',
  [formFields.REPEATS]: cronMacroEnum.NEVER,
};

const NewTaskRule = () => {
  const navigate = useNavigate();
  const { setErrorFeedback, clearFeedback } = useFeedback();
  const { patientId } = useParams();

  const handleSubmit = async (values) => {
    const submitData = mapForm2Api(values);
    clearFeedback();
    try {
      const {
        data: { id },
      } = await api.addTaskRule({
        patientId,
        ...submitData,
      });
      navigate(
        `${PRACTITIONER_PATHS.patients}/${patientId}/home/rules/${id}/info`,
      );
    } catch (error) {
      setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
    }
  };

  return (
    <StyledCard>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onCancel={() => navigate('..')}
        title={<HeaderTitle title="New Task Rule" backUrl=".." />}
      >
        {({ formik }) => <TaskRuleForm formik={formik} />}
      </FormWrapper>
    </StyledCard>
  );
};

export default NewTaskRule;
