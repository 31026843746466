import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import InputPassword from 'components/forms/input-password';

const SettingsPasswordForm = ({ formik, disabled }) => (
  <form onSubmit={formik.handleSubmit}>
    <FormSectionTitle>Password</FormSectionTitle>
    <Grid item container spacing={2} sx={{ pt: 2 }}>
      <Grid item xs={12} md={4}>
        <InputPassword
          label="Current Password"
          autoComplete="current-password"
          isRequired
          {...getFieldProps({ formik, name: 'currentPassword', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputPassword
          label="New Password"
          autoComplete="new-password"
          isRequired
          {...getFieldProps({ formik, name: 'newPassword', disabled })}
        />
      </Grid>
    </Grid>
  </form>
);

SettingsPasswordForm.propTypes = {
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default SettingsPasswordForm;
