import React from 'react';
import PropTypes from 'prop-types';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import WeekPicker from 'components/widgets/week-picker';

import { localWeekStartsOn1 } from 'utils/date';
import { PeriodEnum } from 'utils/enums';

const WoundSizeTimeSelector = ({ viewMode, selectedDate, setSelectedDate }) => {
  const renderSelector = React.useMemo(() => {
    switch (viewMode) {
      case PeriodEnum.WEEK:
        return <WeekPicker value={selectedDate} onChange={setSelectedDate} />;
      case PeriodEnum.MONTH:
      case PeriodEnum.YEAR:
        return (
          <DesktopDatePicker
            views={viewMode === PeriodEnum.YEAR ? ['year'] : ['month', 'year']}
            value={selectedDate}
            onChange={setSelectedDate}
            slotProps={{ textField: { size: 'small' } }}
          />
        );
    }
  }, [viewMode, selectedDate, setSelectedDate]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localWeekStartsOn1}
    >
      {renderSelector}
    </LocalizationProvider>
  );
};

WoundSizeTimeSelector.propTypes = {
  viewMode: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

export default WoundSizeTimeSelector;
