import * as Yup from 'yup';
import pick from 'lodash/pick';

import { INPUT_TYPES, yesNoOptions } from 'utils/constants';

const anotherReasonValue = 'AnotherReason';

export const formFieldEnum = {
  MEDICATIONS: 'takenMedications',
  MEDICATIONS_REASONS: 'notTakenMedicationsReasons',
  MEDICATIONS_OTHER_REASON: 'notTakenMedicationsReasonOther',
};

export const medicationsReasonsEnum = {
  RanOut: 'Ran Out',
  DidntRemember: 'Didn’t Remember',
  DoesntLike: 'Doesn’t Like How It Makes Them Feel',
  ConcernOfSideEffects: 'Concern Of Side-Effects',
  CostOfMedication: 'Cost Of Medication',
  TooManyOtherMedications: 'Too Many Other Medications',
  [anotherReasonValue]: 'Another Reason',
};

export const formFields = ({ values, setFieldValue }) => [
  {
    name: formFieldEnum.MEDICATIONS,
    label: 'Did the patient take all of their medication(s) today?',
    type: INPUT_TYPES.CHIPS,
    options: yesNoOptions,
    isVisible: true,
    required: true,
    changeOverride: () => {
      setFieldValue(formFieldEnum.MEDICATIONS_REASONS, []);
      setFieldValue(formFieldEnum.MEDICATIONS_OTHER_REASON, '');
    },
  },
  {
    name: formFieldEnum.MEDICATIONS_REASONS,
    label: 'Why didn’t the patient take their medication today?',
    type: INPUT_TYPES.MULTIPLE_CHOICE,
    options: Object.entries(medicationsReasonsEnum).map(([value, text]) => ({
      value,
      label: text,
    })),
    isVisible: values[formFieldEnum.MEDICATIONS] === false,
    required: true,
    changeOverride: (e) => {
      if (!e?.includes(anotherReasonValue))
        setFieldValue(formFieldEnum.MEDICATIONS_OTHER_REASON, '');
    },
  },
  {
    name: formFieldEnum.MEDICATIONS_OTHER_REASON,
    placeholder: 'Please Describe Reason',
    type: INPUT_TYPES.COMMENT,
    isVisible:
      values[formFieldEnum.MEDICATIONS_REASONS]?.includes(anotherReasonValue),
    required: true,
  },
];

export const medicationComplianceSchema = Yup.object({
  [formFieldEnum.MEDICATIONS]: Yup.bool().nullable().required('Required'),
  [formFieldEnum.MEDICATIONS_REASONS]: Yup.array().when(
    formFieldEnum.MEDICATIONS,
    {
      is: false,
      then: (schema) => schema.min(1, 'Select at least one option'),
    },
  ),
  [formFieldEnum.MEDICATIONS_OTHER_REASON]: Yup.string().when(
    formFieldEnum.MEDICATIONS_REASONS,
    {
      is: (values) => values.includes(anotherReasonValue),
      then: (schema) => schema.required('Required'),
    },
  ),
});

export const mapForm2Api = ({ timestamp, ...data }) => ({
  ...pick(data, [
    formFieldEnum.MEDICATIONS,
    formFieldEnum.MEDICATIONS_REASONS,
    formFieldEnum.MEDICATIONS_OTHER_REASON,
  ]),
  timestamp: new Date(timestamp).toISOString(),
});
