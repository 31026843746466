import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

export const listStyle = {
  maxHeight: '11rem',
  overflowY: 'auto',
  padding: '0.5rem 0',
};

export const initialStyle = (palette) => [
  new Style({
    stroke: new Stroke({
      color: palette.base.white,
      width: 3,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: palette.base.white }),
    }),
  }),
  new Style({
    stroke: new Stroke({
      color: palette.warning.main,
      width: 2,
    }),
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({ color: palette.primary.dark }),
    }),
  }),
];

export const hideStyle = new Style({});

export const vectorStyle = (palette) =>
  new Style({
    fill: new Fill({ color: palette.wounds.content }),
    stroke: new Stroke({
      color: palette.warning.main,
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: palette.warning.main }),
    }),
  });

export const selectedStyle = (palette) =>
  new Style({
    fill: new Fill({ color: palette.wounds.selected }),
    stroke: new Stroke({
      color: palette.warning.main,
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: palette.warning.main }),
    }),
  });
