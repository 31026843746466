import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import useCachedImage, {
  ImageSizeEnum,
  ImageFitEnum,
} from 'hooks/useCachedImage';

import ChatMessageContent from 'components/chat/chat-message-content';

import {
  MessageContainer,
  MessageUser,
  StyledBody,
  StyledContainer,
  StyledTimeAgo,
} from './ChatWindow.styles';

const ChatMessage = ({
  user,
  createdAt,
  content,
  sent,
  attachment,
  lastMessageRead,
}) => {
  const { cachedUrl } = useCachedImage({
    url: user.profileImageUrl,
    size: ImageSizeEnum.SIZE_64,
    fit: ImageFitEnum.COVER,
  });
  return (
    <MessageContainer>
      <StyledContainer sent={sent}>
        {!sent ? <Avatar src={cachedUrl} /> : null}
        <Box ml={!sent ? 1 : 0}>
          {!sent ? <MessageUser align="left">{user?.name}</MessageUser> : null}
          <StyledBody sent={sent}>
            <ChatMessageContent body={content.body} attachment={attachment} />
          </StyledBody>
        </Box>
      </StyledContainer>
      <StyledTimeAgo sent={sent}>
        <TimeAgo datetime={createdAt} />
        <span>{lastMessageRead}</span>
      </StyledTimeAgo>
    </MessageContainer>
  );
};

ChatMessage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    profileImageUrl: PropTypes.string,
  }).isRequired,
  content: PropTypes.shape({
    body: PropTypes.string,
  }).isRequired,
  attachment: PropTypes.shape({}),
  createdAt: PropTypes.string.isRequired,
  sent: PropTypes.bool.isRequired,
  lastMessageRead: PropTypes.object,
};

export default ChatMessage;
