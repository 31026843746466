import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { AssetTypeEnum } from 'utils/enums';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import FileUpload from 'components/forms/file-upload';
import Input from 'components/forms/input';
import InputPhoneNumber from 'components/forms/input-phone-number';
import ToolTip from 'components/tooltip';

import { StyledAdornment, StyledLink, StyledP } from './Settings.styles';

const DoxyLabel = () => (
  <ToolTip
    content={
      <>
        <StyledP>Doxy.me is a free HIPAA compliant Telehealth service.</StyledP>
        <StyledP>
          To integrate Doxy with your Impact Health account, please click on the
          link or navigate to https://doxy.me/sign-up and create a free account.
          Once you have created an account, or if you already have one, paste
          your meeting room URL in the field below. This should look like:
        </StyledP>
        <StyledP>https://doxy.me/your-room-name-here</StyledP>
        <StyledP>
          Once you have done so, at any time while messaging with a Patient on
          Impact Health, you will be able to click the Telehealth icon at the
          top of the messaging window to launch a video call with your patient.
        </StyledP>
      </>
    }
  >
    <StyledLink
      target="_blank"
      rel="noreferrer"
      href="https://doxy.me/en/features/"
    >
      Doxy Telehealth Link
    </StyledLink>
  </ToolTip>
);

const SettingsUserForm = ({ formik, disabled }) => (
  <form onSubmit={formik.handleSubmit}>
    <FormSectionTitle>Information</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <Input
          label="First Name"
          isRequired
          {...getFieldProps({ formik, name: 'firstName', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Last Name"
          isRequired
          {...getFieldProps({ formik, name: 'lastName', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={<DoxyLabel />}
          startAdornment={
            <StyledAdornment position="start" component="span">
              https://doxy.me/
            </StyledAdornment>
          }
          placeholder="your-room-name-here"
          {...getFieldProps({ formik, name: 'doxyTelehealthLink', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Email"
          autoComplete="username"
          isRequired
          {...getFieldProps({ formik, name: 'email', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputPhoneNumber
          label="Phone Number"
          isRequired
          {...getFieldProps({ formik, name: 'phoneNumber', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputPhoneNumber
          label="Fax Number"
          {...getFieldProps({ formik, name: 'faxNumber', disabled })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FileUpload
          label="Profile Photo Upload"
          type={AssetTypeEnum.USER}
          {...getFieldProps({
            formik,
            name: 'profileImageAssetId',
            disabled,
          })}
        />
      </Grid>
    </Grid>
  </form>
);

SettingsUserForm.propTypes = {
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default SettingsUserForm;
