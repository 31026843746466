import React from 'react';

import { Grid, Stack } from '@mui/material';

import { StyledCard } from 'utils/styles';
import VLUHealingRate from 'theme/media/vluHealingRate.png';

import Title from 'components/titles/Title';

import { InfoCard, SingleChoiceCard } from './InfoCard';

const EconomicOutcomes = () => (
  <Grid container spacing={2}>
    <InfoCard label="VLU Patient Count" content="120 Patients" />
    <InfoCard label="Avg. Time to Diagnosis" content="8 days" />
    <InfoCard label="1 Year Healing Rate" content="95%" />
    <InfoCard label="1 Year Recurrence Rate" content="5%" />
    <Grid item lg={9} xs={12}>
      <StyledCard textAlign="center">
        <Title type="h6" hasGutter>
          VLU Healing Rate - Time from Enrollment
        </Title>
        <img
          src={VLUHealingRate}
          alt="VLU Healing Rate"
          style={{ width: '100%' }}
        />
      </StyledCard>
    </Grid>
    <Grid item lg={3} xs={12}>
      <Stack direction={{ lg: 'column', xs: 'row' }} spacing={2}>
        <SingleChoiceCard
          name="population"
          label="Select Population"
          options={[
            { label: 'Improved PCP', value: 'improvedpcp' },
            { label: 'Baseline PCP', value: 'baselinepcp' },
          ]}
        />
        <SingleChoiceCard
          name="clinical"
          label="Select Clinical Variable"
          options={[
            { label: 'Healing Rate', value: 'healingRate' },
            { label: 'Recurrence Rate', value: 'recurrenceRate' },
            { label: 'Enrollment', value: 'enrollment' },
          ]}
        />
      </Stack>
    </Grid>
  </Grid>
);

export default EconomicOutcomes;
