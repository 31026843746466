import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import update from 'immutability-helper';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import { INPUT_TYPES } from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { FormSubSectionTitle, Label } from 'utils/styles';

import Input from 'components/forms/input';
import Select from 'components/forms/select';

import { StyledColumnLabel, StyledRowLabel } from './DxTable.styles';

export const yesNoOptions = [
  { value: 'Unknown', label: 'Unknown' },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
];

const TableRender = ({ parent, tables, sections, limb, formik, disabled }) => {
  if (!formik.values[parent]) return null;
  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <>
          <Grid item md={3}></Grid>
          {tables?.map(({ key, label }) => (
            <Grid item md={label === 'Obstruction' ? 6 : 3} key={key}>
              <StyledColumnLabel pb={1}>{label}</StyledColumnLabel>
            </Grid>
          ))}
        </>
      </Grid>
      {sections?.map(({ name, label }, idx) => {
        return (
          <Grid item container spacing={2} key={name} alignItems="flex-end">
            <Grid item md={3}>
              <StyledRowLabel>{label}</StyledRowLabel>
            </Grid>
            {tables?.map(({ key, label }) => {
              const hasObstruction = label === 'Obstruction';

              const isPresent =
                formik.values[parent][limb][key][name].selected === 'Yes';

              return (
                <React.Fragment key={limb + key + name}>
                  <Grid
                    item
                    container
                    md={hasObstruction ? 6 : 3}
                    spacing={1}
                    alignItems="flex-end"
                  >
                    {idx === 0 && (
                      <>
                        <Grid item xs={hasObstruction ? 3 : 6}>
                          <Label sx={{ mb: 0 }}>Present</Label>
                        </Grid>
                        <Grid item xs={hasObstruction ? 3 : 6}>
                          <Label sx={{ mb: 0 }}>
                            {label === 'Reflux' ? 'Time' : 'Lumen reduction'}
                          </Label>
                        </Grid>
                        {hasObstruction && (
                          <>
                            <Grid item xs={3}>
                              <Label sx={{ mb: 0 }}>Length</Label>
                            </Grid>
                            <Grid item xs={3}>
                              <Label sx={{ mb: 0 }}>Diameter</Label>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    <Grid item xs={isPresent ? (hasObstruction ? 3 : 6) : 12}>
                      <Select
                        options={yesNoOptions}
                        {...getFieldProps({
                          formik,
                          name: `${parent}.${limb}.${key}.${name}.selected`,
                          disabled,
                          changeOverride: (value) => {
                            formik?.setValues(
                              update(formik?.values, {
                                [parent]: {
                                  [limb]: {
                                    [key]: {
                                      [name]: {
                                        selected: { $set: value },
                                        score: { $set: null },
                                      },
                                    },
                                  },
                                },
                              }),
                            );
                          },
                        })}
                      />
                    </Grid>
                    {isPresent && (
                      <>
                        <Grid
                          item
                          xs={hasObstruction ? 3 : 6}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          <Input
                            type={INPUT_TYPES.NUMERIC}
                            endAdornment={
                              <InputAdornment position="end">
                                {label === 'Reflux' ? 's' : '%'}
                              </InputAdornment>
                            }
                            {...getFieldProps({
                              formik,
                              name: `${parent}.${limb}.${key}.${name}.score`,
                              disabled: disabled,
                            })}
                          />
                        </Grid>
                        {hasObstruction && (
                          <>
                            <Grid item xs={3} sx={{ alignSelf: 'flex-start' }}>
                              <Input
                                type={INPUT_TYPES.NUMERIC}
                                value={undefined}
                                defaultValue={4}
                                endAdornment={
                                  <InputAdornment position="end">
                                    cm
                                  </InputAdornment>
                                }
                              />
                            </Grid>
                            <Grid item xs={3} sx={{ alignSelf: 'flex-start' }}>
                              <Input
                                type={INPUT_TYPES.NUMERIC}
                                value={undefined}
                                defaultValue={16}
                                endAdornment={
                                  <InputAdornment position="end">
                                    mm
                                  </InputAdornment>
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

TableRender.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  parent: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  limb: PropTypes.string,
};

const DxTable = ({
  limbs,
  columns,
  formik,
  sections,
  name: tableName,
  question,
  disabled,
}) =>
  limbs.map((limb) => (
    <Box p={2} key={limb}>
      <FormSubSectionTitle style={{ marginBottom: '1rem' }}>
        {`${question}${limb !== 'ivc' ? ' - ' + startCase(limb) : ''}`}
      </FormSubSectionTitle>
      <TableRender
        sections={sections}
        tables={columns}
        parent={tableName}
        formik={formik}
        disabled={disabled}
        limb={limb}
      />
    </Box>
  ));

DxTable.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
  }).isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  limbs: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  question: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DxTable;
