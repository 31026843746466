import styled from '@emotion/styled';

import OutlinedInput from '@mui/material/OutlinedInput';

export const NotesInput = styled(OutlinedInput)(({ theme, disabled }) => ({
  alignItems: 'flex-start',
  color: theme.palette.base.black,

  '&.Mui-disabled': {
    padding: '0',
    color: theme.palette.base.black,
  },

  '.MuiOutlinedInput-notchedOutline': {
    display: disabled && 'none',
  },
}));
