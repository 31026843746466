import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { getFieldProps } from 'utils/forms';

import StyledIconButton from 'components/buttons/StyledIconButton';
import DateTimeSelector from 'components/forms/date-time-selector';
import Title from 'components/titles/Title';

import { FormBox } from './LogForm.styles';

const LogForm = ({ children, title, rightColumn }) => {
  const formik = useFormikContext();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Title type="h2">{title}</Title>
        <StyledIconButton
          name="close"
          color="black"
          onClick={formik.handleReset}
        />
      </Box>
      <FormBox onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            container
            spacing={2}
            item
            md={7}
            xs={12}
            style={{ height: 'fit-content' }}
          >
            {children}
          </Grid>
          <Grid
            container
            spacing={2}
            item
            md={5}
            xs={12}
            style={{ height: 'fit-content' }}
          >
            <Grid item xs={12}>
              <DateTimeSelector
                {...getFieldProps({ name: 'timestamp', formik })}
              />
            </Grid>
            <Grid item xs={12}>
              {rightColumn}
            </Grid>
          </Grid>
        </Grid>
      </FormBox>
    </>
  );
};

LogForm.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  rightColumn: PropTypes.node,
};

export default LogForm;
