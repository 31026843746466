import styled from '@emotion/styled';

import Button from '@mui/material/Button';

import { StyledCard } from 'utils/styles';
import { breakpointsTheme } from 'theme/muiTheme';

export const AssessmentButtonGroup = styled(StyledCard)(() => ({
  width: '20%',
  minWidth: '20%',
  height: 'fit-content',

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: '100%',
  },
}));

export const FormContainer = styled(StyledCard)(() => ({
  flexGrow: 1,
  padding: '0 0 2rem',
}));

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  gap: '2rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const AssessmentButton = styled(Button)(({ theme, selected }) => ({
  minHeight: '4rem',
  marginBottom: '1rem',
  borderColor: `1px solid ${theme.palette.primary.main}`,
  background: selected ? theme.palette.primary.main : theme.palette.base.white,
  color: selected ? theme.palette.base.white : theme.palette.primary.main,
  textTransform: 'none',

  '&:last-child': {
    marginBottom: 0,
  },

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));
