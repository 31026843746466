import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { actions as usersActions } from 'store/users';
import { getErrorMessage } from 'utils/error';

export const useUserListing = () => {
  const dispatch = useDispatch();

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getUsers: usersActions.doGetUsers,
        },
        dispatch,
      ),
    [dispatch],
  );

  const {
    error,
    items: users,
    loading: isLoading,
    pageCount,
  } = useSelector((state) => state.users.list);

  const errorMessage = useMemo(
    () => (error ? getErrorMessage(error) : ''),
    [error],
  );

  return {
    ...actions,
    isLoading,
    error,
    errorMessage,
    users,
    pageCount,
  };
};

export default useUserListing;
