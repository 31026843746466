import api from 'core/api';

import * as actionTypes from './actionTypes';

export const doGetChatRooms = () => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_CHAT_ROOMS_REQUEST,
  });

  try {
    const { data } = await api.getChatRooms();

    dispatch({
      payload: { data },
      type: actionTypes.GET_CHAT_ROOMS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { error },
      type: actionTypes.GET_CHAT_ROOMS_FAILURE,
    });
  }
};

export const doSelectChatRoom = (roomId) => async (dispatch) => {
  dispatch({
    type: actionTypes.SELECTED_CHAT_ROOM,
    payload: { roomId },
  });
};

export const doGetChatRoomsMessages =
  (roomId, cursor = null) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.GET_CHAT_MESSAGES_REQUEST,
    });

    try {
      const { data } = await api.getChatRoomMessages(roomId, cursor);

      dispatch({
        payload: { roomId, data, cursor },
        type: actionTypes.GET_CHAT_MESSAGES_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: { error },
        type: actionTypes.GET_CHAT_MESSAGES_FAILURE,
      });
    }
  };

export const doSendChatRoomMessage =
  ({ roomId, body, attachmentId, cb, errorCb }) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.SEND_CHAT_MESSAGE_REQUEST,
    });

    try {
      const { data } = await api.sendChatRoomMessage(
        roomId,
        body,
        attachmentId,
      );
      dispatch({
        payload: { data },
        type: actionTypes.SEND_CHAT_MESSAGE_SUCCESS,
      });
      if (cb) cb();
    } catch (error) {
      dispatch({
        payload: { error },
        type: actionTypes.SEND_CHAT_MESSAGE_FAILURE,
      });
      if (errorCb) errorCb(error);
    }
  };

export const doReceiveChatRoomMessage = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.CHAT_MESSAGE_RECEIVED,
    payload: { data },
  });
};

export const doUpdateChatRoomMessagesRead = (roomId) => async (dispatch) => {
  await api.updateChatRoomMessagesRead(roomId);
  dispatch({
    type: actionTypes.CHAT_MESSAGES_READ,
    payload: { roomId },
  });
};

export const doOpenChatRoomWindow = (roomId) => async (dispatch) => {
  dispatch({
    type: actionTypes.CHAT_ROOM_WINDOW_OPEN,
    payload: { roomId },
  });
};

export const doCloseChatRoomWindow = () => async (dispatch) => {
  dispatch({
    type: actionTypes.CHAT_ROOM_WINDOW_CLOSE,
  });
};

export const setCollapseWindow = (collapse) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_COLLAPSE_WINDOW,
    payload: { collapse },
  });
};
