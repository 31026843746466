import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { encounterFormsPathEnum } from 'utils/encounters';

const useEncounterScreen = (forms) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const formPaths = useMemo(
    () => forms.map(({ type }) => encounterFormsPathEnum[type]),
    [forms],
  );

  const currentPath = useMemo(() => pathname.split('/').pop(), [pathname]);

  const currentIndex = useMemo(
    () => formPaths.indexOf(currentPath),
    [formPaths, currentPath],
  );

  const hasPrevious = useMemo(
    () => Boolean(formPaths[currentIndex - 1]),
    [currentIndex, formPaths],
  );

  const hasNext = useMemo(
    () => Boolean(formPaths[currentIndex + 1]),
    [currentIndex, formPaths],
  );

  const goBack = useCallback(() => {
    if (!hasPrevious) return;
    navigate(`../${formPaths[currentIndex - 1]}`);
  }, [currentIndex, formPaths, hasPrevious, navigate]);

  const goNext = useCallback(() => {
    if (!hasNext) return;
    navigate(`../${formPaths[currentIndex + 1]}`);
  }, [currentIndex, formPaths, hasNext, navigate]);

  return {
    hasPrevious,
    hasNext,
    goBack,
    goNext,
    currentPath,
  };
};

export default useEncounterScreen;
