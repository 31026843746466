import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { encounterTypeLabels } from 'utils/encounters';
import { StyledCard } from 'utils/styles';

import ErrorMessage from 'components/error-message';
import Loader from 'components/loader';
import Title from 'components/titles/Title';

import EncounterFormsGroup from './components/EncounterFormsGroup';
import { encountersForms } from './data';

const EncounterDetails = () => {
  const { doRequest: getEncounter, data, error } = useRequest(api.getEncounter);
  const {
    doRequest: getEncounterForms,
    data: encounterForms,
    error: encounterFormsError,
  } = useRequest(api.getEncounterForms);
  const { encounterId } = useParams();

  useEffect(() => {
    getEncounter(encounterId);
  }, [encounterId, getEncounter]);

  useEffect(() => {
    getEncounterForms({ encounterId });
  }, [encounterId, getEncounterForms]);

  const renderContent = useMemo(() => {
    if (error || encounterFormsError)
      return <ErrorMessage message="Error loading encounter" />;

    if (!data || !encounterForms) return <Loader />;

    const forms = encountersForms
      .filter((i) => encounterForms?.assessmentTypes?.includes(i.type))
      .sort(
        (a, b) =>
          encounterForms?.assessmentTypes.indexOf(a.type) -
          encounterForms?.assessmentTypes.indexOf(b.type),
      );

    return encounterForms ? (
      <EncounterFormsGroup forms={forms} encounter={data} />
    ) : (
      <ErrorMessage message="Encounter type not found" />
    );
  }, [data, encounterForms, error, encounterFormsError]);

  return (
    <StyledCard>
      {data?.type && (
        <Title type="h1" isBold>
          {encounterTypeLabels[data.type]}
        </Title>
      )}
      {renderContent}
    </StyledCard>
  );
};

export default EncounterDetails;
