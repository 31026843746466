import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import useUser from 'hooks/useUser';
import { woundImagesStatus } from 'utils/constants';

import StyledIconButton from 'components/buttons/StyledIconButton';

import { Wrapper } from './Header.styles';

const Header = ({
  imageStatus,
  handleRefresh,
  handleFilterChange,
  isImageTracer,
}) => {
  const { logout } = useUser();
  return (
    <Wrapper>
      {Object.values(woundImagesStatus).map((imStatus) => {
        if (imStatus === woundImagesStatus.approved && isImageTracer) return;
        return (
          <Button
            key={imStatus}
            variant={imageStatus === imStatus ? 'contained' : 'outlined'}
            onClick={() => handleFilterChange('status', imStatus)}
          >
            {imStatus}
          </Button>
        );
      })}
      <Box display="flex" justifyContent="space-between" flexGrow="1">
        <StyledIconButton name="sync" color="grey" onClick={handleRefresh} />
        <StyledIconButton name="exit" color="grey" onClick={logout} />
      </Box>
    </Wrapper>
  );
};

Header.propTypes = {
  imageStatus: PropTypes.string.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isImageTracer: PropTypes.bool.isRequired,
};

export default Header;
