import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Typography from '@mui/material/Typography';

import { weight } from 'theme/fonts';

export const Title = ({
  children,
  type,
  hasGutter = false,
  isBlue = false,
  isBold = false,
}) => {
  const StyledTypography = styled(Typography)(() => ({
    fontSize: '1.25rem',
    fontWeight: isBold ? weight.bold : weight.semibold,

    '&.MuiTypography-gutterBottom': {
      marginBottom: '1rem',
    },
  }));

  return (
    <StyledTypography
      component={type}
      color={isBlue ? 'primary' : 'initial'}
      gutterBottom={hasGutter}
    >
      {children}
    </StyledTypography>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  hasGutter: PropTypes.bool,
  isBlue: PropTypes.bool,
  isBold: PropTypes.bool,
};

export default Title;
