import React from 'react';
import PropTypes from 'prop-types';
import { differenceInMinutes, format } from 'date-fns';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import { PATIENT_DASHBOARD_PATHS } from 'navigation/paths';
import { encounterStatusEnum, encounterTypeLabels } from 'utils/encounters';

import StatusIcon from '../status-icon';

import ListItemDelete from './ListItemDelete';
import { EncounterContainer, StyledButton } from './List.styles';

const ListItem = ({
  id,
  startedAt,
  endedAt,
  practitionerFirstName,
  practitionerLastName,
  type,
  siteName,
  status,
  handleDelete,
}) => (
  <EncounterContainer key={id}>
    <div>
      <StatusIcon large status={status} />
      <div>
        <p>{format(new Date(startedAt), 'do, MMMM, yyyy')}</p>
        <p>{`${siteName} - [${encounterTypeLabels[type]}] - ${practitionerFirstName} ${practitionerLastName}`}</p>
        <p>
          {format(new Date(startedAt), "hh:mm aaaaa'm'")} -{' '}
          {differenceInMinutes(new Date(endedAt), new Date(startedAt))} min
        </p>
      </div>
    </div>
    <Box display="flex" alignItems="center">
      <StyledButton
        component={Link}
        to={`${PATIENT_DASHBOARD_PATHS.ENCOUNTERS}/${id}`}
      >
        {status === encounterStatusEnum.DRAFT ? 'Edit' : 'See'} visit details
      </StyledButton>
      {status === encounterStatusEnum.DRAFT && (
        <ListItemDelete handleDelete={handleDelete} />
      )}
    </Box>
  </EncounterContainer>
);

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.string.isRequired,
  endedAt: PropTypes.string.isRequired,
  practitionerFirstName: PropTypes.string.isRequired,
  practitionerLastName: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  handleDelete: PropTypes.func,
};

export default ListItem;
