import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { adminUserRolesOptions } from 'utils/constants';
import { normalizeOptions } from 'utils/forms';

import Select from 'components/forms/select';
import SelectSearchable from 'components/forms/select-searchable';

const UserListingFilters = ({ clearTableState, filters, onFilterChange }) => {
  const { data: systemsData, doRequest: getSystems } = useRequest(
    api.getHospitalSystems,
  );
  const { data: sitesData, doRequest: getSites } = useRequest(
    api.getHospitalSystemSites,
  );

  useEffect(
    () => {
      getSystems({ sort: 'Name', sortDirection: 'Asc' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (filters.hospitalSystems) {
        getSites(filters.hospitalSystems, {
          sort: 'Name',
          sortDirection: 'Asc',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.hospitalSystems],
  );

  const systemOptions = useMemo(
    () => normalizeOptions(systemsData?.data),
    [systemsData],
  );

  const siteOptions = useMemo(
    () => normalizeOptions(sitesData?.data),
    [sitesData],
  );

  return (
    <Box display="flex" gap={2} mb={2}>
      <Select
        name="roles"
        value={adminUserRolesOptions?.length ? filters.roles : []}
        options={adminUserRolesOptions}
        placeholder="User Type"
        onChange={({ target }) => onFilterChange('roles', target.value)}
        multiple
        noTags
      />
      <Select
        name="hospitalSystems"
        options={systemOptions}
        placeholder="Hospital System"
        onChange={({ target }) =>
          onFilterChange('hospitalSystems', target.value)
        }
        value={
          systemOptions && filters.hospitalSystems
            ? filters.hospitalSystems
            : ''
        }
      />
      <SelectSearchable
        name="hospitalSystemSites"
        options={siteOptions}
        onChange={({ target }) =>
          onFilterChange('hospitalSystemSites', target.value)
        }
        value={siteOptions ? filters.hospitalSystemSites : []}
        placeholder="Site"
        isMultiple
        noTags
      />
      <Button color="primary" variant="contained" onClick={clearTableState}>
        Clear
      </Button>
    </Box>
  );
};

UserListingFilters.propTypes = {
  filters: PropTypes.shape({
    roles: PropTypes.array,
    hospitalSystems: PropTypes.string,
    hospitalSystemSites: PropTypes.array,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  clearTableState: PropTypes.func.isRequired,
};

export default UserListingFilters;
