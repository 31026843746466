import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { SubmitBox } from './FormSubmit.styles';

const FormSubmit = ({
  onConfirm,
  onCancel,
  confirmStr = 'Save',
  cancelStr = 'Cancel',
  ...remainingProps
}) => (
  <SubmitBox {...remainingProps}>
    <Button color="secondary" variant="contained" onClick={onCancel}>
      {cancelStr}
    </Button>
    <Button variant="contained" disabled={!onConfirm} onClick={onConfirm}>
      {confirmStr}
    </Button>
  </SubmitBox>
);

FormSubmit.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  confirmStr: PropTypes.string,
  cancelStr: PropTypes.string,
};

export default FormSubmit;
