import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useFormik } from 'formik';

import Box from '@mui/material/Box';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileIcon from '@mui/icons-material/DescriptionOutlined';
import SendIcon from '@mui/icons-material/Send';

import api from 'core/api';
import useChat from 'hooks/useChat';
import useFeedback from 'hooks/useFeedback';
import { AssetTypeEnum } from 'utils/enums';
import { getErrorMessage } from 'utils/error';

import {
  AttachedFile,
  AttachedHiddenField,
  ChatButton,
  StyledTextareaAutosize,
} from './ChatInput.styles';

const ChatInput = ({ roomId, pushedMessage, setPushedMessage }) => {
  const inputFile = React.useRef(null);
  const { onMesssageSend, isSending } = useChat();
  const { setErrorFeedback, clearFeedback } = useFeedback();

  const onSendMessage = React.useCallback(
    ({ body, attachment }, { resetForm }) => {
      clearFeedback();
      onMesssageSend({
        roomId,
        body,
        attachmentId: attachment?.id,
        cb: resetForm,
        errorCb: (error) => setErrorFeedback(getErrorMessage(error)),
      });
    },
    [onMesssageSend, roomId, clearFeedback, setErrorFeedback],
  );

  const { handleChange, handleSubmit, values, resetForm, setFieldValue } =
    useFormik({
      initialValues: { body: '', attachment: null },
      onSubmit: onSendMessage,
    });

  React.useEffect(() => {
    if (pushedMessage) {
      setFieldValue('body', pushedMessage);
      setPushedMessage(null);
    }
  }, [pushedMessage, setFieldValue, setPushedMessage]);

  const onFileAttached = React.useCallback(
    async ({ target }) => {
      clearFeedback();
      const file = target.files[0];
      if (!file) return;
      try {
        const {
          data: { uploadUrl, id, url },
        } = await api.getAssetUploadUrl({
          type: AssetTypeEnum.CHAT,
          fileName: file.name,
          contentType: file.type,
        });
        await axios.put(uploadUrl, file, {
          headers: { 'Content-Type': file.type },
        });
        setFieldValue('attachment', { fileName: file.name, id, url });
      } catch (error) {
        setErrorFeedback(getErrorMessage(error));
      }
    },
    [clearFeedback, setErrorFeedback, setFieldValue],
  );

  React.useEffect(() => {
    resetForm();
  }, [roomId, resetForm]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit();
    }
  };

  return (
    <>
      <Box p={2} display="flex" alignItems="center">
        <StyledTextareaAutosize
          name="body"
          minRows={1}
          maxRows={3}
          value={values.body}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          disabled={isSending}
        />
        <ChatButton
          onClick={() => inputFile.current.click()}
          disabled={values.isSending}
        >
          <AttachFileIcon fontSize="inherit" />
        </ChatButton>
        <ChatButton
          onClick={handleSubmit}
          disabled={!(values.body || values.attachment) || isSending}
        >
          <SendIcon fontSize="inherit" />
        </ChatButton>
      </Box>
      <Box>
        {values.attachment && (
          <AttachedFile
            component="a"
            icon={<FileIcon />}
            href={values.attachment.url}
            target="_blank"
            label={values.attachment.fileName}
            onClick={() => {}}
            onDelete={(e) => {
              e.preventDefault();
              setFieldValue('attachment', null);
            }}
            size="small"
          />
        )}
      </Box>
      <AttachedHiddenField
        type="file"
        ref={inputFile}
        onChange={onFileAttached}
        accept="image/gif,image/png,image/jpeg,application/pdf,.doc,.docx,.odt,.txt,.pages"
      />
    </>
  );
};

ChatInput.propTypes = {
  roomId: PropTypes.string.isRequired,
  pushedMessage: PropTypes.string,
  setPushedMessage: PropTypes.func,
};

export default ChatInput;
