import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  encounterFormsPathEnum,
  encounterFormsTitleEnum,
} from 'utils/encounters';
import { breakpointsTheme } from 'theme/muiTheme';

const validForm = (form, diseases) => {
  if (!form) return false;
  if (!form.enforceDiseases) return true;
  return form.enforceDiseases.reduce(
    (res, d) => (diseases[d] ? true : res),
    false,
  );
};

const EncounterTabs = ({ forms, currentPath, onTabClick, diseases }) => {
  const matches = useMediaQuery(breakpointsTheme.breakpoints.down('md'));

  return (
    forms.length > 1 && (
      <ButtonGroup orientation={matches ? 'vertical' : 'horizontal'}>
        {forms.map((form) => {
          const path = encounterFormsPathEnum[form.type];
          return (
            validForm(form, diseases) && (
              <Button
                key={path}
                variant={currentPath === path ? 'contained' : 'outlined'}
                onClick={() => onTabClick(path)}
              >
                {encounterFormsTitleEnum[form.type]}
              </Button>
            )
          );
        })}
      </ButtonGroup>
    )
  );
};

EncounterTabs.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPath: PropTypes.string,
  onTabClick: PropTypes.func.isRequired,
  diseases: PropTypes.shape({}),
};

export default EncounterTabs;
