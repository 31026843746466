import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Title from 'components/titles/Title';

import { breakpointsTheme } from 'theme/muiTheme';

export const OutterWrapper = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  padding: '2rem',
  background: theme.palette.gradient.main,

  [breakpointsTheme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

export const InnerWrapper = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '0.25rem',
  background: theme.palette.base.white,

  [breakpointsTheme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const Template = ({ children, title }) => (
  <OutterWrapper boxShadow={3}>
    <InnerWrapper>
      {title && (
        <Title type="h1" isBold hasGutter>
          {title}
        </Title>
      )}
      {children}
    </InnerWrapper>
  </OutterWrapper>
);

Template.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

export default Template;
