import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Box from '@mui/material/Box';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';

import Select from 'components/forms/select';
import { useTheme } from 'context/theme';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { getWoundName } from 'utils/woundImages';
import { getWoundTotalGraphLabel } from 'components/widgets/utils';

const SUM_OPTION = 'TOTAL';

const WoundSizeGraph = ({ patientId, woundSizes, timeInterval }) => {
  const { palette } = useTheme();
  const [graphDisplay, setGraphDisplay] = React.useState(SUM_OPTION);
  const { data: woundsData, doRequest, isLoading } = useRequest(api.getWounds);

  React.useEffect(() => {
    doRequest(patientId);
  }, [patientId, doRequest]);

  const woundList = React.useMemo(() => {
    if (isLoading) return [];
    return (
      woundsData?.data?.reduce(
        (res, wound) => ({ ...res, [wound.id]: getWoundName(wound) }),
        {},
      ) || []
    );
  }, [isLoading, woundsData]);

  const woundPoints = React.useMemo(
    () =>
      woundSizes.reduce((res, { size, woundId, timestamp }) => {
        const point = {
          x: new Date(timestamp),
          y: size,
        };
        return res[woundId]
          ? { ...res, [woundId]: [...res[woundId], point] }
          : { ...res, [woundId]: [point] };
      }, {}),
    [woundSizes],
  );

  const totalWoundSize = React.useMemo(() => {
    const lastSizes = {};
    return Object.entries(
      woundSizes.reduce((res, { size, woundId, timestamp }) => {
        lastSizes[woundId] = size;
        const pointSize = Object.values(lastSizes).reduce(
          (sum, value) => sum + value,
          0,
        );
        return {
          ...res,
          [timestamp]: { pointSize, savedSizes: { ...lastSizes } },
        };
      }, {}),
    ).map(([timestamp, point]) => ({
      x: new Date(timestamp),
      y: point.pointSize,
      customLabel: Object.entries(point.savedSizes)
        .map(([key, value]) => {
          return `${woundList[key]}: ${value.toFixed(2)}cm²`;
        })
        .join('\n'),
    }));
  }, [woundList, woundSizes]);

  const selectOptions = React.useMemo(
    () => [
      { value: SUM_OPTION, label: 'All Wounds' },
      ...Object.entries(woundList).map(([value, label]) => ({ value, label })),
    ],
    [woundList],
  );

  const graphData = React.useMemo(() => {
    if (graphDisplay === SUM_OPTION) return totalWoundSize;
    return woundPoints[graphDisplay];
  }, [graphDisplay, totalWoundSize, woundPoints]);

  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        height={180}
        padding={{ left: 60, top: 20, right: 55, bottom: 30 }}
        domainPadding={{ y: 10 }}
        domain={{ x: [timeInterval?.since, timeInterval?.until] }}
        scale={{ x: 'time', y: 'linear' }}
      >
        <VictoryAxis
          crossAxis={false}
          dependentAxis
          fixLabelOverlap
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fontWeight: 'bold' },
            grid: { stroke: 'none' },
          }}
          tickFormat={(tick) => {
            if (!tick || (tick && tick > 0 && tick < 0.1)) {
              return '';
            } else if (tick === 0) {
              return tick;
            }
            return `${tick} cm²`;
          }}
        />
        <VictoryAxis
          key="xAxis"
          crossAxis={false}
          animate={false}
          fixLabelOverlap
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            grid: {
              stroke: palette.grey[700],
              strokeOpacity: 0.2,
              strokeDasharray: '0',
            },
          }}
          tickFormat={(t) => {
            return format(new Date(t), timeInterval?.tickFormat);
          }}
        />
        <VictoryGroup colorScale={palette.chartColorScale}>
          <VictoryArea
            name="areaGraph"
            data={graphData}
            style={{
              data: {
                fillOpacity: 0.8,
              },
            }}
          />
        </VictoryGroup>
        <VictoryGroup colorScale={palette.chartColorScale}>
          <VictoryScatter
            data={graphData}
            name="scatterGraph"
            size={3}
            labels={getWoundTotalGraphLabel}
            labelComponent={
              <VictoryTooltip
                orientation="bottom"
                centerOffset={{ y: 5 }}
                pointerLength={0}
              />
            }
          />
        </VictoryGroup>
      </VictoryChart>
      <Box display="flex" flexWrap="wrap" my={2}>
        <Select
          name="selectGraph"
          value={graphDisplay}
          options={selectOptions}
          onChange={({ target }) => setGraphDisplay(target.value)}
        />
      </Box>
    </div>
  );
};

WoundSizeGraph.propTypes = {
  woundSizes: PropTypes.array.isRequired,
  patientId: PropTypes.string.isRequired,
  timeInterval: PropTypes.shape({
    since: PropTypes.instanceOf(Date),
    until: PropTypes.instanceOf(Date),
    tickFormat: PropTypes.string,
  }).isRequired,
};

export default WoundSizeGraph;
