import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Stack } from '@mui/material';
import { Description } from '@mui/icons-material';

import { StyledImage } from './FileUpload.styles';

const FilePreview = ({ asset }) => {
  const renderAssetIcon = React.useMemo(() => {
    const filename = asset.fileName.split('.').pop();

    return (
      <Stack alignItems="center">
        <Description />
        {filename.toUpperCase()}
      </Stack>
    );
  }, [asset]);

  if (asset.contentType.startsWith('image'))
    return <StyledImage src={asset.url} />;

  return (
    <Box sx={{ marginTop: '0.5rem' }}>
      <Button
        variant="outlined"
        target="_blank"
        href={asset.url}
        sx={{
          width: '6rem',
          height: '6rem',
        }}
      >
        {renderAssetIcon}
      </Button>
    </Box>
  );
};

FilePreview.propTypes = {
  asset: PropTypes.shape({
    fileName: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default FilePreview;
