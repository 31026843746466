import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import StyledBackIconButton from 'components/buttons/StyledBackIconButton';
import Title from 'components/titles/Title';

const BackButton = ({ backUrl, onBack }) => {
  if (onBack) {
    return (
      <StyledBackIconButton color="primary" onClick={onBack}>
        <ArrowBackIosIcon viewBox="0 0 14 24" fontSize="inherit" />
      </StyledBackIconButton>
    );
  }
  if (backUrl) {
    return (
      <StyledBackIconButton component={Link} color="primary" to={backUrl}>
        <ArrowBackIosIcon viewBox="0 0 14 24" fontSize="inherit" />
      </StyledBackIconButton>
    );
  }
  return null;
};

BackButton.propTypes = {
  backUrl: PropTypes.string,
  onBack: PropTypes.func,
};

const HeaderTitle = ({ title, backUrl, onBack, mb = 0 }) => {
  if (!(title || backUrl || onBack)) return null;
  return (
    <Box display="flex" alignItems="center" mb={mb}>
      <BackButton backUrl={backUrl} onBack={onBack} />
      {title && (
        <Title type="span" isBold>
          {title}
        </Title>
      )}
    </Box>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.node,
  backUrl: PropTypes.string,
  onBack: PropTypes.func,
  mb: PropTypes.number,
};

export default HeaderTitle;
