import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

import { headerRoleFromRootPath } from 'navigation/paths';
import { buildQueryStringFromObject, parseParams } from 'utils/queryParams';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 150000,
});

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 150000,
});

export const getRootPath = () => {
  const { pathname } = window.location;
  return `/${pathname.split('/')[1]}`;
};

// Add access token and user role to every API request
api.interceptors.request.use(async (config) => {
  try {
    const { headers } = config;

    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken.toString();

    // Decides the user role using the current location
    const role = headerRoleFromRootPath[getRootPath()];

    return {
      ...config,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        'X-IH-ROLE': role,
      },
    };
  } catch {
    // If the session is invalid, do not add an Authorization header
    return config;
  }
});

const apiConfig = {
  /* users */
  getUsers(params) {
    return api.get('users', {
      params,
      paramsSerializer: (params) => parseParams(params),
    });
  },
  createUser(user) {
    return api.post('users', user);
  },
  getUser(id) {
    return api.get(`users/${id}`);
  },
  deleteUser(id) {
    return api.delete(`users/${id}`);
  },
  updateUser(id, user) {
    return api.put(`users/${id}`, user);
  },
  getCurrentUserDetails() {
    return api.get('users/me');
  },
  updateCurrentUserDetails(data) {
    return api.put('users/me', data);
  },

  /* assests */
  getAssetUploadUrl({ userId, type, fileName, contentType }) {
    return api.post('assets', { userId, type, fileName, contentType });
  },
  getAssetDownloadUrl({ assetIds }) {
    return api.post('download-urls', { assetIds });
  },

  /* wounds */
  getWounds(patientId) {
    return api.get('modules/vlu/wounds', { params: { patientId } });
  },
  createWound(data) {
    return api.post('modules/vlu/wounds', data);
  },
  getWoundSizes(params) {
    const queryString = buildQueryStringFromObject(params);
    return api.get(`modules/vlu/wounds/sizes${queryString}`);
  },
  createWoundSize(data) {
    return api.post('modules/vlu/wounds/sizes', data);
  },
  getWoundBySizeId(sizeId, params) {
    const queryString = buildQueryStringFromObject(params);
    return api.get(`modules/vlu/wounds/sizes/${sizeId}${queryString}`);
  },
  updateWoundSize(id, data) {
    return api.put(`modules/vlu/wounds/sizes/${id}`, data);
  },
  deleteWoundSize(id) {
    return api.delete(`modules/vlu/wounds/sizes/${id}`);
  },

  /* wound-images */
  getWoundImages(params) {
    const queryString = buildQueryStringFromObject(params);
    return api.get(`modules/vlu/wounds/images/${queryString}`);
  },
  getWoundImage(imageId) {
    return api.get(`modules/vlu/wounds/images/${imageId}`);
  },
  updateWoundImage(imageId, surfaces, status, flag, reasonForNoTracing) {
    return api.put(`modules/vlu/wounds/images/${imageId}`, {
      surfaces,
      status,
      flag,
      reasonForNoTracing,
    });
  },
  /* wound-image-note */
  createWoundImageNote(imageId, data) {
    return api.post(`modules/vlu/wounds/images/${imageId}/notes`, data);
  },
  getWoundImageNotes(imageId, { pageSize = 20, page = 1 } = {}) {
    const queryString = buildQueryStringFromObject({
      PageSize: pageSize,
      Page: page - 1,
    });
    return api.get(`modules/vlu/wounds/images/${imageId}/notes${queryString}`);
  },

  /*wound-detectors*/
  getWoundDetectorImages(params) {
    return api.get(
      `modules/vlu/training-model/images/${buildQueryStringFromObject(params)}`,
    );
  },
  getWoundDetectorImage(imageId) {
    return api.get(`modules/vlu/training-model/images/${imageId}`);
  },
  updateWoundDetectorImage(imageId, surfaces, status) {
    return api.put(`modules/vlu/training-model/images/${imageId}`, {
      surfaces,
      status,
    });
  },

  /* hospital-system */
  createHospitalSystem(hospitalSystem) {
    return api.post('hospital-systems', hospitalSystem);
  },
  getHospitalSystems(filters = {}) {
    return api.get(`hospital-systems${buildQueryStringFromObject(filters)}`);
  },
  getHospitalSystem(id) {
    return api.get(`hospital-systems/${id}`);
  },
  updateHospitalSystem(id, hospitalSystem) {
    return api.put(`hospital-systems/${id}`, hospitalSystem);
  },

  /* hospital-system-site */
  createHospitalSystemSite(hospitalSystemId, hospitalSystemSite) {
    return api.post(
      `hospital-sites/${hospitalSystemId}/sites`,
      hospitalSystemSite,
    );
  },
  getHospitalSystemSites(hospitalSystemId, filters = {}) {
    const queryString = buildQueryStringFromObject(filters);

    return api.get(`hospital-sites/${hospitalSystemId}/sites${queryString}`);
  },
  getHospitalSystemSite(hospitalSystemId, id) {
    return api.get(`hospital-sites/${hospitalSystemId}/sites/${id}`);
  },
  updateHospitalSystemSite(hospitalSystemId, id, hospitalSystemSite) {
    return api.put(
      `hospital-sites/${hospitalSystemId}/sites/${id}`,
      hospitalSystemSite,
    );
  },
  getHospitalSystemDiseaseStateCount(hospitalSystemId, diseaseStateId) {
    return api.get(
      `hospital-systems/${hospitalSystemId}/disease-states/${diseaseStateId}/count`,
    );
  },

  /* questionnaires */
  getQuestionnaires(params) {
    return api.get(
      `questionnaires/summary${buildQueryStringFromObject(params)}`,
    );
  },
  getQuestionnairesResponses(params) {
    return api.get(`questionnaires${buildQueryStringFromObject(params)}`);
  },
  getQuestionnaireForm(data) {
    return api.post(`questionnaires`, data);
  },
  getQuestionnaireResponse(id) {
    return api.get(`questionnaires/${id}`);
  },
  addQuestionnaireResponse(id, data) {
    return api.put(`questionnaires/${id}`, data);
  },
  getQuestionnaireResponseNoAuth(id, token) {
    return noAuthApi.get(`questionnaires/${id}/${token}`);
  },
  addQuestionnaireResponseNoAuth(params, data) {
    return noAuthApi.put(`questionnaires/${params.id}/${params.token}`, data);
  },

  /* chat */
  getChatRooms() {
    return api.get('chat/rooms');
  },
  getChatRoomMessages(roomId, cursor, limit = 10) {
    const queryString = buildQueryStringFromObject({ limit, cursor });
    return api.get(`chat/rooms/${roomId}/messages${queryString}`);
  },
  sendChatRoomMessage(roomId, body, attachmentId) {
    return api.post(`chat/rooms/${roomId}/messages`, {
      content: { body },
      attachmentId,
    });
  },
  updateChatRoomMessagesRead(roomId) {
    return api.put(`chat/rooms/${roomId}/messages/read`);
  },

  /*time entries */
  getTimeEntries({ patientId, page, pageSize = DEFAULT_PAGE_SIZE }) {
    return api.get(
      `time-entries${buildQueryStringFromObject({
        patientId,
        page,
        pageSize,
      })}`,
    );
  },
  exportTimeEntries({ patientId, page, pageSize = DEFAULT_PAGE_SIZE }) {
    return api.get(
      `time-entries/export${buildQueryStringFromObject({
        patientId,
        page,
        pageSize,
      })}`,
      { responseType: 'blob' },
    );
  },
  createTimeEntry(body) {
    return api.post(`time-entries`, body);
  },
  getTimeEntry(timeEntryId) {
    return api.get(`time-entries/${timeEntryId}`);
  },
  updateTimeEntry({ timeEntryId, data }) {
    return api.put(`time-entries/${timeEntryId}`, data);
  },
  deleteTimeEntry(timeEntryId) {
    return api.delete(`time-entries/${timeEntryId}`);
  },

  /* history */
  getPatientHistory({ patientId, ...moreFilters }) {
    return api.get(
      `/history/${patientId}${buildQueryStringFromObject(moreFilters)}`,
    );
  },

  /* VluCompression */
  getCompressionList(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`modules/vlu/compression-compliance${queryString}`);
  },
  updateCompression({ id, data }) {
    return api.put(`modules/vlu/compression-compliance/${id}`, data);
  },
  getCompression(id) {
    return api.get(`modules/vlu/compression-compliance/${id}`);
  },
  deleteCompression(id) {
    return api.delete(`modules/vlu/compression-compliance/${id}`);
  },
  insertCompression(data) {
    return api.post(`modules/vlu/compression-compliance`, data);
  },

  /* Pain, Mobility, Leg Swelling */
  getPainMobilityList(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`modules/vlu/pain-and-mobility${queryString}`);
  },
  insertPainMobility(data) {
    return api.post(`modules/vlu/pain-and-mobility`, data);
  },
  getPainMobility(id) {
    // TODO: remove queryString after BE typo fix 'paindAndMobilityId'
    const queryString = buildQueryStringFromObject({ paindAndMobilityId: id });
    return api.get(`modules/vlu/pain-and-mobility/${id}${queryString}`);
  },
  updatePainMobility({ id, data }) {
    return api.put(`modules/vlu/pain-and-mobility/${id}`, data);
  },
  deletePainMobility(id) {
    return api.delete(`modules/vlu/pain-and-mobility/${id}`);
  },

  /* vlu medication */
  getMedicationList(filters = {}) {
    return api.get(
      `modules/vlu/medication-compliance${buildQueryStringFromObject(filters)}`,
    );
  },
  getMedication(id) {
    return api.get(`modules/vlu/medication-compliance/${id}`);
  },
  addMedication(data) {
    return api.post('modules/vlu/medication-compliance', data);
  },
  updateMedication({ id, data }) {
    return api.put(`modules/vlu/medication-compliance/${id}`, data);
  },
  deleteMedication(id) {
    return api.delete(`modules/vlu/medication-compliance/${id}`);
  },

  /* encounters */
  getEncounters(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`encounters${queryString}`);
  },
  addEncounter(data) {
    return api.post('encounters', data);
  },
  getEncounter(encounterId, type) {
    const typeStr = type ? `/${type}` : '';
    return api.get(`encounters/${encounterId}${typeStr}`);
  },
  updateEncounter(encounterId, type, data) {
    const typeStr = type ? `/${type}` : '';
    return api.put(`encounters/${encounterId}${typeStr}`, data);
  },
  deleteEncounter(encounterId) {
    return api.delete(`encounters/${encounterId}`);
  },
  getEncounterTypes(filters) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`encounters/encounter-types${queryString}`);
  },

  /* new encounters endpoints */
  getEncounterNew(encounterId, filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`assessments/eoc/${encounterId}${queryString}`);
  },
  updateEncounterNew(encounterId, data) {
    return api.put(`assessments/eoc/${encounterId}`, data);
  },
  getEncounterForms(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`assessments/eoc/assessment-types${queryString}`);
  },

  /* tasks */
  getTaskRules(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`tasking/rules${queryString}`);
  },
  addTaskRule(data) {
    return api.post('tasking/rules', data);
  },
  getTaskRule(ruleId) {
    return api.get(`tasking/rules/${ruleId}`);
  },
  updateTaskRule(ruleId, data) {
    return api.put(`tasking/rules/${ruleId}`, data);
  },
  getTaskRuleTasks(ruleId, filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`tasking/rules/${ruleId}/tasks${queryString}`);
  },

  /* assessments  */
  getAssessmentECRF(patientId, filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`assessments/ecrf/${patientId}${queryString}`);
  },
  updateAssessmentECRF(patientId, data) {
    return api.put(`assessments/ecrf/${patientId}`, data);
  },

  /* patients */
  getPPF(patientId = {}) {
    return api.get(`patients/patient/${patientId}`);
  },

  /* care-track */
  getClinical(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`care-track/clinical${queryString}`);
  },
  getCareWoundSizes(params) {
    return api.get('care-track/wound-sizes', { params });
  },

  getEvents(filters = {}) {
    const queryString = buildQueryStringFromObject(filters);
    return api.get(`event${queryString}`);
  },

  /* account */
  getAccount() {
    return api.get('account');
  },
  updateAccount(data) {
    return api.put('account', data);
  },
};

export default apiConfig;
