import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import { formatISOString } from 'utils/date';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { getSurfaceAreaString, getWoundName } from 'utils/woundImages';

import Table from 'components/table';
import { RowActions } from 'components/table/RowActions';
import WidgetContainer from 'components/widgets/widget-container';
import DeleteComplianceLog from 'components/compliance-questions/DeleteComplianceLog';

import NewWoundSize from './NewWoundSize';
import EditWoundSize from './EditWoundSize';
import NewWound from './NewWound';
import { modalEnum } from './utils';

const header = [
  { title: 'Date' },
  { title: 'Name' },
  { title: 'Size' },
  { title: 'Image' },
  { title: '' },
];

const WoundSizeLogs = () => {
  const { patientId } = useParams();
  const [modal, setModal] = useState(null);
  const { page, handlePageChange } = useTableState('Timestamp', 'Desc');

  const {
    data: listData,
    doRequest: getList,
    isLoading,
  } = useRequest(api.getWoundSizes);

  const refreshList = useCallback(() => {
    getList({
      PatientId: patientId,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
    });
  }, [page, getList, patientId]);

  useEffect(() => {
    refreshList();
  }, [page, refreshList]);

  const renderModalContent = useMemo(() => {
    switch (modal?.type) {
      case modalEnum.ADD:
        return (
          <NewWoundSize
            handleCancel={() => setModal(null)}
            handleRefresh={refreshList}
            handleAddWound={() =>
              setModal({ type: modalEnum.ADD_WOUND, backTo: modalEnum.ADD })
            }
          />
        );
      case modalEnum.EDIT:
        return (
          <EditWoundSize
            {...modal.item}
            handleCancel={() => setModal(null)}
            handleRefresh={refreshList}
            handleAddWound={() =>
              setModal({
                type: modalEnum.ADD_WOUND,
                backTo: modalEnum.EDIT,
                backToItem: modal.item,
              })
            }
          />
        );
      case modalEnum.ADD_WOUND: {
        const { backTo, backToItem } = modal;
        return (
          <NewWound
            handleSuccess={() => setModal({ type: backTo })}
            handleCancel={({ close }) =>
              setModal(close ? null : { type: backTo, item: backToItem })
            }
          />
        );
      }
      case modalEnum.DELETE:
        return (
          <DeleteComplianceLog
            handleCancel={() => setModal(null)}
            deleteFunc={async () => api.deleteWoundSize(modal.item.id)}
            handleRefresh={refreshList}
          />
        );
      default:
        return null;
    }
  }, [modal, refreshList]);

  const rowsData = useMemo(
    () =>
      listData?.data.map((obj) => ({
        data: [
          formatISOString(obj.timestamp),
          getWoundName(obj.wound),
          getSurfaceAreaString(obj.size),
          obj.woundImage?.asset ? 'Yes' : 'No',
          <RowActions
            key={obj.id}
            handleEdit={() => {
              setModal({ type: modalEnum.EDIT, item: obj });
            }}
            handleDelete={() => {
              setModal({ type: modalEnum.DELETE, item: obj });
            }}
          />,
        ],
      })),
    [listData],
  );

  return (
    <WidgetContainer
      title="Wound Size Logs"
      onAddClick={() => setModal({ type: modalEnum.ADD })}
      modalContent={renderModalContent}
      onModalClose={() => setModal(null)}
      isLogsList
    >
      <Table
        header={header}
        page={page}
        isLoading={isLoading}
        rows={rowsData}
        totalPages={listData?.pageCount || 1}
        handlePageChange={handlePageChange}
      />
    </WidgetContainer>
  );
};

export default WoundSizeLogs;
