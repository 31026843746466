import React, { useState, useEffect } from 'react';
import { intervalToDuration } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import { PATIENT_DASHBOARD_PATHS } from 'navigation/paths';
import { setTimeTracking } from 'store/time-tracking/actions';

import StyledIconButton from 'components/buttons/StyledIconButton';

import { TrackTimeContainer, PlayStopButton } from './Timetrack.styles';

const Timetrack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState('00:00:00');

  const calculateTime = () => {
    const difference = intervalToDuration({
      start: startTime,
      end: new Date(),
    });

    const normalizeTime = (time) =>
      time.toString().length === 1 ? `0${time}` : time;

    return (
      normalizeTime(difference.hours) +
      ':' +
      normalizeTime(difference.minutes) +
      ':' +
      normalizeTime(difference.seconds)
    );
  };

  const deleteTime = () => {
    setStartTime(null);
    setTimeLeft('00:00:00');
  };

  const setTimer = () => {
    if (startTime) {
      dispatch(setTimeTracking({ dateFrom: startTime, dateTo: new Date() }));
      navigate(PATIENT_DASHBOARD_PATHS.TIME_TRACK, { replace: true });
      deleteTime();
    } else {
      setStartTime(new Date());
    }
  };

  useEffect(() => {
    if (startTime) {
      const timer = setTimeout(() => setTimeLeft(calculateTime()), 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    return () => deleteTime();
  }, []);

  return (
    <TrackTimeContainer>
      <span>{startTime ? timeLeft : 'Track Time'}</span>
      <PlayStopButton stop={startTime || undefined} onClick={() => setTimer()}>
        {startTime ? <StopIcon /> : <PlayArrowIcon />}
      </PlayStopButton>
      {startTime ? (
        <StyledIconButton
          onClick={() => deleteTime()}
          name="delete"
          hasMargin
        />
      ) : null}
    </TrackTimeContainer>
  );
};

export default Timetrack;
