import {
  parse,
  parseISO as fnsParseISO,
  format,
  differenceInYears,
} from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import { getTimezoneOffset } from 'date-fns-tz';

export const TIME_FORMAT = 'HH:mm';
export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const DATEBASE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_FIELD_FORMAT = `${US_DATE_FORMAT} ${TIME_FORMAT}`;

const timezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu',
];

export const parseISO = (dateString) => dateString ?? fnsParseISO(dateString);

export const formatDate = (date, formatPattern = DEFAULT_FIELD_FORMAT) =>
  format(date, formatPattern);

export const formatISOString = (
  dateString,
  formatPattern = DEFAULT_FIELD_FORMAT,
) => (dateString ? format(fnsParseISO(dateString), formatPattern) : '');

export const formatBirthDate = (
  dateStr,
  showAge,
  formatPattern = US_DATE_FORMAT,
) => {
  if (!dateStr) return '';
  //just parsing date without time and timezone
  const parsedDate = parse(dateStr.substring(0, 10), 'yyyy-MM-dd', new Date());

  const formatedDate = format(parsedDate, formatPattern);
  if (!showAge) return formatedDate;
  return `${formatedDate} (Age ${differenceInYears(new Date(), parsedDate)})`;
};

export const localWeekStartsOn1 = {
  ...enLocale,
  options: { ...enLocale.options, weekStartsOn: 1 },
};

const getTimezoneTimeString = (timezoneStr) => {
  const offsetHours = getTimezoneOffset(timezoneStr) / 3600000;
  const sign = offsetHours < 0 ? '-' : '+';
  const absOffset = Math.abs(offsetHours);
  return `${sign}${absOffset > 9 ? absOffset : `0${absOffset}`}:00`;
};

export const timezoneOptions = timezones.map((st) => ({
  value: st,
  label: `${st.replace('_', ' ')} (GMT${getTimezoneTimeString(st)})`,
}));
