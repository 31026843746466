import styled from '@emotion/styled';

import Dialog from '@mui/material/Dialog';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    minWidth: 'fit-content',
    padding: '1.25rem',
  },
}));
