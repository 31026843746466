import React from 'react';
import { startOfMinute } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import api from 'core/api';
import {
  useFeedback,
  useRequest,
  useTableState,
  useMountEffect,
  useUpdateEffect,
} from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { formatISOString } from 'utils/date';
import { getErrorMessage } from 'utils/error';
import { downloadFile } from 'utils/files';
import { setTimeTracking } from 'store/time-tracking/actions';

import FormWrapper from 'components/forms/form-wrapper';
import Table from 'components/table';
import Title from 'components/titles/Title';
import { RowActions } from 'components/table/RowActions';
import ConfirmDialog from 'components/confirm-dialog';

import Template from '../../Template';

import AddTime from './add-time';
import { timeTrackingSchema } from './utils';

const patientTableAttributes = [
  { title: 'Start Time' },
  { title: 'End Time' },
  { title: 'Total Time' },
  { title: 'Clinical Care Tasks Performed' },
  { title: '' },
];

const checkTime = (time) =>
  startOfMinute(time === null ? new Date() : new Date(time));

const TimeTrack = () => {
  const dispatch = useDispatch();
  const { page, handlePageChange } = useTableState();
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { patientId } = useParams();
  const {
    data,
    doRequest: getList,
    error,
    isLoading,
  } = useRequest(api.getTimeEntries);

  const { dateTo, dateFrom } = useSelector((state) => state.timeTracking);
  const [toDelete, setToDelete] = React.useState(null);
  const [editing, setEditing] = React.useState(null);

  const listFilters = React.useMemo(
    () => ({ patientId, page: page - 1, pageSize: DEFAULT_PAGE_SIZE }),
    [patientId, page],
  );

  const fetchList = React.useCallback(() => {
    getList(listFilters);
  }, [getList, listFilters]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [listFilters]);

  const handleAdd = ({ startedAt, endedAt, text }) => {
    clearFeedback();
    api
      .createTimeEntry({
        patientId,
        startedAt,
        endedAt,
        text,
      })
      .then(() => {
        setSuccessFeedback('Added with success');
        dispatch(setTimeTracking({ dateFrom: null, dateTo: null }));
        fetchList();
      })
      .catch((err) => setErrorFeedback(getErrorMessage(err)));
  };

  const handleUpdate = (updateData) => {
    clearFeedback();
    api
      .updateTimeEntry({
        timeEntryId: updateData.id,
        data: {
          ...updateData,
          patientId,
        },
      })
      .then(() => {
        setSuccessFeedback('Updated with success');
        setEditing(null);
        fetchList();
      })
      .catch((err) => setErrorFeedback(getErrorMessage(err)));
  };

  const handleDelete = () => {
    clearFeedback();
    api
      .deleteTimeEntry(toDelete)
      .then(() => {
        setSuccessFeedback('Deleted with success');
        fetchList();
      })
      .catch((err) => setErrorFeedback(getErrorMessage(err)));
  };

  const handleExport = () => {
    clearFeedback();
    api
      .exportTimeEntries({ patientId })
      .then(({ headers, data }) => {
        downloadFile(
          data,
          decodeURI(headers['content-disposition'].split("'").slice(-1)),
        );
      })
      .catch(() => setErrorFeedback('Error exporting file'));
  };

  const rows = React.useMemo(
    () =>
      (data?.data || []).map((item) => ({
        patientId,
        data: [
          formatISOString(item.startedAt),
          formatISOString(item.endedAt),
          item.totalMinutes,
          item.text,
          <RowActions
            key={item.id}
            handleEdit={() => setEditing(item)}
            handleDelete={() => setToDelete(item.id)}
          />,
        ],
      })),
    [patientId, data],
  );

  const initialValues = React.useMemo(
    () =>
      editing
        ? {
            ...editing,
            text: editing.text,
          }
        : {
            startedAt: checkTime(dateFrom),
            endedAt: checkTime(dateTo),
            text: '',
          },
    [editing, dateFrom, dateTo],
  );

  return (
    <Template title="Time Tracking">
      <ConfirmDialog
        open={!!toDelete}
        title="Are you sure you want to delete this entry?"
        handleConfirm={() => {
          handleDelete();
          setToDelete(null);
        }}
        handleCancel={() => setToDelete(null)}
      />
      <FormWrapper
        initialValues={initialValues}
        validationSchema={timeTrackingSchema}
        onSubmit={editing ? handleUpdate : handleAdd}
        enableReinitialize
        cancelStr={editing ? 'Cancel Edit' : 'Reset'}
        onCancel={() => {
          if (!editing) return;
          setEditing(null);
        }}
      >
        {({ formik }) => <AddTime formik={formik} />}
      </FormWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={2}
      >
        <Title type="h2">History</Title>
        <Button variant="contained" onClick={handleExport}>
          Export
        </Button>
      </Box>
      <Table
        header={patientTableAttributes}
        page={page}
        rows={rows}
        error={error}
        isLoading={isLoading}
        totalPages={data?.pageCount}
        handlePageChange={handlePageChange}
      />
    </Template>
  );
};

export default TimeTrack;
