import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { OTHER_VALUE, yesNoOptions } from 'utils/constants';
import { anatomyOptions, sideSubtitle } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import { MultipleColumnsSection } from 'components/encounters/procedures-sections';

const fields = {
  ANATOMY: 'anatomies',
  ANATOMY_OTHER: 'anatomyOther',
  MODALITY: 'modalities',
  MODALITY_OTHER: 'modalityOther',
};

const reinterventionCauseOptions = [
  { value: 'AcuteThrombosis', label: 'Acute Thrombosis' },
  { value: 'InStentRestenosis', label: 'In-Stent Restenosis' },
  {
    value: 'StentFailure',
    label: 'Stent Failure (e.g. Compression, Fracture)',
  },
  {
    value: 'InadequateFirstProcedure',
    label: 'Inadequate First Procedure (e.g. Missed the lesion)',
  },
  { value: 'PersistentSVI', label: 'Persistent SVI' },
  { value: 'PersistentObstruction', label: 'Persistent Obstruction' },
  { value: 'DeepVenousReflux', label: 'Deep Venous Reflux' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const reinterventionTypeOptions = [
  { value: 'MechanicalThrombectomy', label: 'Mechanical Thrombectomy' },
  {
    value: 'CatheterDirectedThrombolysis',
    label: 'Catheter Directed Thrombolysis',
  },
  {
    value: 'PharmacomechanicalThrombectomy',
    label: 'Pharmacomechanical Thrombectomy',
  },
  { value: 'BalloonAngioplasty', label: 'Balloon Angioplasty' },
  { value: 'Stenting', label: 'Stenting' },
  { value: 'SVIAblation', label: 'SVI Ablation' },
  {
    value: 'DeepVenousRefluxManagement',
    label: 'Deep Venous Reflux Management',
  },
  { value: 'OpenSurgery', label: 'Open Surgery' },
  { value: OTHER_VALUE, label: OTHER_VALUE },
];

const previouslyStentedOptions = [
  { value: 'NotWithin', label: 'Not within previously stented region' },
  { value: 'FullyWithin', label: 'Fully within previously stented region' },
  {
    value: 'PartiallyWithin',
    label: 'Partially within previously stented region',
  },
];

export const StentRe = ({ formik, disabled }) => {
  const stentReGroup = () => [
    sideSubtitle,
    {
      label: 'Cause for reintervention',
      input: (side) => (
        <Chips
          options={anatomyOptions}
          isMultiple
          {...getFieldProps({
            formik,
            name: `${side}.${fields.ANATOMY}`,
            disabled,
          })}
        />
      ),
    },
    {
      label: 'Previously stented region',
      input: (side) => (
        <Chips
          options={previouslyStentedOptions}
          isMultiple
          {...getFieldProps({
            formik,
            name: `${side}.previouslyStentedRegion`,
            disabled,
          })}
        />
      ),
    },
  ];

  return (
    <>
      <FormSectionTitle></FormSectionTitle>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <DateSelector
            label="Reintervention procedure date"
            {...getFieldProps({
              formik,
              name: 'date',
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={4}>
          <Chips
            label="Cause for reintervention"
            options={reinterventionCauseOptions}
            isMultiple
            otherField={{ name: 'causeOther', value: OTHER_VALUE }}
            {...getFieldProps({
              formik,
              name: 'causes',
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      causes: { $set: value },
                      causeOther: { $set: '' },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Type of reintervention"
            options={reinterventionTypeOptions}
            isMultiple
            otherField={{ name: 'typeOther', value: OTHER_VALUE }}
            {...getFieldProps({
              formik,
              name: 'types',
              disabled,
              changeOverride: (value) => {
                if (!value.includes(OTHER_VALUE)) {
                  formik?.setValues(
                    update(formik?.values, {
                      types: { $set: value },
                      typeOther: { $set: '' },
                    }),
                  );
                }
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chips
            label="Inferior vena cava"
            options={yesNoOptions}
            {...getFieldProps({
              formik,
              name: 'ivc',
              disabled,
            })}
          />
        </Grid>
      </Grid>
      <MultipleColumnsSection items={stentReGroup} />
    </>
  );
};

export default StentRe;

StentRe.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};
