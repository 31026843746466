import React from 'react';
import { isAfter, parseISO } from 'date-fns';

import { formatISOString, TIME_FORMAT } from 'utils/date';

export const findLastReadMessage = (id, user, selectedRoomData, ownerId) => {
  const date = user.readReceipt;
  const lastMessageRead =
    selectedRoomData?.items?.find(
      ({ userId, createdAt }) =>
        userId === ownerId && isAfter(parseISO(date), parseISO(createdAt)),
    )?.id === id;

  return lastMessageRead ? (
    <span>{` - Seen at ${formatISOString(date, TIME_FORMAT)}`}</span>
  ) : null;
};
