import React, { useMemo, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';
import useUserDetails from 'hooks/useUserDetails';
import { TASKS_PATHS } from 'navigation/paths';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { formatISOString, US_DATE_FORMAT } from 'utils/date';
import { DiseaseEnum } from 'utils/enums';
import { breakpointsTheme } from 'theme/muiTheme';

import {
  getTaskName,
  getTaskRepeat,
  ruleTypeLabel,
} from 'pages/practitioner/patients/details/dashboard/sections/home/utils';

import Table from 'components/table';

const defaultFilters = {
  active: 'true',
};

const tableHeader = [
  { title: 'Type' },
  { title: 'Name' },
  { title: 'Repeating' },
  { title: 'Start Date' },
  { title: 'Expiry Date' },
];

const TaskRuleList = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));
  const { patientId } = useParams();
  const { user } = useUserDetails(patientId);

  const {
    data: ruleData,
    doRequest: getTaskRules,
    isLoading,
  } = useRequest(api.getTaskRules);
  const { page, handlePageChange, filters, handleFilterChange } = useTableState(
    '',
    '',
    defaultFilters,
  );

  const pathWayStep = useMemo(
    () =>
      user?.patient?.diseases?.find(
        ({ disease }) => disease === DiseaseEnum.VLU,
      )?.diseasePathwayStep,
    [user],
  );

  const listFilters = useMemo(
    () => ({
      PatientId: patientId,
      Page: page - 1,
      PageSize: DEFAULT_PAGE_SIZE,
      active: filters.active,
    }),
    [patientId, page, filters],
  );

  useEffect(() => {
    getTaskRules(listFilters);
  }, [getTaskRules, listFilters, pathWayStep]);

  const rows = useMemo(
    () =>
      ruleData?.data?.map((field) => ({
        data: [
          ruleTypeLabel[field.type],
          getTaskName(field),
          getTaskRepeat(field.cronExpression),
          formatISOString(field.startDate, US_DATE_FORMAT),
          formatISOString(field.endDate, US_DATE_FORMAT),
        ],
        id: field.id,
      })) ?? [],
    [ruleData],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection={matches ? 'column' : 'row'}
        justifyContent="space-between"
        gap={matches ? '1rem' : 0}
        mb={2}
      >
        <ButtonGroup orientation={matches ? 'vertical' : 'horizontal'}>
          <Button
            variant={filters.active === 'true' ? 'contained' : 'outlined'}
            onClick={() => handleFilterChange('active', 'true')}
          >
            Active Task Rules
          </Button>
          <Button
            variant={filters.active === 'false' ? 'contained' : 'outlined'}
            onClick={() => handleFilterChange('active', 'false')}
          >
            Past Task Rules
          </Button>
        </ButtonGroup>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          component={Link}
          to={TASKS_PATHS.NEW_RULE}
        >
          New Task Rule
        </Button>
      </Box>
      <Table
        header={tableHeader}
        rows={rows}
        page={page}
        isLoading={isLoading}
        totalPages={ruleData?.pageCount ?? 0}
        handlePageChange={handlePageChange}
        handleRowClick={(rowId) => navigate(`rules/${rowId}`)}
      />
    </>
  );
};

export default TaskRuleList;
