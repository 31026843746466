import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';
import useQueryParams from 'hooks/useQueryParams';
import { getErrorMessage } from 'utils/error';
import { formatISOString } from 'utils/date';

import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';

import QuestionnaireForm from 'pages/practitioner/patients/details/dashboard/sections/care-track/questionnaires/questionnaire-form';
import {
  getFormQuestions,
  getQuestionnaireFormSchema,
  questionnairesForms,
} from 'pages/practitioner/patients/details/dashboard/sections/care-track/questionnaires/utils';

import Template from './NewQuestionnaireResponse.styles';

const NewQuestionnaireResponse = () => {
  const navigate = useNavigate();
  const { query } = useQueryParams();
  const { setSuccessFeedback, setErrorFeedback } = useFeedback();
  const { data, doRequest, isLoading } = useRequest(
    api.getQuestionnaireResponseNoAuth,
  );
  const { doRequest: addQuestionnaireResponse } = useRequest(
    api.addQuestionnaireResponseNoAuth,
  );

  const handleCancel = useCallback(() => navigate('/'), [navigate]);

  useEffect(() => {
    if (!query.questionnaireId || !query.token) {
      handleCancel();
    } else {
      doRequest(query.questionnaireId, query.token);
    }
  }, [doRequest, handleCancel, query]);

  const form = questionnairesForms[data?.type];

  const initialValues = form
    ?.flat()
    .filter(({ text }) => !text)
    .reduce((prev, cur) => {
      return cur.fields ? [...prev, ...cur.fields] : [...prev, cur];
    }, [])
    .reduce((obj, { name }) => ({ ...obj, [name]: '' }), {});

  const handleSubmit = async (values) => {
    const payload = { values: { [data?.type]: {} } };

    getFormQuestions(form).map((question) => {
      payload.values[data?.type][question.name] = parseInt(
        values[question.name],
      );
    });

    try {
      await addQuestionnaireResponse(
        { id: query.questionnaireId, token: query.token },
        payload,
      );
      setSuccessFeedback('Added with success');
      handleCancel();
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  if (isLoading) return <Loader />;

  return data?.submittedAt ? (
    <Template
      title={`This ${data?.name} was submitted on ${formatISOString(
        data?.submittedAt,
      )}.`}
    />
  ) : (
    <Template title={data?.name}>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={getQuestionnaireFormSchema(form)}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      >
        {({ formik }) => <QuestionnaireForm formik={formik} form={form} />}
      </FormWrapper>
    </Template>
  );
};

export default NewQuestionnaireResponse;
