import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import api from 'core/api';
import useRequest from 'hooks/useRequest';

import Template from 'pages/practitioner/patients/details/dashboard/Template';
import Loader from 'components/loader';

import Calendar from './components/calendar';
import List from './components/list';
import { StyledBadge } from './EncounterList.styles';

const screenEnum = {
  ENCOUNTERS: 'List',
  CALENDAR: 'Calendar',
  DRAFTS: 'Drafts',
};

const EncounterList = () => {
  const { patientId } = useParams();
  const [screen, setScreen] = useState(screenEnum.ENCOUNTERS);

  const {
    data: encountersList,
    doRequest: getEncounters,
    isLoading,
  } = useRequest(api.getEncounters);

  const renderScreen = useMemo(() => {
    switch (screen) {
      case screenEnum.CALENDAR:
        return <Calendar />;
      case screenEnum.ENCOUNTERS:
        return <List status="Submitted" />;
      case screenEnum.DRAFTS:
        return <List status="Draft" />;
      default:
        return null;
    }
  }, [screen]);

  useEffect(() => {
    getEncounters({
      PatientId: patientId,
      Status: 'Draft',
    });
  }, [getEncounters, patientId]);

  if (isLoading || !encountersList) return <Loader />;

  return (
    <Template title="Encounters">
      <ButtonGroup style={{ marginBottom: '1rem' }}>
        {Object.values(screenEnum).map((key) => (
          <Button
            key={key}
            variant={screen === key ? 'contained' : 'outlined'}
            onClick={() => setScreen(key)}
          >
            {key === 'Drafts' && encountersList.count > 0 ? (
              <StyledBadge
                badgeContent={encountersList.count}
                overlap="rectangular"
              />
            ) : null}
            {key}
          </Button>
        ))}
      </ButtonGroup>
      {renderScreen}
    </Template>
  );
};

export default EncounterList;
