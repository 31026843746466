import React from 'react';
import PropTypes from 'prop-types';

import { INPUT_TYPES } from 'utils/constants';
import { getFieldProps } from 'utils/forms';
import { weight } from 'theme/fonts';

import SingleChoice from 'components/forms/single-choice';
import TableChoice from 'components/compliance-questions/table-choice';
import ScaleSelect from 'components/compliance-questions/scale-select';

const InputComponent = ({ element, formik, disabled }) => {
  switch (element.type) {
    case INPUT_TYPES.INFO:
      return <span style={{ fontWeight: weight.bold }}>{element.text}</span>;
    case INPUT_TYPES.RADIO:
      return (
        <SingleChoice
          key={element.name}
          label={element.label}
          options={element.options?.map((option, inx) => ({
            label: `${inx + 1} - ${option.label}`,
            value: option.value,
          }))}
          {...getFieldProps({ formik, name: element.name, disabled })}
        />
      );
    case INPUT_TYPES.TABLE:
      return (
        <TableChoice formik={formik} element={element} disabled={disabled} />
      );
    case INPUT_TYPES.SCALE:
      return (
        <ScaleSelect
          key={element.name}
          {...getFieldProps({ formik, name: element.name, disabled })}
        />
      );
  }

  return null;
};

InputComponent.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      date: PropTypes.string,
    }),
    errors: PropTypes.shape({}),
    submitCount: PropTypes.number,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  element: PropTypes.object.isRequired,
};

export default InputComponent;
