import { useMemo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'store/hospital-system-sites';

const useHospitalSystemSiteDetails = (hospitalSystemId, siteId) => {
  const dispatch = useDispatch();

  const { error, loading, data } = useSelector(
    (state) => state.hospitalSystemSites?.details || {},
  );

  const hospitalSystem = useSelector(
    (state) => state.hospitalSystems?.byId[hospitalSystemId],
  );

  const bindedActions = useMemo(
    () =>
      bindActionCreators(
        {
          getSystemSiteData: actions.doGetHospitalSystemSite,
        },
        dispatch,
      ),
    [dispatch],
  );

  const isLoaded = useMemo(() => data?.id === Number(siteId), [data, siteId]);

  useEffect(() => {
    if (isLoaded) return;
    bindedActions.getSystemSiteData(hospitalSystemId, siteId);
  }, [hospitalSystemId, siteId, isLoaded, bindedActions]);

  return {
    ...bindedActions,
    data,
    error,
    hospitalSystemName: hospitalSystem?.name,
    isLoading: loading,
  };
};

export default useHospitalSystemSiteDetails;
