import styled from '@emotion/styled';

import { weight } from 'theme/fonts';

export const Practitioner = styled('div')(() => ({
  display: 'flex',
  marginBottom: '1rem',

  '&:last-child': {
    marginBottom: 0,
  },
}));

export const PractitionerInfo = styled('div')(() => ({
  paddingLeft: '1rem',
}));

export const PractitionerTitle = styled('div')(() => ({
  fontWeight: weight.bold,
}));
