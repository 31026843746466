import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES, yesNoOptions } from 'utils/constants';
import { limbOptions } from 'utils/encounters';
import { DiseaseEnum } from 'utils/enums';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import Input from 'components/forms/input';
import Select from 'components/forms/select';

const fields = {
  SOURCE_REFERRAL: 'sourceOfReferral',
  SOURCE_REFERRAL_OTHER: 'sourceOfReferralOther',
  DATE_REFERRAL: 'dateOfReferral',
  ACTIVE_ULCERATION: 'activeUlceration',
  ULCERATED_LIMBS: 'ulceratedLimbSide',
  DATE_ONSET_ACTIVE_ULCER_LEFT: 'currentlyActiveUlcerOnsetLeft',
  DATE_ONSET_ACTIVE_ULCER_RIGHT: 'currentlyActiveUlcerOnsetRight',
  ABI_MEASUREMENT: 'ableToCompleteAnABIMeasurement',
  ACTIVE_ULCERATION_VENOUS_ORIGIN: 'confirmedActiveVLUOrigin',
  ULCERATION_TYPE: 'ulcerationTypes',
  ADDITIONAL_NOTES: 'additionalNotes',
};

const sourceReferralOptions = [
  { value: 'PracticeNurse', label: 'Practice Nurse' },
  { value: 'GeneralPractitioner', label: 'General Practitioner' },
  { value: 'TissueViabilityNurse', label: 'Tissue Viability Nurse' },
  { value: 'DistrictNursing', label: 'District Nursing' },
  { value: 'DermatologyPlastics', label: 'Dermatology/Plastics' },
  { value: 'SelfReferral', label: 'Self-Referral' },
  { value: 'LegUlcerClinic', label: 'Leg Ulcer Clinic' },
  { value: 'OtherConsultant', label: 'Other Consultant' },
  { value: 'Other', label: 'Other (Specify)' },
];

const activeUlcerationOriginOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Unknown', label: 'Unknown' },
];

const ulcerationTypeOptions = [
  { value: 'Mixed', label: 'Mixed' },
  { value: 'Arterial', label: 'Arterial' },
  { value: 'Diabetic', label: 'Diabetic' },
  { value: 'Pressure', label: 'Pressure' },
  { value: 'Unknown', label: 'Unknown' },
];

export const SuspectedVLU = ({ formik, disabled, diseases }) => (
  <>
    <FormSectionTitle>Referral</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <Select
          label="Source of referral"
          options={sourceReferralOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.SOURCE_REFERRAL,
            disabled,
          })}
        />
      </Grid>
      {formik.values[fields.SOURCE_REFERRAL] === 'Other' && (
        <Grid item xs={12} md={4}>
          <Input
            label="Other (specify)"
            isRequired
            {...getFieldProps({
              formik,
              name: fields.SOURCE_REFERRAL_OTHER,
              disabled,
            })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of referral"
          isRequired
          {...getFieldProps({
            formik,
            name: fields.DATE_REFERRAL,
            disabled,
          })}
        />
      </Grid>
    </Grid>
    {diseases[DiseaseEnum.VLU] && (
      <>
        <FormSectionTitle>Suspected VLU</FormSectionTitle>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12}>
            <Chips
              label="Does the subject have active ulceration?"
              options={yesNoOptions}
              isRequired
              {...getFieldProps({
                formik,
                name: fields.ACTIVE_ULCERATION,
                disabled,
                changeOverride: (value) => {
                  if (!value) {
                    formik?.setValues(
                      update(formik?.values, {
                        [fields.ACTIVE_ULCERATION]: { $set: value },
                        [fields.ULCERATED_LIMBS]: { $set: null },
                        [fields.DATE_ONSET_ACTIVE_ULCER_LEFT]: {
                          $set: null,
                        },
                        [fields.DATE_ONSET_ACTIVE_ULCER_RIGHT]: {
                          $set: null,
                        },
                        [fields.ABI_MEASUREMENT]: { $set: null },
                        [fields.ACTIVE_ULCERATION_VENOUS_ORIGIN]: {
                          $set: null,
                        },
                        [fields.ULCERATION_TYPE]: { $set: [] },
                      }),
                    );
                  }
                },
              })}
            />
          </Grid>
          {formik.values[fields.ACTIVE_ULCERATION] && (
            <>
              <Grid container item spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12} md={4}>
                  <Chips
                    label="Ulcerated Limb(s)"
                    options={limbOptions}
                    isRequired
                    {...getFieldProps({
                      formik,
                      name: fields.ULCERATED_LIMBS,
                      disabled,
                      changeOverride: (value) => {
                        formik?.setValues(
                          update(formik?.values, {
                            [fields.ULCERATED_LIMBS]: {
                              $set: value,
                            },
                            [fields.DATE_ONSET_ACTIVE_ULCER_LEFT]: {
                              $set: null,
                            },
                            [fields.DATE_ONSET_ACTIVE_ULCER_RIGHT]: {
                              $set: null,
                            },
                          }),
                        );
                      },
                    })}
                  />
                </Grid>
                {['Left', 'Both'].includes(
                  formik.values[fields.ULCERATED_LIMBS],
                ) && (
                  <Grid item xs={12} md={4}>
                    <DateSelector
                      label="Approximate date of onset for the currently active ulcer (Left Limb)"
                      isRequired
                      {...getFieldProps({
                        formik,
                        name: fields.DATE_ONSET_ACTIVE_ULCER_LEFT,
                        disabled,
                      })}
                    />
                  </Grid>
                )}
                {['Right', 'Both'].includes(
                  formik.values[fields.ULCERATED_LIMBS],
                ) && (
                  <Grid item xs={12} md={4}>
                    <DateSelector
                      label="Approximate date of onset for the currently active ulcer (Right Limb)"
                      isRequired
                      {...getFieldProps({
                        formik,
                        name: fields.DATE_ONSET_ACTIVE_ULCER_RIGHT,
                        disabled,
                      })}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <Chips
                  label="Able to complete an ABI measurement during this visit, or does the patient have an ABI measurement in their record? If yes, please fill out the ABI Form."
                  options={yesNoOptions}
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: fields.ABI_MEASUREMENT,
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Chips
                  label="Confirmed active ulceration of venous origin?"
                  options={activeUlcerationOriginOptions}
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: fields.ACTIVE_ULCERATION_VENOUS_ORIGIN,
                    disabled,
                    changeOverride: (value) => {
                      if (value !== 'No') {
                        formik?.setValues(
                          update(formik?.values, {
                            [fields.ACTIVE_ULCERATION_VENOUS_ORIGIN]: {
                              $set: value,
                            },
                            [fields.ULCERATION_TYPE]: { $set: [] },
                          }),
                        );
                      }
                    },
                  })}
                />
              </Grid>
              {formik.values[fields.ACTIVE_ULCERATION_VENOUS_ORIGIN] ===
                'No' && (
                <Grid item xs={12} md={4}>
                  <Chips
                    label="If no, please specify the type of ulceration"
                    options={ulcerationTypeOptions}
                    isRequired
                    isMultiple
                    {...getFieldProps({
                      formik,
                      name: fields.ULCERATION_TYPE,
                      disabled,
                    })}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Input
              label="Additional Notes"
              type={INPUT_TYPES.COMMENT}
              isRequired
              {...getFieldProps({
                formik,
                name: fields.ADDITIONAL_NOTES,
                disabled,
              })}
            />
          </Grid>
        </Grid>
      </>
    )}
  </>
);

export default SuspectedVLU;

SuspectedVLU.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  diseases: PropTypes.object,
};
