import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import {
  countryOptions,
  defaultCountryCode,
  statesOptions,
} from 'utils/countries';
import { getFieldProps } from 'utils/forms';

import Select from 'components/forms/select';

const CountrySelector = ({
  formik,
  disabled,
  nameCountry = 'country',
  nameState = 'state',
}) => {
  const showStates = React.useMemo(
    () => formik.values.country === defaultCountryCode,
    [formik.values.country],
  );

  return (
    <Grid item container xs={12} md={4}>
      <Grid container spacing={2}>
        <Grid item xs={showStates ? 9 : 12}>
          <Select
            label="Country"
            options={countryOptions}
            dataTest="country-select"
            displayEmptyOption
            {...getFieldProps({
              formik,
              name: nameCountry,
              disabled,
            })}
          />
        </Grid>
        {showStates && (
          <Grid item xs={3}>
            <Select
              label="State"
              options={statesOptions}
              displayEmptyOption
              {...getFieldProps({
                formik,
                name: nameState,
                disabled,
              })}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

CountrySelector.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      country: PropTypes.string,
    }),
  }),
  disabled: PropTypes.bool,
  nameCountry: PropTypes.string,
  nameState: PropTypes.string,
};

export default CountrySelector;
