import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';

import { INPUT_TYPES } from 'utils/constants';

import Input from 'components/forms/input';
import StyledIconButton from 'components/buttons/StyledIconButton';

const InputPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const PasswordToggle = () => (
    <InputAdornment position="end">
      <StyledIconButton
        name={showPassword ? 'visibility' : 'visibilityOff'}
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(event) => event.preventDefault()}
        aria-label="toggle password visibility"
        color="grey"
        isSmall
      />
    </InputAdornment>
  );

  return (
    <Input
      {...props}
      type={showPassword ? INPUT_TYPES.SHORT_TEXT : INPUT_TYPES.PASSWORD}
      endAdornment={!props.disabled && <PasswordToggle />}
    />
  );
};

InputPassword.propTypes = {
  disabled: PropTypes.bool,
};

export default InputPassword;
