import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import useHospitalSystemListing from 'hooks/useHospitalSystemListing';
import useHospitalSystemSiteList from 'hooks/useHospitalSystemSiteList';

import Select from 'components/forms/select';
import SelectSearchable from 'components/forms/select-searchable';
import Toggle from 'components/toggle';

import {
  getFieldProps,
  normalizeDiseases,
  normalizeOptions,
} from 'utils/forms';

const getDiseaseFromSites = (hospitalSystemSites, selectedSites) => {
  const newDiseases = [];
  (hospitalSystemSites || []).forEach((hospitalSystemSite) => {
    if (selectedSites.includes(hospitalSystemSite.id)) {
      hospitalSystemSite.diseases.forEach((disease) => {
        if (!newDiseases.find((newDisease) => newDisease === disease)) {
          newDiseases.push(disease);
        }
      });
    }
  });

  return newDiseases;
};

const sortFilters = { Sort: 'Name', SortDirection: 'Asc' };

const PractitionerFields = ({ formik, readOnly }) => {
  const { hospitalSystems } = useHospitalSystemListing(sortFilters);
  const {
    items: hospitalSystemSites,
    isLoading: isHospitalSystemSitesLoading,
  } = useHospitalSystemSiteList({
    HospitalSystemId: formik.values.practitionerSystem,
    ...sortFilters,
  });

  const [diseases, setDiseases] = useState(
    getDiseaseFromSites(hospitalSystemSites, formik.values.practitionerSites),
  );

  useEffect(() => {
    if (typeof formik.setFieldValue === 'function' && formik.dirty) {
      formik.setFieldValue('practitionerSites', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.practitionerSystem]);

  useEffect(() => {
    if (typeof formik.setFieldValue === 'function' && formik.dirty) {
      formik.setFieldValue('practitionerManagedDiseaseStates', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.practitionerSites]);

  useEffect(() => {
    if (!isHospitalSystemSitesLoading) {
      setDiseases(
        getDiseaseFromSites(
          hospitalSystemSites,
          formik.values.practitionerSites,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHospitalSystemSitesLoading, formik.values.practitionerSites]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectSearchable
          options={normalizeOptions(hospitalSystems)}
          placeholder="System"
          {...getFieldProps({
            formik,
            name: 'practitionerSystem',
            disabled: readOnly,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          options={normalizeOptions(hospitalSystemSites)}
          placeholder="Sites"
          multiple
          {...getFieldProps({
            formik,
            name: 'practitionerSites',
            disabled: readOnly || !formik.values.practitionerSystem,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Toggle
          label="View All Patients in System"
          {...getFieldProps({
            formik,
            name: 'global',
            disabled: readOnly || !formik.values.practitionerSystem,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Managed Disease State(s)"
          options={normalizeDiseases(diseases)}
          placeholder={'Select the managed disease state(s)'}
          multiple
          {...getFieldProps({
            formik,
            name: 'practitionerManagedDiseaseStates',
            disabled: readOnly || !formik.values.practitionerSites.length,
          })}
        />
      </Grid>
    </Grid>
  );
};

PractitionerFields.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      practitionerSystem: PropTypes.string,
      practitionerSites: PropTypes.array,
      practitionerManagedDiseaseStates: PropTypes.array,
    }),
    setFieldValue: PropTypes.func,
    dirty: PropTypes.bool,
  }),
  readOnly: PropTypes.bool,
};

export default PractitionerFields;
