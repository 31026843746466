import { SET_FEEDBACK, CLEAR_FEEDBACK } from './actionTypes';

export const initialState = {
  message: null,
  details: null,
  type: null,
};

const feedback = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_FEEDBACK:
      return action.payload;
    case CLEAR_FEEDBACK:
      return initialState;
    default:
      return state;
  }
};

export default feedback;
