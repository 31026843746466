import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import useHospitalSystemListing from 'hooks/useHospitalSystemListing';
import useTableState from 'hooks/useTableState';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { DiseaseLabels } from 'utils/enums';

import Template from 'pages/system-administrator/template';
import Table from 'components/table';
import ItemsTooltip from 'components/tooltip/ItemsTooltip';

import { columnStyles } from './HospitalSystemListing.styles';

const DEFAULT_SORT_BY = 'Name';
const DEFAULT_SORT_DIRECTION = 'Asc';

const HospitalSystemListing = () => {
  const navigate = useNavigate();

  const { page, sortBy, sortOrder, handlePageChange, handleSortChange } =
    useTableState(DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION);

  const listingFilters = useMemo(
    () => ({
      sort: sortBy,
      sortDirection: sortOrder,
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE,
    }),
    [page, sortBy, sortOrder],
  );

  const { getHospitalSystems, isLoading, hospitalSystems, pageCount } =
    useHospitalSystemListing(listingFilters);

  useEffect(() => {
    if (!isLoading) {
      getHospitalSystems(listingFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHospitalSystems, listingFilters]);

  const hospitalTableAttributes = [
    { title: 'System Name', handleClick: handleSortChange, id: 'Name' },
    {
      title: 'Active Patients',
      handleClick: handleSortChange,
      id: 'ActivePatientCount',
    },
    { title: 'Sites', handleClick: handleSortChange, id: 'SiteCount' },
    {
      title: 'Disease States',
      handleClick: handleSortChange,
      id: 'DiseaseCount',
    },
  ];

  const rows = useMemo(
    () =>
      hospitalSystems
        ? hospitalSystems.map(
            ({ id, name, activePatientCount, siteCount, diseases }) => ({
              id,
              data: [
                name,
                activePatientCount,
                siteCount,
                <ItemsTooltip
                  key={id}
                  items={diseases.map((name) => DiseaseLabels[name])}
                >
                  {diseases.length}
                </ItemsTooltip>,
              ],
            }),
          )
        : [],
    [hospitalSystems],
  );

  return (
    <Template
      title="Hospital Systems"
      actions={
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate('new')}
        >
          Add System
        </Button>
      }
    >
      <Table
        header={hospitalTableAttributes}
        rows={rows}
        page={page}
        minimumRows={DEFAULT_PAGE_SIZE}
        totalPages={pageCount}
        handlePageChange={handlePageChange}
        handleRowClick={(id) => navigate(id)}
        isLoading={isLoading}
        orderBy={sortBy}
        orderDirection={sortOrder}
        columnStyles={columnStyles}
      />
    </Template>
  );
};

export default HospitalSystemListing;
