import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const SubmitBox = styled('div')(({ noMargin }) => ({
  display: 'flex',
  gap: '1rem',
  justifyContent: 'flex-end',
  marginTop: noMargin ? 0 : '1.5rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'initial',
  },
}));
