import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import MuiIcon from '@mui/icons-material/FiberManualRecord';

import { encounterStatusEnum } from 'utils/encounters';

const StyledIcon = styled(MuiIcon)(({ theme, status, large }) => {
  const statusColors = React.useMemo(
    () => ({
      [encounterStatusEnum.DRAFT]: theme.palette.grey[300],
      [encounterStatusEnum.SUBMITTED]: theme.palette.primary.main,
    }),
    [theme.palette],
  );

  return {
    color: statusColors[status] ?? theme.palette.error.main,
    fontSize: large === 'true' ? '1.5rem' : 'inherit',
    marginRight: '1rem',
  };
});

const StatusIcon = ({ large = false, status }) => (
  <StyledIcon large={String(large)} status={status} />
);

StatusIcon.propTypes = {
  large: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

export default StatusIcon;
