import styled from '@emotion/styled';

import Button from '@mui/material/Button';

export const EncounterTypeButton = styled(Button)(({ theme, selected }) => ({
  minHeight: '3rem',
  border: `1px solid ${theme.palette.primary.main}`,
  background: selected ? theme.palette.primary.main : theme.palette.base.white,
  color: selected ? theme.palette.base.white : theme.palette.primary.main,
  textTransform: 'none',

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));
