import styled from '@emotion/styled';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundImage: theme.palette.gradient.main,
}));

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'fit-content',
  minWidth: '30rem',
  padding: '2rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
}));

export const AccountLogo = styled('img')(() => ({
  width: '10rem',
  height: '3rem',
  marginBottom: '2rem',
  objectFit: 'contain',
}));
