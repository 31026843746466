import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import {
  Section,
  Card,
} from 'pages/account/roles-dashboard/RolesDashboard.styles';
import { Title } from 'pages/account/AppAuth.styles';

const NotFound = () => (
  <Section>
    <Card>
      <Title>Page Not Found</Title>
      <Button
        variant="contained"
        component={Link}
        to="/"
        fullWidth
        sx={{ marginTop: '1rem' }}
      >
        Go Home
      </Button>
    </Card>
  </Section>
);

export default NotFound;
