import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { CARE_TRACK_PATHS } from 'navigation/paths';

import Dashboard from './dashboard';
import CompressionList from './patient-data/compression';
import PainMobilityList from './patient-data/pain-mobility';
import MedicationComplianceList from './patient-data/medication-compliance';
import WoundComplianceList from './patient-data/wound-size-logs';
import EncounterAdd from './encounters/add';
import EncounterDetails from './encounters/details';
import Questionnaire from './questionnaires/questionnaire';
import QuestionnaireResponse from './questionnaires/questionnaire-response';
import NewQuestionnaireResponse from './questionnaires/new-questionnaire-response';

const careTrackPathComponents = {
  [CARE_TRACK_PATHS.DASHBOARD]: <Dashboard />,
  [CARE_TRACK_PATHS.COMPRESSION_COMPLIANCE]: <CompressionList />,
  [CARE_TRACK_PATHS.PAIN_MOBILITY]: <PainMobilityList />,
  [CARE_TRACK_PATHS.WOUND_SIZE]: <WoundComplianceList />,
  [CARE_TRACK_PATHS.MEDICATION_COMPLIANCE]: <MedicationComplianceList />,
  [CARE_TRACK_PATHS.ENCOUNTERS_ADD]: <EncounterAdd />,
  [CARE_TRACK_PATHS.ENCOUNTERS_DETAILS]: <EncounterDetails />,
  [CARE_TRACK_PATHS.QUESTIONNAIRE]: <Questionnaire />,
  [CARE_TRACK_PATHS.QUESTIONNAIRE_RESPONSE]: <QuestionnaireResponse />,
  [CARE_TRACK_PATHS.NEW_QUESTIONNAIRE_RESPONSE]: <NewQuestionnaireResponse />,
};

const CareTrack = () => (
  <Routes>
    {Object.values(CARE_TRACK_PATHS).map((routePath) => (
      <Route
        key={routePath}
        path={`${routePath}/*`}
        element={careTrackPathComponents[routePath]}
        exact={routePath.split('/')[0] !== 'encounters'}
      />
    ))}
  </Routes>
);

export default CareTrack;
