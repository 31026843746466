import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { useTheme } from 'context/theme';
import { sidesValues } from 'utils/encounters';
import { FormSectionTitle, FormSubSectionTitle } from 'utils/styles';

export const MultipleColumnsSection = ({ sides, sidesSections }) => {
  const { palette } = useTheme();

  return (
    !!sides?.length &&
    sidesSections?.map(({ title, subtitle, items }, index) => (
      <React.Fragment key={index}>
        {title && <FormSectionTitle>{title}</FormSectionTitle>}
        <Grid container spacing={2} alignItems="center" sx={{ py: 2 }}>
          {subtitle && (
            <Grid item xs={12}>
              <FormSubSectionTitle>{subtitle}</FormSubSectionTitle>
            </Grid>
          )}
          {items.map(({ label, input }, index) => {
            return (
              <React.Fragment key={`${label}-${index}`}>
                {!!index && (
                  <Grid item container spacing={2} alignItems="center">
                    {[...Array(sides.length + 1).keys()].map((index) => (
                      <Grid
                        key={index}
                        item
                        md={12 / (sides.length + 1)}
                        xs={12}
                      >
                        <Divider sx={{ borderColor: palette.grey[300] }} />
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid item container spacing={2} alignItems="center">
                  <Grid item md={12 / (sides.length + 1)} xs={12}>
                    <Box textAlign="right">
                      <span>{label}</span>
                    </Box>
                  </Grid>
                  {sides.map((side) => (
                    <Grid
                      key={side}
                      item
                      md={12 / (sides.length + 1)}
                      xs={12}
                      sx={{ alignSelf: 'flex-start' }}
                    >
                      {input(sidesValues[side])}
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </React.Fragment>
    ))
  );
};

MultipleColumnsSection.propTypes = {
  sides: PropTypes.arrayOf(PropTypes.string),
  sidesSections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
};
