import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';

import FormWrapper from 'components/forms/form-wrapper';

import PainMobilityForm from './PainMobilityForm';
import { painAndMobilitySchema, formFieldEnum, mapFormToApi } from './utils';

const NewPainMobility = ({ handleCancel, handleRefresh }) => {
  const { patientId } = useParams();
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { getUser } = useUserDetails();

  const handleSubmit = (data) => {
    clearFeedback();
    api
      .insertPainMobility({ patientId, ...mapFormToApi(data) })
      .then(() => {
        getUser(patientId);
        setSuccessFeedback(
          'Pain, mobility and leg swelling log created with success.',
        );
        if (handleRefresh) handleRefresh();
        handleCancel();
      })
      .catch((err) => setErrorFeedback(getErrorMessage(err)));
  };

  const initialValues = {
    [formFieldEnum.PAIN]: '',
    [formFieldEnum.MOBILITY]: '',
    timestamp: new Date().toString(),
  };

  return (
    <FormWrapper
      initialValues={initialValues}
      validationSchema={painAndMobilitySchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => <PainMobilityForm formik={formik} titlePrefix="Add" />}
    </FormWrapper>
  );
};

NewPainMobility.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};

export default NewPainMobility;
