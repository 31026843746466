import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const getErrorMessage = (error) =>
  error.response?.data?.title || error.message || 'Something went wrong';

export const getErrorDetails = (error) => {
  const detailsObj = error.response?.data?.errors;
  if (isEmpty(detailsObj)) return [];
  return Object.values(detailsObj).reduce(
    (res, errorArray) => [...res, ...errorArray],
    [],
  );
};

export const getFieldError = ({ name, errors, touched }) =>
  touched && errors && touched[name] && get(errors, name);
