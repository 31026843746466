import * as Yup from 'yup';

import { userRolesValues, USER_GROUPS } from 'utils/constants';
import { stateRegex, phoneValidation } from 'utils/validations';

export const userSchema = Yup.object({
  email: Yup.string()
    .required('Required')
    .email('Invalid Email')
    .max(2048, 'Email too long'),
  firstName: Yup.string().required('Required').max(100, 'First name too long'),
  lastName: Yup.string().required('Required').max(100, 'Last name too long'),
  phoneNumber: phoneValidation().required('Required'),
  faxNumber: phoneValidation('fax'),
  street: Yup.string().max(180, 'Address too long'),
  city: Yup.string().max(100, 'City too long'),
  state: Yup.string()
    .matches(stateRegex, 'Invalid state')
    .max(50, 'State too long'),
  country: Yup.string().max(3, 'Country too long'),
  roles: Yup.array()
    .required('Required')
    .min(1, 'Select at least one ŕole')
    .of(Yup.string().oneOf(userRolesValues, 'Invalid Role')),
  practitionerSystem: Yup.mixed().when('roles', {
    is: (value) => value.includes(USER_GROUPS.practitioner),
    then: () => Yup.string().required('Required'),
  }),
  practitionerSites: Yup.mixed().when('roles', {
    is: (value) => value.includes(USER_GROUPS.practitioner),
    then: () => Yup.array().min(1, 'Required'),
  }),
  practitionerManagedDiseaseStates: Yup.mixed().when('roles', {
    is: (value) => value.includes(USER_GROUPS.practitioner),
    then: () => Yup.array().min(1, 'Required'),
  }),
  hospitalAdminSystem: Yup.mixed().when('roles', {
    is: (value) => value.includes(USER_GROUPS.hospitalAdministrator),
    then: () => Yup.string().required('Required'),
  }),
  eCRFSystem: Yup.mixed().when('roles', {
    is: (value) => value.includes(USER_GROUPS.eCRF),
    then: () => Yup.string().required('Required'),
  }),
  logoutAfterMinutes: Yup.string()
    .nullable()
    .when('autoLogout', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
});
