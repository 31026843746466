import * as Yup from 'yup';

import { INPUT_TYPES } from 'utils/constants';

import { SF36 } from './data/SF36';
import { VeinesQolSym } from './data/VeinesQolSym';
import { PembQol } from './data/PembQol';
import { WoundQol } from './data/WoundQol';
import { EQ5D5L } from './data/EQ5D5L';

export const getFormQuestions = (form) => {
  const formReduced = form?.reduce((acc, cur) => [...acc, ...cur], []);

  return formReduced
    ?.reduce(
      (acc, cur) =>
        cur.type === INPUT_TYPES.TABLE
          ? [...acc, ...cur.fields]
          : [...acc, cur],
      [],
    )
    .filter((field) => field.type !== INPUT_TYPES.INFO);
};

export const getQuestionnaireFormSchema = (form) => {
  const questions = getFormQuestions(form);
  const schema = questions?.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: Yup.string().required('Required') }),
    {},
  );

  return Yup.object(schema);
};

export const questionnairesForms = {
  SF36: SF36,
  VeinesQolSym: VeinesQolSym,
  PembQol: PembQol,
  WoundQol: WoundQol,
  EQ5D5L: EQ5D5L,
};
