import * as Yup from 'yup';
import omit from 'lodash/omit';
import startOfDay from 'date-fns/startOfDay';

import { formatDate, DATEBASE_FORMAT } from 'utils/date';

import {
  ruleTypeEnum,
  ruleTypeLabel,
  cronMacroEnum,
  cronMacroLabel,
  getCronDays,
  dataTypeEnum,
  dataTypeLabel,
} from '../utils';

export const formFields = {
  TYPE: 'type',
  REPEATS: 'repeats',
  REPEAT_DAYS: 'repeatDays',
  DATA_ENTRY: 'dataEntry',
  QUESTIONNAIRE: 'questionnaire',
  GENERIC: 'generic',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

export const ruleTypeOptions = Object.values(ruleTypeEnum).map((value) => ({
  value,
  label: ruleTypeLabel[value],
}));

export const dataTypeOptions = Object.values(dataTypeEnum).map((value) => ({
  value,
  label: dataTypeLabel[value],
}));

export const cronMacroOptions = Object.values(cronMacroEnum).map((value) => ({
  value,
  label: cronMacroLabel[value],
}));

export const dayOptions = [
  { value: 'MON', label: 'Mon' },
  { value: 'TUE', label: 'Tue' },
  { value: 'WED', label: 'Wed' },
  { value: 'THU', label: 'Thu' },
  { value: 'FRI', label: 'Fri' },
  { value: 'SAT', label: 'Sat' },
  { value: 'SUN', label: 'Sun' },
];

export const validationSchema = Yup.object({
  [formFields.TYPE]: Yup.string().required('Required'),
  [formFields.REPEATS]: Yup.string().required('Required'),
  [formFields.REPEAT_DAYS]: Yup.array().when(formFields.REPEATS, {
    is: cronMacroEnum.WEEKLY,
    then: (schema) => schema.min(1, 'Required').required('Required'),
  }),
  [formFields.DATA_ENTRY]: Yup.string().when(formFields.TYPE, {
    is: ruleTypeEnum.DATA_ENTRY,
    then: (schema) => schema.required('Required'),
  }),
  [formFields.QUESTIONNAIRE]: Yup.string().when(formFields.TYPE, {
    is: ruleTypeEnum.QUESTIONNAIRE,
    then: (schema) => schema.required('Required'),
  }),
  [formFields.GENERIC]: Yup.string().when(formFields.TYPE, {
    is: ruleTypeEnum.GENERIC,
    then: (schema) => schema.required('Required'),
  }),
  [formFields.START_DATE]: Yup.date().required('Required').nullable(),
  [formFields.END_DATE]: Yup.date().required('Required').nullable(),
});

const typeField2Api = (data) => {
  switch (data[formFields.TYPE]) {
    case ruleTypeEnum.DATA_ENTRY:
      return {
        dataEntryParameters: {
          type: data[formFields.DATA_ENTRY],
        },
      };
    case ruleTypeEnum.QUESTIONNAIRE:
      return {
        questionnaireParameters: {
          questionnaireType: data[formFields.QUESTIONNAIRE],
        },
      };
    case ruleTypeEnum.GENERIC:
      return {
        genericParameters: {
          detail: data[formFields.GENERIC],
        },
      };
    default:
      return {};
  }
};

const repeatFields2Api = (data) => {
  switch (data[formFields.REPEATS]) {
    case cronMacroEnum.WEEKLY: {
      const days = data[formFields.REPEAT_DAYS];
      return `0 0 * * ${days.join(',')}`;
    }
    case cronMacroEnum.NEVER:
      return null;
    default:
      return data[formFields.REPEATS];
  }
};

const dateParse = (date) =>
  date ? formatDate(startOfDay(new Date(date)), DATEBASE_FORMAT) : null;

export const mapForm2Api = (data) => {
  const reducedData = omit(data, [
    formFields.REPEATS,
    formFields.REPEAT_DAYS,
    formFields.DATA_ENTRY,
    formFields.QUESTIONNAIRE,
    formFields.GENERIC,
  ]);

  return {
    ...reducedData,
    ...typeField2Api(data),
    startDate: dateParse(reducedData[formFields.START_DATE]),
    endDate: dateParse(reducedData[formFields.END_DATE]),
    cronExpression: repeatFields2Api(data),
  };
};

const api2RepeatField = (cron) => {
  switch (cron) {
    case cronMacroEnum.DAILY:
    case cronMacroEnum.MONTHLY:
      return { [formFields.REPEATS]: cron };
    case null:
      return { [formFields.REPEATS]: cronMacroEnum.NEVER };
    default:
      return {
        [formFields.REPEATS]: cronMacroEnum.WEEKLY,
        [formFields.REPEAT_DAYS]: getCronDays(cron).split(','),
      };
  }
};

const api2TypeField = (data) => {
  switch (data[formFields.TYPE]) {
    case ruleTypeEnum.DATA_ENTRY:
      return {
        [formFields.DATA_ENTRY]: data.dataEntryParameters?.type,
      };
    case ruleTypeEnum.QUESTIONNAIRE:
      return {
        [formFields.QUESTIONNAIRE]:
          data.questionnaireParameters?.questionnaireType,
      };
    case ruleTypeEnum.GENERIC:
      return {
        [formFields.GENERIC]: data.genericParameters?.detail,
      };
  }
};

export const mapApi2Form = (data) => ({
  [formFields.TYPE]: data.type,
  [formFields.START_DATE]: data.startDate,
  [formFields.END_DATE]: data.endDate,
  ...api2RepeatField(data.cronExpression),
  ...api2TypeField(data),
});
