import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { INPUT_TYPES, yesNoOptions } from 'utils/constants';
import { limbOptions } from 'utils/encounters';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';

import Chips from 'components/forms/chips';
import DateSelector from 'components/forms/date-selector';
import FileUpload from 'components/forms/file-upload';
import Input from 'components/forms/input';

const fields = {
  DATE_XRAY: 'dateOfXRay',
  LIMB_SIDE: 'limbAssessed',
  ASSET_ID: 'assetId',
  ADDITIONAL_NOTES: 'additionalNotes',
  RESULTS_DIAGNOSTIC_TEST: 'patientHasResultsFromThisDiagnosticTest',
};

const XRay = ({ formik, disabled }) => (
  <>
    <FormSectionTitle>X-Ray</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} md={4}>
        <DateSelector
          label="Date of X-Ray"
          isRequired
          {...getFieldProps({
            formik,
            name: fields.DATE_XRAY,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Chips
          label="Limb(s) Assessed"
          options={limbOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.LIMB_SIDE,
            disabled,
          })}
        />
      </Grid>
    </Grid>
    <FormSectionTitle>Additional Information</FormSectionTitle>
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12}>
        <FileUpload
          label="File Upload"
          {...getFieldProps({
            formik,
            name: fields.ASSET_ID,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Additional Notes"
          type={INPUT_TYPES.COMMENT}
          {...getFieldProps({
            formik,
            name: fields.ADDITIONAL_NOTES,
            disabled,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Chips
          label="Do you have the results from this diagnostic test? If yes, please fill out the Diagnostic Results Form with findings from the X-Ray."
          options={yesNoOptions}
          isRequired
          {...getFieldProps({
            formik,
            name: fields.RESULTS_DIAGNOSTIC_TEST,
            disabled,
          })}
        />
      </Grid>
    </Grid>
  </>
);

export default XRay;

XRay.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};
