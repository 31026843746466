import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import startCase from 'lodash/startCase';

import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryLegend,
  VictoryAxis,
  VictoryLabel,
  VictoryGroup,
  VictoryTooltip,
} from 'victory';

import {
  format,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfWeek,
  endOfMonth,
  endOfYear,
} from 'date-fns';

import { useTheme } from 'context/theme';
import { getTimestampGraphLabel } from 'components/widgets/utils';
import { PeriodEnum } from 'utils/enums';

import {
  formFieldEnum,
  getGraphMobilityValue,
  getGraphPainValue,
  getGraphLegSwellingValue,
} from '../utils';

const PainMobilityGraph = ({
  painMobilityList,
  getPainMobilityData,
  selectedDate,
  isLoading,
  viewMode,
}) => {
  const { patientId } = useParams();
  const { palette } = useTheme();

  const graphSeries = useMemo(
    () => [
      {
        name: formFieldEnum.PAIN,
        getY: getGraphPainValue,
        color: palette.error.main,
      },
      {
        name: formFieldEnum.MOBILITY,
        getY: getGraphMobilityValue,
        color: palette.primary.main,
      },
      {
        name: formFieldEnum.LEG_SWELLING,
        getY: getGraphLegSwellingValue,
        color: palette.success.main,
      },
    ],
    [palette],
  );

  const legend = useMemo(() => {
    return graphSeries.map(({ name, color }) => ({
      name: startCase(name),
      symbol: { fill: color },
    }));
  }, [graphSeries]);

  const timeInterval = useMemo(() => {
    switch (viewMode) {
      case PeriodEnum.WEEK:
        return {
          since: startOfWeek(new Date(selectedDate), { weekStartsOn: 1 }),
          until: endOfWeek(new Date(selectedDate), { weekStartsOn: 1 }),
          tickFormat: 'E',
        };
      case PeriodEnum.MONTH:
        return {
          since: startOfMonth(new Date(selectedDate), { weekStartsOn: 1 }),
          until: endOfMonth(new Date(selectedDate), { weekStartsOn: 1 }),
          tickFormat: 'do',
        };
      case PeriodEnum.YEAR:
        return {
          since: startOfYear(new Date(selectedDate), { weekStartsOn: 1 }),
          until: endOfYear(new Date(selectedDate), { weekStartsOn: 1 }),
          tickFormat: 'MMM',
        };
      default:
        return null;
    }
  }, [selectedDate, viewMode]);

  useEffect(() => {
    if (!timeInterval) return;
    getPainMobilityData({
      PatientId: patientId,
      DateFrom: timeInterval.since.toISOString(),
      DateTo: timeInterval.until.toISOString(),
    });
  }, [timeInterval, getPainMobilityData, patientId]);

  return (
    <>
      <VictoryChart
        padding={70}
        scale={{ x: 'time', y: 'linear' }}
        domain={{
          x: [timeInterval?.since, timeInterval?.until],
          y: [1, 5],
        }}
        domainPadding={{ y: 15 }}
      >
        <VictoryLegend
          x={150}
          y={20}
          orientation="horizontal"
          gutter={20}
          data={legend}
        />

        <VictoryAxis
          dependentAxis
          tickFormat={(y) => {
            switch (y) {
              case 1:
                return 'No Issue';
              case 3:
                return 'Moderate';
              case 5:
                return 'Extreme';
              default:
                return '';
            }
          }}
          tickLabelComponent={<VictoryLabel />}
        />
        <VictoryAxis
          key="xAxis"
          tickFormat={(t) => {
            return format(new Date(t), timeInterval?.tickFormat);
          }}
        />
        {graphSeries.map(({ name, getY, color }) => (
          <VictoryGroup
            eventKey={(x) => {
              x.timestamp;
            }}
            sortKey={(x) => {
              x.timestamp;
            }}
            key={name}
            name={`${name}-serie`}
            data={painMobilityList}
            x={(x) => new Date(x.timestamp)}
            y={getY}
          >
            <VictoryLine
              interpolation="natural"
              style={{
                data: { stroke: color },
                parent: { border: `1px solid ${palette.grey[300]}` },
              }}
            />
            <VictoryScatter
              style={{ data: { fill: color } }}
              size={7}
              labels={getTimestampGraphLabel}
              labelComponent={
                <VictoryTooltip
                  orientation="bottom"
                  centerOffset={{ y: 10 }}
                  pointerLength={0}
                />
              }
            />
          </VictoryGroup>
        ))}
        {isLoading && (
          <VictoryLegend
            x={160}
            y={130}
            data={[
              {
                name: 'loading',
                symbol: { fill: palette.base.white },
              },
            ]}
          />
        )}
      </VictoryChart>
    </>
  );
};

PainMobilityGraph.propTypes = {
  painMobilityList: PropTypes.array.isRequired,
  getPainMobilityData: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  viewMode: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default PainMobilityGraph;
