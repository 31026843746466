import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';
import { userValidation } from 'utils/validations';

import { mapForm2Api } from 'pages/practitioner/settings/form/utils';
import CountrySelector from 'components/forms/country-selector';
import FormWrapper from 'components/forms/form-wrapper';
import Input from 'components/forms/input';
import InputPhoneNumber from 'components/forms/input-phone-number';

import Template from '../template/Template';

const mapApi2Form = (data) => ({
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  street: data?.street ?? '',
  city: data?.city ?? '',
  country: data?.country ?? '',
  state: data?.state ?? '',
  email: data?.email ?? '',
  phoneNumber: data?.phoneNumber ?? '',
  faxNumber: data?.faxNumber ?? '',
});

const UserSettings = () => {
  const [disabled, setDisabled] = React.useState(true);
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();
  const { userDetails, updateCurrentUserDetails } = useUser();

  const data = React.useMemo(() => mapApi2Form(userDetails), [userDetails]);

  const onSettingsSave = async (formData) => {
    clearFeedback();

    try {
      await updateCurrentUserDetails(
        mapForm2Api({
          ...userDetails,
          firstName: formData.firstName,
          lastName: formData.lastName,
          street: formData.street,
          city: formData.city,
          country: formData.country,
          state: formData.state,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          faxNumber: formData.faxNumber,
        }),
      );
      setSuccessFeedback('Details updated with success');
      setDisabled(true);
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  return (
    <Template
      title="User Settings"
      actions={
        disabled && (
          <Button
            variant="contained"
            onClick={() => setDisabled(false)}
            data-test="edit-form-button"
          >
            Edit
          </Button>
        )
      }
    >
      <FormWrapper
        initialValues={data}
        validationSchema={userValidation}
        disabled={disabled}
        confirmStr="Save Details"
        onSubmit={onSettingsSave}
        onCancel={() => setDisabled(true)}
        onEdit={() => setDisabled(false)}
      >
        {({ formik }) => (
          <form onSubmit={formik.handleSubmit}>
            <FormSectionTitle>Information</FormSectionTitle>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="First Name"
                    isRequired
                    {...getFieldProps({
                      formik,
                      name: 'firstName',
                      disabled,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Last Name"
                    isRequired
                    {...getFieldProps({ formik, name: 'lastName', disabled })}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Address"
                  {...getFieldProps({
                    formik,
                    name: 'street',
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="City"
                  {...getFieldProps({ formik, name: 'city', disabled })}
                />
              </Grid>
              <CountrySelector formik={formik} disabled={disabled} />
              <Grid item xs={12} md={4}>
                <Input
                  label="Email"
                  autoComplete="username"
                  isRequired
                  {...getFieldProps({ formik, name: 'email', disabled })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputPhoneNumber
                  label="Phone Number"
                  isRequired
                  {...getFieldProps({
                    formik,
                    name: 'phoneNumber',
                    disabled,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputPhoneNumber
                  label="Fax Number"
                  {...getFieldProps({ formik, name: 'faxNumber', disabled })}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </FormWrapper>
    </Template>
  );
};

export default UserSettings;
