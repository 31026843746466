import React from 'react';
import update from 'immutability-helper';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { SYS_ADMIN_PATHS } from 'navigation/paths';
import { getErrorDetails, getErrorMessage } from 'utils/error';

import Template from 'pages/system-administrator/template';
import ConfirmDialog from 'components/confirm-dialog';
import ErrorMessage from 'components/error-message';
import FormWrapper from 'components/forms/form-wrapper';

import PatientForm, { getPatientFormValues } from '../form';
import { patientSchema } from '../form/validations';

const PatientDetails = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const { patientId } = useParams();
  const { setSuccessFeedback, setErrorFeedback, clearFeedback } = useFeedback();

  const {
    user: patient,
    isLoading,
    error,
  } = useUserDetails(patientId, {
    shouldLoad: true,
    isPatient: true,
  });

  const handleSubmit = (data) => {
    const submitData = update(patient, {
      active: { $set: data.active },
      firstName: { $set: data.firstName },
      lastName: { $set: data.lastName },
      street: { $set: data.street },
      city: { $set: data.city },
      country: { $set: data.country },
      state: { $set: data.state },
      email: { $set: data.email },
      phoneNumber: { $set: data.phoneNumber },
      birthDate: { $set: data.birthDate },
      gender: { $set: data.gender },
      ianaTimezone: { $set: data.ianaTimezone },
      patient: {
        hospitalSystemId: { $set: data.hospitalSystemId },
        diseases: {
          $set: data.diseases.map((disease) => ({
            disease,
            diseasePathwayStep: data.diseasePathwaySteps[disease],
          })),
        },
        practitioners: { $set: data.practitioners },
      },
    });

    clearFeedback();
    api
      .updateUser(patientId, submitData)
      .then(() => {
        setDisabled(true);
        setSuccessFeedback('Patient updated with success');
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  const deletePatient = () => {
    setShowDeleteModal(false);
    clearFeedback();
    api
      .deleteUser(patientId)
      .then(() => {
        setSuccessFeedback('User deleted successfully');
        navigate(SYS_ADMIN_PATHS.patients);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  const templateTitle = React.useMemo(() => {
    if (!patient || isLoading) return 'Loading Patient';
    if (error) return 'Error loading patient';
    if (patient?.id === patientId)
      return `${patient?.firstName} ${patient?.lastName}`;
    return null;
  }, [patient, patientId, error, isLoading]);

  const values = React.useMemo(() => getPatientFormValues(patient), [patient]);

  return (
    <Template
      backUrl={SYS_ADMIN_PATHS.patients}
      title={templateTitle}
      isLoading={!patient || isLoading}
      actions={
        <>
          {disabled && (
            <Button
              variant="contained"
              onClick={() => setDisabled(false)}
              data-test="edit-form-button"
            >
              Edit
            </Button>
          )}
          {process.env.REACT_APP_ENV !== 'production' ? (
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </Button>
          ) : null}
          <Button variant="contained">Reset Password</Button>
        </>
      }
    >
      {error ? (
        <ErrorMessage message="Error loading patient" />
      ) : (
        <>
          <ConfirmDialog
            open={showDeleteModal}
            handleCancel={() => setShowDeleteModal(false)}
            handleConfirm={() => deletePatient()}
            title={`Are you sure you want to delete patient ${patient?.email}?`}
            subtitle="This cannot be undone."
          />
          <FormWrapper
            initialValues={values}
            validationSchema={patientSchema}
            onSubmit={handleSubmit}
            disabled={disabled}
            onCancel={() => setDisabled(true)}
            enableReinitialize
          >
            {({ formik }) => (
              <PatientForm formik={formik} readOnly={disabled} />
            )}
          </FormWrapper>
        </>
      )}
    </Template>
  );
};

export default PatientDetails;
