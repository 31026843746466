import { formatBirthDate, formatISOString } from 'utils/date';

export const mapApiToForm = (data) => ({
  active: data?.active,
  activatedAt: formatISOString(data?.activatedAt),
  deactivatedAt: formatISOString(data?.deactivatedAt),
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  ianaTimezone: data?.ianaTimezone ?? '',
  street: data?.street ?? '',
  city: data?.city ?? '',
  country: data?.country ?? '',
  state: data?.state ?? '',
  email: data?.email ?? '',
  phoneNumber: data?.phoneNumber ?? '',
  birthDate: data?.birthDate
    ? formatBirthDate(data.birthDate, false, 'yyyy-MM-dd')
    : null,
  gender: data?.gender ?? '',
  practitioners: data?.patient?.practitioners?.map(({ id }) => id) ?? [],
  diseases: data?.patient?.diseases?.map(({ disease }) => disease) ?? [],
  diseasePathwaySteps:
    data?.patient?.diseases?.reduce((res, { disease, diseasePathwayStep }) => {
      return { ...res, [disease]: diseasePathwayStep };
    }, {}) ?? {},
});
