import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const WrapperContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80vh',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
  boxShadow: '0 0.1rem 0.25rem ' + theme.palette.grey[300],
}));

export const RoomWrapperContainer = styled('div')(({ theme }) => ({
  width: '25vw',
  minWidth: '25vw',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  borderRight: '1px solid ' + theme.palette.grey[300],

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: '100%',
    borderRight: 0,
  },
}));

export const NoRoomSelected = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.grey[500],
}));
