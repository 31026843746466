import React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Template from 'pages/practitioner/patients/details/dashboard/Template';

import ClinicalGraph from './components/clinical-graph';
import EventsGraph from './components/events-graph';

const Graphs = () => {
  const [currentGraph, setCurrentGraph] = React.useState('Clinical');

  return (
    <Template title="">
      <ButtonGroup style={{ marginBottom: '1rem' }}>
        {['Clinical', 'Events'].map((name) => (
          <Button
            key={name}
            variant={currentGraph === name ? 'contained' : 'outlined'}
            onClick={() => setCurrentGraph(name)}
          >
            {name}
          </Button>
        ))}
      </ButtonGroup>
      {currentGraph === 'Clinical' ? <ClinicalGraph /> : <EventsGraph />}
    </Template>
  );
};

export default Graphs;
