import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { debounce } from 'lodash';

import { Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import useUser from 'hooks/useUser';
import { pathwayStepsOptions } from 'utils/enums';
import { normalizeDiseases } from 'utils/forms';
import { StyledTextField as Input } from 'utils/styles';

import {
  FiltersContainer,
  InviteButton,
  PractitionerFilterButton,
  StyledSelect,
} from './PatientListingHeader.styles';

const PatientListingHeader = ({
  filters,
  onFilterChange,
  onFilterReset,
  isGlobal,
}) => {
  const { userDetails } = useUser();
  const formik = useFormik({
    initialValues: filters,
    enableReinitialize: true,
  });

  const diseases = useMemo(
    () => normalizeDiseases(userDetails?.practitioner?.diseases),
    [userDetails],
  );

  const debouncedFilterChange = React.useMemo(
    () => debounce(onFilterChange, 500),
    [onFilterChange],
  );

  return (
    <>
      <FiltersContainer smReverse>
        <Input
          name="search"
          size="small"
          placeholder="Search..."
          value={formik.values.search}
          sx={{ backgroundColor: 'white' }}
          onChange={(e) => {
            formik.handleChange(e);
            debouncedFilterChange(e.target.name, e.target.value);
          }}
        />
        <InviteButton
          variant="contained"
          startIcon={<AddIcon />}
          component={Link}
          to="invite"
        >
          Invite Patient
        </InviteButton>
      </FiltersContainer>
      <FiltersContainer>
        <StyledSelect
          name="activeStatus"
          value={formik.values.activeStatus}
          onChange={({ target }) => onFilterChange(target.name, target.value)}
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ]}
          placeholder="All Statuses"
        />
        <StyledSelect
          name="diseases"
          value={formik.values.diseases}
          onChange={({ target }) => {
            onFilterReset({
              ...formik.values,
              diseases: target.value,
              diseasePathwaySteps: '',
            });
          }}
          options={diseases}
          placeholder="All Disease States"
        />
        <StyledSelect
          name="diseasePathwaySteps"
          placeholder="All Pathway Steps"
          value={formik.values.diseasePathwaySteps}
          onChange={({ target }) => onFilterChange(target.name, target.value)}
          options={pathwayStepsOptions}
          disabled={!formik.values.diseases}
        />
        <Button variant="contained" onClick={() => onFilterReset()}>
          Reset
        </Button>
        {!!isGlobal && (
          <ButtonGroup>
            <PractitionerFilterButton
              selected={Boolean(filters.myPatients)}
              onClick={() => onFilterChange('myPatients', 'true')}
            >
              My Patients
            </PractitionerFilterButton>
            <PractitionerFilterButton
              selected={!filters.myPatients}
              onClick={() => onFilterChange('myPatients', null)}
            >
              All Patients
            </PractitionerFilterButton>
          </ButtonGroup>
        )}
      </FiltersContainer>
    </>
  );
};

PatientListingHeader.propTypes = {
  filters: PropTypes.shape({
    myPatients: PropTypes.string,
  }),
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool,
};

export default PatientListingHeader;
