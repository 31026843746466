import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import MailIcon from '@mui/icons-material/Mail';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

import {
  useECRF,
  useTableState,
  useUser,
  useUserListing,
  useMountEffect,
  useUpdateEffect,
} from 'hooks';
import { useTheme } from 'context/theme';
import { DEFAULT_PAGE_SIZE, USER_GROUPS } from 'utils/constants';
import { formatBirthDate, formatISOString, US_DATE_FORMAT } from 'utils/date';
import { DiseaseEnum, PathwayStepsLabels } from 'utils/enums';

import Template from 'pages/practitioner/template';
import Table from 'components/table';

import PatientListingHeader from './PatientListingHeader';

const columnStyles = [
  { width: '15%' },
  { width: '12%' },
  { width: '10%' },
  { width: '10%' },
  { width: '10%' },
  { width: '10%' },
  { width: '6%' },
  { width: '6%' },
];

const defaultFilters = {
  search: '',
  myPatients: 'true',
  activeStatus: 'active',
  diseases: '',
  diseasePathwaySteps: '',
};

const PatientListing = () => {
  const navigate = useNavigate();
  const { isECRF } = useECRF();
  const { userDetails } = useUser();
  const { palette } = useTheme();
  const { search: onBackParams } = useLocation();
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    filters,
    handleFilterChange,
    clearTableState,
  } = useTableState('UnreadChatMessages', 'Asc', defaultFilters);
  const { getUsers, users, isLoading, errorMessage, pageCount } =
    useUserListing();

  const listingFilters = React.useMemo(() => {
    const { myPatients, activeStatus, ...moreFilters } = filters;
    const active = activeStatus ? String(activeStatus === 'active') : undefined;

    const assignedPractitioners = !isECRF && myPatients ? userDetails.id : null;

    return {
      roles: [USER_GROUPS.patient],
      sort: sortBy,
      sortDirection: sortOrder,
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE,
      assignedPractitioners: assignedPractitioners,
      active,
      ...moreFilters,
    };
  }, [filters, isECRF, userDetails.id, sortBy, sortOrder, page]);

  const fetchList = React.useCallback(() => {
    getUsers(listingFilters);
  }, [getUsers, listingFilters]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [listingFilters]);

  const patientTableAttributes = React.useMemo(
    () => [
      { title: 'Last Name', id: 'LastName', handleClick: handleSortChange },
      { title: 'First Name', id: 'FirstName', handleClick: handleSortChange },
      { title: 'DOB', id: 'Dob', handleClick: handleSortChange },
      {
        title: 'Last Visit',
        id: 'LastVisitAt',
        handleClick: handleSortChange,
      },
      {
        title: 'Messages',
        id: 'UnreadChatMessages',
        handleClick: handleSortChange,
      },
      { title: 'Step', id: 'step', handleClick: handleSortChange },
      { title: 'Compliance', id: 'compliance', handleClick: handleSortChange },
      { title: 'Status', id: 'status', handleClick: handleSortChange },
    ],
    [handleSortChange],
  );

  const handleRowClick = (patientId) =>
    navigate(`${patientId}/home`, { state: { onBackParams } });

  const findValue = (diseases, type) => {
    const disease = diseases?.find(
      ({ disease }) => disease === DiseaseEnum.VLU,
    );
    if (disease == null) return '-';
    if (type === 'step') {
      return PathwayStepsLabels[disease.diseasePathwayStep];
    } else {
      const value =
        type === 'status'
          ? disease?.diseaseStatusColor
          : disease?.diseasePathwayAdherenceColor;
      return (
        <Box
          key={value}
          width="1rem"
          height="1rem"
          borderRadius="50%"
          style={{ backgroundColor: value }}
        ></Box>
      );
    }
  };

  const rows = React.useMemo(
    () =>
      users?.map(
        ({
          id,
          lastName,
          firstName,
          birthDate,
          haveNewMessages,
          lastVisitAt,
          patient: { diseases },
        }) => ({
          id,
          data: [
            lastName,
            firstName,
            formatBirthDate(birthDate),
            formatISOString(lastVisitAt, US_DATE_FORMAT) || '-',
            haveNewMessages ? (
              <MarkunreadMailboxIcon sx={{ color: palette.primary.main }} />
            ) : (
              <MailIcon sx={{ color: palette.grey[500] }} />
            ),
            findValue(diseases, 'step'),
            findValue(diseases, 'compliance'),
            findValue(diseases, 'status'),
          ],
        }),
      ) ?? [],
    [users, palette],
  );

  return (
    <Template>
      <PatientListingHeader
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterReset={clearTableState}
        isGlobal={userDetails?.practitioner?.global}
        handleRefresh={() => getUsers(listingFilters)}
      />
      <Table
        header={patientTableAttributes}
        page={page}
        rows={rows}
        error={errorMessage}
        isLoading={isLoading}
        totalPages={pageCount}
        handlePageChange={handlePageChange}
        handleRowClick={handleRowClick}
        orderBy={sortBy}
        orderDirection={sortOrder}
        columnStyles={columnStyles}
      />
    </Template>
  );
};

export default PatientListing;
