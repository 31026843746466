import React, { useMemo, useEffect } from 'react';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';
import { compareAsc, format, subMonths } from 'date-fns';
import { useParams } from 'react-router-dom';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryScatter,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import { useTheme } from 'context/theme';
import { formatISOString, US_DATE_FORMAT } from 'utils/date';
import { getWoundName } from 'utils/woundImages';
import { getSimpleGraphLabel } from 'components/widgets/utils';

import VluImageDisplay from 'pages/practitioner/patients/details/dashboard/sections/care-track/vlu-images/vlu-image-display';

const Clinical = () => {
  const { patientId } = useParams();
  const { palette } = useTheme();

  const timeInterval = useMemo(() => {
    return {
      since: subMonths(new Date(), 3),
      until: new Date(),
      tickFormat: 'MMM',
    };
  }, []);

  const { data: woundsData, doRequest: getWounds } = useRequest(
    api.getClinical,
  );

  const wounds = woundsData?.data;

  const woundsFiltered = useMemo(
    () => wounds?.filter(({ woundSizes }) => woundSizes?.length > 0),
    [wounds],
  );

  useEffect(() => {
    if (!timeInterval) return;
    getWounds({
      PatientId: patientId,
      DateFrom: timeInterval.since.toISOString(),
      DateTo: timeInterval.until.toISOString(),
    });
  }, [timeInterval, getWounds, patientId]);

  const woundSizes = useMemo(() => {
    const woundSizesObj = {};
    const datesCheck = {};

    woundsFiltered?.forEach(({ wound }) => {
      woundSizesObj[wound.woundId] = {
        points: [],
        name: getWoundName(wound.wound),
      };
    });

    woundsFiltered?.forEach(({ wound, woundSizes }) => {
      woundSizes.forEach(({ size, timestamp }) => {
        woundSizesObj[wound.woundId].points.push({
          x: new Date(timestamp),
          y: size,
        });
        const keyDate = `${wound.woundId}-${timestamp}`;
        if (!isUndefined(datesCheck[keyDate])) {
          woundSizesObj[wound.woundId].points.splice(datesCheck[keyDate], 1);
        }
        datesCheck[keyDate] = woundSizesObj[wound.woundId].points.length - 1;
        orderBy(woundSizesObj[wound.woundId].points, ['x'], ['asc']);
      });
    });

    woundsFiltered?.forEach(({ wound, woundSizes }) => {
      woundSizes.forEach(({ timestamp }) => {
        Object.keys(woundSizesObj).forEach((key) => {
          if (key !== wound.woundId) {
            const keyDate = `${key}-${timestamp}`;
            if (isUndefined(datesCheck[keyDate])) {
              const index = woundSizesObj[key].points.findIndex(
                ({ x }) => compareAsc(new Date(timestamp), x) === -1,
              );
              const idx =
                index === -1 ? woundSizesObj[key].points.length : index;
              woundSizesObj[key].points.splice(idx, 0, {
                x: new Date(timestamp),
                y: index !== 0 ? woundSizesObj[key].points[idx - 1]?.y : null,
                size: 0.1,
              });
              datesCheck[keyDate] = woundSizesObj[key].points.length - 1;
            }
          }
        });
      });
    });

    return woundSizesObj;
  }, [woundsFiltered]);

  const getPercentageColor = (percentage) => {
    if (!percentage) return palette.base.black;
    if (percentage > 0) {
      return palette.error.main;
    } else {
      return palette.success.main;
    }
  };

  return (
    <>
      <VictoryChart
        theme={VictoryTheme.material}
        height={180}
        padding={{ left: 40, top: 10, bottom: 25 }}
        domainPadding={{ y: 10 }}
        domain={{ x: [timeInterval?.since, timeInterval?.until] }}
        scale={{ x: 'time', y: 'linear' }}
      >
        <VictoryStack colorScale={palette.chartColorScale}>
          {Object.keys(woundSizes).map((key) => (
            <VictoryGroup key={key} data={woundSizes[key].points}>
              <VictoryArea
                style={{
                  data: {
                    stroke: palette.base.black,
                    strokeWidth: 1,
                  },
                }}
              />
              <VictoryScatter
                style={{ data: { fill: palette.base.black } }}
                labels={getSimpleGraphLabel}
                labelComponent={
                  <VictoryTooltip
                    orientation="bottom"
                    centerOffset={{ y: 5 }}
                    pointerLength={0}
                  />
                }
              />
            </VictoryGroup>
          ))}
        </VictoryStack>
        <VictoryAxis
          crossAxis={false}
          dependentAxis
          fixLabelOverlap
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fontSize: '8px' },
            grid: { stroke: 'none' },
          }}
          tickFormat={(tick) => {
            if (!tick || (tick && tick > 0 && tick < 0.1)) {
              return '';
            } else if (tick === 0) {
              return tick;
            }
            return `${tick} cm²`;
          }}
        />
        <VictoryAxis
          key="xAxis"
          crossAxis={false}
          animate={false}
          fixLabelOverlap
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fontSize: '8px' },
            grid: {
              stroke: palette.grey[700],
              strokeOpacity: 0.2,
              strokeDasharray: '0',
            },
          }}
          tickFormat={(t) => format(new Date(t), timeInterval?.tickFormat)}
        />
      </VictoryChart>
      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        {wounds?.map(({ wound }, index) => {
          const woundName = getWoundName(wound.wound);
          const getWoundColor = () => {
            return (
              palette.chartColorScale[
                woundsFiltered.findIndex(
                  (woundData) => woundData?.wound?.woundId === wound.woundId,
                )
              ] || palette.grey[300]
            );
          };
          return (
            <Grid key={`${woundName}-${index}`} item md={6} xs={12}>
              <Box
                border={`1px solid ${palette.grey[300]}`}
                borderRadius={1}
                padding={1}
                height="100%"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  fontWeight="bold"
                  mb={1}
                >
                  <Box
                    height="1rem"
                    width="1rem"
                    minWidth="1rem"
                    mr={1}
                    borderRadius="50%"
                    style={{ backgroundColor: getWoundColor() }}
                  />
                  <span>{woundName}</span>
                </Box>
                <Box display="flex" gap={2}>
                  <VluImageDisplay
                    url={wound?.lastWoundImage?.asset?.url}
                    maskedUrl={wound?.lastWoundImage?.maskedAsset?.url}
                    markerUrl={wound?.lastWoundImage?.markerAsset?.url}
                    size="small"
                  />
                  <Box>
                    <Box display="flex" flexDirection="column" mb={1}>
                      <span>
                        {`Last measure:
                    ${
                      wound.lastWoundSize ? wound.lastWoundSize.toFixed(2) : '-'
                    } cm²`}
                      </span>
                      <span
                        style={{
                          fontSize: '0.75rem',
                          color: palette.grey[700],
                        }}
                      >
                        {formatISOString(
                          wound.lastMeasurementDate,
                          US_DATE_FORMAT,
                        ) || '-'}
                      </span>
                    </Box>
                    <Box display="flex" flexDirection="column" mb={1}>
                      <span>
                        30 day change:{' '}
                        <span
                          style={{
                            color: getPercentageColor(
                              wound.measurement30DaysChangePercentage,
                            ),
                          }}
                        >
                          {wound.measurement30DaysChangePercentage
                            ? wound.measurement30DaysChangePercentage.toFixed(2)
                            : '-'}
                          %
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: '0.75rem',
                          color: palette.grey[700],
                        }}
                      >
                        {`${
                          formatISOString(
                            wound.measurementDateFrom,
                            US_DATE_FORMAT,
                          ) || '-'
                        } to ${
                          formatISOString(
                            wound.measurementDateTo,
                            US_DATE_FORMAT,
                          ) || '-'
                        }`}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Clinical;
