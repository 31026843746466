import * as actionTypes from './actionTypes';

export const initialState = {};

const cachedImages = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_CACHED_IMAGE: {
      return {
        ...state,
        [action.payload.path]: {
          url: action.payload.url,
          cachedAt: new Date(),
        },
      };
    }
    default:
      return state;
  }
};

export default cachedImages;
