import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'core/api';
import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';

import FormWrapper from 'components/forms/form-wrapper';

import WoundSizeForm from './WoundSizeForm';
import { woundSizeSchema } from './utils';

export const NewWoundSize = ({
  handleCancel,
  handleRefresh,
  handleAddWound,
}) => {
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const [submiting, setSubmiting] = useState(false);
  const { patientId } = useParams();
  const { getUser } = useUserDetails();

  const handleSubmit = async (data) => {
    clearFeedback();
    try {
      setSubmiting(true);
      if (!submiting)
        await api.createWoundSize({
          ...data,
          timestamp: new Date(data.timestamp).toISOString(),
        });
      getUser(patientId);
      setSuccessFeedback('Added with success');
      if (handleRefresh) handleRefresh();
      handleCancel();
      setSubmiting(false);
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  const initialValues = {
    woundId: '',
    size: 0,
    timestamp: new Date().toString(),
  };

  return (
    <FormWrapper
      initialValues={initialValues}
      validationSchema={woundSizeSchema}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      {({ formik }) => (
        <WoundSizeForm handleAddWound={handleAddWound} formik={formik} />
      )}
    </FormWrapper>
  );
};

NewWoundSize.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleAddWound: PropTypes.func.isRequired,
};

export default NewWoundSize;
