import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import useAccount from 'hooks/useAccount';
import useUser from 'hooks/useUser';
import { headerRoleFromRootPath } from 'navigation/paths';
import { userGroupsLabels } from 'utils/constants';

import Loader from 'components/loader';

import { Title, SubTitle } from '../AppAuth.styles';

import { AccountLogo, Card, Section } from './RolesDashboard.styles';

export default function RolesDashboard() {
  const navigate = useNavigate();
  const { isLoading, userGroups } = useUser();
  const { accountLogoUrl } = useAccount();
  const [selectedPath, setSelectedPath] = React.useState(null);

  const onRoleSelect = React.useCallback(
    () => navigate(selectedPath),
    [navigate, selectedPath],
  );

  React.useEffect(() => {
    if (!userGroups.length) return;
    const found = Object.entries(headerRoleFromRootPath).find(([, key]) =>
      userGroups.find((userGroup) => userGroup === key),
    );
    if (!found) return;
    const [foundPath] = found;
    if (userGroups.length === 1) {
      navigate(foundPath);
      return;
    }
    setSelectedPath(foundPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroups]);

  return (
    <Section>
      <Card>
        {accountLogoUrl && (
          <AccountLogo src={accountLogoUrl} alt="Account Logo" />
        )}
        <Title>Disease State Management Portal</Title>
        <SubTitle>Brought to you by Impact Health</SubTitle>
        {isLoading ? (
          <Loader />
        ) : (
          <FormControl component="fieldset" style={{ marginBottom: '2rem' }}>
            <RadioGroup
              name="useRole"
              value={selectedPath}
              onChange={({ target }) => setSelectedPath(target.value)}
            >
              {Object.entries(headerRoleFromRootPath).map(
                ([path, key]) =>
                  userGroups?.includes(key) && (
                    <FormControlLabel
                      key={key}
                      value={path}
                      label={userGroupsLabels[key]}
                      control={<Radio />}
                    />
                  ),
              )}
            </RadioGroup>
          </FormControl>
        )}
        <Button
          variant="contained"
          onClick={onRoleSelect}
          disabled={isLoading || !selectedPath}
          fullWidth
        >
          Sign In
        </Button>
      </Card>
    </Section>
  );
}
