import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AppAuth from './AppAuth';
import { PUBLIC_ROUTES } from './routes';

const AppSwitch = () => (
  <Routes>
    {PUBLIC_ROUTES.map((routeProps) => (
      <Route key={routeProps.path} {...routeProps} />
    ))}
    <Route path="*" element={<AppAuth />} />
  </Routes>
);

export default AppSwitch;
